import { default as React, useContext, FormEvent, useCallback, useState, useRef, useEffect, useReducer, useMemo } from 'react';
import { BrowserRouter, HashRouter, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import {  ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {blue,red,green} from '@material-ui/core/colors';
import { GlobalContext, GlobalI, setGlobalContext } from "./ui/withGlobalContext";
import { ScrollToTop } from "./ui/ScrollToTop";

import {Landing} from "./page/Landing";
import {NotFound} from "./page/NotFound";
import {Loading} from "./page/Loading";
import { Project } from './lib/Project';
import { TaskView } from './page/TaskView';
import { JobView } from './page/JobView';
import { TesterSync } from './page/TesterSync';

import { Settings } from './page/Settings';
import { Datastore } from './lib/Datastore';
import { CSV_Import } from './page/CSV_Import';
import { EntityView } from './page/EntityView';
import { Paperwork } from './page/Paperwork';
import { NativePaperwork } from './page/NativePaperwork';
import { Login } from './page/Login';
import { Logout } from './page/Logout';
import { DatastoreSync } from './page/DatastoreSync';
import { Admin } from './page/Admin';
import { TestResultImport } from './page/TestResultImport';
import { TestResultSyncLinks } from './page/TestResultSyncLinks';
import { ListView } from './page/ListView';

import { ErrorBoundary } from './page/ErrorBoundary';

// import { App as CapacitorApp } from '@capacitor/app';
import { RequestView } from './page/RequestView';
import { ErrorView } from './page/ErrorView';
import { ImageView } from './page/ImageView';
import { CreateDocument } from './page/CreateDocument';
import { DocumentView } from './page/DocumentView';
import { EntityCreate } from './page/EntityCreate';

import {Loader, LoaderOptions} from 'google-maps';
import { EventView } from './page/EventView';
import { FormTemplateEditor } from './page/form-template-editor';
import { FormView } from './page/form-view';
import { BatchReportExporter } from './page/batch-report-exporter';
import { BatchTester } from './page/batch-tester';
import { CsvExporter } from './page/csv-exporter';
import { ProfitLossAnalysis } from './page/profitLossAnalysis';


type AppRoute = {
  path:string;
  render:(props:any)=>JSX.Element;
  session:boolean;
}

const theme = responsiveFontSizes(createTheme({
  palette: {
      primary: blue,
      secondary: red,
      success: green
  },
}))

export default function App() {

  const globalRef = useRef<GlobalI|null>(null);
  const [, updateState] = React.useState<any>();
  const forceUpdate = useCallback(() => updateState({}), []);

  //const loaded = true;

  useEffect( () => {
    setup();
  },[]);
  
  
  
  async function setup(){
    
    const project = new Project("Geometer")
    const ds = new Datastore(forceUpdate);
    (window as any).ds = ds;
    await ds.init();

    //const dsm = new DatastoreWorkerManager();


    const options: LoaderOptions = {/* todo */};
    const loader = new Loader('AIzaSyAD3BeJdrP2eRTJ4Sxx1SbSCr7Psl06h0s', options);
    const google = await loader.load();


    const global:GlobalI = {
      theme,
      project,
      ds,
      google,
      //dsm,
      ui: {}
    };    
    setGlobalContext(global);
    globalRef.current = global;
    forceUpdate();




    // CapacitorApp.addListener('backButton', ({canGoBack}) => {
    //   if( ["/list/Task","/list/Job","testerSync","/settings"].indexOf(window.location.pathname) != -1){
    //     CapacitorApp.exitApp();
    //   } else {
    //     window.history.back();
    //   }
    // });


  }
  
  if(globalRef.current == null)
    return null;



  const routes:AppRoute[] = [
    {path:'/', render:Landing, session:true},
    {path:'/list/:type', render:ListView, session:true},
    {path:'/task/:id', render:TaskView, session:true},
    {path:'/job/:id', render:JobView, session:true},
    {path:'/testerSync', render:TesterSync, session:true},
    {path:'/settings', render:Settings, session:true},
    {path:'/entity/:id', render:EntityView, session:true},
    {path:'/paperwork/:id', render:Paperwork, session:true},
    {path:'/nativePaperwork/:id', render:NativePaperwork, session:true},
    {path:'/csv_import', render:CSV_Import, session:true},
    {path:'/datastore_sync', render:DatastoreSync, session:true},
    {path:'/admin', render:Admin, session:true},
    {path:'/admin/create_entity', render:EntityCreate, session:true},
    {path:'/test_result_import', render:TestResultImport, session:true},
    {path:'/test_result_sync_links', render:TestResultSyncLinks, session:true},
    {path:'/form-template-editor', render:FormTemplateEditor, session:true},    
    {path:'/batch-report-exporter', render:BatchReportExporter, session:true},  
    {path:'/csv-exporter', render:CsvExporter, session:true},    
    {path:'/batch-tester', render:BatchTester, session:true},    
    {path:'/job/:id/form', render:FormView, session:true},    
    {path:'/request/:id', render:RequestView, session:true},
    {path:'/image/:id', render:ImageView, session:true},
    {path:'/error/:id', render:ErrorView, session:true},
    {path:'/createDocument', render:CreateDocument, session:true},
    {path:'/document/:id', render:DocumentView, session:true},
    {path:'/event/:id', render:EventView, session:true},
    {path:'/profit-loss-analysis', render:ProfitLossAnalysis, session:true},
    {path:'/login', render:Login, session:false},
    {path:'/logout', render:Logout, session:false},
    {path:'/*', render:NotFound, session:true}
  ];


  let view;

  if(globalRef.current && globalRef.current.ds.initialised){
    view = routes.map( route => {
        return <Route key={route.path} exact path={route.path} render={(props:any) => {
          if( !route.session || (route.session && globalRef.current && globalRef.current.ds.session != null)){
            return React.createElement(route.render,props);
          }else{
            return <Redirect key={route.path} to="/login" />
          }
        }}  />
    });
  }else{
    view  = <Loading key="Loading" />
  }
 
  return <ThemeProvider theme={theme}>
    <CssBaseline />
    <GlobalContext.Provider value={globalRef.current}>
      <BrowserRouter >
      <ScrollToTop>
    <ErrorBoundary {...globalRef.current} >
        <Switch>
          {view}
        </Switch>
    </ErrorBoundary>
      </ScrollToTop>
    </BrowserRouter >
  </GlobalContext.Provider>
  </ThemeProvider>

}


