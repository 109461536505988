import React, { useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent} from "@material-ui/core";

import {Header} from '../../ui/Header'



import { ErrorData } from '../ListView/Filters';
import { CssStyles } from '../../css-types';


export function ErrorView(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

	const styles:CssStyles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
        main:{
            paddingLeft:20,
            paddingRight:20
        },
        loading:{
          marginTop:64,
          textAlign:"center"
        }
	}

    
    const id = props.match.params.id;
    const type:string = props.match.params.type;

    const [request,setRequest] = useState<ErrorData|null>(null);


  async function load(){
      const f = await ds.lp.get("Errors");//await ds.getFilter("Errors")
      setRequest(f.collection[id]);
  }

    useEffect(() => {
      load();
    },[id]);

	return <div style={styles.root} key={"error-"+id} > 	
            <Header title={"Error "+id} back={true} />

            {(request==null)?<div style={styles.loading}>Loading</div>:<Card>
                <CardContent>
                <pre>
                {request.error}
                </pre>
                    
                    <pre>{JSON.stringify(request,undefined,3)}</pre>
                </CardContent>
            </Card>}
                
        </div>
    
}

