import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem, Button} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, SelectField, TaskStatusField, TechField, Timefield } from '../Fields';
import { SdCard, Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';

export interface TaskCardDebugI{
    value:Task
}

export function TaskCardDebug(props:TaskCardDebugI){
    const {value} = props;
    const task = value;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {};

    (window as any).task = value;


    async function clearTestResults(){
        task.clearResults();
    }


  


    return <Card>
        <CardContent>
            <Typography variant="h6" >Task</Typography>
        </CardContent>
        <Divider/>

        <CardContent>
            <EntityField label="Ignore Validation" name="ignoreValidation" type="checkbox" entity={value} />

            <SelectField label="Job" name="jobID" value={value} filter={{type:"Job"}} /> 
       </CardContent>


       <CardContent>
            <pre>Is Pending: {value.isPending() ? "true" : "false"}</pre>
            <pre>Is Mine: {value.forMe() ? "true" : "false"}</pre>
            <pre>v.forMe() and v.isPending() = {value.forMe() && value.isPending() ? "true" : "false"}</pre>
            <pre>{ds.db.getMe().techID}</pre>
            
            <Button color="secondary" onClick={clearTestResults}>Clear Test Results</Button>

       </CardContent>
    </Card>
}


/*
   <EntityField label="Model" name="model" value={value.model} entity={value} />
        <EntityField label="Model" name="serialNumber" value={value.serialNumber} entity={value} />
        <EntityField label="NATA Reference" name="nataReference" value={value.nataReference} entity={value} />
        <EntityField label="Last Calibration Date" name="lastCalibrationDate" value={value.lastCalibrationDate} entity={value} type="date" InputLabelProps={{shrink: true }} />
        <EntityField label="Next Calibration Date" name="nextCalibrationDate" value={value.nextCalibrationDate} entity={value} type="date" InputLabelProps={{shrink: true }} />
  */