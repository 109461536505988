import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem, Button, Box, IconButton} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, SelectField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Settings, Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';
import { Document } from '../../lib/class/Document';
import { downloadBlobFile } from '../../lib/download';
import { VirtualFile } from '../../lib/class/VirtualFile';
import useRouter from 'use-react-router';
import { Tech } from '../../lib/class/Tech';
import { User } from '../../lib/class/User';

export interface UserCardI{
    value:User
}

export function UserCard(props:UserCardI){
    const {value} = props;
    const tech = value;

    const router = useRouter();

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds,google } = global;

    const styles = {};

   


    return <Card>
        <CardContent>  
            
        <EntityField label="First Name" name="firstName" type="string" entity={value} />
        <EntityField label="Last Name" name="lastName" type="string" entity={value} />
        <EntityField label="Email" name="email" type="string" entity={value} />
        <EntityField label="Password" name="password" type="string" entity={value} />
        <EntityField label="admin" name="admin" type="checkbox" entity={value} />
        <SelectField label="Tech" name="techID" value={value} filter={{type:"Tech"}} />   
    
        
    
     </CardContent>
    </Card>
}


    // id: number;
    // type: string;
    // deleted:boolean;
    // firstName: string;
    // lastName: string;
    // email:string;
    // password:string|null;
    // admin:boolean;
    // techID:number|null;