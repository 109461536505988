import React,{ useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {CircularProgress} from "@material-ui/core";

import {Header} from '../../ui/Header'



import { VirtualFile } from '../../lib/class/VirtualFile';


export function ImageView(props:any){
    
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
        main:{
            paddingLeft:20,
            paddingRight:20
        }
	}

    const [imageData,setImageData] = useState("");
    const [imageDataLoading,setImageDataLoading] = useState(true);

    const id = props.match.params.id;
    const file = ds.db.get(id) as VirtualFile;

    async function loadImage(){
        const image = await ds.getImageBase64(file);
        setImageData(image);
        setImageDataLoading(false);
        
    }

    useEffect( () => {
        loadImage();
    }, [file.id]);


	return <div style={styles.root} key={"entityview"+id} > 	
            <Header title={"Image "+file.id} back={true} />
            <div style={styles.main}>
                {imageDataLoading?<CircularProgress color="primary" />:null}
                {imageData!=""?<img src={imageData} />:null}
                
            <br/>

          
        </div>
    </div>
    
}

