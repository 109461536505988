import React, { useContext, useEffect, useReducer } from 'react';
import useRouter from 'use-react-router';
import {GlobalContext} from '../../ui/withGlobalContext';

import { Card, CardContent, Divider, Typography, Box, IconButton} from "@material-ui/core";
import { Task } from '../../lib/class/Task';
import { ReadOnlyField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Warning,Settings as SettingsIcon } from '@material-ui/icons';

export interface TaskCardI{
    value:Task
}

export function TaskCard(props:TaskCardI){
    const {value} = props;
    const task = value;

    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {};
    const issue = task.getIssue();

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"*",forceUpdate),[]);

    return <Card>
        <CardContent>

        <Box display="flex" p={1} >
            <Box flexGrow={1}><Typography variant="h6" >Task</Typography></Box>
            {ds.db.getMe().isSudo()?<Box><IconButton onClick={() => router.history.push("/entity/"+value.id)} ><SettingsIcon /></IconButton> </Box>:null}
        </Box>
                
        </CardContent>
        <Divider/>
        <CardContent>
            <ReadOnlyField label="Task Type" value={value.getType().toString()}  />
            <TechField name="techID" label="Assigned Technician" task={task} />
            <Timefield label="Schedule" task={task} name="scheduleTimestamp"   />
            {task.startTimestamp != null?<Timefield label="Start Time" task={task} name="startTimestamp"  /> :null}
            {task.endTimestamp != null?<Timefield label="End Time" task={task} name="endTimestamp"  /> :null}
            <TaskStatusField name="status" label="Task Status" task={task} />
            {issue.length>0? <ReadOnlyField label="Current Issue" error value={issue} InputProps={{endAdornment:<Warning style={{color:theme.palette.warning.main}}  />}} />:null}
        </CardContent>
    </Card>
}
