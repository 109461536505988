import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import { Card, CardContent, Divider, Typography} from "@material-ui/core";
import { Task, TaskStatus } from '../../lib/class/Task';

import {DataField, TaskStatusField, Timestampfield} from "../Fields";
import { TaskDurationField } from './TestingForm2021';

interface LabourFormI{
    task:Task;
}

export function LabourForm(props:LabourFormI){
    const {task} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;



    const job = task.getJob();
    const site = job.getSite();




    let showForm = false;
    if(task.status == TaskStatus.Pending || task.status == TaskStatus.Successful)
    showForm = true;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"status",forceUpdate),[]);
    

    

    return <div>
        <Card>
            <CardContent>
                <Typography variant="h6">Labour Form</Typography>
            </CardContent>
            <Divider/>

            <CardContent>
            <Typography variant="subtitle1">Task start information:</Typography>
            {task.status != TaskStatus.Cancelled? <CardContent>
            <Typography variant="subtitle1">Task start information:</Typography>
            <Timestampfield task={task} label="Task Start" name="startTimestamp" preValue={task.scheduleTimestamp} />
            </CardContent>:null }
               </CardContent>

            <CardContent>
                <Typography variant="subtitle1">Please complete:</Typography>
                <DataField task={task} label="Comments" field={"Comments"} />

            </CardContent>


            <CardContent>  
                <Typography variant="subtitle1">Finalise task:</Typography>
               <TaskStatusField name="status" label="Task Status" task={task} />
                {task.status != TaskStatus.Cancelled? <Timestampfield task={task} label="Task End" name="endTimestamp"  preValue={task.startTimestamp} /> :null}
       
                <TaskDurationField task={task} />      
            </CardContent>


        </Card>
    </div>
}