import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {TextField} from "@material-ui/core";
import { Base } from '../../lib/class/Base';


export interface SelectFieldI{
    label?:string;
    name:string;
    filter:any;
    value:Base;
}



export function SelectField(props:SelectFieldI){
        const {name, value, label, filter} = props;

        const global = useContext(GlobalContext);
        const { theme, project, ui, ds } = global;

        async function change(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
            const key = event.target.name;
            const v:number|null = parseInt(event.target.value);
            // if(v == "")
                // v = null;

            const d:any = {};
            d[key] = v;
            await ds.updateEntity(value,d);
        //    await ds.forceSave();
        }





    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,name,forceUpdate),[]);


    const options = ds.db.filter(filter).sort((a,b) => a.toString().localeCompare(b.toString()) );
    
    let v:any = (value as any)[name];
    if(v == null)
        v = "";
    return  <TextField select name={name} label={label} value={v} margin="normal" fullWidth onChange={(e) => change(e)} SelectProps={{native: true}} InputLabelProps={{shrink: true}}>
        {options.map(option => <option key={option.id} value={option.id}>{option.toString()}</option> )}
        <option value=""></option>
    </TextField>

}
