import { default as React, useContext, useEffect, useReducer } from 'react';
import { Badge, Box, CircularProgress, Divider, IconButton, Typography } from "@material-ui/core";
import { Check, Clear, Sync, Warning } from "@material-ui/icons";
import { GlobalContext } from './withGlobalContext';
import useReactRouter from 'use-react-router';
import { DatastoreTask } from '../lib/DatastoreTask';





export function DatastoreBadge(){
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const router = useReactRouter();

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    const syncTask = ds.getTask("sync");
    useEffect(() => syncTask.sub(0,"*",forceUpdate),[]);




    const badgeContent = !ds.isSyncing() ? ds.myEvents.length : 0; 

    let icon:React.ReactElement;
    
    if(!ds.autoSync){
        icon = <Clear  style={{color:theme.palette.error.main}} />
    }else if(ds.syncError.length > 0){
        icon = <Warning  style={{color:theme.palette.warning.main}} />
    } else if(ds.isSyncing()){
        icon = <CircularProgress size={30} color="primary" />
    }else if(ds.requiresSync()){
        icon =  <Sync style={{color:theme.palette.info.main}} />
    }else{
        icon = <Check style={{color:theme.palette.success.main}} />
    }


    const styles = {
        box:{
            display:"flex"
        },
        divider:{
            marginRight:15
        }
    }


    function nav(link:string){
        router.history.push(link)
    }

    let debugPanel:React.ReactElement|null = null;

    if(ds.debug){
        const tasks = ds.getTasks();
        debugPanel = <Box style={styles.box}>
            {tasks.map(task => <TaskProgress key={task.key} size={30} task={task} />)}
            <Divider orientation="vertical" flexItem style={styles.divider}/>
        </Box>
    }


    return <React.Fragment>
        {debugPanel}
        <IconButton size="small"  onClick={() => nav("/datastore_sync") } >
            <Badge color="secondary" badgeContent={badgeContent}>{icon}</Badge>
        </IconButton>
    </React.Fragment>



}


interface TaskProgressI{
    task:DatastoreTask;
    size?:number;
}

function TaskProgress(props:TaskProgressI){
    const {task} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => task.sub(0,"*",forceUpdate),[]);

    const styles = {
        box:{
            marginRight:30
        }
    };

    let content:React.ReactElement|null = null;

    if(task.isRunning())
        content = <CircularProgress style={{color:theme.palette.warning.main }}   variant="indeterminate" {...props}  />  //<DirectionsRun style={{color:theme.palette.info.main}} />
    else if(task.shouldRun())
        content = <CircularProgress variant="determinate" {...props} value={task.getValue()} />
    else
        content =  <CircularProgress style={{color:theme.palette.success.main }}   variant="determinate" {...props} value={100} /> ///<Check style={{color:theme.palette.success.main}} />


        return <Box position="relative" display="inline-flex" style={styles.box}>
            {content}
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                <Typography  variant="caption" component="div" color="textSecondary">{`${task.key}`}</Typography>
            </Box>
        </Box>

}