import React,{ MouseEvent, useContext, useMemo, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Box, Button, Card, CardContent, Grid} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { SvgEllipse, SvgEllipseForm, SvgEllipseRender } from './items/ellipse';
import { SvgText, SvgTextForm, SvgTextRender } from './items/text';

import image from '../../forms/ct-testing.svg';
import template from '../../forms/ct-testing.json';


type SvgItem = SvgText | SvgEllipse;


export function FormTemplateEditor(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    
	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
        page:{
            position:"relative"
        },
        col:{
            display:'inline-block'
        },
        box:{
            display:'flex',
            userSelect:"none"
        }
	} as const

    const [svgRef,setSvgRef] = useState<SVGSVGElement|null>(null);
    const offset = useMemo(() => svgRef ? svgRef.getBoundingClientRect() : null,[svgRef])
    const [items,setItems] = useState<SvgItem[]>(template as any);

    const [drag,setDrag] = useState(false);
    const startDrag = (i:number) => {
        setFocusedItemIndex(i);
        setDrag(true);
    }
    const stopDrag = () => setDrag(false);

    const [focusedItemIndex,setFocusedItemIndex] = useState(-1);
    const stopFocus = () => setFocusedItemIndex(-1);

    const focusedItem = useMemo(() => focusedItemIndex === -1 ? null : items[focusedItemIndex], [focusedItemIndex,items])



    function moveMouse(e:MouseEvent){
        if(!drag) return;
        if(focusedItem === null) return;
        if(offset === null) return;

        const item = {...focusedItem};
        item.x = e.clientX - offset.left + window.scrollX ;
        item.y = e.clientY - offset.top + window.scrollY;
        updateFocusedItem(item);
    }

    function updateFocusedItem(item:SvgItem){
        items[focusedItemIndex] = item;
        setItems([...items]);
    }

    function removeFocusedItem(){
        const cloned = [...items]
        cloned.splice(focusedItemIndex,1);
        setItems(cloned);
        setFocusedItemIndex(-1);
    }

    function change(item:SvgItem|null){
        if(item === null){
            removeFocusedItem();
        }else{
            updateFocusedItem(item);
        }
    }

    function createEllipse(){
        const e:SvgEllipse = {type:"ellipse",key:"test",x:50,y:50,width:10,height:5};
        const i = [...items];
        i.push(e);
        setItems(i);
        setFocusedItemIndex(i.length-1);
    }
    function createText(){
        const e:SvgText ={type:"text",key:"test2",x:10,y:20,size:12};
        const i = [...items];
        i.push(e);
        setItems(i);
        setFocusedItemIndex(i.length-1);
    }

   
	return <div style={styles.root} key={"create"} onMouseUp={stopDrag} > 	
            <Header title={"Form Template Editor"} back={true} />
            <div style={styles.box}>
                <div style={styles.col}>
                    <Card>
                        <CardContent>
                            <div style={styles.page}>
                                <svg ref={setSvgRef} aria-hidden="true" width="816" height="1056" onMouseMove={moveMouse} >
                                    <image x="0" y="0"  href={image} />
                                    {items.map((item,i) => <SvgItemRender key={i} value={item} onMouseDown={() => startDrag(i)} selected={i===focusedItemIndex} />)}
                                </svg>

                            </div>
                        </CardContent>
                    </Card>
                </div>

                <div style={styles.col}>
                    {focusedItem && <Card>
                        <CardContent>
                            <SvgItemForm value={focusedItem} onChange={change} onRemove={removeFocusedItem} />
                        </CardContent>
                    </Card>}

                    <Card>
                        <CardContent>
                            <Button color="primary" onClick={createText}>Create Text</Button>
                            <Button color="primary" onClick={createEllipse}>Create Ellipse</Button>
                        </CardContent>
                    </Card>
                </div>
            </div>

            <div>
                <Card>
                    <CardContent>
                        <pre>{JSON.stringify(items,undefined,3)}</pre>
                    </CardContent>
                </Card>
            </div>

            <div>
                <Card>
                    <CardContent>
                        <pre>const texts = {JSON.stringify(items.filter(i => i.type === 'text').map(i => i.key),undefined,3)}</pre>
                    </CardContent>
                </Card>
            </div>

            <div>
                <Card>
                    <CardContent>
                    <pre>const ellipses = {JSON.stringify(items.filter(i => i.type === 'ellipse').map(i => i.key),undefined,3)}</pre>
                    </CardContent>
                </Card>
            </div>
        

        </div>  
    
}

interface SvgItemRenderI{
    value:SvgItem;
    selected:boolean;
    onMouseDown:()=>void;
}

function SvgItemRender(props:SvgItemRenderI){
    const {value, selected, onMouseDown} = props;

    if(value.type === 'ellipse')
        return <SvgEllipseRender value={value} onMouseDown={onMouseDown} selected={selected} />

    if(value.type === 'text')
        return <SvgTextRender value={value} onMouseDown={onMouseDown} selected={selected} />

    return null;

}

interface SvgItemFormI{
    value:SvgItem;
    onChange:(value:SvgItem)=>void;
    onRemove:()=>void;
}

function SvgItemForm(props:SvgItemFormI){
    const {value, onChange, onRemove} = props;

    if(value.type === 'ellipse')
        return <SvgEllipseForm value={value} onChange={onChange} onRemove={onRemove} />

    if(value.type === 'text')
        return <SvgTextForm value={value} onChange={onChange} onRemove={onRemove} />

    return null;
}