import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem, Button, Box, IconButton} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Settings, Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';
import { Document } from '../../lib/class/Document';
import { downloadBlobFile } from '../../lib/download';
import { VirtualFile } from '../../lib/class/VirtualFile';
import useRouter from 'use-react-router';
import { Tech } from '../../lib/class/Tech';

export interface TechCardI{
    value:Tech
}

export function TechCard(props:TechCardI){
    const {value} = props;
    const tech = value;

    const router = useRouter();

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds,google } = global;

    const styles = {};

   


    return <Card>
        <CardContent>  
            
        <EntityField label="First Name" name="firstName" type="string" entity={value} />
        <EntityField label="Last Name" name="lastName" type="string" entity={value} />
        <EntityField label="Email" name="email" type="string" entity={value} />
        <EntityField label="Phone" name="phone" type="string" entity={value} />
        <EntityField label="Address" name="address" type="string" entity={value} />
        
        </CardContent>
        <Divider/>
        <CardContent>
        <Button color="primary" variant="outlined" fullWidth onClick={() => tech.geocode(google)} >Geocode</Button>
    
     </CardContent>
    </Card>
}


/*
   <EntityField label="Model" name="model" value={value.model} entity={value} />
        <EntityField label="Model" name="serialNumber" value={value.serialNumber} entity={value} />
        <EntityField label="NATA Reference" name="nataReference" value={value.nataReference} entity={value} />
        <EntityField label="Last Calibration Date" name="lastCalibrationDate" value={value.lastCalibrationDate} entity={value} type="date" InputLabelProps={{shrink: true }} />
        <EntityField label="Next Calibration Date" name="nextCalibrationDate" value={value.nextCalibrationDate} entity={value} type="date" InputLabelProps={{shrink: true }} />
  */