import React, { useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import { Theme} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Job } from '../../lib/class/Job';


import useMediaQuery from '@material-ui/core/useMediaQuery';

import {JobCard} from "../../ui/ClassView/JobCard"
import {SiteCard} from "../../ui/ClassView/SiteCard"
import { CssStyles } from '../../css-types';


interface JobViewProps{
    id?:number;
    match?:any;
}

export function JobView(props:JobViewProps){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const sm = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));



	const styles:CssStyles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		}, 
        main:{
            paddingLeft:sm?0:20,
            paddingRight:sm?0:20
          },
          loading:{
              textAlign:"center",
              marginTop:100,
              color:"#888"
          }
	}

    
    const id = props.id || props.match.params.id;
    const job = ds.db.get(id) as Job;

    const site = job.getSite();

    const [loaded,setLoaded] = useState(false);
    useEffect( () => setLoaded(true),[]);

	return <div style={styles.root} key={"jobview"+id}> 	
            <Header title={"Job "+job.toString()} back={true} />
            {loaded?<div style={styles.main}>

                <SiteCard value={site} />
                <br/>
                <JobCard value={job} extended={true} />

                
            </div>:<div style={styles.loading}>Loading</div>}
        </div>
    
}

