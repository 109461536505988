import React, { useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';
import { Task } from '../../lib/class/Task';
import { TaskTypeData } from '../../lib/class/TaskTypeData';
import { Datom } from '../../lib/class/Datom';
import { DatomFieldRaw } from './DatomFieldRaw';






export interface DualDatomFieldI{
    label?:string;
    name:string;
    amendName?:string;
    grandParentName:string;
    grandParentLabel:string;
    value:Task;
}


export function DualDatomField(props:DualDatomFieldI){
    const {label,name,amendName,grandParentName,grandParentLabel,value} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;


    const dv = ds.db.find({type:"Datom",value:"Datom Value"}) as Datom;
    const grandparent = ds.db.find({type:"Datom",value:grandParentName}) as Datom;

    const [parent,setParent] = useState<Datom|null>(null)
    const [child,setChild] = useState<Datom|null>(null)

    useEffect(() => {
        try{
        const id = value.getJobDataItemKey(name).valueID 
        if(id!= null){
            const meter = ds.db.get(id) as Datom;
            const brand = meter.getParent();
            setParent(brand);
            setChild(meter);
        }else{
            //check for original unamended
            if(typeof amendName != "undefined"){
                const id = value.getJob().getJobDataItemKey(amendName).valueID;
                if(id != null){
                    const meter = ds.db.get(id) as Datom;
                    const brand = meter.getParent();
                    setParent(brand);
                    setChild(meter);
                    return;
                }
            }
            setParent(null);
            setChild(null)
        }
    }catch(E){
        console.log(E);
    }
    },[]);


    function blur(){
        // ds.forceSave();
    }

    function parentChange(v:Datom|null){
        console.log("parentChange",v);
        setParent(v);
        setChild(null);
    }

    
    function childChange(v:Datom|null){
        console.log("childChange",v);
        setChild(v);
        if(v == null)
            value.upsertTaskData(name,{valueID:null});
        else
            value.upsertTaskData(name,{valueID:v.id});


        blur();
    }

  
    const taskType = value.getType();
    const taskTypeDatas = taskType.getData().filter( tt => tt.name == name  );
    if(taskTypeDatas.length == 0 )
        return <div>No task type {name}</div>;
    
    const taskTypeData:TaskTypeData = taskTypeDatas[0];
    const validation = value.validateData(taskTypeData);

    return <React.Fragment>
        <DatomFieldRaw label={grandParentLabel} onChange={parentChange} parent={grandparent} options={grandparent.getValues("Meter Brand")} type="Meter Brand" value={parent}  />
        <DatomFieldRaw label={label} onChange={childChange} parent={parent} options={parent!=null?parent.getValues("Meter Model"):[]} type="Meter Model" value={child} disabled={parent==null} error={validation.length!=0} helperText={validation}  />
    </React.Fragment>

}



