import React, { useContext, useEffect, useReducer } from 'react';
import {TextField,  ButtonGroup, Button, FormHelperText, InputLabel} from "@material-ui/core";

import {GlobalContext} from '../../ui/withGlobalContext';
import { Task } from '../../lib/class/Task';


interface DataFieldI{
    task:Task;
    field:string;
    label?:string;
    multiline?:boolean;
    booleanDisplay?:[string,string];
}



export function DataField(props:DataFieldI){
    const {task,field,multiline,label,
        booleanDisplay = ["Yes","No"]} = props; 

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {
        buttonGroup:{
            marginTop:20
        }
    };

    const [taskTypeData,taskData] = task.getData(field);
    // console.log(field, taskTypeData);
    const type = taskTypeData.getDataType();
    // console.log("type",type);

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"value",forceUpdate),[]);

    const l = label ? label : taskTypeData.name;
    const v = taskData.value==null ? "" : taskData.value;
    const validation = task.validateData(taskTypeData);

    async function update(e:any){
        await ds.updateEntityField(taskData,e);
    }

    async function booleanUpdate(e:any){
        const updateValue = v === e.value ? null : e.value;
        await ds.updateEntityField(taskData,{value:updateValue});
    }

    function blur(){
        // ds.forceSave();
    }



    if(type.name === "boolean"){
     return <div style={{margin:theme.spacing(1)}}>
            <InputLabel>{l}</InputLabel>
            <div>
                <ButtonGroup variant="contained" style={styles.buttonGroup} >
                    <Button size="large" color={v===true?"primary":"default"} onClick={ () => booleanUpdate({value:true})} >{booleanDisplay[0]}</Button>
                    <Button size="large" color={v===false?"primary":"default"} onClick={ () => booleanUpdate({value:false})} >{booleanDisplay[1]}</Button>
                </ButtonGroup>
                {(validation.length!=0)?<FormHelperText error={true}>{validation}</FormHelperText>:<FormHelperText> </FormHelperText>}
         
            </div>
        </div>
    }else if(type.name === "string"){

        return <TextField margin="normal" fullWidth label={l} name="value" value={v}  multiline={multiline} onBlur={blur} onChange={(e) => update(e) } error={validation.length!=0} helperText={validation}  />
    }else if (type.name === "number"){
        return <TextField margin="normal" fullWidth label={l} type="number" name="value" value={v}  onBlur={blur}  onChange={(e) => update(e) }  error={validation.length!=0} helperText={validation} onWheel={ (event:any) => { event.target.blur() } } />
    }else{
        return <div>Data type not supported</div>
    }


}
