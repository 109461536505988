import React, { useContext,useRef,useCallback } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import { Typography, Button,Grid, Card, CardContent, CircularProgress, Table, TableHead, TableRow, TableCell, TableBody} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Check, Clear } from '@material-ui/icons';
import { TestResult } from '../../lib/class/TestResult';
import { Serial } from '../../lib/Serial';
import { CssStyles } from '../../css-types';
import { bruteParse } from '../../lib/class/TestUnitParse/parseFile';
import { AutoTestResult } from '../../lib/class/AutoTestResult';
import { TestResultRow } from '../../lib/class/TestResultRow';
import { TestResult590BV2 } from '../../lib/class/TestResult590BV2';

type Action = "created"|"existed"|"error";

function getMs(){
    return new Date().valueOf();
}

interface SerialPort{
    readable:any;
}

interface USBRef{
    serial:Serial;
    error:null|string;
    data:null|string;
    results:null| TestResult[] | AutoTestResult[] | TestResultRow[] | TestResult590BV2[];
}

export function TesterSync(){

    const global = useContext(GlobalContext);
    const {theme, project, ui, ds} = global;

    const [, updateState] = React.useState<any>();
    const forceUpdate = useCallback(() => updateState({}), []);

    const usbRef = useRef<USBRef>({
        serial:new Serial(),
        error:null,
        data:null,
        results:null

    });

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
        inner:{
            paddingLeft:20,
            paddingRight:20
        },
        errorCard:{
            marginTop:40,
            color:"red"
        }
	}


    async function connectSerial(){
        const serial = usbRef.current.serial;
        usbRef.current.error = null;
        try{
            await serial.connect()
            forceUpdate();
            const data = await serial.receiveData();
            usbRef.current.data = data;
            await verifyMessage();
        }catch(E){
            usbRef.current.error = (E as any).toString();
        }
        forceUpdate();
    }

    async function verifyMessage(){
        let file = usbRef.current.data;
        if(file == null)
            throw "File was empty";
        let results = await bruteParse(ds,file);
        usbRef.current.results = results;
        await ds.save();
        forceUpdate();
    }





    let display:React.ReactElement|null = null;
    if(usbRef.current != null){

        const {serial,data,results} = usbRef.current;
        if( results != null){
            display = <ReceivedMessage results={results} />
        } else if(serial.isConnected()){
            display = <InitiateDownload />
        }else if( !serial.isConnected() ){
            display = <ConnectSerialDialog connectSerial={connectSerial} />
        } else{
            display = <div>Error rendering</div>
        }

    }


    return <div style={styles.root}>
        <Header title="CT Tester Sync" back={true} />

            <div style={styles.inner}>
                <Grid container>
                    <Grid item xs={12}>
                        {display}
                        {usbRef.current.error!=null?<Card style={styles.errorCard}>
                            <CardContent>
                                {usbRef.current.error}
                            </CardContent>
                        </Card>:null}

                    </Grid>
                </Grid>
            </div>

        <Navigation target="testerSync"  />
    </div>  
    
}



interface ConnectSerialDialogI{
    connectSerial:()=>void;
}

function ConnectSerialDialog(props:ConnectSerialDialogI){
    const {connectSerial} = props;

    const styles:CssStyles = {
        prompt:{
            textAlign:"center"
        }
    };

    return   <Card>
        <CardContent>
            <div style={styles.prompt}>
            <Typography>Please connect CT Tester via USB and press the button</Typography>
            <br/>
            <Button variant="outlined" color="primary" onClick={connectSerial}>Connect device</Button>
            </div>
        </CardContent>
    </Card>
}


interface InitiateDownloadI{

}


function InitiateDownload(props:InitiateDownloadI){
    const {} = props;

    const styles:CssStyles = {
        prompt:{
            textAlign:"center"
        }
    };

    return   <Card>
        <CardContent>
            <div style={styles.prompt}>
                <Typography>On the tester, select Main Menu item 3 (PRINT/UPLOAD/DOWNLOAD)</Typography>
                <Typography>then select menu item 2 (DOWNLOAD TEST RESULTS)</Typography>
                <Typography>then select menu item 1 (START DOWNLOAD)</Typography>
                <Typography>Ensure that format is set to (MULTI-LINE REC)</Typography>
                <br/>
                <br/>
                <CircularProgress color="primary" />
            </div>
        </CardContent>
    </Card>
}

export interface ProcessedMessage{
    toString:()=>string;
    hasError:()=>boolean;
    getError:()=>string;
}



interface ReceivedMessageI{
    results:ProcessedMessage[]
}



export function ReceivedMessage(props:ReceivedMessageI){
    const {results} = props;


	const global = useContext(GlobalContext);
	const {theme, project, ui, ds} = global;


    const styles:CssStyles = {
        prompt:{
            textAlign:"left"
        }
    };

    return   <Card>
        <CardContent>
            <div style={styles.prompt}>
            <Typography>Got: {results.length} records</Typography>
            <br/>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Record</TableCell>
                        <TableCell>Action</TableCell>
                        <TableCell>Error</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {results.map( (result,i) => {
                    return  <TableRow key={i}>
                    <TableCell>{result.toString()}</TableCell>
                    <TableCell>
                        {result.hasError()?<Clear style={{color:theme.palette["error"].main}}  />:<Check style={{color:theme.palette["success"].main}} />}
                    </TableCell>
                    <TableCell>{result.getError()} </TableCell>
            
                </TableRow>
            
                })}
                </TableBody>
            </Table>
             
            </div>
                <br/><br/>
            <div>If there are errors, please call Keith on <a href="tel:+61401937510">0401 937 510</a></div>
        </CardContent>
    </Card>
}
