import { default as React, useEffect } from 'react';
import useReactRouter from 'use-react-router';


interface ScrollToTopProps{
 
    children:any;
}

export function ScrollToTop(props:ScrollToTopProps) {


    const router = useReactRouter()


  useEffect(() => {
    const unlisten = router.history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return <React.Fragment>{props.children}</React.Fragment>;
}
