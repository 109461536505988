import moment from "moment";
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";

export interface TestUnitI {
  id: number;
  type: string;
  deleted: boolean;
  //name: string;
  model: string;
  nataReference: string;
  serialNumber: string;
  lastCalibrationDate: number;
  nextCalibrationDate: number;
}

export class TestUnit extends Base {
  // name: string;
  model: string;
  serialNumber: string;
  nataReference: string;
  lastCalibrationDate: number;
  nextCalibrationDate: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    // this.name = event.data.name;
    this.model = event.data.model;
    this.serialNumber = event.data.serialNumber;
    this.nataReference = event.data.nataReference;
    this.lastCalibrationDate = event.data.lastCalibrationDate;
    this.nextCalibrationDate = event.data.nextCalibrationDate;
  }

  toSearchString() {
    return `'Test Unit' ${this.type} ${this.serialNumber} ${this.model}`;
  }

  getLastCalibration() {
    return moment(this.lastCalibrationDate);
  }

  getNextCalibration() {
    return moment(this.nextCalibrationDate);
  }

  toString() {
    return `${this.serialNumber} ${this.model}`;
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      //   name:this.name,
      model: this.model,
      serialNumber: this.serialNumber,
      nataReference: this.nataReference,
      lastCalibrationDate: this.lastCalibrationDate,
      nextCalibrationDate: this.nextCalibrationDate,
    };
  }
}
