import React, { useEffect, useRef, useContext } from 'react'
import ReactDOM from "react-dom";

import {GlobalContext} from "../withGlobalContext";
import useReactRouter from 'use-react-router';

interface MapI{
	options?:any;
	onMount?:()=>any;
	openWindow?:Place|null;
	places:Place[];
	onSelect?:(place:Place)=>void;
	height?:number;
}

export interface Place{
	id:string;
	lat:number;
    lng:number;
    color:any;
	value:any;
	label:any;
	link:any;
	icon:any;
}

interface GeoLocation{
	lat:number;
	lng:number;
}


export function PlaceMap(props:MapI) {

	const {options,onMount,places,openWindow,onSelect,height} = props;
	const global = useContext(GlobalContext);
	const {project, theme, ds, ui, google} = global;
	const { history, location, match } = useReactRouter();

	const divRef = useRef<HTMLDivElement>(null);
	const mapRef = useRef<google.maps.Map|null>(null);
	const markersRef = useRef<google.maps.Marker[]|null>(null);
	const stepDisplayRef = useRef<google.maps.InfoWindow | null>(null);

	const styles = {
		div: {
			height:height?height:400,
		}
	}


	const myInterval = useRef<any|null>(null);


	useEffect(() => {
		myInterval.current = setInterval(() => {displayMyLocation()}, 5000);

		return () => {
			console.log("deregister")
			clearInterval(myInterval.current);
		}

	},[]);


	function attachInstructionText(marker: google.maps.Marker, place: Place) {
		google.maps.event.addListener(marker, 'click', () => {
			//windowPlaceRef.current = place;
			const stepDisplay = stepDisplayRef.current;
			const map = mapRef.current;

			if (stepDisplay != null && map != null) {
				stepDisplay.setContent("<div id='mapLink'></div>");
				stepDisplay.open(map, marker);
				if (place.link != null)
					setTimeout(() => { ReactDOM.render(place.link, document.getElementById("mapLink")); }, 100);
			}
		});
	}

	function setupMarkers(){
		const markers = [];//markersRef.current;
		const map = mapRef.current
		
			for(const place of places){
				const marker =  new google.maps.Marker();

				marker.setMap(map);
				marker.setPosition({lat:place.lat,lng:place.lng});

				if(place.icon != null)
					marker.setIcon(place.icon);

				attachInstructionText(marker, place);

				markers.push(marker)
				
			}

			markersRef.current = markers;

		}


		async function displayMyLocation(){
			console.log("display my location")
			const map = mapRef.current
			const pos = await getMyLocation();
			if(pos === null) return;
			const color = 'black';
			const icon = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path fill="${color}" d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.85 7h10.29l1.08 3.11H5.77L6.85 7zM19 17H5v-5h14v5z"/><circle cx="7.5" cy="14.5" r="1.5" fill="${color}"  /><circle cx="16.5" cy="14.5" r="1.5" fill="${color}" /></svg>`
			const encodedIcon = "data:image/svg+xml;base64," + btoa(icon);
			const marker =  new google.maps.Marker();
			marker.setIcon(encodedIcon);
			marker.setMap(map);
			marker.setPosition(pos);

		}


	async function getMyLocation():Promise<GeoLocation>{
		return new Promise((resolve,reject) => {
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
				(position: GeolocationPosition) => {
					const pos:GeoLocation = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};
					resolve(pos);
				},reject);
			} else reject()
		});
	}

	function init(){
		if(typeof google == "undefined") 
			return;
			
		if(mapRef.current == null)
			setup();
	}

	function setup() {
		if(divRef.current != null){
			mapRef.current = (new google.maps.Map(divRef.current, options));
			stepDisplayRef.current = new google.maps.InfoWindow();
			setupMarkers();

			displayMyLocation();
		}
	}

  useEffect( () => init(), []);

  
  return <div ref={divRef} style={styles.div}></div>

}

PlaceMap.defaultProps = {
  options: {
    center: { lat: -32.777730, lng: 146.307672 },
	zoom: 6,
	zoomControl: true,
	mapTypeControl: false,
	scaleControl: true,
	streetViewControl: false,
	rotateControl: false,
	fullscreenControl: false
  },
}
