import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem, Button, Box, IconButton} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Settings, Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';
import { Document } from '../../lib/class/Document';
import { downloadBlobFile } from '../../lib/download';
import { VirtualFile } from '../../lib/class/VirtualFile';
import useRouter from 'use-react-router';

export interface DocumentCardI{
    value:Document
}

export function DocumentCard(props:DocumentCardI){
    const {value} = props;
    const task = value;

    const router = useRouter();

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {};

    async function openFile(){
        if(value.fileID != null){
            const virtualFile = ds.db.get(value.fileID) as VirtualFile;
            const ab = await ds.geVirtualFile(virtualFile);
            downloadBlobFile(ab, virtualFile.getFilename() );
        }
    }



    return <Card>
        <CardContent>  
            
        <Box display="flex" p={1} >
            <Box flexGrow={1}>
            <Typography variant="h6" >{value.label}</Typography>
            </Box>
            {ds.db.getMe().isSudo()?<Box><IconButton onClick={() => router.history.push("/entity/"+value.id)} ><Settings /></IconButton> </Box>:null}
        </Box>
           
              </CardContent>
        <Divider/>
        <CardContent>
        <Typography><b>Description:</b> {value.description}</Typography>
        </CardContent>
        <Divider/>
        <CardContent>
        <Button color="primary" variant="outlined" fullWidth onClick={() => openFile()} disabled={value.fileID == null}>Download File</Button>
    
     </CardContent>
    </Card>
}


/*
   <EntityField label="Model" name="model" value={value.model} entity={value} />
        <EntityField label="Model" name="serialNumber" value={value.serialNumber} entity={value} />
        <EntityField label="NATA Reference" name="nataReference" value={value.nataReference} entity={value} />
        <EntityField label="Last Calibration Date" name="lastCalibrationDate" value={value.lastCalibrationDate} entity={value} type="date" InputLabelProps={{shrink: true }} />
        <EntityField label="Next Calibration Date" name="nextCalibrationDate" value={value.nextCalibrationDate} entity={value} type="date" InputLabelProps={{shrink: true }} />
  */