
import { Base } from "./Base";
import { TaskType } from "./TaskType";
import { DataEvent, Datastore } from "../Datastore";
import { DataType } from "./DataType";

export interface TaskTypeDataI {
  id: number;
  type: string;
  deleted: boolean;
  name: string;
  taskTypeID: number;
  dataTypeID: number;
}

export class TaskTypeData extends Base {
  name: string;
  taskTypeID: number;
  dataTypeID: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.name = event.data.name;
    this.taskTypeID = event.data.taskTypeID;
    this.dataTypeID = event.data.dataTypeID;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.taskTypeID = transform(this.taskTypeID);
    this.dataTypeID = transform(this.dataTypeID);
  }

  getTaskType(): TaskType {
    return this.ds.db.get(this.taskTypeID) as TaskType;
  }

  getDataType(): DataType {
    return this.ds.db.get(this.dataTypeID) as DataType;
  }

  toString() {
    return `${this.name}`;
    // try {
    //   let taskTypeID = this.getTaskType();
    //   let dataType = this.getDataType();
    //   return `${this.name}:${dataType.toString()} for ${taskTypeID.toString()}`;
    // } catch (E) {
    //   return "error";
    // }
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      taskTypeID: this.taskTypeID,
      name: this.name,
      dataTypeID: this.dataTypeID,
    };
  }
}
