import React, { useContext, useState } from 'react';
import moment from "moment";
import {GlobalContext} from '../../ui/withGlobalContext';

import {Button, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job, JobJ } from '../../lib/class/Job';

import useRouter from 'use-react-router';
import { Base } from '../../lib/class/Base';
import { uploadFile } from '../../lib/download';
import { importCSV, readFileText } from '../../lib/CSV';
import { Site, SiteJ } from '../../lib/class/Site';
import { JobData, JobDataI } from '../../lib/class/JobData';
import { TestResult } from '../../lib/class/TestResult';
import { ReceivedMessage } from '../TesterSync';
import { AutoTestResult } from '../../lib/class/AutoTestResult';
import { TestResultRow } from '../../lib/class/TestResultRow';

export function TestResultSyncLinks(){

    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const [results,setResults] = useState<TestResult[]|null>(null);

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64,
            paddingLeft:20,
            paddingRight:20
		}
	}


    async function run(){
        const lastYear = (new Date(2023,10,1)).valueOf();

        const testResults:TestResult[] = ds.db.filter({type:"TestResult"}).filter(t => t.timestamp > lastYear);

        const notLinked:TestResult[] = [];

        for(const testResult of testResults){
            try{
                const link = testResult.getTaskDataLink();
            }catch(E){
                notLinked.push(testResult);
            }
        }

        for(const testResult of notLinked){
            try{
                const link = await testResult.syncLinks();
            }catch(E){

            }
        }



        const autoTestResults:AutoTestResult[] = ds.db.filter({type:"AutoTestResult"})
          for(const testResult of autoTestResults){
            try{
                if(testResult.taskID === null){
                    const link = testResult.syncLinks();
                }
            }catch(E){
                // notLinked.push(testResult);
            }
        }






        const testResultRow:TestResultRow[] = ds.db.filter({type:"TestResultRow"})
          for(const testResult of testResultRow){
            try{
                if(testResult.taskID === null){
                    const link = testResult.syncLinks();
                }
            }catch(E){
                // notLinked.push(testResult);
            }
        }
        
        await ds.save();
        setResults(notLinked);
    }




	return  <div style={styles.root} > 	
            <Header title="Test Result Sync Links" back={true} />
            {results == null ?<Card>
                <CardContent>
                    <Button onClick={run} color="primary" variant="outlined" >Run</Button>
                </CardContent>
            </Card>:<ReceivedMessage results={results} />}
        

            <Navigation  />
        </div>
    
}
