import { default as React, useContext, useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar,Badge} from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import WorkIcon from '@material-ui/icons/Work';
import useRouter from 'use-react-router';


import { GlobalContext } from './withGlobalContext';
import { Description, Devices, Settings } from '@material-ui/icons';


    const useStyles = makeStyles({
        root: {
            top: 'auto',
            bottom: 0
        },
      });


      interface NavigationI{
          target?:string;
      }


export function Navigation(props:NavigationI){
    const classes = useStyles();
    const router = useRouter();

    const global = useContext(GlobalContext);
    const { theme, project, ui,ds } = global;

    const {target} = props;


    const indexes = ["list/Task","list/Job","list/Document","testerSync","settings"];
    let value = -1;
    if(target)
        value = indexes.indexOf(target);

 

    function navigate(value:number){
        router.history.push("/"+indexes[value]);
    }


    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.lp.sub(0,"*",forceUpdate),[]);
    const list = ds.lp.get("Navigation");
    const jobCount = list.subjects[1].data.length;
    const taskCount = list.subjects[0].data.length;

    return  <AppBar position="fixed" className={classes.root}>
        <BottomNavigation  value={value} onChange={(event, newValue) => { navigate(newValue); }} showLabels>
            <BottomNavigationAction label="Tasks" icon={<Badge badgeContent={taskCount} color="primary"><RestoreIcon /></Badge>} />
            <BottomNavigationAction label="Jobs" icon={<Badge badgeContent={jobCount} color="primary"><WorkIcon /></Badge>} />
            <BottomNavigationAction label="Documents" icon={<Description />} />
            <BottomNavigationAction label="Tester" icon={<Devices />} />
            <BottomNavigationAction label="Settings" icon={<Settings />} />
        </BottomNavigation>
    </AppBar>


}