
import moment from "moment";
import { Job, JobJ } from '../../../lib/class/Job';
import { Site, SiteJ } from '../../../lib/class/Site';
import { JobData, JobDataI } from '../../../lib/class/JobData';
import { getsertDatom } from '../../../lib/class/Datom';
import { JobTypeData } from '../../../lib/class/JobTypeData';
import { rowHasKeys } from '../utils';
import { Datastore } from '../../../lib/Datastore';
import { MeteringProvider } from '../../../lib/class/MeteringProvider';
import { JobType } from '../../../lib/class/JobType';




export interface NstRow {
"Reference":string;
"NMI":string;
"SP.ID":string;
"Service Equ No":string;
"Cable/Conductor":string;
"Service Type":string;
"Meter.ID":string;
"Meter Type":string;
"Grouping":string;
"HV.Feeder":string;
"Circuit":string;
"Meter Group":string;
"Sensitive Load":string;
"Customer.Type":string;
"Unit Number":string;
"Street Number":string;
"Street Name":string;
"Suburb":string;
"Postcode":string;
}

const nstCsvKeys = [
"NMI",
"SP.ID",
"Service Equ No",
"Cable/Conductor",
"Service Type",
"Meter.ID",
"Meter Type",
"Grouping",
"HV.Feeder",
"Circuit",
"Meter Group",
"Sensitive Load",
"Customer.Type",
"Unit Number",
"Street Number",
"Street Name",
"Suburb",
"Postcode"
];



export function isNstRow(row:unknown):row is NstRow{
   return rowHasKeys('nst',nstCsvKeys, row);
}


export async function importNstRow(ds:Datastore, row:NstRow) {
    const site = await importNstSite(ds,row);
    const job = await importNstJob(ds,site,row);
    return job;
}




async function importNstSite(ds:Datastore, row:NstRow):Promise<Site>{

    const mpb = ds.db.find<MeteringProvider>({type:"MeteringProvider",name:"Jemena"});

    let address = `${row['Unit Number']} ${row['Street Number']} ${row['Street Name']} ${row['Suburb']} ${row['Postcode']}`;

    const s:SiteJ = {
        id:0,
        type:"Site",
        deleted:false,
        nmi:row["NMI"],
        customerName:"",
        customerContactName:"",
        customerPhone:"",
        address,
        mpID:mpb.id,
        nmiAmend:null,
        customerNameAmend:null,
        customerContactNameAmend:null,
        customerPhoneAmend:null,
        addressAmend:null,
        location:null,
        lifeSupport:false,
        meterBoxLocation:"",
        meterBoxLocationAmend:null,
        hazard: "",
        severity: ""
    }
    const site:Site = await ds.upsert({type:s.type,nmi:s.nmi}, s);
    return site;
}



export async function importNstJob(ds:Datastore, site:Site, row:NstRow) {


    const jobType = ds.db.find<JobType>({type:"JobType",name:"Asset Inspection"});
    const j:JobJ = {
        id:0,
        type:"Job",
        deleted:false,
        siteID:site.id,
        typeID:jobType.id,
        startDate: moment("24 10 2022","DD MMM YYYY").valueOf(), 
        dueDate: moment("30 12 2022","DD MMM YYYY").valueOf(), 
        sent:false,
        ignore:false
    }



    const jobData = {
        "Reference":row['Reference'],
        "SP ID":row['SP.ID'],
        "Service Equipment Number":row['Service Equ No'],
        "Cable / Conductor":row['Cable/Conductor'],
        "Service Type":row['Service Type'],
        "Meter Number":row['Meter.ID'],
        "HV Feeder":row['HV.Feeder'],
        "Circuit":row['Circuit'],
        "Meter Group":row['Meter Group'],
        "Sensitive Load":row['Sensitive Load'],
        "Customer Type":row['Customer.Type']
    };

    const job:Job = await upsertJob(ds, j, jobData);
    return job;
}




    async function upsertJob(ds:Datastore, data:any, jobData:any){

        // find any jobdata
        const workOrderType = ds.db.find<JobTypeData>({type:"JobTypeData",name:"Reference"});

        const matchingDatas:JobData[] = ds.db.filter({ type:"JobData", dataID:workOrderType.id, value:jobData["Reference"] });
        const jobs:Job[] = matchingDatas.map( d => d.getJob() );

        let job:Job;
        if(jobs.length === 0){
            console.log("not found creating")
            job = await ds.createEntity(data);
        } else if(jobs.length > 1){
            throw new Error("Multiple matches");
        }else{
            console.log("found match")
            job = jobs[0];
        }


        //run through job data and upsert
        const keys = Object.keys(jobData);
        for(const key of keys){
            const value = jobData[key];
            const dataTypes = ds.db.filter({type:"JobTypeData",name:key, jobTypeID:job.typeID}) as JobTypeData[];
            if(dataTypes.length === 0)
                throw new Error("dataType no matches");

            const dataType = dataTypes[0];
            const t = dataType.getDataType();
            
            const jd:JobDataI = {
                id:0,
                type:"JobData",
                deleted:false,
                jobID:job.id,
                dataID:dataType.id,
                value:null,
                valueID:null
            }

            if( t.name === "id" ){
                if(value != null){
                    jd.valueID = value.id;
                }

            }else{
                jd.value = value
            }

            await ds.upsert({ type:"JobData", dataID:dataType.id, jobID:job.id },jd);

        }

        return job
    }

