import React, { createElement, useContext, useRef, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Button, Divider, Typography, Box, Grid, TableCell, TableRow, Table} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { useEffect } from 'react';

import useRouter from 'use-react-router';
import { Task } from '../../lib/class/Task';
import { TaskTypeData } from '../../lib/class/TaskTypeData';
import { VirtualFile } from '../../lib/class/VirtualFile';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';
import { TestResult } from '../../lib/class/TestResult';
import moment from 'moment';
import { downloadTextFile } from '../../lib/download';

import "./printTable.css"
import { resizeImage } from '../../lib/CompressImage';
import { CssStyles } from '../../css-types';

type KeyPair = [string,any,any];


type BooleanPair = [string,string,boolean];

export function NativePaperwork(props:any){

    const router = useRouter();
	const global = useContext(GlobalContext);
	const {project,theme,ds} = global;

    const styles:CssStyles = {
		header:{
			paddingTop:64
		},
		page:{
			fontFamily:"Times New Roman !important"
		//	"pageBreakAfter": "always"
		} ,
        outer:{
            //padding:20
        },
        title:{
            textAlign:"center",
            fontSize:20,
            fontWeight:"bold",
            marginBottom:15
        } ,
        subtitle:{
            //textAlign:"center",
            fontSize:14,
            fontWeight:"bold"
        } 
	}


	const [doc,setDoc] = useState<any|null>(null);
	const [images,setImages] = useState<any|null>(null);
	const [error,setError] = useState<string|null>(null);


    const id = props.match.params.id;
    const job = ds.db.get(id) as Job;

	async function load(job:Job){

		console.log("Load!");

		const site = job.getSite();
		const tasks = job.getTasks();

		document.title = site.getNMI();
		//6100000000

		const preinspection = tasks.filter( t => t.getType().toString() == "Pre-inspection" && t.isComplete() && !t.hasIssues() )[0];
		const ctTest = tasks.filter( t => t.getType().toString() == "CT Testing" && t.isComplete() && !t.hasIssues() )[0];
	
		
		if(preinspection == undefined)
			throw "No Pre-inspection";

		if(ctTest == undefined)
			throw "No CT Test";



		let job_meter_make = ""
		let ctTest_meter_make = ""


		try{
			const id = job.getJobDataItemKey("Meter Model").valueID
			if(id != null)
				job_meter_make = ds.db.get(id).getParent().toString();
			//console.log("job_meter_make",job_meter_make);
		}catch(E){
			//console.error("er",E);
		}

		try{
			const id = ctTest.getJobDataItemKey("Meter Model Amend").valueID;
			if( id != null )
				ctTest_meter_make = ds.db.get(id).getParent().toString();
			console.log("ctTest_meter_make",ctTest_meter_make);
		}catch(E){
			//console.error("er",E);

		}

		let instrument:TestUnit|null = null;



		function applymV(data:string){
			if(typeof data != "undefined" && data != "")
				return data+"mV";
			else
				return ""
		}


		try{
			const id = ctTest.getJobDataItemKey("CT Blue Phase Tapping 1 Result").valueID;
			if(id != null){
				console.log(id);
				const result:TestResult = ds.db.get(id);
				console.log(result);
				instrument = result.getTestUnit();
			}
		}catch(E){
			console.error(E);
		}

		if(instrument == null)
			throw "Error finding instrument";


		function amend(value:string,amend:string|undefined|null){
			const valueStr = typeof value == "undefined" ? "" : value.toString();
			if(typeof amend == "undefined" || amend == null || amend == "")
				return <React.Fragment>{valueStr}</React.Fragment>
			else{
				const amendStr = typeof amend == "undefined" ? "" : amend.toString();
				return <React.Fragment><s style={{color:"red"}}>{valueStr}</s> {amendStr}</React.Fragment>
			}
		}


        const doc = <Page>
            <div style={styles.title}>Jemena CT Testing Paperwork</div>
       
                <table width="100%">
                <tbody>
                    <tr>
                        <td><div style={styles.subtitle}>Customer Details</div></td>
                        <td style={{textAlign:"right"}}><div style={styles.subtitle}>Notification Period: <span className="ans">{`${job.getStartDate().format("DD/MM/YYYY")} to ${job.getDueDate().format("DD/MM/YYYY")}`}</span></div></td>
                    </tr>
                </tbody>
            </table>


           
            <table width="100%" className="printTable">
                <tbody>
                    <tr>
                        <td className="q" >Customer:</td><td className="ans">{amend(site.customerName,site.customerNameAmend)}</td>
                        <td className="q">Record ID:</td><td className="ans">{amend(job.getJobDataItemValueString("Record ID"),ctTest.getJobDataItemValueString("Record ID Amend"))}</td>
                    </tr>
                    <tr>
                        <td className="q">Address:</td>
                        <td colSpan={3} className="ans">{amend(site.address,site.addressAmend)}</td>
                    </tr>
                    <tr> 
                        <td className="q">NMI:</td><td className="ans">{amend(site.nmi,site.nmiAmend)}	</td>
                        <td className="q">MPB:</td><td className="ans">	{amend(site.getMeterProvider().toString(),ctTest.getJobDataItemValueString("MPB Amend"))}	</td>
                    </tr>
                </tbody>
            </table>
           
            <br/>
            <div style={styles.subtitle}>Meter Details</div>
            <table width="100%" className="printTable">
                <tbody>
                    <tr>
                        <td className="q">Make:</td><td className="ans">{amend(job_meter_make,ctTest_meter_make)}</td>
                        <td className="q">Model:</td><td className="ans">{amend(job.getJobDataItemValueString("Meter Model"),ctTest.getJobDataItemValueString("Meter Model Amend"))}</td>
                    </tr>
                    <tr>
                        <td className="q" >Number:</td><td className="ans">{amend(job.getJobDataItemValueString("Meter Number"),ctTest.getJobDataItemValueString("Meter Number Amend"))}</td>
                        <td className="q">Rating:</td><td className="ans">{amend(job.getJobDataItemValueString("Meter Current Rating"),ctTest.getJobDataItemValueString("Meter Current Rating Amend"))}</td>
                    </tr>
                    <tr>
                        <td className="q" >Constant K:</td><td className="ans">	{amend(job.getJobDataItemValueString("Constant K"),ctTest.getJobDataItemValueString("Constant K Amend"))}</td>
                  
                    </tr>
                </tbody>
            </table>
           
            <br/>
            <div style={styles.subtitle}>CT Details</div>
            <table width="100%" className="printTable">
                <tbody>
                    <tr>
                        <td className="q" >Make:</td><td className="ans">{ctTest.getJobDataItemValueString("CT Brand").toString()}</td>
                        <td className="q">Type:</td><td className="ans">{ctTest.getJobDataItemValueString("CT Type").toString()}</td>
                    </tr>
                    <tr>
                        <td className="q" >Class:</td><td className="ans">{ctTest.getJobDataItemValueString("CT Class").toString()}</td>
                        <td className="q">Standard:</td><td className="ans">{ctTest.getJobDataItemValueString("CT Design Standard").toString()}</td>
                    </tr>
                    <tr>
                        <td className="q" >Conn.Ratio:</td><td className="ans">{amend(job.getJobDataItemValueString("CT Ratio"),ctTest.getJobDataItemValueString("CT Ratio Amend"))}</td>
                        <td className="q">VA/Burden:</td><td className="ans">{`${ctTest.getJobDataItemValueString("CT Burden VA")}VA ${ctTest.getJobDataItemValueString("CT Burden R")}Ω`}</td>
                    </tr>
                </tbody>
            </table>


            <br/>
            <div style={styles.subtitle}>Test Details</div>
            <table width="100%" className="printTable">
                <tbody>
                    <tr><td className="q" >Preliminary:</td><td className="ans" colSpan={8}>{preinspection.getTech().toString()} {preinspection.getStartTimestamp().format("DD/MM/YY HH:mm").toString()}</td>  </tr>
                    <tr><td className="q">CT Test:</td><td className="ans" colSpan={8}>{ctTest.getTech().toString()} {ctTest.getStartTimestamp().format("DD/MM/YY HH:mm").toString()}</td></tr>
                  
                    <tr className="noWrap">
                        <td>Phase</td>
                        <td>Serial Numbers</td>
                        <td>Tapping 1</td>
                        <td>Rec #</td>
                        <td>Tapping 2</td>
                        <td>Rec #</td>
                        <td>Tapping 3</td>
                        <td>Rec #</td>
                        <td>2nd Volt Check</td>
                    </tr>   
                    <tr>
                        <td>Red</td>
                        <td className="ans">{amend(job.getJobDataItemValueString("CT Red Phase Serial Number"),ctTest.getJobDataItemValueString("CT Red Phase Serial Number Amend"))}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Red Phase Tapping 1 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Red Phase Tapping 1 Record Number").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Red Phase Tapping 2 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Red Phase Tapping 2 Record Number").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Red Phase Tapping 3 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Red Phase Tapping 3 Record Number").toString()}</td>
                        <td className="ans">{applymV(ctTest.getJobDataItemValueString("CT Red Phase Energised Voltage")).toString()}</td>
                    </tr>
                    <tr>
                        <td>White</td>
                        <td className="ans">{amend(job.getJobDataItemValueString("CT White Phase Serial Number"),ctTest.getJobDataItemValueString("CT White Phase Serial Number Amend"))}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT White Phase Tapping 1 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT White Phase Tapping 1 Record Number").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT White Phase Tapping 2 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT White Phase Tapping 2 Record Number").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT White Phase Tapping 3 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT White Phase Tapping 3 Record Number").toString()}</td>
                        <td className="ans">{applymV(ctTest.getJobDataItemValueString("CT White Phase Energised Voltage")).toString()}</td>
                    </tr>
                    <tr>
                        <td>Blue</td>
                        <td className="ans">{amend(job.getJobDataItemValueString("CT Blue Phase Serial Number"),ctTest.getJobDataItemValueString("CT Blue Phase Serial Number Amend"))}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Blue Phase Tapping 1 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Blue Phase Tapping 1 Record Number").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Blue Phase Tapping 2 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Blue Phase Tapping 2 Record Number").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Blue Phase Tapping 3 Ratio").toString()}</td>
                        <td className="ans">{ctTest.getJobDataItemValueString("CT Blue Phase Tapping 3 Record Number").toString()}</td>
                        <td className="ans">{applymV(ctTest.getJobDataItemValueString("CT Blue Phase Energised Voltage")).toString()}</td>
                    </tr>
     
                    </tbody>
            </table>


                    <br/>



            <div style={styles.subtitle}>Test Details</div>
            <table width="100%" className="printTable">
                <tbody>
                <tr>
                        <td>Instrument Description</td>
                        <td>Serial No.</td>
                        <td>Calibration test date</td>
                        <td>NATA reference No.</td>
                        <td>Next test due date</td>
                        <td>Instrument within calibration date?</td>
                    </tr>    
                    <tr>
                        <td className="ans">{instrument.model}</td>
                        <td className="ans">{instrument.serialNumber}</td>
                        <td className="ans">{instrument.getLastCalibration().format("DD/MM/YY")}</td>
                        <td className="ans">{instrument.nataReference}</td>
                        <td className="ans">{instrument.getNextCalibration().format("DD/MM/YY")}</td>
                        <td className="ans">{(moment().valueOf() > instrument.getNextCalibration().valueOf()) ? "No" : "Yes"}</td>
                    </tr>
             
                </tbody>
            </table>

            <br/>
            <div style={styles.subtitle}>Customer Details</div>
            <table width="100%" className="printTable">
                <tbody>
                <tr> <td className="q" >Company Name:</td><td className="ans" colSpan={3}>{amend(site.customerName,site.customerNameAmend)}</td>  </tr>
                <tr><td className="q">Contact Name:</td><td className="ans">{amend(site.customerContactName,site.customerContactNameAmend)}</td>   <td className="q">Contact Phone:</td><td className="ans">{amend(site.customerPhone,site.customerPhoneAmend)}</td></tr>
                </tbody>
            </table>

			
		
		

            <br/>
            <div style={styles.subtitle}>Job Comments</div>
            <table width="100%" className="printTable">
                <tbody>
                <tr> <td className="ans">{genLines(ctTest.getJobDataItemValueString("Comments"))}</td>  </tr>
                </tbody>
            </table>

            <br/>
            <div style={styles.subtitle}>JHA Details</div>
            <table width="100%" className="printTable">
                <tbody>
                <tr> <td >Do you fully understand all the instructions</td> <td className="ans">{ctTest.getJobDataItemValueString("JHA Instructions")?"Yes":"No"}</td>  </tr>
                <tr> <td >Do you have the correct PPE?</td> <td className="ans">{ctTest.getJobDataItemValueString("JHA PPE")?"Yes":"No"}</td>  </tr>
                <tr> <td >Is a site induction required?</td> <td className="ans">{ctTest.getJobDataItemValueString("JHA Induction")?"Yes":"No"}</td></tr>
                <tr> <td >Do you have safe access &amp; egress from meter position</td> <td className="ans">{ctTest.getJobDataItemValueString("JHA Access")?"Yes":"No"}</td></tr>
                <tr> <td >Can the meter be safely isolated?</td> <td className="ans">{ctTest.getJobDataItemValueString("JHA Isolated")?"Yes":"No"}</td></tr>
                <tr> <td >Are you aware of all live points?</td> <td className="ans">{ctTest.getJobDataItemValueString("JHA Live Points")?"Yes":"No"}</td></tr>
                <tr> <td >Is Traffic management required?</td> <td className="ans">{ctTest.getJobDataItemValueString("JHA Traffic")?"Yes":"No"}</td></tr>
                <tr><td >Note any control measures used:</td><td className="ans">{ctTest.getJobDataItemValueString("JHA Control Measures").toString()}</td></tr>
                </tbody>
            </table>

        







      
       
        </Page>  


        setDoc(doc);


		function genLines(str:string){
			const lines = str.toString().split("\n");
			
			//lines = lines.map(escapeHtml);
			//lines = lines.map( (l,i) => `<tspan x="0" dy="${i*1.2}em">${l}</tspan>` );
			const ps = lines.map( (l,i) => <p key={i} style={{marginBlockStart: "0em", marginBlockEnd: "0.5em"}} >{l}</p> );
			return ps;
		  }

	
		


		function escapeHtml(html:string){

			console.log(html)
			const text = document.createTextNode(html);
			const p = document.createElement('p');
			p.appendChild(text);
			console.log(p.innerHTML)
			return p.innerHTML;
		  }


		setImages(<React.Fragment>
			<div style={styles.page}>
				<ImagePage task={ctTest} field="Meter photo" label="Meter showing date, serial number, NMI and CT constant" />
			</div>

			<div style={styles.page}>
				<ImagePage task={ctTest} field="Sealed panel photo" label="Photo of whole panel sealed" />
			</div>

			<div style={styles.page}>
				<ImagePage task={ctTest} field="Test block photo" label="Photo of Test Block (showing current links closed)" />
			</div>

			<div style={styles.page}>
			<ImagePage task={ctTest} field="CT chamber photo" label="Photo of CT Chamber with seals on current transformers"/>
			</div>


			<div style={styles.page}>
			<ImagePage task={ctTest} field="CT name plate photo" label="Photo of the CT name plate" />
			</div>


			<div style={styles.page}>
			<ImagePage task={ctTest} field="Main switch photo" label="Photo of Main switch or isolating switches in on position" />
			</div>


			<div style={styles.page}>
			<ImagePage task={ctTest} field="Security locks photo" label="Photo of any security locks / seals fitted to outer doors" />
			</div>

		</React.Fragment>)



	}

	useEffect(() => {
		(async () => {
			try{
				await load(job);
			}catch(E){
				setError((E as any).toString())
			}
		})();
	},[]);


	return <React.Fragment >
		{(error!=null)?<div style={{color:"red"}}>{error} <Button variant="outlined" onClick={() => router.history.goBack()}  >Back</Button> </div>:null}  
        <div style={styles.outer} className="noPrintPadding">
            {doc}     
			{images}
        </div>


	</React.Fragment>
    
}


interface PageI{
	children:any;
}

function Page(props:PageI){
	const {children} = props;

	const styles = {
		box:{
			height:"295mm",
			width:"208mm",
//			textAlign:"center",
            //border:"1px solid black",
            marginBottom:20,
            padding:20

		} 
	}

  
	return <div style={styles.box} className="noPrintBorder">
        {children}
	</div>


}



interface ImagePageI{
	task:Task;
	field:string;
	label?:string;
}

function ImagePage(props:ImagePageI){
	const {task, field, label} = props;

	const styles:CssStyles = {
		box:{
			height:"295mm",
		//	width:"208mm",
			textAlign:"center"
		} ,
		image:{
			maxWidth:"180mm",
			maxHeight:"250mm"
			//height:"75%"
		},
		label:{
			marginTop:10,
			textAlign:"center"
		} ,
		innerBox:{
			width:"100%",
			margin:"auto",
			textAlign:"center"
		} 
	}

    const router = useRouter();
	const global = useContext(GlobalContext);
	const {project,theme,ds} = global;

    const [imageData,setImageData] = useState("");

	async function loadImage(){
		const taskType = task.getType();
		const taskTypeDatas = taskType.getData().filter( tt => tt.name == field  );
		const taskTypeData:TaskTypeData = taskTypeDatas[0];
		const taskData = task.getJobDataItem(taskTypeData);
		const file = taskData.getLink() as VirtualFile;
		const image = await ds.getImageBase64(file);

		const resized = await resizeImage(image,500);
		setImageData(resized);
	}

	useEffect( () => {
		loadImage();
	},[])

	if(imageData == "")
		return null;
  
	return <Page>
		<Box alignItems="center" display="flex" p={1}  m={1}  sx={ styles.box }>
			<Box p={1} style={styles.innerBox}>
				<img src={imageData} style={styles.image} />
				<div style={styles.label}>{label}</div>
			</Box>
		</Box>
	</Page>


}