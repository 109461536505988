import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { TaskType } from "./TaskType";
import { JobTypeData } from "./JobTypeData";

export interface JobTypeI {
  id: number;
  type: string;
  deleted: boolean;
  name: string;
  description: string;
}

export class JobType extends Base {
  name: string;
  description: string;

  cachedTaskType: TaskType[];
  cachedJobData: JobTypeData[];

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.name = event.data.name;
    this.description = event.data.description;

    this.cachedTaskType = [];
    this.cachedJobData = [];
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
  }

  processTaskTypes() {
    this.cachedTaskType = this.ds.db.filter({
      type: "TaskType",
      jobTypeID: this.id,
    });
  }

  getTasks(): TaskType[] {
    return this.cachedTaskType;
  }

  processJobTypeData() {
    this.cachedJobData = this.ds.db.filter({
      type: "JobTypeData",
      jobTypeID: this.id,
    });
  }

  getData(): JobTypeData[] {
    return this.cachedJobData;
  }

  toString() {
    return this.name;
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      name: this.name,
      description: this.description,
    };
  }
}
