/*global addEventListener*/
import React, { useContext,useReducer,useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Divider, Typography, Button, CardContent, Card, CircularProgress} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import useRouter from 'use-react-router';
import { downloadTextFile } from '../../lib/download';
import { SyncEventResultI } from '../../lib/Datastore';
import { Check, Clear, Warning } from '@material-ui/icons';
import { useEffect } from 'react';
import { CssStyles } from '../../css-types';


const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));

export function DatastoreSync(){

    const router = useRouter();
    const global = useContext(GlobalContext);
	const {ds,project,theme} = global;

	const styles:CssStyles = {
		root:{
			paddingTop:64,
			paddingBottom:64,
            paddingLeft:20,
            paddingRight:20
		},
        content:{
            textAlign:"center"
        },
        error:{
            color:"red"
        },
        warningDiv:{
            margin:20
        }
	}

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    const syncTask = ds.getTask("sync");
    useEffect(() => syncTask.sub(0,"*",forceUpdate),[]);


    // const [uploads,setUploads] = useState<readonly Request[] | null>(null)
    
    // async function getFiles(){
    //     let files = await ds.cacheListUploads();
    //     setUploads(files);

    // }

    // useEffect( () => {
    //     getFiles();
    // },[])




 

    function nav(link:string){
        router.history.push(link)
    }


  const classes = useStyles();
	
	return <div style={styles.root}>
		<Header title="Datastore Sync" back={true} />
        <Card>
            <CardContent>
                <div className={classes.root}>
                    <div style={styles.content}>
                        { (!ds.isSyncing() ) ? <React.Fragment>
                            <Button fullWidth variant="outlined" color="primary" onClick={ () => ds.forceSync() }>Synchronise Database</Button>
                            <br/><br/>

                            {ds.myEvents.length == 0 && ds.uploadFileCount == 0 ? <div>Press synchronise to check if there is new data</div>  : null }
                            {ds.myEvents.length != 0 ? <div style={styles.warningDiv}><Warning style={{verticalAlign:"middle",color:"orange"}} /> You have {ds.myEvents.length} actions to synchronise</div>:null }
                            {ds.uploadFileCount > 0 ? <div style={styles.warningDiv}><Warning style={{verticalAlign:"middle",color:"orange"}} /> You have {ds.uploadFileCount} files to upload</div>:null }




                         </React.Fragment>:<CircularProgress color="primary" />}
                            {ds.syncError.length > 0 ? <div style={styles.error}><Clear style={{verticalAlign:"middle",color:"red"}} /> {ds.syncError}</div>:null} 
                    </div>
                </div>    
            </CardContent>
        </Card>
        
        <br/><br/>




                            

		<Navigation target="datastore_sync"  />
	</div>  
    
}



