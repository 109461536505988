import React, { useContext, useEffect, useReducer } from 'react';
import useRouter from 'use-react-router';
import {GlobalContext} from '../../ui/withGlobalContext';

import { Card, CardContent, Divider, Typography, Button} from "@material-ui/core";
import { Task, TaskJ, TaskStatus } from '../../lib/class/Task';

import {DataField, TaskStatusField, Timestampfield} from "../Fields";
import { TaskDurationField } from './TestingForm2021';
import { TaskType } from '../../lib/class/TaskType';
import { JhaForm } from './JhaForm';

interface PreinspectionFormI{
    task:Task;
}

export function PreinspectionForm(props:PreinspectionFormI){
    const {task} = props;

    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const job = task.getJob();
    const site = job.getSite();

    async function scheduleTest(){   
    
        let task = findExistingPendingTest();
        if(task == null)
            task = await scheduleNewTest();

        router.history.push(`/task/${task.id}`);
    }


    function findExistingPendingTest():Task|null{
        const taskType = ds.db.find<TaskType>({type:"TaskType",name:"CT Testing",jobTypeID:job.typeID});
        const filter = {
            type:"Task",
            jobID:job.id,
            typeID:taskType.id,
            status:TaskStatus.Pending
        }

        const existing = ds.db.filter(filter)
        if(existing.length > 0)
            return existing[0];
        else
            return null;
    }


    async function scheduleNewTest():Promise<Task>{

        
        const me = ds.db.getMe();
        const techID:number|null = me.techID;

        const taskType = ds.db.find<TaskType>({type:"TaskType",name:"CT Testing",jobTypeID:job.typeID});

        const t:TaskJ = {
            id:0,
            type:"Task",
            deleted:false,
            jobID:job.id,
            typeID:taskType.id,
            scheduleTimestamp:null,
            startTimestamp:null,
            endTimestamp:null,
            techID:techID,
            status:TaskStatus.Pending,
            ignoreValidation:false
        }

        const task:Task = await ds.createEntity(t);
        await task.createMissingData();;
        task.processJob();
        return task;
    }



    let showForm = false;
    if(task.status == TaskStatus.Pending || task.status == TaskStatus.Successful)
    showForm = true;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"status",forceUpdate),[]);
    



    return <div>
        <Card>
            <CardContent>
                <Typography variant="h6">Pre-inspection Form</Typography>
            </CardContent>
            <Divider/>
            
            {task.status != TaskStatus.Cancelled? <CardContent>
            <Typography variant="subtitle1">Task start information:</Typography>
            <Timestampfield task={task} label="Task Start" name="startTimestamp" preValue={task.scheduleTimestamp} />
            </CardContent>:null }

            {showForm && <JhaForm task={task} />}        

            <CardContent>
                <Typography variant="subtitle1">Please complete:</Typography>
                <DataField task={task} label="Comments" field={"Comments"} multiline />

            </CardContent>


            <CardContent>  
                <Typography variant="subtitle1">Finalise task:</Typography>
               <TaskStatusField name="status" label="Task Status" task={task} />
                {task.status != TaskStatus.Cancelled? <Timestampfield task={task} label="Task End" name="endTimestamp" preValue={task.startTimestamp} /> :null}
                <TaskDurationField task={task} />
            </CardContent> 

            <Divider />

            <CardContent> 
                <Button color="primary" fullWidth variant="outlined" onClick={scheduleTest}>Schedule New CT Test</Button>
            </CardContent>

        </Card>
    </div>
}