import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../withGlobalContext';

import {TextField, Card, CardContent, Divider, Typography,Table, TableRow, TableCell, TableBody, TableHead, Button} from "@material-ui/core";
import { Task, TaskStatus } from '../../lib/class/Task';
import { isEmpty } from "../../lib/Data";

import {DataField, AmendmentField, TaskStatusField, VirtualFileDataField, DatomField, DualDatomField, Timestampfield} from "../Fields";
import { Check, Clear, Warning } from '@material-ui/icons';
import useRouter from 'use-react-router';

interface JhaFormI{
    task:Task;
}


export function JhaForm(props:JhaFormI){
    const {task} = props;


    const rows:[string,string][] = [
        ["Do you fully understand all instructions","JHA Instructions"],
        ["Is your PPE & Equipment are suitable to undertake task","JHA PPE"],
        ["Does the job require any permits/authorities","JHA Permits"],
        ["If required, is the permit valid","JHA Permit Valid"],
        ["Are you or your team member suitably qualified and experienced for the tasks that are to be carried out","JHA Qualified"],
        ["Worksite - Access available","JHA Access"],
        ["Does work site require safety induction is current induction permit valid","JHA Induction"],
        ["Work requires manual handling, if so what method will be used to reduce risks","JHA Manual Handling"],
        ["Traffic Management - Is the worksite subject to vehicle or pedestrian traffic requiring traffic management","JHA Traffic"],
        ["Environmental - Noise Level, is there loud machinery nearby requiring earmuffs","JHA Noise"],
        ["Is there plant and machinery in the area that you should be aware of","JHA Machinery"],
        ["Is the meter panel asbestos","JHA Asbestos"],
        ["Inspection & Test - Can the metering equipment be safely: Inspected/Tested/Isolated","JHA Isolated"],
        ["Are you working near live apparatus that require controls to be implemented to eliminate the risk","JHA Live Points"],
        ["Work Site left clean & Tidy","JHA Clean"],
        ["Mechanical Aids","JHA Mechanical Aids"],
        ["Trolley","JHA Trolley"],
        ["Colleague","JHA Colleague"],
        ["Other","JHA Other"],
        ["Vehicle","JHA Vehicle"],
        ["Pedestrian","JHA Pedestrian"],
        ["Access Permit Issued","JHA Access Permit Issued"],
        ["Insulated Gloves","JHA Insulated Gloves"],
        ["Mats/Covers Safety","JHA Mats/Covers Safety"],
        ["Observers required","JHA Observers Required"]
    ];

        
    return <CardContent>
    <Typography variant="subtitle1">Please complete a Job Hazard Assessment:</Typography>
    {rows.map( r => <DataField key={r[1]} task={task} label={r[0]} field={r[1]} /> )}
    <DataField task={task} label="Note any control measures used" field={"JHA Control Measures"}  multiline={true}/>
    </CardContent>

}

