

import { DataEvent, Datastore } from "../Datastore";

import { Base } from "./Base";


export interface VirtualFileI{
    id: number;
    type: string;
    deleted:boolean;
    name:string;
    timestamp:number;
    extension:string;
    mimetype:string;
    size:number;
    hash:string;
    key:string;
    serverHas:boolean;
}

export class VirtualFile extends Base{
    
    name:string;
    timestamp:number;
    extension:string;
    mimetype:string;
    key:string;
    hash:string;
    size:number;
    serverHas:boolean;
    


    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.name =  event.data.name;
        this.timestamp = event.data.timestamp;
        this.extension =  event.data.extension;
        this.mimetype =  event.data.mimetype;
        this.hash =  event.data.hash;
        this.key =  event.data.key;
        this.serverHas =  event.data.serverHas;
        this.size =  event.data.size;
    }


    getFilename(){
        return `${this.name}.${this.extension}`;
    }



  
    toString(){
        return `${this.name}.${this.extension}`;
    }

    toJSON():VirtualFileI{
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            name:this.name,
            timestamp:this.timestamp,
            extension:this.extension,
            size:this.size,
            mimetype:this.mimetype,
            hash:this.hash,
            key:this.key,
            serverHas:this.serverHas
        };
    }


}