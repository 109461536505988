import React, { useContext,useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Divider, Typography, Button, CardContent, Card} from "@material-ui/core";

export class ErrorBoundary extends React.Component<any,any> {
    state = { hasError:false,reset:false,errorInfo:""};

    constructor(props:any) {
      super(props);
      this.state = { reset:false, hasError: false,errorInfo:"" };

    }
  
    static getDerivedStateFromError(error:any,) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error:any, errorInfo:any) {
      // You can also log the error to an error reporting service
      const msg = window.location+"\n"+errorInfo.componentStack;
      this.setState({errorInfo:msg});


      try{
        this.props.ds.api.sendError(msg);
      }catch(E){

      }
      //logErrorToMyService(error, errorInfo);
    }

    reset(){
      window.location.pathname = "/settings"
      this.setState({hasError:false,errorInfo:""})
      console.log(this.props); //.history.push("/")
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI

    	const styles = {
            root:{
                paddingTop:64,
                paddingBottom:64,
                paddingLeft:20,
                paddingRight:20
            }
        }

   
    
      return <div style={styles.root}>
        <Card>
          <CardContent>
          <h1>Something went wrong </h1>
          <pre>{this.state.errorInfo}</pre>
          <br/><br/>
          <Button fullWidth onClick={() => this.reset()} color="primary" variant="outlined">Okay</Button>
          </CardContent> 
        </Card>
        </div>
      }
  
      return this.props.children; 
    }
  }