import moment from "moment";
import { BackupRow } from "../../page/ListView/BackupRow";
import { BaseRow } from "../../page/ListView/BaseRow";
import { DataEventRow } from "../../page/ListView/DataEventRow";
import { DocumentRow } from "../../page/ListView/DocumentRow";
import { ErrorRow } from "../../page/ListView/ErrorRow";
import { ListFilterI, RequestData } from "../../page/ListView/Filters";
import { JobRow } from "../../page/ListView/JobRow";
import { KeyConflictsRow } from "../../page/ListView/KeyConflicts";
import { RequestRow } from "../../page/ListView/RequestRow";
import { TaskRow } from "../../page/ListView/TaskRow";
import { TestResultRow as TestResultRowDisp } from "../../page/ListView/TestResultRow";
import { TestResultRowRow } from "../../page/ListView/TestResultRowRow";
import { UncaughtExceptionsRow } from "../../page/ListView/UncaughtExceptionsRow";
import { AutoTestResult } from "../class/AutoTestResult";
import { Base } from "../class/Base";
import { Job } from "../class/Job";
import { JobType } from "../class/JobType";
import { Task } from "../class/Task";
import { TestResult } from "../class/TestResult";
import { TestResultRow } from "../class/TestResultRow";
import { DataEvent, Datastore } from "../Datastore";
import { SubscriptionService } from "../SubscriptionService";
import { getTimestamp } from "../time";
import { PList, PListI } from "./PList";
import { TestResult590BV2 } from "../class/TestResult590BV2";

export function createListProcessor(ds: Datastore) {
  const def: PListI[] = [
    {
      key: "Job",
      typeCheck: (v: Base) => v.type == "Job",
      timeFn: (v: any) => v.getTime(),
      temporal: true,
      Render: JobRow,
      raw: false,
      removeDeleted: false,
      groups: [
        {
          key: "All Pending",
          test: (v: Job) =>
            !v.isIgnored() && v.isPending(),
          badge: true,
          warning: false,
        },
        {
          key: "Pending Testing",
          test: (v: Job) =>
            !v.isIgnored() && v.isPending() && v.typeID === 198,
          badge: true,
          warning: false,
        },
        {
          key: "Pending CT",
          test: (v: Job) =>
            !v.isIgnored() && v.isPending() && v.typeID === 8,
          badge: true,
          warning: false,
        },
        {
          key: "Assignable",
          test: (v: Job) => !v.isIgnored() && v.isAssignable(),
          badge: true,
          warning: true,
        },
        {
          key: "Overdue",
          test: (v: Job) => !v.isIgnored() && v.isOverdue(),
          badge: true,
          warning: true,
        },
        {
          key: "Issues",
          test: (v: Job) => !v.isIgnored() && v.hasIssues(),
          badge: true,
          warning: true,
        },
        {
          key: "To Send",
          test: (v: Job) => !v.isIgnored() && !v.isSent() && v.isCompleted(),
          badge: true,
          warning: true,
        },
        {
          key: "Complete",
          test: (v: Job) => !v.isIgnored() && v.isSent() && v.isCompleted(),
          badge: true,
          warning: false,
        },
        {
          key: "Not Geocoded",
          test: (v: Job) => !v.hasLocation(),
          badge: true,
          warning: false,
        },
        {
          key: "Ignored",
          test: (v: Job) => v.isIgnored(),
          badge: true,
          warning: false,
        },
        { key: "All", test: (v: Job) => true, badge: true, warning: false },
      ],
    },
    {
      key: "Task",
      typeCheck: (v: Base) => v.type == "Task",
      timeFn: (v: any) => v.getTimestamp(),
      temporal: true,
      Render: TaskRow,
      raw: false,
      removeDeleted: true,
      groups: [
        {
          key: "Today",
          test: (v: Task) => v.isToday() && v.forMe(),
          badge: true,
          warning: false,
        },
        {
          key: "Pending",
          test: (v: Task) => v.isPending() && v.forMe(),
          badge: true,
          warning: false,
        },
        {
          key: "Issues",
          test: (v: Task) => v.hasIssues() && v.forMe(),
          badge: true,
          warning: true,
        },
        {
          key: "Complete",
          test: (v: Task) => v.isComplete() && v.forMe(),
          badge: true,
          warning: false,
        },
        { key: "All", test: (v: Task) => true, badge: true, warning: false }
      ],
    },

    {
      key: "Document",
      typeCheck: (v: Base) => v.type == "Document",
      timeFn: (v: any) => v.getTimestamp(),
      temporal: false,
      Render: DocumentRow,
      raw: false,
      removeDeleted: true,
      groups: [
        { key: "All", test: (v: Task) => true, badge: true, warning: false },
      ],
    },

    {
      key: "TestResult",
      typeCheck: (v: Base) => v.type == "TestResult",
      timeFn: (v: any) => v.getTimestamp(),
      temporal: true,
      Render: TestResultRowDisp,
      raw: false,
      removeDeleted: true,
      groups: [
        {
          key: "Issues",
          test: (v: TestResult) => v.hasError(),
          badge: true,
          warning: true,
        },
        {
          key: "Complete",
          test: (v: TestResult) => v.isComplete(),
          badge: true,
          warning: false,
        },
        {
          key: "All",
          test: (v: TestResult) => true,
          badge: true,
          warning: false,
        },
      ],
    },

    {
      key: "TestResult590BV2",
      typeCheck: (v: Base) => v.type == "TestResult590BV2",
      timeFn: (v: any) => v.getTimestamp(),
      temporal: true,
      Render: TestResultRowDisp,
      raw: false,
      removeDeleted: true,
      groups: [
        {
          key: "Issues",
          test: (v: TestResult590BV2) => v.hasError(),
          badge: true,
          warning: true,
        },
        {
          key: "Complete",
          test: (v: TestResult590BV2) => v.isComplete(),
          badge: true,
          warning: false,
        },
        {
          key: "All",
          test: (v: TestResult590BV2  ) => true,
          badge: true,
          warning: false,
        },
      ],
    },

    {
      key: "AutoTestResult",
      typeCheck: (v: Base) => v.type == "AutoTestResult",
      timeFn: (v: any) => v.getTimestamp(),
      temporal: true,
      Render: TestResultRowDisp, // maybe diff
      raw: false,
      removeDeleted: true,
      groups: [
        {
          key: "Issues",
          test: (v: AutoTestResult) => v.hasError(),
          badge: true,
          warning: true,
        },
        {
          key: "Completed",
          test: (v: AutoTestResult) => !v.hasError(),
          badge: true,
          warning: false,
        },
        {
          key: "All",
          test: (v: AutoTestResult) => true,
          badge: true,
          warning: false,
        },
      ],
    },

    {
      key: "TestResultRow",
      typeCheck: (v: Base) => v.type == "TestResultRow",
      timeFn: (v: any) => v.getTimestamp(),
      temporal: true,
      Render: TestResultRowRow, // maybe diff
      raw: false,
      removeDeleted: true,
      groups: [
        {
          key: "Issues",
          test: (v: TestResultRow) => v.hasError(),
          badge: true,
          warning: true,
        },
        {
          key: "Completed",
          test: (v: TestResultRow) => !v.hasError(),
          badge: true,
          warning: false,
        },
        {
          key: "All",
          test: (v: TestResultRow) => true,
          badge: true,
          warning: false,
        },
      ],
    },
    {
      key: "Base",
      typeCheck: (v: Base) => true,
      timeFn: (v: any) => moment(),
      temporal: false,
      Render: BaseRow,
      raw: false,
      removeDeleted: false,
      groups: [
        {
          key: "All",
          test: (v: Base) => !v.isDeleted(),
          badge: true,
          warning: false,
        },
        {
          key: "Removed",
          test: (v: Base) => v.isDeleted(),
          badge: true,
          warning: false,
        },
      ],
    },
    {
      key: "Navigation",
      typeCheck: (v: Base) => v.type == "Task" || v.type == "Job",
      timeFn: (v: any) => moment(),
      temporal: false,
      Render: BaseRow,
      raw: false,
      removeDeleted: true,
      groups: [
        {
          key: "My Tasks",
          test: (v: Task) => v.type == "Task" && v.forMe() && v.isToday(),
          badge: true,
          warning: false,
        },
        {
          key: "Job Count",
          test: (v: Job) => v.type == "Job" && !v.isIgnored() && v.isPending(),
          badge: true,
          warning: false,
        },
        {
          key: "All",
          test: (v: DataEvent) => true,
          badge: true,
          warning: false,
        },
      ],
    },
    // {
    //   key: "DataEvent",
    //   typeCheck: (v: Base) => true,
    //   timeFn: (v: any) => moment(v.timestamp),
    //   temporal: false,
    //   Render: DataEventRow,
    //   raw: true,
    //   removeDeleted: false,
    //   rawFn: async () => {
    //     const events = JSON.parse(JSON.stringify(ds.events));
    //     const myEvents = JSON.parse(JSON.stringify(ds.myEvents));
    //     events.map((e: any) => (e.myEvent = false));
    //     myEvents.map((e: any) => (e.myEvent = true));

    //     const all = events.concat(myEvents);
    //     for (let i = 0; i < all.length; i++) all[i].id = i;

    //     return all;
    //   },
    //   groups: [
    //     {
    //       key: "All",
    //       test: (v: DataEvent) => true,
    //       badge: true,
    //       warning: false,
    //     },
    //     {
    //       key: "Events",
    //       test: (v: DataEvent) => !v.myEvent,
    //       badge: true,
    //       warning: false,
    //     },
    //     {
    //       key: "My Events",
    //       test: (v: DataEvent) => v.myEvent,
    //       badge: true,
    //       warning: false,
    //     },
    //   ],
    // },
    // {
    //   key: "Requests",
    //   typeCheck: (v: Base) => true,
    //   timeFn: (v: any) => moment(v.timestamp),
    //   temporal: true,
    //   Render: RequestRow,
    //   raw: true,
    //   removeDeleted: false,
    //   rawFn: async () => {
    //     const requests: RequestData[] = await ds.api.request(
    //       "recorder/requests"
    //     );
    //     for (let i = 0; i < requests.length; i++) requests[i].id = i;

    //     return requests;
    //   },
    //   groups: [
    //     {
    //       key: "Errors",
    //       test: (v: RequestData) => v.status != 200,
    //       badge: true,
    //       warning: false,
    //     },
    //     {
    //       key: "Uploads",
    //       test: (v: RequestData) => typeof v.fileSize != "undefined",
    //       badge: true,
    //       warning: false,
    //     },
    //     {
    //       key: "All",
    //       test: (v: RequestData) => true,
    //       badge: true,
    //       warning: false,
    //     },
    //   ],
    // },
    // {
    //   key: "Errors",
    //   typeCheck: (v: Base) => true,
    //   timeFn: (v: any) => moment(v.timestamp),
    //   temporal: true,
    //   Render: ErrorRow,
    //   raw: true,
    //   removeDeleted: false,
    //   rawFn: async () => {
    //     const requests: RequestData[] = await ds.api.request("recorder/errors");
    //     for (let i = 0; i < requests.length; i++) requests[i].id = i;

    //     return requests;
    //   },
    //   groups: [
    //     {
    //       key: "All",
    //       test: (v: RequestData) => true,
    //       badge: true,
    //       warning: false,
    //     },
    //   ],
    // },
    // {
    //   key: "Backups",
    //   typeCheck: (v: Base) => true,
    //   timeFn: (v: any) => moment(v.timestamp),
    //   temporal: true,
    //   Render: BackupRow,
    //   raw: true,
    //   removeDeleted: false,
    //   rawFn: async () => {
    //     const requests: RequestData[] = await ds.api.request(
    //       "recorder/backups"
    //     );
    //     for (let i = 0; i < requests.length; i++) requests[i].id = i;

    //     return requests;
    //   },
    //   groups: [
    //     {
    //       key: "All",
    //       test: (v: RequestData) => true,
    //       badge: true,
    //       warning: false,
    //     },
    //   ],
    // },
    // {
    //   key: "KeyConflicts",
    //   typeCheck: (v: Base) => true,
    //   timeFn: (v: any) => moment(v.timestamp),
    //   temporal: true,
    //   Render: KeyConflictsRow,
    //   raw: true,
    //   removeDeleted: false,
    //   rawFn: async () => {
    //     const requests: RequestData[] = await ds.api.request(
    //       "recorder/keyConflicts"
    //     );
    //     for (let i = 0; i < requests.length; i++) requests[i].id = i;

    //     return requests;
    //   },
    //   groups: [
    //     {
    //       key: "All",
    //       test: (v: RequestData) => true,
    //       badge: true,
    //       warning: false,
    //     },
    //   ],
    // },
    // {
    //   key: "UncaughtExceptions",
    //   typeCheck: (v: Base) => true,
    //   timeFn: (v: any) => moment(v.timestamp),
    //   temporal: true,
    //   Render: UncaughtExceptionsRow,
    //   raw: true,
    //   removeDeleted: false,
    //   rawFn: async () => {
    //     const requests: RequestData[] = await ds.api.request(
    //       "recorder/uncaughtExceptions"
    //     );
    //     for (let i = 0; i < requests.length; i++) requests[i].id = i;

    //     return requests;
    //   },
    //   groups: [
    //     {
    //       key: "All",
    //       test: (v: RequestData) => true,
    //       badge: true,
    //       warning: false,
    //     },
    //   ],
    // },
  ];

  const lp = new ListProcessor(ds, def);
  return lp;
}

export class ListProcessor extends SubscriptionService {
  ds: Datastore;
  lists: PList[];

  lastMyEventCount: number;
  lastEventCount: number;
  hasRollup: boolean;
  processCount: number;
  lastFullProcess: number;

  loading: boolean;

  constructor(ds: Datastore, lists: PListI[]) {
    super("LP");
    this.ds = ds;
    this.lists = lists.map((list) => new PList(list));
    this.lastMyEventCount = 0;
    this.lastEventCount = 0;
    this.hasRollup = false;
    this.processCount = 0;
    this.lastFullProcess = 0;
    this.loading = true;
  }

  isLoading() {
    return this.loading;
  }

  processRequired() {
    const result =
      this.isFullProcessDue() ||
      this.hasRollup ||
      this.lastEventCount != this.ds.events.length ||
      this.lastMyEventCount != this.ds.myEvents.length;
    //console.log("processRequired",this.isFullProcessDue() , this.hasRollup, this.lastEventCount, this.ds.events.length, this.lastMyEventCount, this.ds.myEvents.length, " => ",result)
    return result;
  }

  reset() {
    this.lastMyEventCount = 0;
    this.lastEventCount = 0;
    this.hasRollup = false;
    this.loading = true;
    this.lastFullProcess = 0;
    for (const list of this.lists) list.reset();
  }

  isFullProcessDue() {
    const prev = moment(this.lastFullProcess);
    const now = moment();

    return now.isAfter(prev, "date");
  }

  async run() {
    console.log("[lp] running processor");

    this.ds.db.filter({ type: "Job" }).map((t: Job) => t.resetTasks());
    this.ds.db.filter({ type: "Task" }).map((t: Task) => t.processJob());
    this.ds.db.filter({ type: "JobType" }).map((t: JobType) => {
      t.processJobTypeData();
      t.processTaskTypes();
    });

    if (this.isFullProcessDue()) {
      console.log("doing a full process!");
      this.reset();
      this.lastFullProcess = getTimestamp();
    }

    const skipTemporalRemove =
      this.lastMyEventCount == 0 && this.lastEventCount == 0;

    const changedLookup: { [id: number]: Base } = {};
    const pool = this.ds.db.filter({}, true);

    for (
      this.lastEventCount;
      this.lastEventCount < this.ds.events.length;
      this.lastEventCount++
    ) {
      const i = this.lastEventCount;
      const event = this.ds.events[i];
      const eventChanges = this.getEventChanged(event, pool);
    
      for (const c of eventChanges) {
        if (typeof changedLookup[c.id] == "undefined") changedLookup[c.id] = c;
      }
    }

    if (this.hasRollup) {
      if (this.lastMyEventCount > 0) this.lastMyEventCount--;
      this.hasRollup = false;
    }

    for (
      this.lastMyEventCount;
      this.lastMyEventCount < this.ds.myEvents.length;
      this.lastMyEventCount++
    ) {
      const i = this.lastMyEventCount;
      const event = this.ds.myEvents[i];
      const eventChanges = this.getEventChanged(event, pool);

      for (const c of eventChanges) {
        if (typeof changedLookup[c.id] == "undefined") changedLookup[c.id] = c;
      }
    }

    this.lastMyEventCount = this.ds.myEvents.length; // in the case it is reset to 0

    //console.log(changedLookup);

    this.processChanged(Object.values(changedLookup), skipTemporalRemove);

    this.getBenchmarkTable();

    this.loading = false;
    this.render(0, "*");
  }

  processChanged(changed: Base[], skipTemporalRemove = false) {
    const startTraverse = getTimestamp();

    const bucket = changed.slice();
    const processed: { [id: number]: Base } = {};

    for (let i = 0; i < bucket.length; i++) {
      const b = bucket[i];
      if (typeof processed[b.id] == "undefined") {
        processed[b.id] = b;
        
        const children = b.getChildrenBases();
        for (const child of children)
          if (typeof processed[child.id] == "undefined") bucket.push(child);
      }
    }
    const items = Object.values(processed);

    const startProcess = getTimestamp();
    console.log("[lp] traversed a total of ", items.length);
    if (items.length != 0) this.processList(items, skipTemporalRemove);
    const end = getTimestamp();
    const traverseDuration = Math.round((startProcess - startTraverse) / 1000);
    const processDuration = Math.round((end - startProcess) / 1000);
    console.log(`[lp] took ${traverseDuration}s to traverse`);
    console.log(`[lp] took ${processDuration}s to process`);
  }

  getEventChanged(event: DataEvent, pool: Base[]): Base[] {
    const changed: Base[] = [];
    if (event.action == "create") {
      changed.push(this.ds.db.get(event.filter.id));
    } else if (event.action == "update") {
      // console.log(JSON.stringify(event.filter));

      if ("id" in event.filter) {
        // console.log(event);
        const item = this.ds.db.get(event.filter.id);
        if(item)
          changed.push(item);
        else
          console.log(event, "targets non existing item")

      }
      // for (const entity of pool) {
      //   if (entity.filter(event.filter)) {
      //     changed.push(entity);
      //   }
      // }
    }

    // console.log(changed);
    return changed;
  }

  process(item: Base, skipProcess = false, skipTemporalRemove = false) {
    for (const list of this.lists)
      if (!list.raw) list.process(item, skipProcess, skipTemporalRemove);
  }

  processList(items: Base[], skipTemporalRemove: boolean) {
    let s, e;
    s = Date.now();
    for (const item of items) this.process(item, true, skipTemporalRemove);
    e = Date.now();

    console.log(`process ${Math.round((e - s) / 1000)}`);

    s = Date.now();

    for (const list of this.lists) {
      const ss = Date.now();
      list.processTemporalSet();
      const ee = Date.now();

      console.log(
        `processTemporalSet ${list.key} ${Math.round((ee - ss) / 1000)}`
      );
    }

    e = Date.now();

    console.log(`-----------------`);
    console.log(`processTemporalSet ${Math.round((e - s) / 1000)}`);

    this.processCount++;
  }

  get(key: string): ListFilterI {
    if (this.processRequired()) {
      const task = this.ds.getTask("process");
      task.triggerRun();
    }

    for (const list of this.lists) {
      if (list.key == key) return list.getList();
    }

    throw `No List with key ${key}`;
  }

  getRaw(key: string): PList {
    for (const list of this.lists) {
      if (list.key == key) return list;
    }

    throw `No List with key ${key}`;
  }

  toJSON() {
    const j: any = {
      lists: this.lists.map((list) => list.toJSON()),
      lastMyEventCount: this.lastMyEventCount,
      lastEventCount: this.lastEventCount,
      hasRollup: this.hasRollup,
      processCount: this.processCount,
      lastFullProcess: this.lastFullProcess,
    };
    return j;
  }

  fromJSON(json: any, ds: Datastore) {
    //this.lists = lists.map( list => new PList(list));
    this.lastMyEventCount = json.lastMyEventCount;
    this.lastEventCount = json.lastEventCount;
    this.hasRollup = json.hasRollup;
    this.processCount = json.processCount;
    this.loading = json.loading;
    this.lastFullProcess = json.lastFullProcess;

    for (let i = 0; i < json.lists.length; i++) {
      const list = json.lists[i];
      if (list.key == this.lists[i].key) {
        this.lists[i].fromJSON(list, ds);
      }
    }
  }

  getBenchmarkTable() {
    const children = this.lists.map((l) => l.getBenchmarkTable()).flat();
    const t = children.map((c) => ({
      parent: c[0],
      key: c[1],
      time: c[2],
      count: c[3],
      avg: c[4],
    })); //console.log(children);
    console.table(t);
  }
}

// this.lists = lists.map( list => new PList(list));
// this.lastMyEventCount = 0;
// this.lastEventCount = 0;
// this.hasRollup = false;
// this.processCount = 0;
// this.loading = true;
