import React, { useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, Theme, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';


import useMediaQuery from '@material-ui/core/useMediaQuery';

import {JobCard} from "../../ui/ClassView/JobCard"
import {SiteCard} from "../../ui/ClassView/SiteCard"
import {TaskCard} from "../../ui/ClassView/TaskCard"
import { DocumentCard } from '../../ui/ClassView/DocumentCard';
import { Document } from '../../lib/class/Document';
import { CssStyles } from '../../css-types';


export function DocumentView(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const sm = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));



	const styles:CssStyles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		}, 
        main:{
            paddingLeft:sm?0:20,
            paddingRight:sm?0:20
          },
          loading:{
              textAlign:"center",
              marginTop:100,
              color:"#888"
          }
	}

    console.log(props);
    const id = props.match.params.id;
    const doc = ds.db.get(id) as Document;

    const [loaded,setLoaded] = useState(false);
    useEffect( () => setLoaded(true),[]);

	return <div style={styles.root} key={"docView"+id}> 	
            <Header title={"Document "+doc.toString()} back={true} />
            {loaded?<div style={styles.main}>
                <DocumentCard value={doc} />
            </div>:<div style={styles.loading}>Loading</div>}
        </div>
    
}

