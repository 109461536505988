import React, { useContext } from "react";

import { Document, DocumentI } from "../../lib/class/Document";
import { GlobalContext } from "../../ui/withGlobalContext";


import useRouter from "use-react-router";
import moment from "moment";
import { List as ListIcon, Map, Add as AddIcon } from "@material-ui/icons";
import { Fab } from "@material-ui/core";
import { CssStyles } from "../../css-types";

interface ListActionsI{
    type:string;
    map:boolean;
    setMap:(v:boolean)=>void;
}


export function ListActions(props:ListActionsI){
    const {type,map,setMap} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const router = useRouter();


    const styles:CssStyles = {
        base:{
            position:"fixed",
            right:20,
            bottom:70
        }
    }

    const add:any = {
        "Document":async () => {
            const d:DocumentI = {
                id:0,
                deleted:false,
                type:"Document",
                timestamp:moment().valueOf(),
                label:"Untitled",
                description:"",
                fileID:null

            }

            const doc = await ds.createEntity(d);
            router.history.push("/entity/"+doc.id);
            return;
        }
    }



    const buttons:any = [];

    if(typeof add[type] != "undefined"){
        buttons.push(  <Fab key="Add" color="primary" onClick={add[type]}>
        <AddIcon />
        </Fab>)
    }

    if(type == "Job" || type == "Task"){
        if(map){
            buttons.push(  <Fab key="mapButton" color="primary" onClick={() => setMap(false)}><ListIcon /></Fab>)
        }else{
            buttons.push(  <Fab key="mapButton" color="primary" onClick={() => setMap(true)}><Map /></Fab>)
        }
       
    }


    return <div style={styles.base}>
        {buttons}
    </div>

    
}