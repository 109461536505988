import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Divider, Typography, Button, CardContent, Card} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import useRouter from 'use-react-router';
import { ReadOnlyField } from '../../ui/Fields';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));

export function Settings(){

    const router = useRouter();
    const global = useContext(GlobalContext);
	const {ds,project,theme} = global;

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64,
            paddingLeft:20,
            paddingRight:20
		}
	}


    async function logout(){
        router.history.push("/logout")
    }

    
 
    function nav(link:string){
        router.history.push(link)
    }

  const classes = useStyles();


  const me = ds.db.getMe();

	
	return <div style={styles.root}>
		<Header title="Settings" back={true} />

        <Card>
        <CardContent>
            <Typography variant="h5">User</Typography>
        </CardContent>
        <Divider />
        <CardContent>
            <ReadOnlyField label="Name"  value={me.toString()}   />
            <ReadOnlyField label="Email" value={me.email}   />            
            <Button fullWidth variant="outlined" color="primary" onClick={logout}>Logout</Button>   
        </CardContent>
     </Card>

            <br/>
          
    
            <Card>
        <CardContent>
            <Typography variant="h5">App version</Typography>
        </CardContent>
        <Divider />
        <CardContent>
           1.0.1
        </CardContent>
     
     </Card>

   
     <br/>


     <Card>
        <CardContent>
            <Typography variant="h5">Datastore</Typography>
        </CardContent>
        <Divider />
        <CardContent>
            <div>Events: {ds.events.length}</div>
            <div>My Events: {ds.myEvents.length}</div>
            <div>Entities Result: {ds.db.eventResult.length}</div>
            <div>My Entities Result: {ds.db.myEventResult.length}</div>
        </CardContent>
     
     </Card>

   
     <br/>

            <Card>
                <CardContent>
                    <Typography variant="h5">Actions</Typography>
                </CardContent>
                <Divider />
                <CardContent>
                    <div className={classes.root}>
                        <Button fullWidth variant="outlined" color="primary" onClick={() => nav("/admin")}>Admin Tools</Button>   
                       </div>
                </CardContent>
            </Card>

    
		<Navigation target="settings"  />
	</div>  
    
}
