import { API } from "./API";
import { sleep } from "./async";
import { Base } from "./class/Base";
import { Database } from "./class/Database";
import { SessionI } from "./class/Session";
import { VirtualFile } from "./class/VirtualFile";
import { generateRandomHexString } from "./Data";
import { DatastoreTask, DatastoreTaskI } from "./DatastoreTask";
import { createListProcessor, ListProcessor } from "./ListProcessor/ListProcessor";
import { objectsEqual } from "./Object";
import { SubscriptionService } from "./SubscriptionService";
import { getTimestamp } from "./time";

export type Action = "create"|"remove"|"update";

export interface SyncEventResultI{
    timestamp:number;
    newItems:number;
    newTotal:number;
    oldTotal:number;
    pushedItems:number;
}


export interface DataEvent{
    userID:number;
    timestamp:number;
    action:Action;
    data:any;
    filter:any;
    key:string;
    myEvent:boolean;
}


type DatastoreTaskLookup = {[key:string]:DatastoreTask};

export class Datastore extends SubscriptionService{

    readonly protocol:string;
    readonly domain:string;
    readonly apiDomain:string;
    readonly api:API;
    readonly db:Database;
    readonly lp:ListProcessor;
    readonly cache:string;
    readonly hasUpdated:()=>void;
    readonly version:number;

    private tasksI:DatastoreTaskI[];
    private tasks:DatastoreTask[];
    private taskLookup:DatastoreTaskLookup;
    private taskInterval:NodeJS.Timeout|null;
    private packupEvent:any;

    initialised:boolean;
    
    session:SessionI|null;
    events:DataEvent[];
    myEvents:DataEvent[];
    uploadFileCount:number;

    autoSync:boolean;
    debug:boolean;

    hasRollup:boolean;
    lastEventCount:number;
    lastMyEventCount:number;
    lastProcessSave:number;
    syncError:string;
    syncStats:SyncEventResultI|null;


    constructor(updateFn:()=>void){

        super("datastore");

        this.protocol = "https";
        this.domain = "geometer.epidev.com";//window.location.host;
        this.apiDomain = "api."+this.domain;
        this.cache = "geometer3";
        this.version = 0;

        this.api = new API(this);
        this.db = new Database(this);
        this.lp = createListProcessor(this);
        this.hasUpdated = updateFn;

        
        this.initialised = false;

        this.session = null;
        this.events = [];
        this.myEvents = [];
        this.uploadFileCount = 0;

        this.autoSync = true;
        this.debug = true;


        this.hasRollup = false;
        this.lastEventCount = 0;
        this.lastMyEventCount = 0;
        this.lastProcessSave = 0;

        this.syncError = "";
        this.syncStats = null;

        this.tasksI = [
            {key:"save",rate:60*1000,due:getTimestamp(),requiredFn: () => this.saveRequired(),running:false,fn: async () => await this.save()  },
            {key:"sync",rate:5*60*1000,due:getTimestamp(),requiredFn: () => true,running:false,fn: async () => await this.runSyncEvent() },
            {key:"process",rate:45*1000,due:getTimestamp(),requiredFn: () => this.lp.processRequired(),running:false,fn: async () => await this.lp.run()}
        ];

        this.tasks = [];
        this.taskLookup = {};
        this.taskInterval = null;
        this.packupEvent = null;

        for(const taskI of this.tasksI){   
            const task = new DatastoreTask(taskI);
            this.tasks.push(task);
            this.taskLookup[task.key] = task;
        }


    }
    


    saveRequired(){
        return this.hasRollup || this.lastEventCount != this.events.length || this.lastMyEventCount != this.myEvents.length || this.lastProcessSave != this.lp.processCount;
    }



    async login(email:string,password:string){
		const e = email.trim().toLocaleLowerCase();
		const p = password.trim();
        const session = await this.api.request("login",{email:e,password:p});
        this.session = session;
        await this.syncEvents(); //await this.loadAPI();
        await this.save()
        this.setup();      
        this.updated();

    }

	async logout(force=false){
		if(!force && this.requiresSync())
			throw "Datastore requires sync before logout"
		
		if(!force)
			await this.api.request("logout",{}); 

        await this.cacheRemove();
        this.cleanSetup();
	}

    async init(){
        console.log('%c datastore init', 'background: #222; color: #bada55'); 
        try{
            await this.load();
        }catch(E){
            this.cleanSetup();
        }
      
    }

    cleanSetup(){

        this.unSetup();
        this.initialised = true;

        this.session = null;
        this.events = [];
        this.myEvents = [];
        this.uploadFileCount = 0;

        this.autoSync = true;
        this.debug = true;


        this.hasRollup = false;
        this.lastEventCount = 0;
        this.lastMyEventCount = 0;
        this.lastProcessSave = 0;

        this.syncError = "";
        this.syncStats = null;

        this.taskInterval = null;
        this.packupEvent = null;

    }



    async load(){
        const cache = await this.cacheGet();
        const json = JSON.parse(cache);

        this.autoSync = json.autoSync;
        this.debug = json.debug;
        this.events = json.events;
        this.myEvents = json.myEvents;
        this.lastEventCount = this.events.length;
        this.lastMyEventCount = this.myEvents.length;
        this.session = json.session;

        this.uploadFileCount = (await this.cacheListUploads()).length;
    
        if(typeof json.taskDue != "undefined")
            for(const taskI of this.tasksI){
                if( typeof json.taskDue[taskI.key] != "undefined" )
                    taskI.due = json.taskDue[taskI.key]
            }   

        this.updated();
        this.lp.fromJSON(json.lp,this);
        this.lastProcessSave = json.lp.processCount
        await this.lp.run();
        this.setup();
    }



    setup(){
        this.enableTaskInterval();
        this.registerPackUp();  
        this.initialised = true;
    }

    unSetup(){
        this.unregisterPackUp();
        this.disableTaskInterval();
    }


    enableTaskInterval(){
        this.disableTaskInterval();

        this.taskLookup["save"].rescheduleTask();
        this.taskLookup["sync"].rescheduleTask();
        this.taskLookup["process"].rescheduleTask();

        this.taskInterval = setInterval(() => this.taskProcess() ,5*1000); // run every 10 seconds
    }


    disableTaskInterval(){
        if( this.taskInterval != null){
            clearInterval(this.taskInterval);
            this.taskInterval = null
        }
    }



    getTasks(){
        return this.tasks;
    }

    getTask(key:string){
        if(typeof this.taskLookup[key] != "undefined")
            return this.taskLookup[key];
        else
            throw `No task with the key ${key}`;
    }
  


    async taskProcess(){
        const now = getTimestamp();
        for(const task of this.tasks)
            await task.process();
    }



    hasSession(){
        return this.session != null;
    }

    requiresSync(){
        if(this.myEvents.length > 0)
            return true;

        if(this.uploadFileCount > 0)
            return true;


        return false;

    }

    toggleAutoSync(){
        this.autoSync = !this.autoSync;
        this.hasUpdated();
        this.forceSave();
    }


    toggleDebug(){
        this.debug = !this.debug;
        this.hasUpdated();
        this.forceSave();
    }

    async forceSave(){
        await this.taskLookup["save"].forceRun();
    }

    async forceSync(){
        await this.taskLookup["sync"].forceRun();
    }  

    isSyncing(){
        return this.taskLookup["sync"].isRunning();
    }

    pushSyncDueForward(){  
        this.taskLookup["save"].rescheduleTask();
        this.taskLookup["sync"].rescheduleTask(5*60*1000);
        this.taskLookup["process"].rescheduleTask(10*60*1000);
    }

    registerPackUp(){
        this.unregisterPackUp();

        this.packupEvent = (e:any) => {
            if(this.taskLookup["save"].isRequired()){
                this.save();
                e.preventDefault();
                e.returnValue = "Datastore has not finished saving";
            }
        }
        
        window.addEventListener("beforeunload",this.packupEvent)
    }
    unregisterPackUp(){
        if(this.packupEvent != null){
            window.removeEventListener("beforeunload",this.packupEvent);
            this.packupEvent = null;
        }
    }

    updated(){
        let userID:null|number = null;
        if(this.session != null) 
            userID = this.session.userID;

        this.db.process(userID,this.events,this.myEvents);
        //this.hasUpdated();

    }



    toJSON(){
        const d:any = {};
        d.initialised = this.initialised;
        d.cache = this.cache;
        d.version = this.version;
        d.events = this.events;
        d.myEvents = this.myEvents;
        d.autoSync = this.autoSync;
        d.debug = this.debug;
        d.session = this.session;

        try{
            d.lp = this.lp.toJSON();
        }catch(E){
            
        }

        d.taskDue = {}; 
        for(const task of this.tasks)
            d.taskDue[task.key] = task.getDue();



        return d;
    }

    toJSONDebug(){
        try{
            const j = this.toJSON();
            j.events = j.events.length;
            j.myEvents = j.myEvents.length;
            j.lp = JSON.stringify(j.lp).length;

            return j;
        }catch{
            return "error";
        }
    }

    async cacheGet():Promise<any>{  
		return await this.cacheGetFile("/datastore.json");
	}

	async cacheGetFile(file:string):Promise<any>{
			const cache = await caches.open(this.cache);
			const response = await cache.match(file);
			if(typeof response != "undefined"){
				const data = await response.text();
				return data;
			}else{ 
				console.log("[ds] cache did not exist");
				throw "cache did not exist";
			}

	}

    async save(){
        await sleep(100);
        await this.cacheSave();

        this.lastEventCount = this.events.length;
        this.lastMyEventCount = this.myEvents.length;
        this.lastProcessSave = this.lp.processCount;
        this.hasRollup = false;
    }



	async cacheSave():Promise<void>{
        const cache = await caches.open(this.cache)
        const data = JSON.stringify(this.toJSON());
        console.log("[ds] cache saved",'/datastore.json');
        await cache.put('/datastore.json', new Response(data));	
	}


    async cacheRemove():Promise<void>{
        const cache = await caches.open(this.cache);
        const result = await cache.delete('/datastore.json');
        console.log("cache remove",result);
    }


    async createEntity(data:any){


        if(this.session == null) 
            throw "No session";

        const copied = Object.assign({},data);
        const newID = this.db.myEventResult.length;
        copied.id = newID;
        copied.deleted = false;

        //default attributes

        const event:DataEvent = {
            timestamp:getTimestamp(),
            userID:this.session.userID,
            data:copied,
            filter:{id:newID}, //for easy roll up
            action:"create",
            key:generateRandomHexString(32),
            myEvent:true
        } 

        this.myEvents.push(event);
        this.updated();
        this.pushSyncDueForward();
        
        //this.triggerProcess();
        const entity = this.db.get(newID);

        this.render(entity.id,"*");



        return entity;

    }


    async updateEntity(entity:any,data:any,rollup=false){
        const cloned = {...data}
        delete cloned.id;

        if(this.session == null) 
            throw "No session";


        let done = false;

        if(rollup && this.myEvents.length > 0){  //check if last event is same filter
            const lastEvent = this.myEvents[this.myEvents.length-1]
            if( objectsEqual(lastEvent.filter,{id:entity.id}) ){
                Object.assign(lastEvent.data,cloned);
                this.myEvents[this.myEvents.length-1] = lastEvent; //reassign
                done = true;
                this.hasRollup = true;
                this.lp.hasRollup = true;
            }
        }


        if(!done){
            const event:DataEvent = {
                timestamp:getTimestamp(),
                userID:this.session.userID,
                data:cloned,
                filter:{id:entity.id},
                action:"update",
                key:generateRandomHexString(32),
                myEvent:true
            } 

            this.myEvents.push(event);
        }


        this.updated();
        this.pushSyncDueForward();

        const updated_entity = this.db.get(entity.id);


        for(const key in data)
            this.render(entity.id,key);

        this.render(entity.id,"*");


        return updated_entity;

    }


    async upsert(filter:any,data:any){
        const subjects = this.db.filter(filter);
      
        let subject;
        if(subjects.length === 0){
            console.log("not found creating")
            subject = await this.createEntity(data);
        } else if(subjects.length > 1){
            throw new Error("Multiple matches");
        }else{
            console.log("found match")
            subject = subjects[0];
            subject = await this.updateEntity(subject,data,true); // TODO check into this
        }

        return subject
    }


    async updateEntityField(entity:Base,e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>|any){
        let n:string;
        let v:any;
        
        let p:any = {};
        if("target" in e){
            n = e.target.name;
            v = e.target.value;
            p[n] = v;
        }else{
           p = e;
        }

        console.log(p);

        //TODO validate name key
        await this.updateEntity(entity,p,true);
        //await this.save();
        //console.log("updateEntityField done")
    }

    async clearMyEvents(){
        this.myEvents = [];
        await this.save();     
         this.updated();

    }
    async clearEvents(){
        this.events = [];
        await this.save();
        this.updated();

    }

    async loadAPI(){
        const new_events:DataEvent[] = await this.api.request(`datastore?from=${this.events.length}`); 
        new_events.map( e => e.myEvent = false);
        this.events = this.events.concat(new_events);
    }

    async injectAPI(){
		await this.api.request("inject",{events:this.myEvents});
  
	}




    calcRollupEventOffsets(events:DataEvent[]):[number,number]{
        const nextID = this.db.eventResult.length; 
        let firstIncomingID:number = nextID;
        let found = false;
        for(const event of events){
            if(event.action == "create"){
                firstIncomingID = event.filter.id;
                found = true;
                break;
            }
        }
        const offsetAmount = nextID-firstIncomingID;

        return [firstIncomingID,offsetAmount];

    }

    rollupEventIDs(e:DataEvent,firstIncomingID:number,offsetAmount:number){
        const event:any = Object.assign({},e) as DataEvent;
        const mods = ["data","filter"];
        for(const mod of mods){
            const dataKeys = Object.keys(event[mod]).filter( key => key == "id" || (key.indexOf("ID") != -1)  );
            for(const key of dataKeys){
                const id = event[mod][key]
                if( id !== null && id >= firstIncomingID ){
                    event[mod][key] =  id + offsetAmount;
                    console.log(`rolling up event[${mod}][${key}] = ${id} -> ${event[mod][key]}`);
                }
            }
        }
        return event as DataEvent;
    }

    async syncEvents():Promise<SyncEventResultI>{
        const oldEventCount = this.events.length;
        const oldMyEventCount = this.myEvents.length;
        const oldTotal = oldEventCount+oldMyEventCount;

		const new_events = await this.api.request("syncEvents",{myEvents:this.myEvents,eventCount:this.events.length});
        this.events = this.events.concat(new_events);

        let tempMyEvents = this.myEvents.slice(oldMyEventCount);
        const [firstIncomingID,offsetAmount] = this.calcRollupEventOffsets(tempMyEvents);
        tempMyEvents = tempMyEvents.map( e => this.rollupEventIDs(e,firstIncomingID,offsetAmount) )

        this.myEvents = tempMyEvents; // keep any new items that were added after request was initiated
        //this.requiresSave = true; this.triggerProcess()
        this.updated();

        const newTotal = this.events.length;
        const newItems = newTotal-oldTotal;
        const pushedItems = oldMyEventCount
        const timestamp = getTimestamp();

        return {newItems,newTotal,oldTotal,pushedItems,timestamp}
        
    }


    private async runSyncEvent():Promise<void>{

        try{
            this.syncError = "";
            this.autoSync = true;
            this.hasUpdated();

            try{
                if(this.autoSync){
                    const stats = await this.syncEvents();
                    this.syncStats = stats;
                }
            }catch(E:any){
                this.syncError = E.toString();
            }

            this.hasUpdated();
            if(this.autoSync)
                await this.processUploads();
        }catch(E){
            console.error(E);
        }

        this.hasUpdated();
    }




    async cacheUploadSave(file:File,key:string):Promise<void>{
		const cache = await caches.open(this.cache)
        //let hash = await SHA256fromFileHex(file);
        const filename = "/uploads/"+key
        const data = file // apparently already a blob
        //console.log(data);
        await cache.put(filename, new Response(data));
        console.log("[ds] upload cache saved",filename);	


        this.uploadFileCount = (await this.cacheListUploads()).length;
        this.hasUpdated();

	}

	async cacheListUploads():Promise<readonly Request[]>{  
        const cache = await caches.open(this.cache);
        let keys = await cache.keys();
        keys = keys.filter( key => key.url.indexOf("/uploads/") != -1 );
    
        return keys;
    }

    async clearUploadCache(){
        const uploads = await this.cacheListUploads();
		const cache = await caches.open(this.cache)
        for(const upload of uploads){
            cache.delete(upload);
        }


        this.uploadFileCount = (await this.cacheListUploads()).length;
        this.hasUpdated();
    }

    async processUpload(target:Request){
        const cache = await caches.open(this.cache);
        //console.log("[ds] cache get "+target.url);
        const item = await cache.match(target.url);
        if(item == undefined)
            throw "does not exist in cache"

        const buffer = await item.blob()
        const url = target.url;
        const key = url.substring(url.lastIndexOf("/")+1);
        //console.log(key)
        //let calcHash = await SHA256fromBlobHex(buffer);
        console.log("[ds] uploading "+target.url);
        const result = await this.api.request("upload",buffer,key);

        //pull file updates
        const stats = await this.syncEvents();

        console.log("[ds] done uploading");
        await cache.delete(target); // server does the check, remove if request was okay

        // const virtualFile = this.db.find({type:"VirtualFile",key:key}) as VirtualFile;
        // await this.updateEntityField(virtualFile,{serverHas:true});      
        

        this.uploadFileCount = (await this.cacheListUploads()).length;

        this.hasUpdated();
    }


    async processUploads(){
        const available = await this.cacheListUploads();
        if(available.length == 0){ 
                return true;
        }else{
            for(const target of available){
                await this.processUpload(target);
            }
            await this.syncEvents() // resync to get virtual file updates
        }
    }



    async getImageBase64(virtualFile:VirtualFile){
       
        const ab = await this.geVirtualFile(virtualFile);
        const uint8 =  new Uint8Array( ab );
        let bin = "";
        for(let i = 0; i < uint8.length; i++)
            bin += String.fromCharCode(uint8[i]);
        return `data:${virtualFile.mimetype};base64,${btoa(bin)}`;
       
    }

    

    async geVirtualFile(virtualFile:VirtualFile):Promise<ArrayBuffer>{
        if(virtualFile.serverHas){
            const ab = await this.api.request("uploads/"+virtualFile.id) as ArrayBuffer;
            return ab;
        }else{
            const cache = await caches.open(this.cache);
            const file = await cache.match("/uploads/"+virtualFile.key);
            if(file == undefined) throw "Missing file in cache"
            const ab = await file.arrayBuffer();
            return ab;
        }

    }

    

}
