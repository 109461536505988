
import CSVparse from 'csv-parse';

export async function importJSON(file:File){
	const text = await readFileText(file)
	return JSON.parse(text);
}




export async function importCSV(file:File){
	const text = await readFileText(file)
	const table = await readCSVFile(text);
	const data = translateCSVFile(table);
	return data;
}

async function translateCSVFile(table:any){
	const header = table.shift(); // remove header
	const body = table;

	const data:any = [];
	for(const row of body){
		const obj:any = {};
		header.map( (col:string,i:number) => {
			const n = col.trim();
			let v = row[i];
			if(typeof v == "string")
			 v = v.trim();
			obj[n] = v;
		});
		data.push(obj);
	}
	return data;
}



export async function readFileText(file:File):Promise<string>{
	return new Promise((resolve,reject) => {
		const reader = new FileReader();
		
		reader.onload = (e:any) => {
			let d = e.target.result.toString("utf-8");
			d = d.replace(/\xA0/g," ");
			resolve(d);
		};
		
		reader.onerror = function(error:any) {
			reject(error);
		}

		reader.readAsText(file);
	});

}



async function readCSVFile(d:string){
	return new Promise((resolve,reject) => {
		d = d.replace(/\xA0/g," ");
		CSVparse(d, {}, (err:any, data:any) => {
			if(err != null)
				reject(err);
			else	
				resolve(data);
		});
	});
}




