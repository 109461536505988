import React, { useContext, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Button, Card, CardContent, Divider, TextField, Typography} from "@material-ui/core";

import useRouter from 'use-react-router';
import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { CssStyles } from '../../css-types';


export function Login(){

	const global = useContext(GlobalContext);
	const {project,theme,ds} = global;
	const router = useRouter();


	const styles:CssStyles = {
		heading:{
			textAlign:"center"
		} ,
		root:{
			paddingTop:20,
			paddingLeft:20,
			paddingRight:20
		},
		error:{
			textAlign:"center",
			marginTop:20,
			color:"red"
		} 
	}


	const [email,setEmail] = useState("");
	const [password,setPassword] = useState("");
	const [error,setError] = useState("");

	const [loading,setLoading] = useState(false);


	async function login(){
		setLoading(true);
		setError("");
		try{
			await ds.login(email,password);
			router.history.push("/");
		}catch(E){
			setLoading(false);
			setError((E as any).toString());
		}
	}



	return <React.Fragment>

		<div style={styles.root}>
		<Card>
            <CardContent>
                <div style={styles.heading}>Elektrotech Login</div>
            </CardContent>
			<Divider />

            <CardContent>
			<form onSubmit={(e) => { e.preventDefault(); login(); return false; } }>
				<TextField label="Email" name="email" margin="normal" fullWidth onChange={ e => setEmail(e.target.value)} autoFocus={true} />
				<TextField label="Password" name="password" type="password" margin="normal" fullWidth onChange={ e => setPassword(e.target.value)} />
				<br/><br/>
				<Button color="primary" variant="outlined" fullWidth type="submit" disabled={loading}>{loading?"Loading":"Login"}</Button>
			</form>

				{error.length > 0 ? <div style={styles.error}>{error}</div>:null}
            </CardContent>
        </Card>
		</div>
	</React.Fragment>
}