

// import { SerialOriginal as IonicSerialType, Serial as IonicSerial } from '@ionic-native/serial';
import { getTimestamp } from './time';
import { sleep } from './async';

type SerialType = "android"|"chrome";

export class Serial{

    chromeSerial:SerialPort|null
    // ionicSerial:IonicSerialType|null;
    type:SerialType;
    data:string;
    recvTimeout:number;
    acceptTimeout:number;
    lastReceived:number;
    startedTransfer:boolean;
    // error:string|null;


    reader:ReadableStreamDefaultReader<Uint8Array>|null;
    writer:WritableStreamDefaultWriter<Uint8Array>|null;

    active:boolean;

    constructor(){
        this.type = window.hasOwnProperty("cordova") ? "android" : "chrome";
        // this.ionicSerial = null;
        this.chromeSerial = null;
        this.recvTimeout = 10000;
        this.acceptTimeout = 1000;
        this.lastReceived = 0;
        this.startedTransfer = false;
        this.data = "";
        this.reader = null;
        this.writer = null;
        this.active = false

    }

    async connect(){

        this.startedTransfer = false;
        this.data = "";

        if(this.type == "android")
            await this.connectAndroid();
        else
            await this.connectDesktop();
    }

    isConnected(){
        if(this.type == "android"){
            // return this.ionicSerial != null
        }else
            return this.chromeSerial != null
    }

    async connectAndroid(){
        // const ionicSerial =  IonicSerial;//new IonicSerial();
        
        const config:any = {}//{vid: 0x03eb,pid:0x6119,driver:"CdcAcmSerialDriver"};
        // await ionicSerial.requestPermission(config);
        // await ionicSerial.open( {baudRate: 115200,dataBits:8,stopBits:1,parity:0,dtr:false,rts:true,sleepOnPause:true});
        // this.ionicSerial = ionicSerial;
        // console.log(ionicSerial);
    }

    async initReceive(){
        if(this.type == "android")
        await this.initReceiveAndroid();
    else
        await this.initReceiveDesktop();
    }




    /*
    async initReceiveAndroid(){    
        if(this.ionicSerial == null) 
            throw "Not connected";

            console.log("initReceiveAndroid")
        // let obv = this.ionicSerial.registerReadCallback();
        // console.log(obv);
        // obv.subscribe(res => {
        //     console.log(res);
        // });


//        console.log(obv);

           // const reader = this.chromeSerial.readable.getReader();

            try{
                while(true){
                    const value = await this.ionicSerial.read();
                    if( value instanceof ArrayBuffer )
                        this.recvData(value as Uint8Array);
                    else
                        console.log(value);
                
                }   

            }catch(E){

            } finally{
                await this.ionicSerial.close();
            }

    }
*/


async initReceiveAndroid(){    
    // if(this.ionicSerial == null) 
    //     throw "Not connected";

    //     console.log("initReceiveAndroid")
    // const obv = this.ionicSerial.registerReadCallback();

    // obv.subscribe( {
    //     next:(x)=>{ 
    //             if(x instanceof ArrayBuffer)
    //                 this.recvData(x as Uint8Array);
    //             else
    //             console.log('data: ', x); 
    //         },
    //     error:()=>{ console.log('errors already caught... will not run'); }
    //   });


    // console.log(obv);
}



    async initReceiveDesktop(){
        if(this.chromeSerial == null || this.chromeSerial.readable == null || this.chromeSerial.writable == null)
            throw "Not connected";
    
        this.reader = this.chromeSerial.readable.getReader();
        this.writer = this.chromeSerial.writable.getWriter();

        const t = setInterval(() => {
            if(  this.startedTransfer && (this.lastReceived + this.acceptTimeout) < getTimestamp()){
            console.log("sending y");
            if(this.writer)
                this.writer.write(new Uint8Array(['y'.charCodeAt(0)]));
            }
        },500);

        this.active = true
        
        try{
            while(this.active){
                if(this.reader){
                const { value, done } = await this.reader.read();
            
                if(value){
                    this.recvData(value)
                }

                    

                if (done) 
                  break;


                }else{
                    break;
                }
            
            }

            
    
    
        }catch(E){
     
        } finally{
            clearInterval(t);
        }

    }

    closeStreams(){

    }

    async closePort(){
        // try{
        // if(this.ionicSerial)
        //     await this.ionicSerial.close();
        // }catch(E){
        //     console.log(E);

        // }

        try{
            this.active = false;

            if(this.writer != null){
                this.writer.releaseLock();
            }
            if(this.reader != null)
                this.reader.releaseLock();

            if(this.chromeSerial)
                await this.chromeSerial.close();
            }catch(E){
                console.log(E);

    
            }
    }


    recvData(data:Uint8Array){
        this.startedTransfer = true;
        const string = new TextDecoder().decode(data);
  
        this.data += string;
        this.lastReceived = getTimestamp();

        if(string.indexOf("\n") !== -1)
            return true;

        return false;

        

    }

	async connectDesktop(){
        const chromeSerial = await navigator.serial.requestPort({filters:[]});	  // await n.serial.getPorts([{vendorID:0x03eb}])	
        await chromeSerial.open({
            baudRate:9600, 
            dataBits:8,
            stopBits: 1,
            parity: "none",
            flowControl: "none"})  // ({baudRate:115200});
        this.chromeSerial = chromeSerial;
     
	}



    async receiveData(){

        this.initReceive();
        while(true){
            await sleep(1000);
            if(  this.startedTransfer && (this.lastReceived + this.recvTimeout) < getTimestamp())
                break;
        }
    
        await this.closePort();
        console.log(this.data)
        console.log("all data received");
        return this.data;

    }


}