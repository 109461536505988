import { SHA256fromStringHex } from "../../Data";
import { Datastore } from "../../Datastore";
import { TestResult, TestResultI } from "../TestResult";
import { TestResult590BV2 } from "../TestResult590BV2";

export async function syncRecord(ds: Datastore, txtRecord: string) {
  const hexHash = await SHA256fromStringHex(txtRecord);
  const resultFilter = { type: "TestResult", hash: hexHash };

  const results = ds.db.filter(resultFilter);
  let testResult: TestResult;
  if (results.length > 0) {
    //already saved
    testResult = results[0];
    testResult = await testResult.syncLinks();
  } else {
    const resultD: TestResultI = {
      id: 0,
      type: "TestResult",
      deleted: false,
      testUnitID: null,
      recordNumber: null,
      // siteID: null,
      timestamp: null,
      data: txtRecord,
      hash: hexHash,
      techID: ds.db.getMe().id,
    };
    testResult = (await ds.createEntity(resultD)) as TestResult;
    testResult = await testResult.syncLinks();
  }

  return testResult;
}



export async function syncRecord590GV2(ds: Datastore, txtRecord: string) {
  const hexHash = await SHA256fromStringHex(txtRecord);
  const resultFilter = { type: "TestResult590BV2", hash: hexHash };

  const results = ds.db.filter(resultFilter);
  let testResult: TestResult590BV2;
  if (results.length > 0) {
    //already saved
    testResult = results[0];
    testResult = await testResult.syncLinks();
  } else {
    const resultD: TestResultI = {
      id: 0,
      type: "TestResult590BV2",
      deleted: false,
      testUnitID: null,
      recordNumber: null,
      // siteID: null,
      timestamp: null,
      data: txtRecord,
      hash: hexHash,
      techID: ds.db.getMe().id,
    };
    testResult = (await ds.createEntity(resultD)) as TestResult590BV2;
    testResult = await testResult.syncLinks();
  }

  return testResult;
}
