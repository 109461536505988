


export function objectsEqual(a:any,b:any){
    if(typeof a != "object" || typeof b != "object" || a === null || b === null)
        return false;

    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);

    if(aKeys.length != bKeys.length)
        return false;

    for(const key of aKeys){
        if(bKeys.indexOf(key) == -1)
            return false;

        if(a[key] != b[key])
            return false;
    }

    return true;
}