import React from 'react'
import {Task, TaskStateToColor, TaskStateToString } from '../lib/class/Task';
import { ColorChip } from './ColorChip';




interface TaskChipI{
    task:Task;
    taskName:boolean;
}

export function TaskChip(props:TaskChipI){
    const {task,taskName} = props;

    const styles = {
        chip:{
            marginLeft:5,
            marginRight:5
        }
    };

    const state = task.getState();
    const color = TaskStateToColor(state );
    const s = Object.assign({},styles.chip);
    return <ColorChip  size="small" color={color} style={s} label={TaskStateToString(state)} />

}