
  

  import {sleep} from "./async";
import { google } from "google-maps";

//import { wait } from "./async";

/*global google*/

//import * as googleMaps from '@google/maps';

//let googleMapsClient:googleMaps.GoogleMapsClient;
// googleMapsClient is a node js client.


//   export function config(key:string){

//     let myWindow = window as any
//     myWindow.initMap = initMap;

//     function initMap(){
//      /* 
//       googleMapsClient = googleMaps.createClient({
//         key: key,
//         Promise: Promise
// 	  });
// 	  */

// 	}
	
//     //dynamically inject script tag for google maps api
//     (function(d:Document) {
// 		let script: HTMLScriptElement = d.createElement('script');
// 		script.type = 'text/javascript';
// 		script.async = true;
// 		script.defer = true;
// 		script.onload = function(){
// 			// remote script has loaded
// 		};
		
// 		script.src = 'https://maps.googleapis.com/maps/api/js?key='+key+'&region=AU&callback=initMap';
// 		d.getElementsByTagName('head')[0] .appendChild(script);
//   	}(document));



//   }

export interface LngLat{
	lat:number;
	lng:number;
}

  export async function geocode(google:google,address:string){

		while(true){
			try{
				const result = await geocodeRun(google, address);
				return result;
			}catch(E:any){
				if(E.toString() == "OVER_QUERY_LIMIT"){
					console.log("waiting 2 secs on over query")
					await sleep(2000);
				}else
					throw E;
			}
			
		}
  }


  export async function geocodeRun(google:google, address:string):Promise<LngLat>{
	const geocoder = new google.maps.Geocoder();
	return await new Promise( (resolve,reject) => {

		console.log({ 'address': address})
		geocoder.geocode( { 'address': address}, function(results, status) {
			if (status == google.maps.GeocoderStatus.OK) {
				const l = results[0].geometry.location;
				const result:LngLat = {lat:l.lat(),lng:l.lng()}
				resolve(result);
			}else{
				reject(status);
			}
		});
	});
  }





export function getMapURL(address:string): string {
    const uri = "https://www.google.com/maps/search/?api=1&query=" + address;
    return encodeURI(uri)
}