
import useRouter from 'use-react-router';

import {ListItem,ListItemText} from "@material-ui/core";
import { Warning } from '@material-ui/icons';
import { Job } from '../../lib/class/Job';
import {  TaskStateToColor } from '../../lib/class/Task';
import { ColorChip } from '../../ui/ColorChip';
import React from 'react'
import { CssStyles } from '../../css-types';
import { TaskType } from '../../lib/class/TaskType';



export interface JobRowI{
    value:Job;
    style?:any;
}

export function JobRow(props:JobRowI){
    const {value,style} = props;
    const router = useRouter();

    const styles:CssStyles = {
        root:{
            marginBottom:20
        },
        chip:{
            marginLeft:5,
            marginRight:5
        },
        outer:{
            display:"flex"
        },
        title:{
            display:"inline-block",
            whiteSpace:"nowrap",
            flexGrow: 1
        },
        warning:{
            verticalAlign:"middle"
        },
        subText:{
            width:"100%",
            overflow:"hidden",
            whiteSpace:"nowrap"
        },
        row:Object.assign({
            height:84,
            overflow:"hidden"
        },style),
        error:{
            color:"red",
            whiteSpace:"nowrap",
            fontSize:12,
        }
    };

    const site = value.getSite();
    const type = value.getType();

    let warning:any = <span style={styles.error}>{value.getIssue()} <Warning style={styles.warning} color="secondary" /></span>

    if(!value.hasIssues())
        warning = null;

    // if(!value.isOverdue()){
    //     let due = value.getDueDate();
    //     dueString = <React.Fragment>due in {due.fromNow()}</React.Fragment>
    // }


    let requiredTasks:TaskType[] = [];
    try{
        requiredTasks = type.getTasks().filter( t => t.isRequired() );
    }catch(E){
        
    }
    const chips =  requiredTasks.map( t => {
        const color = TaskStateToColor( value.getTaskState(t) );
        const s = Object.assign({},styles.chip);
        return <ColorChip key={t.id} size="small" color={color} style={s} label={t.name} />
    })

    let typeInfo:any = null;
    try{
        typeInfo = <span style={styles.title} >{type.toString()}</span>
    }catch{
        typeInfo = <span>Invalid type info</span>
    }

    let siteInfo:any = null;
    try{
        siteInfo = <span style={styles.subText}>{site.getNMI()} &bull; {site.getAddress()}</span>
    }catch{
        siteInfo = <span>invalid site</span>
    }

    return <ListItem style={styles.row} button divider onClick={() => router.history.push("/job/"+value.id)} >
        <ListItemText primary={<span style={styles.outer}>{typeInfo} {warning} {chips} </span>} secondary={siteInfo} />
    </ListItem>

}