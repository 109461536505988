import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, List,ListItem, ListItemText,  Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, IconButton, TextField, MenuItem} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { Map, Message, Phone, Navigation, Add, Remove, Cancel, Clear } from '@material-ui/icons';
import { createBlankLink } from '../../lib/download';
import { JobType } from '../../lib/class/JobType';
import { JobTypeData, JobTypeDataI } from '../../lib/class/JobTypeData';
import { TaskType } from '../../lib/class/TaskType';
import { TaskTypeData, TaskTypeDataI } from '../../lib/class/TaskTypeData';
import { DataType } from '../../lib/class/DataType';
import { EntityField, SelectField } from '../Fields';
import { CssStyles } from '../../css-types';

export interface TaskTypeCardI{
    value:TaskType
}

export function TaskTypeCard(props:TaskTypeCardI){
    const {value} = props;


    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        title:{
           // width:250,
            fontWeight:"bold"
        }
    }



    const datas = value.getData(); 
    
    const string = ds.db.find<DataType>({type:"DataType",name:"string"})

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,"*",forceUpdate),[]);

    async function addData(){
        const j:TaskTypeDataI = {
            id:0,
            type:"TaskTypeData",
            deleted:false,
            taskTypeID:value.id,
            name:"",
            dataTypeID:string.id
        };
        await ds.createEntity(j);
        forceUpdate();
       // await ds.save();
    }

    return <Card>
        <CardContent>
            <Typography variant="h6" >Task Type</Typography>
        </CardContent>
        <Divider/>
        <CardContent>

            <EntityField label="Name" name="name" type="string" entity={value} />
            <EntityField label="Description" name="description" type="string" entity={value} />
            
            <EntityField label="Required" name="required" type="checkbox" entity={value} />

            <SelectField label="Job Type" name="jobTypeID" value={value} filter={{type:"JobType"}} />   

            <br/><br/>
            <Typography variant="subtitle1" >Job Data Items</Typography>
            <List>
            {datas.map( data => <ListItem key={data.id} ><JobTypeDataRow id={data.id} /></ListItem> )}
            </List>
            <IconButton color="primary" onClick={addData}><Add /></IconButton>
        </CardContent>


        <CardContent>
            <pre>
                {`${value.name}\n`}
                {`============\n`}
                {datas.map( d => `${d.name} ${d.id}\n`)}
            </pre>
        </CardContent>


    </Card>


}

interface JobTypeDataRowI{
    id:number;
}

function JobTypeDataRow(props:JobTypeDataRowI){
    const {id} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {
        field:{
            margin: theme.spacing(1),
        }
    }
    const value = ds.db.get(id);

    const dataTypes = ds.db.filter({type:"DataType"})
    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,"*",forceUpdate),[]);

    console.log("render",value)

    return <div>

        <EntityField label="Name" name="name" type="string" entity={value}  />
        <SelectField label="Type" name="dataTypeID" value={value} filter={{type:"DataType"}} />       
        <IconButton style={styles.field} color="secondary" onClick={() => ds.updateEntityField(value,{deleted:true})}><Clear /></IconButton>
    </div>

}

