import React, { useContext, useEffect, useState } from 'react';
import { Base } from '../../lib/class/Base';
import { Header } from '../../ui/Header';
import { GlobalContext } from '../../ui/withGlobalContext';
import { Job } from '../../lib/class/Job';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment, { Moment } from 'moment';
import { Tech } from '../../lib/class/Tech';
import { Task } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import Link from '../../ui/Link';
import { Rate } from './rates';


type Detailed = {
    job:Job;
    site:Site;
    tech:Tech;
    time:Moment;
    task:Task;
    status:string;
    jobType:string
    taskType:string;
    rate:Rate;
    profit:number;
}

type Result = {
    detailed:Detailed[]
}

export function ProfitLossAnalysis(props:any){
    const global = useContext(GlobalContext);
	const {ds} = global;

    const styles = {
        root:{
            paddingTop:64,
			paddingBottom:64,
            paddingLeft:20,
            paddingRight:20
        }
    }

    useEffect(() => {
        run();
    },[])

    const [result,setResult] = useState<Result|null>(null);

    function run(){
        const tasks:Task[] = ds.db.filter({type:"Task"});
        const completed = tasks.filter(j => j.isComplete() )

        const detailed:Detailed[] = completed.map(task => {
            const job = task.getJob()
            const tech = task.getTech()
            const site = job.getSite();
            const time = task.getEndTimestamp();
            const status = task.getTaskStatus();
            const jobType = job.getType().toString();
            const rate = task.getRate();
            const profit = rate.businessHours;
            const taskType = task.getType().toString();
            return {time, job, task, tech, site, status, jobType,taskType,rate, profit};
    
        })
        .filter(j => j.status !== "Cancelled")
        .sort((a,b) => a.time.valueOf() - b.time.valueOf());
        setResult({detailed});
    }








    if(result === null) return <div style={styles.root}>
         <Header title="Profit Loss Analysis" back={true} />
         loading
    </div>

    const startDate = moment(Math.min(...result.detailed.map(d => d.time.valueOf())))
    const endDate = moment();

    const dates = [];

    for(let i = startDate; i.isBefore(endDate);){
        dates.push(i.clone())
        i.add(1, 'day');
    }

    return <div style={styles.root}>
        <Header title="Profit Loss Analysis" back={true} />
    
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Kevin Count</TableCell>
                    <TableCell>Andrew Count</TableCell>
                    <TableCell>Total Count</TableCell>
                    <TableCell>Kevin Income</TableCell>
                    <TableCell>Andrew Income</TableCell>
                    <TableCell>Total Income</TableCell>
                    <TableCell>Kevin Loss</TableCell>
                    <TableCell>Andrew Loss</TableCell>
                    <TableCell>Total Loss</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {dates.map(date => <PrintRow key={date.valueOf()} date={date} result={result} />)}
            </TableBody>
        </Table>


        <pre>
            {result.detailed.map((j,i) => <DebugRow key={i} detailed={j} />)}
        </pre>




    </div>

}



interface DebugRowI{
    detailed:Detailed;
}

function DebugRow(props:DebugRowI){
    const {detailed} = props;
    const {time, site, jobType, status, job, taskType, profit, task, rate, tech} = detailed;
    return <div>
        <Link to={`/task/${task.id}`}>
        {time.format("DD/MM/YY HH:mm")} {jobType}:{taskType} {site.nmi} {status} {tech.firstName} {rate.code} ${profit}
        </Link>
    </div>


}









interface PrintRowI{
    date:Moment;
    result:Result
}

function PrintRow(props:PrintRowI){
    const {date,result} = props;
    const {detailed} = result;
    const todayItems = detailed.filter(j => j.time.isSame(date,'date'));

    const kevinItems = todayItems.filter(j => j.tech.firstName === "Kevin")
    const andrewItems = todayItems.filter(j => j.tech.firstName === "Andrew")

    const totalTotal = calcTotalProfit(todayItems).toFixed(2);
    const totalKevin = calcTotalProfit(kevinItems).toFixed(2);
    const totalAndrew = calcTotalProfit(andrewItems).toFixed(2);
    



    const isWeekend = date.day() === 0 || date.day() === 6

    const styles ={
        base:{
            background: isWeekend ? '#eee' : ''
        }
    }


    return <TableRow key={date.valueOf()} style={styles.base}>
        <TableCell>{date.format("DD/MM/YYYY")}</TableCell>
        <TableCell>{kevinItems.length}</TableCell>
        <TableCell>{andrewItems.length}</TableCell>
        <TableCell>{todayItems.length}</TableCell>
        <TableCell>${totalKevin}</TableCell>
        <TableCell>${totalAndrew}</TableCell>
        <TableCell>${totalTotal}</TableCell>
        <TableCell>?</TableCell>
        <TableCell>?</TableCell>
        <TableCell>?</TableCell>
    </TableRow> 


}


function calcTotalProfit(ds:Detailed[]){
    let total = 0;
    for(let i = 0; i < ds.length; i++)
        total += ds[i].profit;
    return total;
}


