
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";

export interface DatomI {
  id: number;
  type: string;
  deleted: boolean;
  value: any;
  parentID: number | null;
  typeID: number | null;
}

export class Datom extends Base {
  value: any;
  parentID: number;
  typeID: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);
    this.value = event.data.value;
    this.parentID = event.data.parentID;
    this.typeID = event.data.typeID;
  }

  toString() {
    // let parentStr = "";
    // let typeStr = "";
    // try{
    //     let parent = this.ds.db.get(this.parentID);
    //     parentStr = (parent.value);
    // }catch(E){

    // }
    // try{
    //     let type = this.ds.db.get(this.typeID);
    //     typeStr = (type.value);
    // }catch(E){
    // }

    // return `${this.value}:${typeStr} of ${parentStr}`;
    return this.value === null || this.value === undefined ? "" : this.value;
  }

  toSearchString() {
    const str: string[] = [];

    try {
      const parent: Datom = this.ds.db.get(this.parentID);
      str.push(parent.toString());
    } catch (E) {}
    try {
      const type: Datom = this.ds.db.get(this.typeID);
      str.push(type.toString());
    } catch (E) {}

    str.push(this.value);

    str.push(`'Datom`);

    return str.join(" ");
  }

  getParent(): Datom {
    return this.ds.db.get(this.parentID);
  }

  getValues(type: string): Datom[] {
    const dv = this.ds.db.find({ type: "Datom", value: "Datom Value" }) as Datom;
    const t = this.ds.db.find({
      type: "Datom",
      value: type,
      typeID: dv.id,
    }) as Datom;
    return this.ds.db.filter({
      type: "Datom",
      parentID: this.id,
      typeID: t.id,
    });
  }

  getkeys(): Datom[] {
    const dk = this.ds.db.find({ type: "Datom", value: "Datom Key" }) as Datom;
    return this.ds.db.filter({
      type: "Datom",
      parentID: this.id,
      typeID: dk.id,
    });
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      value: this.value,
      parentID: this.parentID,
      typeID: this.typeID,
    };
  }
}

export async function getsertDatom(
  ds: Datastore,
  datomType: string,
  value: any,
  failNoExist = false
):Promise<Datom|null> {
  if (value == "" || value == null) return null;

  //get datom types ID
  //let dt = ds.db.find({type:"Datom",value:"Datom Type"}) as Datom;
  const dv = ds.db.find({ type: "Datom", value: "Datom Value" }) as Datom;
  const dk = ds.db.find({ type: "Datom", value: "Datom Key" }) as Datom;

  const type = ds.db.find({
    type: "Datom",
    value: datomType,
    typeID: dv.id,
  }) as Datom;

  //let values = type.getValues();
  //let keys = values.map( v => v.getkeys()).flat();
  //let matches =  keys.filter(k => k.value == value); //ds.db.filter({type:"Datom",parentID:type.id,value:value});

  const same_type_datoms = ds.db.filter({ type: "Datom", typeID: type.id });

  console.log("same_type_datoms", same_type_datoms);

  // let keys = same_type_datoms.map((v) => v.getkeys()).flat();
  // console.log("keys", keys);

  const matches = same_type_datoms.filter((k) => k.value == value);

  if (matches.length > 1) throw Error(`Ambiguous datom keys for '${value}'`);
  else if (matches.length == 1) return matches[0]; //.getParent();
  else if (matches.length == 0) {
    if (failNoExist)
      throw new Error(`Datom ${datomType} ${value} Does not exist`);
    const ndv: DatomI = {
      id: 0,
      type: "Datom",
      deleted: false,
      value: value,
      parentID: dv.id,
      typeID: type.id,
    };
    const v = await ds.createEntity(ndv);

    const dvk: DatomI = {
      id: 0,
      type: "Datom",
      deleted: false,
      value: value,
      parentID: v.id,
      typeID: dk.id,
    };
    const k = await ds.createEntity(dvk);
    return v;
  }

  return null;
}
