
import bcrypt from "bcryptjs";
import { DataTypeI } from "./class/DataType";
import { DatomI } from "./class/Datom";
import { JobTypeI } from "./class/JobType";
import { JobTypeDataI } from "./class/JobTypeData";
import { MeteringProviderJ } from "./class/MeteringProvider";
import { TaskTypeJ } from "./class/TaskType";
import { TaskTypeDataI } from "./class/TaskTypeData";
import { TechJ } from "./class/Tech";
import { UserI } from "./class/User";
import { Datastore } from "./Datastore";




export async function injectMyEvents(ds:Datastore){
    if(ds.session == null){
        throw "No session";
    }else{
        let id = 0;

        const apiUser:UserI = {id:id++,type:"User",firstName:"API",lastName:"",deleted:false,email:"",password:"",admin:true,techID:null};
        
        const kevinUser:UserI = {id:id++,type:"User",firstName:"Kevin",lastName:"Brown",deleted:false,email:"kevinbrown@elektrotech.com.au",password:await bcrypt.hash("qwerty12",10),admin:true,techID:null};
        const andrewUser:UserI = {id:id++,type:"User",firstName:"Andrew",lastName:"Dowdell",deleted:false,email:"andrew.dowdell@elektrotech.com.au",password:await bcrypt.hash("qwerty12",10),admin:true,techID:null};
        const keithUser:UserI = {id:id++,type:"User",firstName:"Keith",lastName:"Brown",deleted:false,email:"keith@epidev.com",password:await bcrypt.hash("qwerty12",10),admin:true,techID:null};
        const dotUser:UserI = {id:id++,type:"User",firstName:"Dot",lastName:"Brown",deleted:false,email:"dot.brown@elektrotech.com.au",password:await bcrypt.hash("qwerty12",10),admin:true,techID:null};

        const kevinTech:TechJ =  {id:id++,type:"Tech",firstName:"Kevin",lastName:"Brown",deleted:false,email:"kevinbrown@elektrotech.com.au",phone:"0400810644", address:"",location:null};
        const andrewTech:TechJ =  {id:id++,type:"Tech",firstName:"Andrew",lastName:"Dowdell",deleted:false,email:"andrew.dowdell@elektrotech.com.au",phone:"0417425149", address:"",location:null};
        kevinUser.techID = kevinTech.id;
        andrewUser.techID = andrewTech.id;

        const agility:MeteringProviderJ = {id:id++,type:"MeteringProvider",name:"Agility",deleted:false};

        const ct_testing:JobTypeI ={ id:id++,type:"JobType",name:"Mondo CT Testing 2021",description:"CT testing for Mondo",deleted:false};
        const taskPreinsepction:TaskTypeJ = {id:id++,type:"TaskType",name:"Pre-inspection",description:"Inspection for Mondo",required:true,jobTypeID:ct_testing.id,deleted:false};
        const taskTesting:TaskTypeJ = {id:id++,type:"TaskType",name:"CT Testing",description:"Test for Mondo",jobTypeID:ct_testing.id,required:true,deleted:false};
       
        const taskLabour:TaskTypeJ = {id:id++,type:"TaskType",name:"Labour",description:"General Labour",jobTypeID:ct_testing.id,required:false,deleted:false};
    

        const stringType:DataTypeI =  {id:id++,type:"DataType",name:"string",deleted:false};
        const numberType:DataTypeI =  {id:id++,type:"DataType",name:"number",deleted:false};
        const booleanType:DataTypeI =  {id:id++,type:"DataType",name:"boolean",deleted:false};
        const idType:DataTypeI =  {id:id++,type:"DataType",name:"id",deleted:false};


        const datom:DatomI =  {id:id++,type:"Datom",value:"Datom",parentID:null,typeID:null, deleted:false};

        const dvID = id++;
        //let datomType:DatomI =  {id:id++,type:"Datom",value:"Datom Type",parentID:datom.id,typeID:datom.id, deleted:false};
        const datomValue:DatomI =  {id:dvID,type:"Datom",value:"Datom Value",parentID:dvID,typeID:dvID, deleted:false};
        const datomKey:DatomI =  {id:id++,type:"Datom",value:"Datom Key",parentID:dvID,typeID:dvID, deleted:false};
      

        // let Datom_CT_Make:DatomI =  {id:id++,type:"Datom",value:"CT Make",parentID:datomValue.id, typeID:datomValue.id, deleted:false};
        // let CT_Make_EC:DatomI =  {id:id++,type:"Datom",value:"Energy Controls",parentID:Datom_CT_Make.id,typeID:datomValue.id,deleted:false};
        // let CT_Make_EC_key:DatomI =  {id:id++,type:"Datom",value:"EC",parentID:CT_Make_EC.id,typeID:datomKey.id,deleted:false};
         
        
        const datoms:DatomI[] = [
            datom,
            //datomType,
            datomValue,
            datomKey,
            // Datom_CT_Make,
            // CT_Make_EC,
            // CT_Make_EC_key,
            {id:id++,type:"Datom",value:"Meter Model",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"Meter Brand",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"Meter Current Rating",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"Constant K",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"CT Ratio",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"CT Brand",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"CT Type",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"CT Class",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"CT Burden Ratio",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"CT Burden VA",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"CT Burden R",parentID:datomValue.id, typeID:datomValue.id, deleted:false},
            {id:id++,type:"Datom",value:"CT Design Standard",parentID:datomValue.id, typeID:datomValue.id, deleted:false},


        ]
     




const jobTypeData:JobTypeDataI[] = [
{id:id++,type:"JobTypeData",name:"Work Order", dataTypeID: stringType.id, jobTypeID:ct_testing.id,deleted:false},
{id:id++,type:"JobTypeData",name:"Record ID", dataTypeID: stringType.id, jobTypeID:ct_testing.id,deleted:false},
{id:id++,type:"JobTypeData",name:"Constant K", dataTypeID: idType.id, jobTypeID:ct_testing.id,deleted:false},
{id:id++,type:"JobTypeData",name:"CT Ratio", dataTypeID: idType.id, jobTypeID:ct_testing.id,deleted:false},
{id:id++,type:"JobTypeData",name:"Meter Number", dataTypeID: stringType.id, jobTypeID:ct_testing.id,deleted:false},
{id:id++,type:"JobTypeData",name:"Meter Model", dataTypeID: idType.id, jobTypeID:ct_testing.id,deleted:false},

{id:id++,type:"JobTypeData",name:"Meter Current Rating", dataTypeID: idType.id, jobTypeID:ct_testing.id,deleted:false},

{id:id++,type:"JobTypeData",name:"CT Red Phase Serial Number", dataTypeID: stringType.id, jobTypeID:ct_testing.id,deleted:false},
{id:id++,type:"JobTypeData",name:"CT White Phase Serial Number", dataTypeID: stringType.id, jobTypeID:ct_testing.id,deleted:false},
{id:id++,type:"JobTypeData",name:"CT Blue Phase Serial Number", dataTypeID: stringType.id, jobTypeID:ct_testing.id,deleted:false}
];

const taskTypeDatas:TaskTypeDataI[] = [
{id:id++,type:"TaskTypeData",name:"Comments", dataTypeID: stringType.id, taskTypeID:taskPreinsepction.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Comments", dataTypeID: stringType.id, taskTypeID:taskLabour.id, deleted:false},

{id:id++,type:"TaskTypeData",name:"CT Brand", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Type", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Class", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Burden Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Burden VA", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Burden R", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Design Standard", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},


{id:id++,type:"TaskTypeData",name:"CT Tapping Count", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},

{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 1 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 1 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 1 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 1 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 1 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 1 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 1 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 1 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 1 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},


{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 2 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 2 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 2 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 2 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 2 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 2 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 2 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 2 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 2 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},


{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 3 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 3 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Red Phase Tapping 3 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 3 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 3 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Tapping 3 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 3 Ratio", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 3 Record Number", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Tapping 3 Result", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},


{id:id++,type:"TaskTypeData",name:"CT Red Phase Serial Number Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Serial Number Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Serial Number Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},


{id:id++,type:"TaskTypeData",name:"CT Red Phase Energised Voltage", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT White Phase Energised Voltage", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Blue Phase Energised Voltage", dataTypeID: numberType.id, taskTypeID:taskTesting.id, deleted:false},
        
{id:id++,type:"TaskTypeData",name:"Customer Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Customer Phone Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Address Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"NMI Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"MPB Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Record ID Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},


{id:id++,type:"TaskTypeData",name:"Constant K Amend", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT Ratio Amend", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Meter Number Amend", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Meter Model Amend", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Meter Current Rating Amend", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},

{id:id++,type:"TaskTypeData",name:"Comments", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Customer Correspondence Notes", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},


{id:id++,type:"TaskTypeData",name:"JHA Instructions", dataTypeID: booleanType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"JHA PPE", dataTypeID: booleanType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"JHA Induction", dataTypeID: booleanType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"JHA Access", dataTypeID: booleanType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"JHA Isolated", dataTypeID: booleanType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"JHA Live Points", dataTypeID: booleanType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"JHA Traffic", dataTypeID: booleanType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"JHA Control Measures", dataTypeID: stringType.id, taskTypeID:taskTesting.id, deleted:false},


{id:id++,type:"TaskTypeData",name:"Meter photo", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Sealed panel photo", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Test block photo", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT chamber photo", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"CT name plate photo", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Main switch photo", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false},
{id:id++,type:"TaskTypeData",name:"Security locks photo", dataTypeID: idType.id, taskTypeID:taskTesting.id, deleted:false}
];


        let instances:any = [];

        instances.push(apiUser);
        
        instances.push(kevinUser);
        instances.push(andrewUser);
        instances.push(keithUser);
        instances.push(dotUser);

        instances.push(kevinTech);
        instances.push(andrewTech);

        instances.push(agility);
        instances.push(ct_testing);
        instances.push(taskPreinsepction);
        instances.push(taskTesting);
        instances.push(taskLabour);

        instances.push(stringType);
        instances.push(numberType);
        instances.push(booleanType);
        instances.push(idType);

     


        instances = instances.concat(datoms);

        instances = instances.concat(jobTypeData);
        instances = instances.concat(taskTypeDatas);
    
        for(const instance of instances)
            await ds.createEntity(instance);

        await ds.save();
        ds.updated();
    }

}