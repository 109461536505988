type EntityLookup = { [id: number]: AttributeLookup };
type AttributeLookup = { [key: string]: SubscriptionLookup };
type SubscriptionLookup = { [id: number]: Function };

export class SubscriptionService {
  debug: boolean;
  label: string;
  idCount: number;
  subscriptions: EntityLookup;

  constructor(label: string) {
    this.label = label;
    this.idCount = 0;
    this.subscriptions = {};
    this.debug = false;
    //this.enableDebug();
  }

  enableDebug() {
    this.debug = true;
  }

  sub(id: number, key: string, fn: Function) {
    if (this.debug) console.log(`[${this.label}] sub`);
    const callbackID = this.idCount++;

    if (typeof this.subscriptions[id] == "undefined")
      this.subscriptions[id] = {};

    if (typeof this.subscriptions[id][key] == "undefined")
      this.subscriptions[id][key] = {};

    this.subscriptions[id][key][callbackID] = fn;

    const remove = () => {
      this.unSub(id, key, callbackID);
    };
    return remove;
  }

  unSub(id: number, key: string, callbackID: number) {
    if (this.debug) console.log(`[${this.label}] unSub`);
    delete this.subscriptions[id][key][callbackID];
  }

  render(id: number, key: string) {
    //if(this.debug)console.log(`[${this.label}] render for ${Object.keys(this.subscriptions).length} subs`);
    if (typeof this.subscriptions[id] == "undefined") {
      if (this.debug) console.log(`No subscriptions for entity ${id}`);
      return;
    }

    const entity = this.subscriptions[id];

    if (typeof entity[key] == "undefined") {
      if (this.debug)
        console.log(`No subscriptions for attribute ${key} for entity ${id}`);
      return;
    }

    const subscriptions = entity[key];

    for (const subID_str in subscriptions) {
      const subID = parseInt(subID_str);
      const sub = this.subscriptions[id][key][subID];
      try {
        sub();
      } catch (E) {
        console.error(E);
        this.unSub(id, key, subID);
      }
    }
  }
}
