
import { DataEvent, Datastore } from "../Datastore";

interface BaseJ {
  id: number;
  type: string;
  deleted: boolean;
}

export class Base {
  id: number;
  type: string;
  deleted: boolean;
  ds: Datastore;
  events: DataEvent[];

  constructor(ds: Datastore, event: DataEvent) {
    this.id = event.data.id;
    this.type = event.data.type;
    this.deleted = event.data.deleted;
    this.events = [event];

    this.ds = ds;
  }

  isDeleted() {
    return this.deleted;
  }

  transformID(transform: (v: number) => number) {
    this.id = transform(this.id);
  }

  toSearchString() {
    return `'${this.type}' '${this.id}'`;
  }

  toJSON(): BaseJ {
    return {
      id: this.id,
      type: this.type,
      deleted: this.deleted,
    };
  }

  getIDKeys() {
    const me: any = this;
    const dataKeys = Object.keys(me).filter((key) => key.indexOf("ID") != -1); //key == "id" ||
    return dataKeys;
  }

  getIDs() {
    const keys = this.getIDKeys();
    const ids: number[] = [];

    const me: any = this;
    for (const key of keys) {
      const id = me[key];
      if (id != null) ids.push(id);
    }
    return ids;
  }

  getChildrenBases() {
    const children: Base[] = [];
    const ids = this.getIDs();
    for (const id of ids) {
      try {
        const child = this.ds.db.get(id);
        children.push(child);
      } catch (E) {}
    }
    return children;
  }

  addEvent(event: DataEvent) {
    this.events.push(event);
    this.applyEvent(event);
  }

  applyEvent(event: DataEvent) {
    const a = this;
    for (const [key, value] of Object.entries(event.data)) {
      (this as any)[key] = value;
    }
  }

  filter(list: any) {
    const a = this;
    for (const [key, value] of Object.entries(list)) {
      //console.log("filtering",key,value,(this as any)[key]);
      if ((this as any)[key] != value) return false;
    }

    return true;
  }
}
