import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {TextField,  ButtonGroup, Button, FormHelperText, InputLabel} from "@material-ui/core";
import moment from "moment";

import {GlobalContext} from '../../ui/withGlobalContext';
import { Task } from '../../lib/class/Task';
import { Job } from '../../lib/class/Job';
import { DatomField } from './DatomField';
import { DatomFieldRaw } from './DatomFieldRaw';


interface JobDataFieldI{
    job:Job;
    field:string;
    label?:string;
    multiline?:boolean;
}



export function JobDataField(props:JobDataFieldI){
    const {job,field,multiline,label} = props; 
    const task = job;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {
        buttonGroup:{
            marginTop:20
        }
    };

    const [taskTypeData,taskData] = task.getData(field);
    const type = taskTypeData.getDataType();

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"value",forceUpdate),[]);

    const l = label ? label : taskTypeData.name;
    const v = taskData.value==null ? "" : taskData.value;
    // let validation = task.validateData(taskTypeData);

    async function update(e:any){
        await ds.updateEntityField(taskData,e);
    }

        function blur(){
            // ds.forceSave();
        }




    if(type.name == "boolean"){
     return <div style={{margin:theme.spacing(1)}}>
            <InputLabel>{l}</InputLabel>
            <div>
                <ButtonGroup variant="contained" style={styles.buttonGroup} >
                    <Button size="large" color={v===true?"primary":"default"} onClick={ () => update({value:true})} >Yes</Button>
                    <Button size="large" color={v===false?"primary":"default"} onClick={ () => update({value:false})} >No</Button>
                </ButtonGroup>
                
         
            </div>
        </div>
    }else if(type.name == "string"){

        return <TextField margin="normal" fullWidth label={l} name="value" value={v}  multiline={multiline} onChange={(e) => update(e) } onBlur={blur}  />
    }else if (type.name == "number"){
        return <TextField margin="normal" fullWidth label={l} type="number" name="value" value={v}  onChange={(e) => update(e) }   onBlur={blur} onWheel={ (event:any) => { event.target.blur() } } />
    //}else if (type.name == "id"){
        //return <DatomFieldRaw label={l} type="Datom Value" onChange={changeType} parent={dv} options={dv.getValues("Datom Value")} value={typeValue}  />
    }else{
        return <div>Data type not supported</div>
    }


}
