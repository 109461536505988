import { Job } from "../../lib/class/Job";
import { Site } from "../../lib/class/Site";
import { Task, TaskStatus } from "../../lib/class/Task";
import { Datastore } from "../../lib/Datastore";


export function getJstValues(ds:Datastore, job:Job, task:Task, site:Site, label:string){

    const startTimestamp = (task.status == TaskStatus.Successful) ? task.getStartTimestamp() : task.getScheduleTimestamp();
    const endTimestamp =  (task.status == TaskStatus.Successful) ? task.getEndTimestamp()  : task.getScheduleTimestamp();
    const prelimDate = startTimestamp.format("DD/MM/YY");
    const prelimTimeStarted = startTimestamp.format("HH:mm");
    const prelimTimeFinished = endTimestamp.format("HH:mm");
    const prelimTech = task.getTech();


    function bool(key:string,negate:boolean=false){
        const v = task.getDataString(key)
        if(v === null || v === undefined) return null;
        return negate ? !v : v;
    }

    const texts = {
        "Job Started":[prelimTimeStarted],
        "Completed":[prelimTimeFinished],
        "Customer Name":[site.customerName,site.customerNameAmend],
        "Site Address":[site.address, site.addressAmend],
        "NMI": [site.nmi, site.nmiAmend],
        "Type":[label],
        "Date":[prelimDate],
        "Signature":[prelimTech.toString()],
        "Assessor":[prelimTech.toString()],
        "Comments":[task.getDataString('Comments')],
        "Control measures used":[task.getDataString('JHA Control Measures')]
    };

    console.log(task.getDataString("JHA Instructions"));
    const ellipses = {
        "Written Verbal YES": bool("JHA Instructions"),
        "Written Verbal NO": bool("JHA Instructions", true),
        "PPE YES": bool("JHA PPE"),
        "PPE NO": bool("JHA PPE",true),
        "Induction YES": bool("JHA Induction"),
        "Induction NO": bool("JHA Induction",true),
        "Worksite YES": bool("JHA Access"),
        "Worksite NO": bool("JHA Access",true),
        "Qualified YES": bool("JHA Qualified"),
        "Qualified NO": bool("JHA Qualified",true),
        "Permits YES": bool("JHA Permits"),
        "Permits No": bool("JHA Permits",true),
        "Environmental YES": bool("JHA Noise"),
        "Environmental NO": bool("JHA Noise",true),
        "Traffic Management YES": bool("JHA Traffic"),
        "Traffic Management NO": bool("JHA Traffic",true),
        "Manual YES": bool("JHA Manual Handling"),
        "Manual NO": bool("JHA Manual Handling",true),
        "Permit valid YES": bool("JHA Permit Valid"),
        "Permit valid NO": bool("JHA Permit Valid",true),
        "Inspection & Test YES": bool("JHA Isolated"),
        "Inspection & Test NO": bool("JHA Isolated",true),
        "Asbestos YES": bool("JHA Asbestos"),
        "Asbestos NO": bool("JHA Asbestos",true),
        "Plant YES": bool("JHA Machinery"),
        "Plant NO": bool("JHA Machinery",true),
        "Clean YES": bool("JHA Clean"),
        "Clean NO": bool("JHA Clean",true),
        "Live YES": bool("JHA Live Points"),
        "Live NO": bool("JHA Live Points",true),

        "Mechanical Aids YES": bool("JHA Mechanical Aids"),
        "Trolley YES": bool("JHA Trolley"),
        "Colleague YES": bool("JHA Colleague"),
        "Other YES": bool("JHA Other"),
        "Access Permit YES": bool("JHA Access Permit Issued"),
        "Insulated Gloves YES": bool("JHA Insulated Gloves"),
        "Matts YES": bool("JHA Mats/Covers Safety"),
        "Observers YES": bool("JHA Observers Required"),
        "Vehicle YES": bool("JHA Vehicle"),
        "Pedestrian YES": bool("JHA Pedestrian")
    };

    return [texts, ellipses];
}













