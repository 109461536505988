import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../withGlobalContext';

import {TextField, Card, CardContent, Divider, Typography,Table, TableRow, TableCell, TableBody, TableHead, Button, List, ListItem} from "@material-ui/core";
import { Task, TaskStatus } from '../../lib/class/Task';
import { isEmpty } from "../../lib/Data";

import {DataField, AmendmentField, TaskStatusField, VirtualFileDataField, DatomField, DualDatomField, Timestampfield} from "../Fields";
import { Check, Clear, Warning } from '@material-ui/icons';
import useRouter from 'use-react-router';

interface TestingFormI{
    task:Task;
}

export function TestingForm(props:TestingFormI){
    const {task} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const job = task.getJob();
    const site = job.getSite();
   
    //let showForm = false;
    //if(task.status === TaskStatus.Pending || task.status === TaskStatus.Successful)
    const showForm = true;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"status",forceUpdate),[]);

    // 400, 410 430 single column
    // 420 both columns
    // 500, 510 530 single column
    // 520 both columns

    return <div>
        <Card>
            <CardContent>
                <Typography variant="h6">Meter Testing Form</Typography>
            </CardContent>
            <Divider/>

            {task.status != TaskStatus.Cancelled? <CardContent>
            <Typography variant="subtitle1">Task start information:</Typography>
            <Timestampfield task={task} label="Task Start" name="startTimestamp" preValue={task.scheduleTimestamp} />
            </CardContent>:null }

          {showForm?<div>
            <CardContent>
                <Typography variant="subtitle1">Please complete a Job Hazard Assessment:</Typography>
                <DataField task={task} label="Do you fully understand all the instructions?" field={"JHA Instructions"} />
                <DataField task={task} label="Do you have the correct PPE?" field={"JHA PPE"} />
                <DataField task={task} label="Is a site induction required?" field={"JHA Induction"} />
                <DataField task={task} label="Do you have safe access &amp; egress from meter position?" field={"JHA Access"} />
                <DataField task={task} label="Can the meter be safely isolated?" field={"JHA Isolated"} />
                <DataField task={task} label="Are you aware of all live points?" field={"JHA Live Points"} />
                <DataField task={task} label="Is traffic management required?" field={"JHA Traffic"} />
                <DataField task={task} label="Note any control measures used" field={"JHA Control Measures"}  multiline={true}/>
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Please review the following meter information:</Typography>
                <DualDatomField value={task} grandParentLabel="Meter Brand" grandParentName="Meter Brand"  label="Meter Model" name="Meter Model Amend" amendName="Meter Model"  />
                <AmendmentField task={task} label={"Meter Number"} value={job.getJobDataItemString("Meter Number")} field={"Meter Number Amend"} />
                <DatomField value={task} label={"Number of Configured Phases"} name={"Number of Phases"} type={"Number of Phases"} parent="Datom Value" />
                <DatomField value={task} label={"Number of Configured Elements"} name={"Number of Elements"} type={"Number of Elements"} parent="Datom Value" />
                <DatomField value={task} label={"Meter Current Rating"} name={"Meter Current Rating"} type={"Meter Current Rating"} parent="Datom Value" />
                <DataField task={task} label={"Main Cover Seal"} field={"Main Cover Seal"}  />
                <DataField task={task} label={"Terminal Cover Seal"} field={"Terminal Cover Seal"}  />
                <DatomField value={task} label={"Found Meter Condition"} name={"Found Meter Condition"} type={"Found Meter Condition"} parent="Datom Value" />
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Initial Readings</Typography>
                <DataField task={task} label={"Initial Total Kwh"} field={"Initial Total Kwh"}  />  
                <DataField task={task} label={"Initial Peak Kwh"} field={"Initial Peak Kwh"}  />  
                <DataField task={task} label={"Initial Off Peak Kwh"} field={"Initial Off Peak Kwh"}  />  
                <DataField task={task} label={"Initial Dedicated Circuit Kwh"} field={"Initial Dedicated Circuit Kwh"}  />  
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Final Readings</Typography>
                <DataField task={task} label={"Final Total Kwh"} field={"Final Total Kwh"}  />  
                <DataField task={task} label={"Final Peak Kwh"} field={"Final Peak Kwh"}  />  
                <DataField task={task} label={"Final Off Peak Kwh"} field={"Final Off Peak Kwh"}  />  
                <DataField task={task} label={"Final Dedicated Circuit Kwh"} field={"Final Dedicated Circuit Kwh"}  />  
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Testing Parameters</Typography>
                <DataField task={task} label={"Test Voltage Red"} field={"Test Voltage Red"}  />  
                <DataField task={task} label={"Test Voltage White"} field={"Test Voltage White"}  />  
                <DataField task={task} label={"Test Voltage Blue"} field={"Test Voltage Blue"}  />  
                <DataField task={task} label={"Test Temperature"} field={"Test Temperature"}  />    
            </CardContent>

            <Divider/>


            <CardContent>
                <TaskCTDetails task={task} />
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Operational Checks</Typography>
                <DataField task={task} label={"Register Test High"} field={"Register Test High"} booleanDisplay={["Pass","Fail"]} />  
                <DataField task={task} label={"Register Test Low"} field={"Register Test Low"}  booleanDisplay={["Pass","Fail"]} />  
                <DataField task={task} label={"Creep Test Forward"} field={"Creep Test Forward"}  booleanDisplay={["Pass","Fail"]} />  
                <DataField task={task} label={"Creep Test Reverse"} field={"Creep Test Reverse"}  booleanDisplay={["Pass","Fail"]} />  
            </CardContent>

            <CardContent>
                <Typography variant="subtitle1">Replaced Meter 1 Record</Typography>
                <DataField task={task} label={"Removed Meter Number 1"} field={"Removed Meter Number 1"}  />  
                <DataField task={task} label={"Removed Meter Readings 1"} field={"Removed Meter Readings 1"}  />  
                <DataField task={task} label={"Installed Meter Number & Code 1"} field={"Installed Meter Number & Code 1"}  />  
            </CardContent>
          
            <CardContent>
                <Typography variant="subtitle1">Replaced Meter 2 Record</Typography>
                <DataField task={task} label={"Removed Meter Number 2"} field={"Removed Meter Number 2"}  />  
                <DataField task={task} label={"Removed Meter Readings 2"} field={"Removed Meter Readings 2"}  />  
                <DataField task={task} label={"Installed Meter Number & Code 2"} field={"Installed Meter Number & Code 2"}  />  
            </CardContent>
          
            <CardContent>
                <Typography variant="subtitle1">Replaced Meter 3 Record</Typography>
                <DataField task={task} label={"Removed Meter Number 3"} field={"Removed Meter Number 3"}  />  
                <DataField task={task} label={"Removed Meter Readings 3"} field={"Removed Meter Readings 3"}  />  
                <DataField task={task} label={"Installed Meter Number & Code 3"} field={"Installed Meter Number & Code 3"}  />  
            </CardContent>


          
            <Divider/>


            <CardContent>
                <Typography variant="subtitle1">Photos:</Typography>
                <VirtualFileDataField task={task} field={"Meter panel as found photo"} label={"Meter panel as found photo"} accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Meter Serial Number & date as found photo"} label={"Meter Serial Number & date as found photo"}  accept="image/*" capture="environment"/>
                <VirtualFileDataField task={task} field={"Switchboard Circuit breakers as found photo"} label={"Switchboard Circuit breakers as found photo"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Solar inverter showing generation as found photo"} label={"Solar inverter showing generation as found photo"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Meter panel as left photo"} label={"Meter panel as left photo"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Switchboard Circuit breakers as left photo"} label={"Switchboard Circuit breakers as left photo"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Solar inverter showing generation as left photo"} label={"Solar inverter showing generation as left photo"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Other photo"} label={"Other photo"}  accept="image/*" capture="environment" />
            </CardContent>
            </div>:null}

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Notes:</Typography>
                <DataField task={task} field={"Comments"} multiline={true} />
            </CardContent>

            <Divider/>

            <CardContent>  
                <Typography variant="subtitle1">Finalise task:</Typography>
                <TaskStatusField name="status" label="Task Status" task={task} />
                {task.status !== TaskStatus.Cancelled? <Timestampfield task={task} label="Task End" name="endTimestamp"  preValue={task.startTimestamp} /> :null}
                
                <TaskDurationField task={task} />
             
            
            </CardContent>

        </Card>
        
        <br/><br/>

        <TestResultsView task={task} />

        <br/><br/>
        <TestDisplays task={task} />

    </div>

}
//         <AmendmentField task={task} label={"Record ID"} value={job.getJobDataItemString("Record ID")} field={"Record ID Amend"} />
//   <AmendmentField task={task} label={"MPB"} value={site.getMeterProvider().toString()} field={"MPB Amend"}     />
//<DataField task={task} field={"Customer Correspondence Notes"} multiline={true} />


interface TaskCTDetailsI{
    task:Task;
}


function TaskCTDetails(props:TaskCTDetailsI){
    const {task} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const job = task.getJob();


    const [taskTypeData,taskData] = task.getData("Number of Elements");
    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"valueID",forceUpdate),[]);
    
    let elementCount:number;
    try{
        elementCount = parseInt(taskData.getLink().toString());
    }catch(E){
        return <React.Fragment>
            <CardContent>
                <Typography variant="subtitle1">Meter Test Results</Typography>
            </CardContent>

            <div><Warning style={{color:theme.palette["warning"].main}} /> Invalid element count, please enter number of used elements above</div>

        </React.Fragment>
    }


    const showElement2 = elementCount === 2;


    return <React.Fragment>
        <CardContent>
            <Typography variant="subtitle1">Meter Test Results</Typography>
        </CardContent>

        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:100, Power Factor: Unity, Single Phase: 10, Multi Phase: 100</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 1"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 1 Record Number"}  />
        </CardContent>

        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:100, Power Factor: 0.866 Lag, Single Phase: 10, Multi Phase: 75</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 2"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 2 Record Number"}  />
        </CardContent>


        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:100, Power Factor: 0.5 Lag, Single Phase: 5, Multi Phase: 50</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 3"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 3 Record Number"}  />
        </CardContent>

        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:50, Power Factor:Unity, Single Phase: 5, Multi Phase: 50</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 4"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 4 Record Number"}  />
        </CardContent>

        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:50, Power Factor:0.866, Single Phase: 5, Multi Phase: 30</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 5"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 5 Record Number"}  />
        </CardContent>

        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:50, Power Factor:0.5, Single Phase: 5, Multi Phase: 30</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 6"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 6 Record Number"}  />
        </CardContent>

        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:10, Power Factor:Unity, Single Phase: 1, Multi Phase: 5</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 7"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 7 Record Number"}  /> 
        </CardContent>

        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:10, Power Factor:0.866, Single Phase: 1, Multi Phase: 5</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 8"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 8 Record Number"}  />
        </CardContent>

        <CardContent>
            <Typography variant="subtitle1">Element 1 - Rated Load:10, Power Factor:0.5, Single Phase: 1, Multi Phase: 4</Typography>
            <DataField task={task} label={"Error %"} field={"Element 1 % Error Test 9"}  />  
            <DataField task={task} label={"Record Number"} field={"Element 1 Error Test 9 Record Number"}  />
        </CardContent>

        {showElement2 && <>

            <Divider />

            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:100, Power Factor: Unity, Single Phase: 10, Multi Phase: 100</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 1"}  />
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 1 Record Number"}  /> 
            </CardContent>

            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:100, Power Factor: 0.866 Lag, Single Phase: 10, Multi Phase: 75</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 2"}  />
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 2 Record Number"}  />
            </CardContent>


            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:100, Power Factor: 0.5 Lag, Single Phase: 5, Multi Phase: 50</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 3"}  />
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 3 Record Number"}  />
            </CardContent>

            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:50, Power Factor:Unity, Single Phase: 5, Multi Phase: 50</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 4"}  />
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 4 Record Number"}  />
            </CardContent>

            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:50, Power Factor:0.866, Single Phase: 5, Multi Phase: 30</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 5"}  />
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 5 Record Number"}  />
            </CardContent>

            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:50, Power Factor:0.5, Single Phase: 5, Multi Phase: 30</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 6"}  />
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 6 Record Number"}  />
            </CardContent>


            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:10, Power Factor:Unity, Single Phase: 1, Multi Phase: 5</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 7"}  /> 
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 7 Record Number"}  />
            </CardContent>

            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:10, Power Factor:0.866, Single Phase: 1, Multi Phase: 5</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 8"}  />
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 8 Record Number"}  />
            </CardContent>

            <CardContent>
                <Typography variant="subtitle1">Element 2 - Rated Load:10, Power Factor:0.5, Single Phase: 1, Multi Phase: 4</Typography>
                <DataField task={task} label={"Error %"} field={"Element 2 % Error Test 9"}  />
                <DataField task={task} label={"Record Number"} field={"Element 2 Error Test 9 Record Number"}  />
            </CardContent>
        </>}

    </React.Fragment>

}



export interface TaskDurationFieldI{
    task:Task;
}

export function TaskDurationField(props:TaskDurationFieldI){
    const {task} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"startTimestamp",forceUpdate),[]);
    useEffect(() => ds.sub(task.id,"endTimestamp",forceUpdate),[]);

    let duration  = "";
    try{
        duration = ""+task.getDuration();
    }catch(E){

    }


    if(duration == "")
        return null;
    else
        return <TextField label="Duration" fullWidth value={duration+" hours"} margin="normal" disabled/>

}





interface TestResultViewI{
    task:Task;
}



function TestResultsView(props:TestResultViewI){
    const {task} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const router = useRouter();

    const [taskTypeData,taskData] = task.getData("Number of Elements");
    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"valueID",forceUpdate),[]);
    
    let elementCount:number;
    try{
        elementCount = parseInt(taskData.getLink().toString());
    }catch(E){
        return <React.Fragment>
            <CardContent>
                <Typography variant="subtitle1">Meter Test Results</Typography>
            </CardContent>

            <div><Warning style={{color:theme.palette["warning"].main}} /> Invalid element count, please enter number of used elements above</div>

        </React.Fragment>
    }


    const showElement2 = elementCount === 2;

    const elements:number[] = [1];
    if(showElement2)
        elements.push(2);


    const tests:number[] = []
    for(let i = 0; i < 9; i++)
        tests.push(i+1)
    

    let allFound = true;

    return <div>
        <Card>
            <CardContent>
                <Typography variant="h6">CT Test Results</Typography>
            </CardContent>

            <Divider/>

            {elementCount === 0 && <div><Warning style={{color:theme.palette["warning"].main}} /> Invalid element count, please enter number of used elements above</div>}

            <CardContent>
            
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Test</TableCell>
                        {elements.map( c => <TableCell key={c}>Element {c}</TableCell> )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tests.map( test => {
                   
                    return <TableRow key={test}>
                        <TableCell>{test}</TableCell>
                            {elements.map( element => {
                              const recordNumber = task.getJobDataItemString(`Element ${element} Error Test ${test} Record Number`);
                              const resultID = task.getJobDataItemString(`Element ${element} Error Test ${test} Result`);
                        
                                let disp:React.ReactElement;

                                if(isEmpty(recordNumber)){
                                    disp = <Clear style={{color:theme.palette["error"].main}} />;
                                    allFound = false;
                                }else if(isEmpty(resultID)){
                                    disp = <Warning style={{color:theme.palette["warning"].main}} />;
                                    allFound = false;
                                }else    
                                    disp = <Check onClick={() => router.history.push("/entity/"+resultID)} style={{color:theme.palette["success"].main}} />;

                            return <TableCell key={`${test}-${element}`}>{disp}</TableCell>
                        } )}
                    </TableRow>
                })}
                </TableBody>


            </Table>
            <br/><br/>
            <div><Typography variant="subtitle1">Legend:</Typography></div>
            <div><Check style={{verticalAlign:"middle",color:theme.palette["success"].main}} /> Result linked</div>
            <div><Warning style={{verticalAlign:"middle",color:theme.palette["warning"].main}} /> Result not found</div>
            <div><Clear style={{verticalAlign:"middle",color:theme.palette["error"].main}} /> Record not specified</div>

                <br/><br/>

                <div>{allFound?<React.Fragment><Check style={{verticalAlign:"middle",color:theme.palette["success"].main}} />  All found</React.Fragment>:<Button fullWidth variant="outlined" color="primary"  onClick={() => router.history.push("/test_result_sync_links")}>Synchronise Test Results</Button>}</div>


            </CardContent>
        </Card>
    </div>



}

interface TestDisplaysI{
    task:Task;
}


function TestDisplays(props:TestDisplaysI){
    const {task} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const router = useRouter();

    const autoTestsResults = ds.db.filter({type:"AutoTestResult",taskID:task.id});
    const testResultRows = ds.db.filter({type:"TestResultRow",taskID:task.id});

  return <div>
        <Card>
            <CardContent>
                <Typography variant="h6">Test Results</Typography>
            </CardContent>

            <Divider/>

            <div>
                <List>
                    {autoTestsResults.map(v => <ListItem key={v.id} onClick={() =>router.history.push(`/entity/${v.id}`)}>{v.toString()}</ListItem> )}

                    {testResultRows.map(v => <ListItem key={v.id} onClick={() =>router.history.push(`/entity/${v.id}`)}>{v.toString()}</ListItem> )}
                </List>
            </div>



        </Card>
    </div>


}







//{recordNumber}:{resultID}