import React from 'react'
import useRouter from 'use-react-router';

import {ListItem,ListItemText} from "@material-ui/core";
import { Base } from '../../lib/class/Base';


export interface BaseRowI{
    value:Base;
    style?:any;
}

export function BaseRow(props:BaseRowI){
    const {value,style} = props;
    const router = useRouter();

    const styles = {
        row:Object.assign({
            opacity:value.deleted?0.25:1,
            height:84,
            overflow:"hidden"
        },style)
    };


    return <ListItem style={styles.row} button divider onClick={() => router.history.push("/entity/"+value.id)} >
        <ListItemText primary={value.toString()} secondary={`ID:${value.id} type:${value.type} `} />
    </ListItem>

}