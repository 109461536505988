import React, { useContext, useEffect, useReducer } from 'react'
import useRouter from 'use-react-router';

import { ListItem, ListItemText } from "@material-ui/core";

import { TestResult } from '../../lib/class/TestResult';
import { TestResultRow } from '../../lib/class/TestResultRow';
import { Close, Remove } from '@material-ui/icons';
import { GlobalContext } from '../../ui/withGlobalContext';

export interface TestResultRowRowI{
    value:TestResultRow;
    style?:any;
}

export function TestResultRowRow(props:TestResultRowRowI){
    const {value,style} = props;
    const router = useRouter();


    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {
        row:Object.assign({
            opacity:value.deleted?0.25:1,
            height:84,
            overflow:"hidden"
        },style)
    };

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,"deleted",forceUpdate),[]);



    function remove(e:any){
        console.log(value.id);
        ds.updateEntity(value,{deleted:true})
        e.stopPropagation();
    }



    return <ListItem style={styles.row} button divider onClick={() => router.history.push("/entity/"+value.id)} >
        <ListItemText primary={value.toString()} secondary={<>{`${value.recordNumber} ${value.meterNumber} ${value.getTimestampString()} by ${value.getTechString()}`} <Close onClick={remove} color="secondary" /></>} />
    </ListItem>
}