import moment from "moment";
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { TestUnit } from "./TestUnit";
import { Tech } from "./Tech";
import { parseFile689bAuto } from "./TestUnitParse/689B-auto";
import { Task } from "./Task";

export interface AutoTestResultI {
  id: number;
  type: string;
  deleted: boolean;
  timestamp: number | null;
  testUnitID: number | null;
  siteID: number | null;
  meterNumber: string | null;
  element: number | null;
  hash: string; // is this needed?
  data: string;
  techID: number;
  taskID: number | null;
}

export class AutoTestResult extends Base {
  timestamp: number | null;
  testUnitID: number | null;
  siteID: number | null;
  meterNumber: string | null;
  element: number | null;
  hash: string;
  data: string;
  techID: number;
  taskID: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.siteID = event.data.siteID;
    this.meterNumber = event.data.meterNumber;
    this.timestamp = event.data.timestamp;
    this.testUnitID = event.data.testUnitID;
    this.hash = event.data.hash;
    this.data = event.data.data;
    this.element = event.data.element;
    this.techID = event.data.techID;
    this.taskID = event.data.taskID;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.techID = transform(this.techID);
    if (this.testUnitID != null) this.testUnitID = transform(this.testUnitID);
  }

  toSearchString() {
    const str: string[] = ['"AutoTestResult"'];

    try {
      str.push(this.getTestUnit().toString());
    } catch {}

    try {
      str.push(this.getTimestampString());
    } catch {}

    if (this.meterNumber != null) str.push(this.meterNumber);

    return str.join(" ");
  }

  getTech(): Tech {
    if (this.techID == null) throw "Tech ID not set";
    return this.ds.db.get(this.techID) as Tech;
  }
  getTechString(): string {
    try {
      return this.getTech().toString();
    } catch (E) {
      return "Tech NA";
    }
  }

  getTestUnit(): TestUnit {
    if (this.testUnitID == null) throw "Test Unit ID not set";
    return this.ds.db.get(this.testUnitID) as TestUnit;
  }

  getTimestamp() {
    return moment(this.timestamp);
  }

  getTimestampString() {
    let timestampString = "Timestamp NA";
    try {
      const timestamp = this.getTimestamp();
      timestampString = timestamp.format("DD/MM/YYYY HH:mm");
    } catch (E) {}
    return `${timestampString} `;
  }

  toString() {
    let unitString = "Unit NA";
    const timestamp = this.getTimestampString();

    try {
      const unit = this.getTestUnit();
      unitString = unit.toString();
    } catch (E) {}

    return `Unit ${unitString} - on ${timestamp}`;
  }

  async syncLinks() {
    const parsed = parseFile689bAuto(this.data);
    const update_data: any = {};

    let updated = false;

    if (this.testUnitID == null) {
      const testUnitD: any = {
        type: "TestUnit",
        serialNumber: parsed.operatorName,
      };
      const testUnits: TestUnit[] = this.ds.db.filter(testUnitD);
      let testUnit: TestUnit;
      if (testUnits.length === 0) {
        throw new Error("Unrecognized test unit serial number");
      } else {
        testUnit = testUnits[0];
      }
      update_data.testUnitID = testUnit.id;
      updated = true;
    }

    if (this.meterNumber == null) {
      update_data.meterNumber = parsed.meterNumber;
      updated = true;
    }

    // if (this.timestamp == null) {
      update_data.timestamp = parsed.testSteps[0].timestamp;
      updated = true;
      // console.log(parsed.testSteps[0].timestamp);
    // }

    if (true || this.element === null || this.element === undefined) {
      const autoTestResults = this.ds.db.filter({type:"AutoTestResult",taskID:this.taskID}).filter(t => t.id !== this.id);

      let elementNumber = 1;
      if(autoTestResults.length > 0){
        const otherParsed = parseFile689bAuto(autoTestResults[0].data);
        elementNumber = (otherParsed.testSteps[0].timestamp >  parsed.testSteps[0].timestamp) ? 1 : 2;
      }

      update_data.element = elementNumber;
      updated = true;
    }

    let e: AutoTestResult = this;
    if (updated)
      e = (await this.ds.updateEntity(
        this,
        update_data,
        true
      )) as AutoTestResult;

    try {
      e = await e.syncTask();
    } catch (E) {}

    return e;
  }

  async syncTask(): Promise<AutoTestResult> {
    const parsed = parseFile689bAuto(this.data);
    const timestamp = this.getTimestamp();
    const targetTask = this.ds.db.filter({
      type: "TaskType",
      name: "Meter Test",
    })[0];
    if (targetTask === undefined)
      throw new Error("Can't find target task type");

    const tasks = this.ds.db.filter({
      type: "Task",
      typeID: targetTask.id,
    }) as Task[];

    let matchingMeterTask: Task | null = null;

    for (const task of tasks) {
      try {
        const job = task.getJob();
        const s = task.getDataString("Meter Number Amend")
        const meterNumber = (s === '' || s === null) ? job.getJobDataItemString("Meter Number") : s;
        // console.log(this.meterNumber,s);
        if (meterNumber == this.meterNumber) {
          matchingMeterTask = task;
          break;
        }
      } catch (E) {}
    }

    if (matchingMeterTask === null) {
      throw new Error("Could not find matching meter number");
    }
    const task = matchingMeterTask;
    console.log("found matching task", task);
    return await this.ds.updateEntity(this, { taskID: task.id });
    // const key = `Element ${parsed.elementNumber} Auto Results`;

    // task.upsertTaskData(key, {
    //   valueID: this.id,
    // });
  }

  // getTaskDataLink() {
  //   const parsed = parseFile689bAuto(this.data);
  //   const key = `Element ${parsed.elementNumber} Auto Results`;

  //   const typeData = this.ds.db.filter({ type: "TaskTypeData" });
  //   const matchedKeys = typeData.filter((t) => t.name === key);

  //   const typeIDs = matchedKeys.map((t) => t.id);

  //   const taskDatas = this.ds.db
  //     .filter({ type: "TaskData" })
  //     .filter((t) => typeIDs.indexOf(t.dataID) !== -1);

  //   const linked = taskDatas.filter((t) => t.valueID === this.id);

  //   if (linked.length == 1) return linked[0];
  //   else if (linked.length > 1) throw "Multiple task link matches!";
  //   else throw "No task link found";
  // }

  hasError() {
    const error = this.getError();
    return error.length !== 0;
  }

  getError() {
    if (this.timestamp == null) return "Timestamp not set";
    if (this.testUnitID == null) return "Test unit not linked";
    if (this.taskID == null) return "Task not linked";
    if (this.element == null) return "Element not set";

    // try {
    //   const link = this.getTaskDataLink(); // will throw an error
    // } catch (E) {
    //   return (E as any).toString();
    // }

    return "";
  }
  decode() {
    const record = parseFile689bAuto(this.data);
    return record;
  }

  toJSON(): AutoTestResultI {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      timestamp: this.timestamp,
      testUnitID: this.testUnitID,
      hash: this.hash,
      data: this.data,
      techID: this.techID,
      siteID: this.siteID,
      taskID: this.taskID,
      meterNumber: this.meterNumber,
      element: this.element,
    };
  }
}
