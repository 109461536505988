import { JobType } from "../class/JobType";
import { MeteringProvider } from "../class/MeteringProvider";
import { Site } from "../class/Site";
import { Job } from "../class/Job";
import { TaskType } from "../class/TaskType";

import { Task } from "../class/Task";
import { TaskQuestion } from "../class/TaskQuestion";
import { JobTask } from "../class/JobTask";
import { Tech } from "../class/Tech";
import { DataEvent, Datastore } from "../Datastore";
import { Base } from "../class/Base";
import { JobTypeData } from "../class/JobTypeData";
import { JobData } from "../class/JobData";
import { DataType } from "../class/DataType";
import { TaskData } from "../class/TaskData";
import { TaskTypeData } from "../class/TaskTypeData";
import { TestUnit } from "../class/TestUnit";
import { TestResult } from "../class/TestResult";
import { User } from "../class/User";
import { Session } from "./Session";
import { VirtualFile } from "./VirtualFile";
import { Datom } from "./Datom";
import { SiteData } from "./SiteData";
import { SiteTypeData } from "./SiteTypeData";
import { Document } from "./Document";
import { AutoTestResult } from "./AutoTestResult";
import { TestResultRow } from "./TestResultRow";
import { TestResult590BV2 } from "./TestResult590BV2";

export class Database {
  eventResult: Base[];
  myEventResult: Base[];

  lastProcessedEventResult: number;
  lastProcessedMyEventResult: number;

  userID: number | null;
  ds: Datastore;

  //result:EventResultLookup;

  constructor(ds: Datastore) {
    this.ds = ds;
    this.eventResult = [];
    this.myEventResult = [];
    this.lastProcessedEventResult = 0;
    this.lastProcessedMyEventResult = 0;

    this.userID = null;

    //this.result = [];
    //this.entityID = 0;
  }

  process(userID: number | null, events: DataEvent[], myEvents: DataEvent[]) {
    //  let itemsChanged:{[id:number]:Base} = {};

    // function mergeChanged(changes:Base[]){
    //   for(let change of changes){
    //     if(typeof itemsChanged[change.id] == "undefined")
    //       itemsChanged[change.id] = change;
    //   }
    // }

    //console.log("[db] processing",this.lastProcessedEventResult,events.length);

    for (let i = this.lastProcessedEventResult; i < events.length; i++) {
      const event = events[i];
      //let changed =
      this.processEvent(event, this.eventResult);
      // mergeChanged(changed);
    }
    this.lastProcessedEventResult = events.length;
    this.myEventResult = this.eventResult.slice();

    //always process all myEvents (there could be a significant optimisation here)
    for (let i = 0; i < myEvents.length; i++) {
      const event = myEvents[i];
      //let changed =
      this.processEvent(event, this.myEventResult);
      //mergeChanged(changed);
    }

    // console.log(this.myEventResult);
    for (let i = 0; i < this.myEventResult.length; i++) {
  
      if (this.myEventResult[i] === undefined) {
        // console.log(this.myEventResult[i]);
        // console.log("missing", i);

        const fakeEvent: DataEvent = {
          userID: 0,
          timestamp: Date.now(),
          action: "create",
          data: { id: i, type: "Base", deleted: true },
          filter: { id: i },
          key: `fake-${i}`,
          myEvent: true,
        };

        this.myEventResult[i] = new Base(this.ds, fakeEvent);
      }
    }

    this.lastProcessedMyEventResult = myEvents.length;
    this.userID = userID;
  }

  processEvent(event: DataEvent, pool: Base[]) {
    //:Base[]{

    // let changed = [];
    switch (event.action) {
      case "create":
        const entity = this.classFactory(event);
        // if (entity.id != pool.length) console.log("mismatch on ", entity.id);

        pool[entity.id] = entity; //.push(entity);

        // changed.push(entity);
        break;
      case "update": {
        if (typeof pool[event.filter.id] != "undefined")
          pool[event.filter.id].addEvent(event);

        // for (const i of pool) {
        //   if (i != undefined && i.filter(event.filter)) {
        //     i.addEvent(event);
        //     // changed.push(i);
        //   }
        // }
        break;
      }
      default:
        throw `Action '${event.action}' not recognised`;
    }

    // return changed;
  }

  getMe(): User {
    if (this.userID == null) throw "User is not set";
    return this.get(this.userID);
  }

  get(id: number): any {
    return this.myEventResult[id];
  }

  find<T>(filter: Partial<T>): T {
    const results = this.filter(filter);
    if (results.length === 1) return results[0];
    else if (results.length === 0)
      throw `No item found ${JSON.stringify(filter)}`;
    else
      throw `Ambiguous search with filter ${JSON.stringify(
        filter
      )} returns ${results.map((r) => r.toString()).join(", ")}`;
  }

  filter(list: any, includeDeleted = false): any[] {
    let subjects = this.myEventResult;
    if (!includeDeleted)
      subjects = subjects.filter((b) => b.filter({ deleted: false }));

    return subjects.filter((b) => b.filter(list));
  }

  classFactory(event: DataEvent) {
    switch (event.data.type) {
      case "Job":
        return new Job(this.ds, event);
      case "JobTask":
        return new JobTask(this.ds, event);
      case "JobType":
        return new JobType(this.ds, event);
      case "MeteringProvider":
        return new MeteringProvider(this.ds, event);
      case "Site":
        return new Site(this.ds, event);
      case "Task":
        return new Task(this.ds, event);
      case "TaskQuestion":
        return new TaskQuestion(this.ds, event);
      case "TaskType":
        return new TaskType(this.ds, event);
      case "Tech":
        return new Tech(this.ds, event);
      case "JobTypeData":
        return new JobTypeData(this.ds, event);
      case "JobData":
        return new JobData(this.ds, event);
      case "DataType":
        return new DataType(this.ds, event);
      case "TaskData":
        return new TaskData(this.ds, event);
      case "TaskTypeData":
        return new TaskTypeData(this.ds, event);
      case "TestUnit":
        return new TestUnit(this.ds, event);
        case "TestResult":
          return new TestResult(this.ds, event);
      case "TestResult590BV2":
      return new TestResult590BV2(this.ds, event);
      case "User":
        return new User(this.ds, event);
      case "Session":
        return new Session(this.ds, event);
      case "VirtualFile":
        return new VirtualFile(this.ds, event);
      case "Datom":
        return new Datom(this.ds, event);
      case "SiteData":
        return new SiteData(this.ds, event);
      case "SiteTypeData":
        return new SiteTypeData(this.ds, event);
      case "Document":
        return new Document(this.ds, event);
      case "AutoTestResult":
        return new AutoTestResult(this.ds, event);
      case "TestResultRow":
        return new TestResultRow(this.ds, event);

      default:
        console.error("No class found");
        return new Base(this.ds, event);
    }
  }
}
