import { default as React, useContext } from 'react';

import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core'
import { GlobalContext } from './withGlobalContext';
import useReactRouter from 'use-react-router';


import { ArrowBackIos } from "@material-ui/icons";
import { DatastoreBadge } from './DatastoreBadge';
import { CssStyles } from '../css-types';


interface HeaderI{
    title:string;
    back:boolean;
}


export function Header(props: HeaderI) {

    const global = useContext(GlobalContext);
    const { theme, project, ui } = global;
    const router = useReactRouter()

    const {title, back} = props;

    const styles:CssStyles = {
        root: {
            flexGrow: 1,
            userSelect: "none"
        },
        grow: {
            flexGrow: 1
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        logo: {
            height: 24
        },
        title: {
            maxWidth:window.innerWidth-130,
            overflow:"hidden",
            whiteSpace:"nowrap"
        } 
    };

    function goBack() {
        router.history.goBack();
    }

    return <React.Fragment>
        <AppBar position="fixed" style={styles.root} color="default">
            <Toolbar variant="dense">
                {back ? <IconButton edge="start" size="small" color="inherit" onClick={goBack}> <ArrowBackIos /> </IconButton> : null}
                <div style={styles.grow}>
                    <Typography variant="h6" style={styles.title} color="inherit">{title}</Typography>
                </div>
                <DatastoreBadge />
           </Toolbar>
        </AppBar>
    </React.Fragment>
}
