

import { DataEvent, Datastore } from "../Datastore";

import { Base } from "./Base";
import { Tech } from "./Tech";


export interface UserI{
    id: number;
    type: string;
    deleted:boolean;
    firstName: string;
    lastName: string;
    email:string;
    password:string|null;
    admin:boolean;
    techID:number|null;
}

export class User extends Base{
    
    firstName:string;
    lastName:string;
    email:string;
    password:string|null;
    admin:boolean;
    techID:number|null;
    


    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.firstName =  event.data.firstName;
        this.lastName =  event.data.lastName;
        this.email =  event.data.email;
        this.password =  event.data.password;
        this.admin =  event.data.admin;
        this.techID =  event.data.techID;
    }


    isAdmin(){
        return this.admin;
    }

    isSudo(){
        return this.id == 3 && this.firstName == "Keith";
    }

    transformID(transform:(v:number)=>number){
        super.transformID(transform);
        if(this.techID != null)
            this.techID = transform(this.techID);
    }


    getTech():Tech{
        if(this.techID == null) throw "Tech ID not set";
        return this.ds.db.get(this.techID);
    }


    toString(){
        return `${this.firstName} ${this.lastName}`;
    }

    toJSON(){
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            firstName:this.firstName,
            lastName:this.lastName,
            email:this.email,
            password:this.password,
            admin:this.admin,
            techID:this.techID
        };
    }


}