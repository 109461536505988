import React,{ useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, Divider, Typography, Button} from "@material-ui/core";

import {Header} from '../../ui/Header'



import { Clear } from '@material-ui/icons';


export function EventView(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
        main:{
            paddingLeft:20,
            paddingRight:20
        }
	}

    
    const id = props.match.params.id;
    const event = ds.events[id];


    function removeItem(){
        const item = ds.events.splice(id,1);
        console.log(item);
        ds.save();


    }




	return <div style={styles.root} key={"event-view-"+id} > 	
            <Header title={"Event "+id} back={true} />
           
            <Card>
                <CardContent>
                    <Typography variant="h6" >Debug</Typography>

                </CardContent>
                <CardContent>
                    <Button fullWidth variant="outlined" onClick={removeItem} color="secondary" > <Clear /> Remove</Button>
                </CardContent>
            </Card>
            <br/>

            <Card>
                <CardContent>
                    <Typography variant="h6" >Debug</Typography>
                </CardContent>

                <Divider/>
                <CardContent>
                    <pre>{JSON.stringify(event,undefined,3)}</pre>

                  
                </CardContent>
            </Card>
                
            </div>
        
    
}
