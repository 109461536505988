import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import { Typography} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';


export function NotFound(){

	const global = useContext(GlobalContext);
	const {project,theme} = global;

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		}
	}
	
	return <div style={styles.root}>
		<Header title="Error!" back={true} />
			<Typography variant="h4"  align="center">Page Not found</Typography>


		<Navigation target="not found"  />
	</div>  
	
}