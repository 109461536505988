import moment from "moment";
import { Datom } from "../../lib/class/Datom";
import { Job } from "../../lib/class/Job";
import { Site } from "../../lib/class/Site";
import { Task, TaskStatus } from "../../lib/class/Task";
import { Datastore } from "../../lib/Datastore";
import { splitComments } from "./split-comments";

export function getMeterTestingValues(ds:Datastore, job:Job, task:Task, site:Site) {

    const meter:Datom = job.getJobDataItemValueString('Meter Model');
    const meterAmend = task.getDataString('Meter Model Amend');
    const brand = meter.getParent();

    const startTimestamp = task.getStartTimestamp();
    const startTime = startTimestamp.format("HH:mm")
    const endTimestamp = task.getEndTimestamp();
    const endTime = endTimestamp.format("HH:mm")
    const date = endTimestamp.format("DD/MM/YY");


    let testUnitSerial  = "";
    let testUnitNextCalibrationDate = "";

     if(task.status == TaskStatus.Successful){
        try{
            const testUnit = task.getTestUnit();
            testUnitSerial  = testUnit.serialNumber
            testUnitNextCalibrationDate = moment(testUnit.nextCalibrationDate).format("DD/MM/YY");
        }catch(E){

        }
     }

    // const results = task.getTestResultsStruct();



const ellipses = {
   "Fully understand instructions YES":task.getDataString("JHA Instructions"),
   "Fully understand instructions NO":!task.getDataString("JHA Instructions"),
   "Site induction required YES":task.getDataString("JHA Induction"),
   "Site induction required NO":!task.getDataString("JHA Induction"),
   "Correct PPE NO":!task.getDataString("JHA PPE"),
   "Correct PPE YES":task.getDataString("JHA PPE"),
   "Traffic management YES":task.getDataString("JHA Traffic"),
   "Traffic management NO":!task.getDataString("JHA Traffic"),
   "Aware of live points YES":task.getDataString("JHA Live Points"),
   "Aware of live points NO":!task.getDataString("JHA Live Points"),
   "Safely isolated YES":task.getDataString("JHA Isolated"),
   "Safely isolated NO":!task.getDataString("JHA Isolated"),
   "Safe access YES":task.getDataString("JHA Access"),
   "Safe access NO":!task.getDataString("JHA Access")
}
console.log(ellipses)

const texts = {
   "Reference":  [job.getJobDataItemValueString('Reference ID').toString()],
   "Control measures": [],
   "Customer Name": [site.customerContactName, site.customerContactNameAmend],
   "NMI": [site.nmi,site.nmiAmend],
   "Location": [splitComments(site.meterBoxLocation,70),splitComments(site.meterBoxLocationAmend,70)],
   "Address": [site.address,site.addressAmend],
   "Life Support": [site.lifeSupport?"YES":"NO"],
   "Phone": [site.customerPhone,site.customerContactNameAmend],
   "Network Tariff": [""],
   "Meter Number": [job.getJobDataItemValueString('Meter Number'), task.getDataString('Meter Number Amend')],
   "Meter Make": [brand.toString()],
   "Main Cover Seal": [task.getDataString('Main Cover Seal')],
   "Meter Type": [meter.toString(), meterAmend],
   "Current Rating":  [task.getDataString('Meter Current Rating')],
   "Terminal Cover Seal":  [task.getDataString('Terminal Cover Seal')],
   "Found Meter Condition": [task.getDataString('Found Meter Condition')],
   "Test Set Number": [testUnitSerial],
   "Next Calibration Date": [testUnitNextCalibrationDate],
   "Initial Total Kwh": [task.getDataString("Initial Total Kwh")],
   "Initial Peak Kwh":  [task.getDataString("Initial Peak Kwh")],
   "Initial Off Peak Kwh":  [task.getDataString("Initial Off Peak Kwh")],
   "Initial Dedicated Circuit Kwh":  [task.getDataString("Initial Dedicated Circuit Kwh")],
   "Final Total Kwh":  [task.getDataString("Final Total Kwh")],
   "Final Peak Kwh": [task.getDataString("Final Peak Kwh")],
   "Final Off Peak Kwh": [task.getDataString("Final Off Peak Kwh")],
   "Final Dedicated Circuit Kwh": [task.getDataString("Final Dedicated Circuit Kwh")],

   "Test Voltage Red":  [task.getDataString('Test Voltage Red')],
   "Test Voltage White": [task.getDataString('Test Voltage White')],
   "Test Voltage Blue":  [task.getDataString('Test Voltage Blue')],
   "Test Temperature":  [task.getDataString('Test Temperature')],

   "Error 1": [task.getDataString("Element 1 % Error Test 1")],
   "Error 2": [task.getDataString("Element 1 % Error Test 2")],
   "Error 3": [task.getDataString("Element 1 % Error Test 3")],
   "Error 4": [task.getDataString("Element 1 % Error Test 4")],
   "Error 5": [task.getDataString("Element 1 % Error Test 5")],
   "Error 6": [task.getDataString("Element 1 % Error Test 6")],
   "Error 7": [task.getDataString("Element 1 % Error Test 7")],
   "Error 8": [task.getDataString("Element 1 % Error Test 8")],
   "Error 9": [task.getDataString("Element 1 % Error Test 9")],
   "Error 10": [task.getDataString("Element 2 % Error Test 1")],
   "Error 11": [task.getDataString("Element 2 % Error Test 2")],
   "Error 12": [task.getDataString("Element 2 % Error Test 3")],
   "Error 13": [task.getDataString("Element 2 % Error Test 4")],
   "Error 14": [task.getDataString("Element 2 % Error Test 5")],
   "Error 15": [task.getDataString("Element 2 % Error Test 6")],
   "Error 16": [task.getDataString("Element 2 % Error Test 7")],
   "Error 17": [task.getDataString("Element 2 % Error Test 8")],
   "Error 18": [task.getDataString("Element 2 % Error Test 9")],

   "Register test L": [passFailRender(task.getDataString("Register Test Low"))],
   "Register test H":  [passFailRender(task.getDataString("Register Test High"))],
   "Forward": [passFailRender(task.getDataString("Creep Test Forward"))],
   "Reverse": [passFailRender(task.getDataString("Creep Test Reverse"))],

   "Removed Meter 1": [task.getDataString("Removed Meter Number 1")],
   "Removed Readings 1":  [task.getDataString("Removed Meter Readings 1")],
   "Installed Meter 1": [task.getDataString("Installed Meter Number & Code 1")],

   "Removed Meter 2": [task.getDataString("Removed Meter Number 2")],
   "Removed Readings 2": [task.getDataString("Removed Meter Readings 2")],
   "Installed Meter 2": [task.getDataString("Installed Meter Number & Code 2")],

   "Removed Meter 3": [task.getDataString("Removed Meter Number 3")],
   "Removed Readings 3":[task.getDataString("Removed Meter Readings 3")],
   "Installed Meter 3": [task.getDataString("Installed Meter Number & Code 3")],

   "Removed Meter 4": "",
   "Removed Readings 4": "",
   "Installed Meter 4": "",
   
   "Tester": [`Elektrotech: ${task.getTech().toString()}`],

   "Date": [date],
   "Start": [startTime],
   "Finish": [endTime],
   "Comments": [splitComments(task.getDataString("Comments"),120)],
   "Aborted Status":[ task.status == TaskStatus.Aborted ? "ABORTED" : "" ]
};


return [texts, ellipses];
}


function passFailRender(v:null|undefined|boolean){
    if(v === null) return "";
    if(v === undefined) return "";
    if(v) return "PASS";
    if(!v) return "FAIL";
}
