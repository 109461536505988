import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {TextField, IconButton, InputAdornment} from "@material-ui/core";
import { Task } from '../../lib/class/Task';
import { ArrowDropDown, Restore } from '@material-ui/icons';
import { Datom, DatomI } from '../../lib/class/Datom';








export interface DatomFieldI{
    label?:string;
    name:string;
    amendName?:string;
    parent:string;
    value:Task;
    type:string;
}



export function DatomField(props:DatomFieldI){
        const {name, value, type, label, parent, amendName} = props;

        const global = useContext(GlobalContext);
        const { theme, project, ui, ds } = global;


        async function addRecord(parentID:number){
            const msg = window.prompt("Enter Label");
            if(msg == null || msg == "")
                return;

                const dv = ds.db.find({type:"Datom",value:"Datom Value"}) as Datom;
                const t =  ds.db.find({type:"Datom",value:type,parentID:dv.id}) as Datom;
                
            try{
                const check =  ds.db.find({type:"Datom",typeID:t.id,value:msg,parentID:parentID}) as Datom;
                await update(parentID,check.id);
                // ds.forceSave();
                return;
            }catch(E){

            }

            const d:DatomI = {
                id:0,
                type:"Datom",
                deleted:false,
                value:msg,
                typeID:t.id,
                parentID:parentID
            } 
            const datom = await ds.createEntity(d);
            forceUpdate();
            await update(parentID,datom.id);
            // ds.forceSave();
        }

        async function update(parentID:number,  e:string|number){
            if(e == "create_new")
                return addRecord(parentID)
            else{
                const valueID = (e=="")?null:parseInt(e+"");
                await ds.updateEntityField(taskData,{valueID:valueID})         
                // ds.forceSave();
            }
            
        }


    let amended = false;

    const job = value.getJob();


      
    const [taskTypeData,taskData] = value.getData(name);
    //let typeData = taskTypeData.getDataType();

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"valueID",forceUpdate),[]);
    //useEffect(() => ds.sub(parent.id,"value",forceUpdate),[]);

    const l = label ? label : taskTypeData.name;
    let v = (taskData.valueID==null) ? "" : taskData.valueID;
    const validation = value.validateData(taskTypeData);


    /*

    let taskType = value.getType();
    let taskTypeDatas = taskType.getData().filter( tt => tt.name == name  );

    let taskTypeData:TaskTypeData;

    if(taskTypeDatas.length == 1)
        taskTypeData = taskTypeDatas[0];
    else
        return <div>No '{name}' field found for {taskType.toString()}</div>
        
    let taskData:TaskData|null;
    try{
        taskData = value.getJobDataItem(taskTypeData);
        selectValue = ""+taskData.valueID;
      
    }catch(E){
        taskData = null;
    }
*/
console.log(`${name} '${v}' ${typeof v}`);

    if( typeof amendName != "undefined"){  
        if(v == ""){

            amended = false;
            const data = job.getJobDataItemKey(amendName);
            console.log("amend",amendName,data);
            if(data.valueID != null){
                //let v = ds.db.get(data.valueID);
                v = data.valueID;//v.id;
                console.log("amnded",name,"to",v);
            }
        }else{
            amended = true;
        }
    }else{
        amended = false;
    }


    async function restore(){
        await ds.updateEntity(taskData,{valueID:null});
        // ds.forceSave();
    }


  
    const matches = ds.db.filter({type:"Datom",value:parent});
    //console.log(matches);
    if(matches.length > 1)
        return <div>Parent is ambiguous</div>
    else if(matches.length == 0)
        return <div>Parent {parent} does not exist</div>
    else{
        const p:Datom = matches[0];
        let options = p.getValues(type);
        options = options.sort((a,b) => a.toString().localeCompare(b.toString(), {ignorePunctuation: true} ))

        console.log(v,options);
        const endAdornment = <React.Fragment>
            <InputAdornment position="end">
                {amended?<IconButton onClick={restore}><Restore /> </IconButton>:null}
                <ArrowDropDown color="action" />
            </InputAdornment>
        </React.Fragment>
    
        /*  <IconButton onClick={() => addRecord(p.id)}><Add /> </IconButton>*/


        //let validation = value.validateData(taskTypeData);

        return  <TextField select name={name} onBlur={blur}  label={l} value={v} margin="normal" fullWidth onChange={(e) => update(p.id,e.target.value)} SelectProps={{IconComponent:(props)=><React.Fragment></React.Fragment>,native: true, endAdornment:endAdornment}} InputLabelProps={{shrink: true}} error={validation.length!=0} helperText={validation} >
            {options.map(option => <option key={option.id} value={option.id}>{option.toString()}</option> )}
            <option key={""} value="" ></option>
            <option key={"create_new"} value="create_new" >Create New</option>
        </TextField>
    }
}

