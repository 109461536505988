import React,{ useContext, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, Button, Select, MenuItem} from "@material-ui/core";

import {Header} from '../../ui/Header'



import { Add } from '@material-ui/icons';
import useRouter from 'use-react-router';


export function EntityCreate(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const router = useRouter();

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
        main:{
            paddingLeft:20,
            paddingRight:20
        }
	}


    const types = ["JobType","JobTask","TaskType","Site","Job","Datom","TestUnit", "MeteringProvider","Tech", "User"];
    // const entities = ds.db.filter({type:"Job"});
    console.log(types)


    const [type,setType] = useState<string>("");


    // const forceUpdate = useReducer((x) => x + 1, 0)[1];
    // useEffect(() => ds.sub(entity.id,"deleted",forceUpdate),[]);

    async function create(){
        console.log(type);
        const entity = await ds.createEntity({type:type});
        nav(`/entity/${entity.id}`);
        // await ds.updateEntity(entity,{deleted:!entity.deleted});
    }


    function nav(link:string){
        router.history.push(link)
    }


    function change(e:any){
        console.log(e);
        setType(e.target.value);
    }


	return <div style={styles.root} key={"create"} > 	
            <Header title={"Create Entity"} back={true} />
    
            <Card>
                <CardContent>
                    Create Entity
                    <Select label="Entity Type" value={type} onChange={change}>
                        {types.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)}
                    </Select>

                </CardContent>
                <CardContent>
                    <Button fullWidth variant="outlined" onClick={() => create()} color="primary" > <Add /> Create</Button>
                </CardContent>
            </Card>
            <br/>

                
        </div>
    
}

