
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { Site } from "./Site";
import { SiteTypeData } from "./SiteTypeData";




export interface SiteDataI{
    id: number;
    type: string;
    deleted:boolean;
    siteID: number;
    dataID: number;
    value: any;
    valueID:number|null;
}

export class SiteData extends Base{
    
    siteID:number;
    dataID:number;
    value:any;
    valueID:number|null;



    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.siteID = event.data.siteID;
        this.dataID =  event.data.dataID;
        this.valueID =  event.data.valueID;
        this.value =  event.data.value;
    }


    transformID(transform:(v:number)=>number){
        super.transformID(transform);
        this.siteID = transform(this.siteID);
        this.dataID = transform(this.dataID);

        const jobDataType = this.getDataType();
        const dataType = jobDataType.getDataType();
        if(dataType.name == "id")
            this.value = transform(this.value);

    }

    getLink(){
        if(this.valueID == null) throw "Value ID is null";
        
        return this.ds.db.get(this.valueID)
    }


    getSite():Site{
        return this.ds.db.get(this.siteID) as Site;
    }

    getDataType():SiteTypeData{
        return this.ds.db.get(this.dataID) as SiteTypeData;
    }

    toString(){
        return `${this.value}`;
    }


    toJSON(){
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            siteID:this.siteID,
            dataID:this.dataID,
            value:this.value,
            valueID:this.valueID
        };
    }


}