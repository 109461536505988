
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { Task } from "./Task";
import { TaskTypeData } from "./TaskTypeData";




export interface TaskDataI{
    id: number;
    type: string;
    deleted:boolean;
    taskID: number;
    dataID: number;
    value: any;
    valueID: number|null;
}

export class TaskData extends Base{
    
    taskID:number;
    dataID:number;
    value:any;
    valueID: number|null;

    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.taskID = event.data.taskID;
        this.dataID =  event.data.dataID;
        this.value =  event.data.value;
        this.valueID =  event.data.valueID;
    }

    transformID(transform:(v:number)=>number){
        super.transformID(transform);
        this.taskID = transform(this.taskID);
        this.dataID = transform(this.dataID);

        const taskDataType = this.getDataType();
        const dataType = taskDataType.getDataType();
        if(dataType.name == "id")
            this.value = transform(this.value);
    }

    getTask():Task{
        return this.ds.db.get(this.taskID) as Task;
    }

    getDataType():TaskTypeData{
        return this.ds.db.get(this.taskID) as TaskTypeData;
    }

    getLink(){
        if(this.valueID == null) {
            throw "No value ID";
        }else{
            return this.ds.db.get(this.valueID);
        }
    }

    toString(){
        return `value: ${this.value} taskID:${this.taskID} dataID:${this.dataID}`;
    }


    toSearchString(): string {
        return this.toString();
    }


    toJSON(){
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            taskID:this.taskID,
            dataID:this.dataID,
            value:this.value,
            valueID:this.valueID
        };
    }


}

