import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {TextField, InputAdornment} from "@material-ui/core";
import { ArrowDropDown } from '@material-ui/icons';
import { Datom, DatomI } from '../../lib/class/Datom';




export interface DatomFieldRawI{
    label?:string;
    onChange:(v:Datom|null) =>void;
    parent:Datom|null;
    options:Datom[];
    value:Datom|null;
    disabled?:boolean;
    type:string;
    error?:boolean;
    helperText?:string;

}



export function DatomFieldRaw(props:DatomFieldRawI){
        const {label, value, options,  type, parent, onChange, disabled, error, helperText } = props;

        const global = useContext(GlobalContext);
        const { theme, project, ui, ds } = global;


        async function addRecord(){
            if(parent == null) throw "Parent is null";

            let msg = window.prompt("Enter Label");
            if(msg == null || msg == "")
                return;
            
            msg = msg.trim();

            const dv = ds.db.find({type:"Datom",value:"Datom Value"}) as Datom;
            const t =  ds.db.find({type:"Datom",value:type,parentID:dv.id}) as Datom;


            try{
                const check =  ds.db.find({type:"Datom",typeID:t.id,value:msg,parentID:parent.id}) as Datom;
                onChange(check);
                return;
            }catch(E){

            }

            //console.log(dv);

            const d:DatomI = {
                id:0,
                type:"Datom",
                deleted:false,
                value:msg,
                typeID:t.id,
                parentID:parent.id
            } 
            const datom = await ds.createEntity(d);  
            onChange(datom);
            
        }

        async function update( v:string|number){
            if(v == "create_new")
                return addRecord()
            else if(v == ""){
                onChange(null);
                blur()
            }else{
                const datom = ds.db.get(parseInt(""+v))
                onChange(datom);
                blur()
            }
        }

        function blur(){
            // ds.forceSave();
        }


        const endAdornment = <React.Fragment>
        <InputAdornment position="end">
          
            <ArrowDropDown color="action" />
        </InputAdornment>
    </React.Fragment>
 
            let v = "";
            if(value != null)
                v = ""+value.id;
            

        //console.log(label,value,v);

        const sortedOptions = options.sort((a,b) => a.toString().localeCompare(b.toString(), {ignorePunctuation: true} ))



        return  <TextField select label={label} value={v}  margin="normal" fullWidth onChange={(e) => update(e.target.value)} SelectProps={{IconComponent:(props)=><React.Fragment></React.Fragment>,native: true, endAdornment:endAdornment}} InputLabelProps={{shrink: true}} disabled={disabled} error={error} helperText={helperText} >
            {sortedOptions.map(option => <option key={option.id} value={option.id}>{option.toString()}</option> )}
            <option key={""} value="" ></option>
            <option key={"create_new"} value="create_new" >Create New</option>
        </TextField>
    
}



/*  {amended?<IconButton onClick={restore}><Restore /> </IconButton>:null}*/