import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem, Button} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Router, Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TaskData } from '../../lib/class/TaskData';
import useRouter from 'use-react-router';

export interface DatomCardI{
    value:Datom
}

export function DatomCard(props:DatomCardI){
    const {value} = props;
    const task = value;

    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {};


    async function changeType(v:Datom|null){
        await ds.updateEntity(value,{typeID:v==null?null:v.id},true);
    }
    async function changeParent(v:Datom|null){
        await ds.updateEntity(value,{parentID:v==null?null:v.id},true);
    }

    const typeParent = null;

    let typeValue = null;
    try{
        typeValue = ds.db.get(value.typeID);
    }catch(E){

    }

    
    const parentParent = null;
    let parentValue = null;

    try{
        parentValue = ds.db.get(value.parentID);
    }catch(E){

    }


    const dv = ds.db.find({type:"Datom",value:"Datom Value"}) as Datom;
    const dk = ds.db.find({type:"Datom",value:"Datom Key"}) as Datom;
    const all = ds.db.filter({type:"Datom"}).filter( d => d.typeID != dk.id);
    //let dv = ds.db.find({type:"Datom",value:"Datom Value"}) as Datom;

    const taskData = ds.db.filter({type:"TaskData",valueID:value.id}) as TaskData[]

    return <Card>
        <CardContent>
            <Typography variant="h6" >Datom</Typography>
        </CardContent>
        <Divider/>
        <CardContent>
        <EntityField label="Value" name="value"  entity={value} />
        <DatomFieldRaw label={"Type"} type="Datom Value" onChange={changeType} parent={dv} options={dv.getValues("Datom Value")} value={typeValue}  />
        <DatomFieldRaw label={"Parent"} type="" onChange={changeParent} parent={dv} options={all} value={parentValue}  />
        </CardContent>


        <Divider />


        <CardContent>

        {taskData.map( (taskdata,i) => <div key={i}>
            <Button color="primary" onClick={() => router.history.push("/task/"+taskdata.taskID) }>{taskdata.getTask().toString()}</Button>
        </div>)}

        </CardContent>
    </Card>
}


