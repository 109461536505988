import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../withGlobalContext';

import {TextField, Card, CardContent, Divider, Typography,Table, TableRow, TableCell, TableBody, TableHead, Button} from "@material-ui/core";
import { Task, TaskStatus } from '../../lib/class/Task';
import { isEmpty } from "../../lib/Data";

import {DataField, AmendmentField, TaskStatusField, VirtualFileDataField, DatomField, DualDatomField, Timestampfield} from "../Fields";
import { Check, Clear, Warning } from '@material-ui/icons';
import useRouter from 'use-react-router';
import { JhaForm } from './JhaForm';

interface TestingForm2021I{
    task:Task;
}


export function TestingForm2021(props:TestingForm2021I){
    const {task} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;



    const job = task.getJob();
    const site = job.getSite();

   

    let showForm = false;
    if(task.status == TaskStatus.Pending || task.status == TaskStatus.Successful)
    showForm = true;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"status",forceUpdate),[]);

    return <div>
        <Card>
            <CardContent>
                <Typography variant="h6">CT Testing Form</Typography>
            </CardContent>
            <Divider/>

            {task.status != TaskStatus.Cancelled? <CardContent>
            <Typography variant="subtitle1">Task start information:</Typography>
            <Timestampfield task={task} label="Task Start" name="startTimestamp" preValue={task.scheduleTimestamp} />
            </CardContent>:null }

          {showForm?<div>
            <JhaForm task={task} />
            
            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Please review the following meter information:</Typography>
                <DualDatomField value={task} grandParentLabel="Meter Brand" grandParentName="Meter Brand"  label="Meter Model" name="Meter Model Amend" amendName="Meter Model"  />
                <DatomField value={task} label="Constant K" name="Constant K Amend" amendName="Constant K" parent="Datom Value" type="Constant K"/>
                <DatomField value={task} label={"Meter Current Rating"} name={"Meter Current Rating Amend"} amendName={"Meter Current Rating"} type={"Meter Current Rating"} parent="Datom Value" />
                <AmendmentField task={task} label={"Meter Number"} value={job.getJobDataItemString("Meter Number")} field={"Meter Number Amend"} />
              
          
            </CardContent>

            <Divider/>
            <CardContent>
                <Typography variant="subtitle1">Please enter the CT details:</Typography>
                <DatomField value={task} label={"CT Brand"} name={"CT Brand"}  type={"CT Brand"} parent="Datom Value" />
                <DatomField value={task} label={"CT Ratio"} name={"CT Ratio Amend"} amendName={"CT Ratio"} type={"CT Ratio"} parent="Datom Value" />
                <DatomField value={task} label={"CT Type"} name={"CT Type"}  type={"CT Type"} parent="Datom Value" />
                <DatomField value={task} label={"CT Class"} name={"CT Class"}  type={"CT Class"} parent="Datom Value" />
                <DatomField value={task} label={"CT Burden VA"} name={"CT Burden VA"}  type={"CT Burden VA"} parent="Datom Value" />
                <DatomField value={task} label={"CT Burden Impedance"} name={"CT Burden R"}  type={"CT Burden R"} parent="Datom Value" />
                <DatomField value={task} label={"CT Design Standard"} name={"CT Design Standard"}  type={"CT Design Standard"} parent="Datom Value" />
            </CardContent>

            <Divider/>

            <CardContent>
                <TaskCTDetails task={task} />
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Photos:</Typography>
                <VirtualFileDataField task={task} field={"Meter photo"} label={"Photo of meter showing date, serial number, NMI and CT constant"} accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Sealed panel photo"} label={"Photo of whole panel sealed"}  accept="image/*" capture="environment"/>
                <VirtualFileDataField task={task} field={"Test block photo"} label={"Photo of Test Block (showing current links closed)"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"CT chamber photo"} label={"Photo of CT Chamber with seals on current transformers"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"CT name plate photo"} label={"Photo of the CT name plate"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Main switch photo"} label={"Photo of Main switch or isolating switches in on position"}  accept="image/*" capture="environment" />
                <VirtualFileDataField task={task} field={"Security locks photo"} label={"Photo of Any security locks / seals fitted to outer doors"}  accept="image/*" capture="environment" />
            </CardContent>
            </div>:null}

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Notes:</Typography>
                <DataField task={task} field={"Comments"} multiline={true} />
            </CardContent>

            <Divider/>

            <CardContent>  
                <Typography variant="subtitle1">Finalise task:</Typography>
                <TaskStatusField name="status" label="Task Status" task={task} />
                {task.status != TaskStatus.Cancelled? <Timestampfield task={task} label="Task End" name="endTimestamp"  preValue={task.startTimestamp} /> :null}
                
                <TaskDurationField task={task} />
             
            
            </CardContent>

        </Card>
        
        <br/><br/>

        <TestResultsView task={task} />

    </div>

}
//         <AmendmentField task={task} label={"Record ID"} value={job.getJobDataItemString("Record ID")} field={"Record ID Amend"} />
//   <AmendmentField task={task} label={"MPB"} value={site.getMeterProvider().toString()} field={"MPB Amend"}     />
//<DataField task={task} field={"Customer Correspondence Notes"} multiline={true} />


interface TaskCTDetailsI{
    task:Task;
}


function TaskCTDetails(props:TaskCTDetailsI){
    const {task} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const job = task.getJob();


    const [taskTypeData,taskData] = task.getData("CT Tapping Count");
    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"value",forceUpdate),[]);
    
    let tappingCount = taskData.value;
    if(tappingCount == null)
        tappingCount = 0;

    return <React.Fragment>
    <Typography variant="subtitle1">Please enter the CT phase details:</Typography>
        <DataField task={task} field={"CT Tapping Count"} label="Number of CT Tappings (1 or 3)" />

        <AmendmentField task={task} label={"CT Red Phase Serial Number"} value={job.getJobDataItemString("CT Red Phase Serial Number")} field={"CT Red Phase Serial Number Amend"} />
        {tappingCount > 0 ? <DataField task={task} field={"CT Red Phase Tapping 1 Record Number"} /> : null}
        {tappingCount > 0 ?  <DatomField value={task} name={"CT Red Phase Tapping 1 Ratio"} type={"CT Ratio"} parent="Datom Value" />  : null}
            {tappingCount > 1 ? <DataField task={task} field={"CT Red Phase Tapping 2 Record Number"} /> : null}
            {tappingCount > 1 ?  <DatomField value={task} name={"CT Red Phase Tapping 2 Ratio"}  type={"CT Ratio"} parent="Datom Value" /> : null}
            {tappingCount > 2 ?  <DataField task={task} field={"CT Red Phase Tapping 3 Record Number"} /> : null}
            {tappingCount > 2 ?  <DatomField value={task} name={"CT Red Phase Tapping 3 Ratio"}  type={"CT Ratio"} parent="Datom Value"/> : null}
        <br/><br/>

        <AmendmentField task={task} label={"CT White Phase Serial Number"} value={job.getJobDataItemString("CT White Phase Serial Number")} field={"CT White Phase Serial Number Amend"} />
        {tappingCount > 0 ?<DataField task={task} field={"CT White Phase Tapping 1 Record Number"} /> : null}
            {tappingCount > 0 ?<DatomField value={task} name={"CT White Phase Tapping 1 Ratio"}  type={"CT Ratio"} parent="Datom Value" /> : null}
            {tappingCount > 1 ? <DataField task={task} field={"CT White Phase Tapping 2 Record Number"} /> : null}
            {tappingCount > 1 ?<DatomField value={task} name={"CT White Phase Tapping 2 Ratio"}  type={"CT Ratio"} parent="Datom Value" /> : null}
            {tappingCount > 2 ? <DataField task={task} field={"CT White Phase Tapping 3 Record Number"} /> : null}
            {tappingCount > 2 ?<DatomField value={task} name={"CT White Phase Tapping 3 Ratio"}  type={"CT Ratio"} parent="Datom Value" /> : null}
        <br/><br/>

        <AmendmentField task={task} label={"CT Blue Phase Serial Number"} value={job.getJobDataItemString("CT Blue Phase Serial Number")} field={"CT Blue Phase Serial Number Amend"} />
        {tappingCount > 0 ?<DataField task={task} field={"CT Blue Phase Tapping 1 Record Number"} /> : null}
            {tappingCount > 0 ?<DatomField value={task} name={"CT Blue Phase Tapping 1 Ratio"}  type={"CT Ratio"} parent="Datom Value" /> : null}
            {tappingCount > 1 ? <DataField task={task} field={"CT Blue Phase Tapping 2 Record Number"} /> : null}
            {tappingCount > 1 ?<DatomField value={task} name={"CT Blue Phase Tapping 2 Ratio"}   type={"CT Ratio"} parent="Datom Value" /> : null}
            {tappingCount > 2 ? <DataField task={task} field={"CT Blue Phase Tapping 3 Record Number"} /> : null}
            {tappingCount > 2 ?<DatomField value={task} name={"CT Blue Phase Tapping 3 Ratio"}  type={"CT Ratio"} parent="Datom Value" /> : null}

        <br/><br/>

        <DataField task={task} field={"CT Red Phase Energised Voltage"} label="CT Red Phase Energised Voltage (mV)" />
        <DataField task={task} field={"CT White Phase Energised Voltage"} label="CT White Phase Energised Voltage (mV)"/>
        <DataField task={task} field={"CT Blue Phase Energised Voltage"} label="CT Blue Phase Energised Voltage (mV)"/>

    </React.Fragment>

}



export interface TaskDurationFieldI{
    task:Task;
}

export function TaskDurationField(props:TaskDurationFieldI){
    const {task} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"startTimestamp",forceUpdate),[]);
    useEffect(() => ds.sub(task.id,"endTimestamp",forceUpdate),[]);

    let duration  = "";
    try{
        duration = ""+task.getDuration();
    }catch(E){

    }


    if(duration == "")
        return null;
    else
        return <TextField label="Duration" fullWidth value={duration+" hours"} margin="normal" disabled/>



}





interface TestResultViewI{
    task:Task;
}



function TestResultsView(props:TestResultViewI){
    const {task} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const router = useRouter();

    const [taskTypeData,taskData] = task.getData("CT Tapping Count");
    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"value",forceUpdate),[]);
    
    let tappingCount = taskData.value;
    if(tappingCount == null)
        tappingCount = 0;

    const tappings:number[] = [];
    for(let i = 0; i < tappingCount; i++)
        tappings.push(i+1);

    const phases = ["Red","White","Blue"]


    let allFound = true;

    return <div>
        <Card>
            <CardContent>
                <Typography variant="h6">CT Test Results</Typography>
            </CardContent>

            <Divider/>

            <CardContent>
            
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Phase</TableCell>
                        {tappings.map( c => <TableCell key={c}>Tapping {c}</TableCell> )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {phases.map( p => {
                   
                    return <TableRow key={p}>
                        <TableCell>{p}</TableCell>
                        {tappings.map( c => {
                              const recordNumber = task.getJobDataItemString(`CT ${p} Phase Tapping ${c} Record Number`);
                              const resultID = task.getJobDataItemString(`CT ${p} Phase Tapping ${c} Result`);

                                let disp:React.ReactElement;

                                if(isEmpty(recordNumber)){
                                    disp = <Clear style={{color:theme.palette["error"].main}} />;
                                    allFound = false;
                                }else if(isEmpty(resultID)){
                                    disp = <Warning style={{color:theme.palette["warning"].main}} />;
                                    allFound = false;
                                }else    
                                    disp = <Check style={{color:theme.palette["success"].main}} />;

                            return <TableCell key={`${p}-${c}`}>{disp}</TableCell>
                        } )}
                    </TableRow>
                })}
                </TableBody>


            </Table>
            <br/><br/>
            <div><Typography variant="subtitle1">Legend:</Typography></div>
            <div><Check style={{verticalAlign:"middle",color:theme.palette["success"].main}} /> Result linked</div>
            <div><Warning style={{verticalAlign:"middle",color:theme.palette["warning"].main}} /> Result not found</div>
            <div><Clear style={{verticalAlign:"middle",color:theme.palette["error"].main}} /> Record not specified</div>

                <br/><br/>


                <div>{allFound?<React.Fragment><Check style={{verticalAlign:"middle",color:theme.palette["success"].main}} />  All found</React.Fragment>:<Button fullWidth variant="outlined" color="primary"  onClick={() => router.history.push("/test_result_sync_links")}>Synchronise Test Results</Button>}</div>


            </CardContent>
        </Card>
    </div>



}

//{recordNumber}:{resultID}