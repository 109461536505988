import * as React from "react";

import { Link as RouterLink } from 'react-router-dom'
import { Link as MLink}  from '@material-ui/core';
import { CssStyles } from "../css-types";



export default function Link(props:any){

       const styles:CssStyles = {
            base:{
                textDecoration:"none"
            }
        };
        
        const style = Object.assign({}, styles.base, props.style);
        const MyLink = (props:any) => <RouterLink to={props.to} {...props} />

        if(typeof props.href == "undefined")
            return <MLink component={RouterLink} className={props.className} style={style} color={props.color} to={props.to} >{props.children}</MLink>
        else
            return <MLink  style={style} className={props.className} color={props.color} target="_blank" href={props.href}>{props.children}</MLink>
    
}