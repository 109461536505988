import moment, { Moment } from "moment";

interface TestResult689B {
  rec_num: number;
  rec_ver: string;
  dsp_ver: string;
  time_stamp: number;
  operator: string;
  loc: string;
  mtr_id: string;
  mtr_type: string;
  mtr_class: string;
  wiring: string;
  mtr_const: number;
  temp: number;
  test_mode: string;
  inj_cur_ph: [number, number];
  test_len_p: number;
  test_type: string;
  rslt_flags: string;
  mtr_err: number;
  meas_energy: [number, number];
  //   pulse_op_const: number;
  freq: number;
  phaseResults: [PhaseResult, PhaseResult, PhaseResult];
}

interface PhaseResult {
  voltage: number;
  current: number;
  phase: number;
  realPower: number;
  reactivePower: number;
  apparentPower: number;
  powerFactor: number;
}

export function parseFile689B(file: string): TestResult689B {
  const r = file
    .replaceAll(`"`, ``)
    .split("\n")
    .map((c) => c.split(",").map((c) => c.trim()));
  //   console.log(r);

  if (
    r[0][0] !== "<rslt_record_start>" ||
    r[r.length - 1][0] !== "<rslt_record_end>"
  )
    throw new Error("Missing tags");

  const [day, month, year, hour, minute, second] = r[4].slice(2);
  const date = `${day}/${month}/${year} ${hour}:${minute}`;
  //   console.log(date);

  const hasPulseOp = r[20][0] === "pulse_op_const";

  const freqIndex = 19 + (hasPulseOp ? 1 : 0);
  const resultsIndex = 21 + (hasPulseOp ? 1 : 0);
  const results = r.slice(resultsIndex, -1);

  const result: TestResult689B = {
    rec_num: parseInt(r[1][2]),
    rec_ver: r[2][2],
    dsp_ver: r[3][2],
    time_stamp: moment(date, "DD/MM/YYYY HH:mm").valueOf(),
    operator: r[5][2],
    loc: r[6][2],
    mtr_id: r[7][2],
    mtr_type: r[8][2],
    mtr_class: r[9][2],
    wiring: r[10][2],
    mtr_const: parseFloat(r[11][2]),
    temp: parseFloat(r[12][2]),
    test_mode: r[13][2],
    inj_cur_ph: [parseFloat(r[14][2]), parseFloat(r[14][3])],
    test_len_p: parseFloat(r[15][2]),
    test_type: r[16][2],
    rslt_flags: r[17][2],
    mtr_err: parseFloat(r[18][2]),
    meas_energy: [parseFloat(r[19][2]), parseFloat(r[19][3])],
    freq: parseFloat(r[freqIndex][2]),
    phaseResults: parsePhaseResults(results),
  };

  //   console.log(result);

  //   return result;
  return result;
}

function parsePhaseResults(
  r: string[][]
): [PhaseResult, PhaseResult, PhaseResult] {
  return [
    parsePhaseResult(r, 0),
    parsePhaseResult(r, 1),
    parsePhaseResult(r, 2),
  ];
}

function parsePhaseResult(r: string[][], index: number): PhaseResult {
  return {
    voltage: parseFloat(r[0][2 + index]),
    current: parseFloat(r[1][2 + index]),
    phase: parseFloat(r[2][2 + index]),
    realPower: parseFloat(r[3][2 + index]),
    reactivePower: parseFloat(r[4][2 + index]),
    apparentPower: parseFloat(r[5][2 + index]),
    powerFactor: parseFloat(r[6][2 + index]),
  };
}
