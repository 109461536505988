import { SHA256fromStringHex } from "../../Data";
import { Datastore } from "../../Datastore";
import { AutoTestResult, AutoTestResultI } from "../AutoTestResult";
import { parseFile689bAuto } from "./689B-auto";

export async function syncAutoRecord(
  ds: Datastore,
  txtRecord: string
): Promise<AutoTestResult> {
  const parsed = parseFile689bAuto(txtRecord); // this is so a error is thrown if not compatible
  const hexHash = await SHA256fromStringHex(txtRecord);
  const resultFilter = { type: "AutoTestResult", hash: hexHash };

  const results: AutoTestResult[] = ds.db.filter(resultFilter);

  if (results.length > 0) {
    console.log("already exists");
    results[0].syncLinks();
    return results[0];
  } else {
    const resultD: AutoTestResultI = {
      id: 0,
      type: "AutoTestResult",
      deleted: false,
      testUnitID: null,
      timestamp: null,
      siteID: null,
      taskID: null,
      element: null,
      meterNumber: null,
      data: txtRecord,
      hash: hexHash,
      techID: ds.db.getMe().id,
    };
    let testResult = (await ds.createEntity(resultD)) as AutoTestResult;
    testResult = await testResult.syncLinks();
    return testResult;
  }
}
