import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, List,ListItem,  Divider, Typography, IconButton} from "@material-ui/core";
import { Add, Clear } from '@material-ui/icons';
import { JobType } from '../../lib/class/JobType';
import { JobTypeDataI } from '../../lib/class/JobTypeData';
import { DataType } from '../../lib/class/DataType';
import { EntityField, SelectField } from '../Fields';
import { CssStyles } from '../../css-types';

export interface JobTypeCardI{
    value:JobType
}

export function JobTypeCard(props:JobTypeCardI){
    const {value} = props;


    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        title:{
           // width:250,
            fontWeight:"bold"
        }
    }



    const datas = value.getData();  
    const string = ds.db.find<DataType>({type:"DataType",name:"string"});

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,"*",forceUpdate),[]);

    async function addData(){
        const j:JobTypeDataI = {
            id:0,
            type:"JobTypeData",
            deleted:false,
            jobTypeID:value.id,
            name:"",
            dataTypeID:string.id
        };
        await ds.createEntity(j);
        forceUpdate();
       // await ds.save();
    }

    return <Card>
        <CardContent>
            <Typography variant="h6" >Job Type</Typography>
        </CardContent>
        <Divider/>
        <CardContent>


        <EntityField label="Name" name="name" type="string" entity={value} />
        <EntityField label="Description" name="description" type="string" entity={value} />

     
            <br/><br/>
            <Typography variant="subtitle1" >Job Data Items</Typography>
            <List>
            {datas.map( data => <ListItem key={data.id} ><JobTypeDataRow id={data.id} /></ListItem> )}
            </List>
            <IconButton color="primary" onClick={addData}><Add /></IconButton>
        </CardContent>


        <CardContent>
            <pre>
                {`${value.name}\n`}
                {`============\n`}
                {datas.map( d => `${d.name}\n`)}
            </pre>
        </CardContent>
    </Card>


}

interface JobTypeDataRowI{
    id:number;
    //value:JobTypeData;
}

function JobTypeDataRow(props:JobTypeDataRowI){
    const {id} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {
        field:{
            margin: theme.spacing(1),
        }
    }


    const value = ds.db.get(id);

    const dataTypes = ds.db.filter({type:"DataType"})
    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,"*",forceUpdate),[]);

    console.log("render",value)

    return <div>

        <EntityField label="Name" name="name" type="string" entity={value}  />
        <SelectField label="Type" name="dataTypeID" value={value} filter={{type:"DataType"}} />       
        <IconButton style={styles.field} color="secondary" onClick={() => ds.updateEntityField(value,{deleted:true})}><Clear /></IconButton>
    </div>

}

/*
 
        <TextField select label="Type" name="dataTypeID" value={value.dataTypeID} onChange={(e) => ds.updateEntityField(value,e)} style={styles.field}>
            {dataTypes.map( type => <MenuItem key={type.id} value={type.id}>{type.toString()}</MenuItem> )}
        </TextField> 
        */
//style={styles.field}

////<TextField label="Name" name="name" value={value.name} onChange={(e) => ds.updateEntityField(value,e)} style={styles.field} />  