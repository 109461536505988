
export function splitComments(comments:string|undefined|null, length:number){
    // make sure every line is less then 

    if(comments === undefined || comments === null)
        return "";

    return comments.trim().split("\n").map( l => (splitString(l,length))).flat().join('\n');

}

function splitString(str:string, length:number){
    const sections = [];

    const words = str.trim().split(" ");

    let sectionLength = 0;
    let section:string[] = [];
    for(const word of words){
        if( (sectionLength + word.length) > length ){
            sectionLength = word.length+1;
            sections.push(section.join(" "));
            section = [word];
        }else{
            section.push(word);
            sectionLength += word.length+1;
        }
    }

    sections.push(section.join(" "));


    return sections;
}