
import React from 'react'
import useRouter from 'use-react-router';

import {ListItem,ListItemText} from "@material-ui/core";
import { Warning } from '@material-ui/icons';
import {  Task } from '../../lib/class/Task';
import { TaskChip } from '../../ui/TaskChip';
import { CssStyles } from '../../css-types';


export interface TaskRowI{
    value:Task;
    style?:any;
}

export function TaskRow(props:TaskRowI){
    const {value,style} = props;
    const router = useRouter();

    const styles:CssStyles = {
        root:{
            marginBottom:20
        },
        chip:{
            marginLeft:5,
            marginRight:5
        },
        outer:{
            display:"flex"
        },
        title:{
            display:"inline-block",
            flexGrow: 1
        },
        warning:{
            verticalAlign:"middle"
        },
        subText:{
            whiteSpace:"nowrap"
        },

        issueText:{
            whiteSpace:"nowrap",
            fontSize:12,
            color: 'red'
        },

        row:Object.assign({
            opacity:value.deleted?0.25:1,
            height:84,
            overflow:"hidden"
        },style)
    };


    const type = value.getType();
    const job = value.getJob();
    const site = job.getSite();
    const time = value.getTimeString();


    
  let warning:any = null;
    if(value.hasIssues())
     warning = <React.Fragment> <span style={styles.issueText}>{value.getIssue()}</span> <Warning style={styles.warning} color="secondary" /></React.Fragment>

    return <ListItem style={styles.row} button divider onClick={() => router.history.push("/task/"+value.id)} >
        <ListItemText primary={<span style={styles.outer}><span style={styles.title} >{time} &bull; {type.toString()}</span> {warning}  <TaskChip task={value} taskName={false} />  </span>} secondary={<span style={styles.subText}>{site.nmi} &bull; {site.address}</span>} />
    </ListItem>
}

