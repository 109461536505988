import { Button, TextField } from "@material-ui/core";
import { ChangeEvent } from "react";


export interface SvgEllipse{
    type:"ellipse"
    key:string;
    x:number;
    y:number;
    width:number;
    height:number;
}



interface SvgEllipseRenderI{
    value:SvgEllipse;
    data:boolean;
}

export function SvgEllipseRender(props:SvgEllipseRenderI){
    const {value,data} = props;

    return `<ellipse cx="${value.x}" cy="${value.y}" rx="${value.width}" ry="${value.height}" stroke="${data?"blue":"none"}" stroke-width="1" fill="none" />`
}

