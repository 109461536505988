import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {TextField} from "@material-ui/core";
import { Task } from '../../lib/class/Task';





export interface TechFieldI{
    label?:string;
    name:string;
    task:Task;
}



export function TechField(props:TechFieldI){
        const {task, name, label} = props;

        const global = useContext(GlobalContext);
        const { theme, project, ui, ds } = global;

        async function changeStatus(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
            const key = event.target.name;
            const temp = event.target.value;
            let v:number|null;
            if( temp == "") 
                v = null;
            else
                v = parseInt(temp);
                
            const d:any = {};
            d[key] = v;
            await ds.updateEntity(task,d);
           // await ds.save();

        //    ds.forceSave();
        }




    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,name,forceUpdate),[]);


    const techs = ds.db.filter({type:"Tech"});
    let value = (task as any)[name];
    if(value == null)
        value = "";
    
    return  <TextField select name={name} label={label} value={value} margin="normal" fullWidth onChange={(e) => changeStatus(e)} SelectProps={{native: true}} InputLabelProps={{shrink: true}}>
        {techs.map(tech => <option key={tech.id} value={tech.id}>{tech.toString()}</option> )}
        <option key={""} value="" >Not Assigned</option>
    </TextField>

}
