




// import {
//     Filesystem,
//     Directory
//  } from '@capacitor/filesystem';

//  import { Capacitor } from '@capacitor/core';

export function downloadTextFile(data:string,filename:string){
    const charset = "text;charset=utf-8;";
    const csvFile = data;
    const blob = new Blob([csvFile], { type: charset });
    if (((navigator as unknown) as any).msSaveBlob) { // IE 10+
        ((navigator as unknown) as any).msSaveBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
  
            const newContent = document.createTextNode(filename); 
            link.appendChild(newContent);
            document.body.appendChild(link);
            link.click();
           document.body.removeChild(link);
        }
    }
  }
  
  

  async function blobToBase64(blob:Blob):Promise<string>{
    return new Promise((resolve,reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            resolve(reader.result as string);
        }
    });
  }


export async function downloadBlobFile(data:ArrayBuffer,filename:string){

    const blob = new Blob([data]); //  { type: charset }

    // if(Capacitor.isNativePlatform()){

       
    //     const base64 = await blobToBase64(blob);
    //     Filesystem.writeFile({
    //         path: filename,
    //         data: base64,
    //         directory: Directory.Documents//,
    //      //   encoding: Encoding.UTF8
    //      });

         

    //     const uriResult = await Filesystem.getUri({
    //         directory: Directory.Documents, //FilesystemDirectory.Data,
    //         path: filename
    //       });
    //       const uri = uriResult.uri;
    //       //await AppLauncher.openUrl({ url: getUriResult.uri });
    //       const fileOpener = (cordova.plugins as any).fileOpener2;
    //       console.log(uri);
    //       fileOpener.open(uri);
         


     if (((navigator as unknown) as any).msSaveBlob) { // IE 10+
        ((navigator as unknown) as any).msSaveBlob(blob, filename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
  
            const newContent = document.createTextNode(filename); 
            link.appendChild(newContent);
            document.body.appendChild(link);
            link.click();
           document.body.removeChild(link);
        }
    }
    
  }
  

  
  
  
  export function downloadFileURL(url:string,filename:string){
  
          const link = document.createElement("a");
          if (link.download !== undefined) {
       
              link.setAttribute("href", url);
              link.setAttribute("download", filename);
    
              const newContent = document.createTextNode(filename); 
              link.appendChild(newContent);
              document.body.appendChild(link);
              link.click();
             document.body.removeChild(link);
          }
      
    }


      
  export async function uploadFile(accept?:string,capture?:string):Promise<File>{
    //   console.log("uploadFile");
         return new Promise( (resolve,reject) => {

            // console.log("promise");
            
            const link = document.createElement("input");
            link.setAttribute("type", "file");
            
            if(typeof accept != "undefined")
                link.setAttribute("accept",accept);
            if(typeof capture != "undefined")
                link.setAttribute("capture",capture);

                window.addEventListener("focus", (e: Event) => {
                    setTimeout(() => {
                        console.log("timeout");
                        try{
                            document.body.removeChild(link);
                        }catch(E){
                            // nothing
                        }
                    },500);
                }, { once: true });
          

            link.addEventListener("change", (e: Event) => {
                 console.log(e);
                if(e == null || e.target == null){
                    reject("Null event");
                    document.body.removeChild(link);
                }else{
                    const files = (e.target as HTMLInputElement).files;
                    // console.log(files);
                   if(files == null){
                        reject("No file") 
                        document.body.removeChild(link);
                   }else if(files.length != 1){
                        reject("No file");
                        document.body.removeChild(link);
                   }else{
                       const file = files[0];
                        resolve(file);
                        document.body.removeChild(link);
                   }
                }
            });
            document.body.appendChild(link);
            link.click();
          

            // console.log("link clicked");
        })

    }




  export async function uploadFiles(accept?:string,capture?:string):Promise<File[]>{
    //   console.log("uploadFile");
         return new Promise( (resolve,reject) => {

            // console.log("promise");
            
            const link = document.createElement("input");
            link.setAttribute("type", "file");
            
            if(typeof accept != "undefined")
                link.setAttribute("accept",accept);
            if(typeof capture != "undefined")
                link.setAttribute("capture",capture);

                link.setAttribute("multiple","multiple");

                window.addEventListener("focus", (e: Event) => {
                    setTimeout(() => {
                        console.log("timeout");
                        try{
                            document.body.removeChild(link);
                        }catch(E){
                            // nothing
                        }
                    },500);
                }, { once: true });
          

            link.addEventListener("change", (e: Event) => {
                 console.log(e);
                if(e == null || e.target == null){
                    reject("Null event");
                    document.body.removeChild(link);
                }else{
                    const files = (e.target as HTMLInputElement).files;
                    // console.log(files);
                   if(files == null){
                        reject("No file") 
                        document.body.removeChild(link);
                   }else{
                        const list:File[] = []
                        for(let i = 0; i < files.length; i++)
                            list.push(files[i])
                        resolve(list);
                        document.body.removeChild(link);
                   }
                }
            });
            document.body.appendChild(link);
            link.click();
          

            // console.log("link clicked");
        })

    }




 export function createBlankLink(href:string){
        const a = document.createElement("a")
        a.href =  href;
        a.target = "_blank";
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }    
