
import { SubscriptionService } from "./SubscriptionService";
import { getTimestamp } from "./time";

export interface DatastoreTaskI {
  key: string;
  rate: number;
  due: number;
  requiredFn: () => boolean;
  running: boolean;
  fn: () => Promise<void>;
}

export class DatastoreTask extends SubscriptionService {
  readonly key: string;
  private rate: number;
  private due: number;
  private requiredFn: () => boolean;
  private running: boolean;
  private fn: Function;
  //ss:SubscriptionService;

  constructor(props: DatastoreTaskI) {
    super("task-" + props.key);
    //this.enableDebug();
    this.key = props.key;
    this.rate = props.rate;
    this.due = props.due;
    this.requiredFn = props.requiredFn;
    this.running = props.running;
    this.fn = props.fn;
    //this.ss = new SubscriptionService();
  }

  isRunning() {
    return this.running;
  }

  isDue() {
    const now = getTimestamp();
    return now > this.due;
  }

  getDue() {
    return this.due;
  }

  isRequired() {
    return this.requiredFn();
  }

  shouldRun() {
    return this.isRequired() && !this.isRunning();
  }

  // setRequired(pushBack:boolean,rate?:number){
  //     this.required = true;

  //     if(pushBack)

  // }

  async process() {
    if (this.isDue()) {
      //if(this.key == "process") console.log("[dt] process");

      //if(this.key == "process") console.log("[dt] shouldRun?",this.shouldRun());
      if (this.shouldRun()) await this.run();
      this.rescheduleTask();
    } else {
      this.render(0, "*");
    }
  }

  async run() {
    if (this.running) return; // don't run if already running
    this.running = true;
    this.render(0, "*");
    await this.fn();
    this.running = false;
    //this.required = false;
    this.render(0, "*");
  }

  rescheduleTask(rate?: number) {
    this.due = getTimestamp() + (rate ? rate : this.rate);
  }

  async forceRun() {
    //this.required = true;
    //this.due = 0;
    await this.run();
  }

  triggerRun() {
    //this.required = true;
    if (!this.isRunning()) this.due = 0;
    //await this.run();
  }

  getValue() {
    const now = getTimestamp();
    const diff = now - this.due;
    let ratio = diff / this.rate;
    if (ratio > 1) ratio = 1;

    return Math.round((1 - ratio) * 100);
  }
}
