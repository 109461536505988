import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';

export interface TestUnitCardI{
    value:TestUnit
}

export function TestUnitCard(props:TestUnitCardI){
    const {value} = props;
    const task = value;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {};




    return <Card>
        <CardContent>
            <Typography variant="h6" >Test Unit</Typography>
        </CardContent>
        <Divider/>
        <CardContent>
        <EntityField label="Model" name="model" entity={value} />
        <EntityField label="Serial Number" name="serialNumber" entity={value} />
        <EntityField label="NATA Reference" name="nataReference"  entity={value} />
        <EntityField label="Last Calibration Date" name="lastCalibrationDate" entity={value} type="date" InputLabelProps={{shrink: true }} />
        <EntityField label="Next Calibration Date" name="nextCalibrationDate"  entity={value} type="date" InputLabelProps={{shrink: true }} />
    </CardContent>
    </Card>
}


