interface PlainObject {
    hasOwnProperty<K extends string>(key: K): this is Record<K, unknown>;

    // Object.hasOwn() is intended as a replacement for Object.hasOwnProperty(). See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwn
    hasOwn<K extends string>(key: K): this is Record<K, unknown>;
}

// lodash isPlainObject does not type narrow
export function isPlainObject(value: unknown): value is PlainObject {
    return !!value && typeof value === 'object' && !Array.isArray(value);
}
