import React,{ ChangeEvent } from "react";
import { Button, TextField } from "@material-ui/core";



export interface SvgText{
    type:"text"
    key:string;
    x:number;
    y:number;
    size:number;
}




interface SvgTextRenderI{
    value:SvgText;
    selected:boolean;
    onMouseDown:()=>void;
}

export function SvgTextRender(props:SvgTextRenderI){
    const {value, onMouseDown, selected} = props;

    return <text x={value.x} y={value.y} fill={selected?"green":"blue"} fontSize={value.size} strokeWidth={1} stroke="none" onMouseDown={onMouseDown} >{value.key}</text>
}





interface SvgTextFormI{
    value:SvgText;
    onChange:(value:SvgText)=>void;
    onRemove:()=>void;
}

export function SvgTextForm(props:SvgTextFormI){
    const {value,onChange,onRemove} = props;

    const {key,x,y,size} = value;


    function change(key: keyof SvgText, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        const {value:v} = event.target;
        const cloned:SvgText = {...value};
        switch(key){
            case 'size':
                cloned.size = parseFloat(v);
                break;
            case 'x':
                cloned.x = parseFloat(v);
                break;
            case 'y':
                cloned.y = parseFloat(v);
                break;
            case 'key':
                cloned.key = v;
                break;
            default:
                break;
        }
        onChange(cloned);
    }

    return <div>
        <TextField label="key" value={key} type="text" fullWidth margin="normal" onChange={(e) => change('key',e)} />
        <TextField label="x" value={x} type="number" fullWidth margin="normal" onChange={(e) => change('x',e)}  />
        <TextField label="y" value={y} type="number" fullWidth margin="normal" onChange={(e) => change('y',e)}  />
        <TextField label="size" value={size} type="number" fullWidth margin="normal" onChange={(e) => change('size',e)}  />
        <Button color="secondary" onClick={onRemove}>Remove</Button>
    </div>
}