import { Datastore } from "../../Datastore";
import { TestResult } from "../TestResult";
import { TestResult590BV2 } from "../TestResult590BV2";
import { parseFile590GV2 } from "./590G-V2";
import { parseFile689 } from "./689";
import { sync689B } from "./sync689B";
import { syncAutoRecord } from "./syncAutoRecord";
import { syncRecord, syncRecord590GV2 } from "./SyncRecord";


type TesterType = "590G"|"590G-V2"|"689"|"689B"|"689B-auto"

export async function parseFile(ds:Datastore, file: string, type:TesterType) {
    console.log("type",type, 'with', file)

    if(type === "590G"){
        throw new Error("Currently not supported")
        // return parseFile590G(file);
    }else if(type === "590G-V2"){
        const parts = parseFile590GV2(file);
        console.log('parts',parts);
        const results:TestResult590BV2[] = [];
        for(const record of parts){
            const testResult = await syncRecord590GV2(ds,record);
            results.push(testResult);
        }
        await ds.save();
        return results
    } else if(type === "689") {
        
        const parts = parseFile689(file);
        console.log(parts);
        const results:TestResult[] = [];
        for(const record of parts){
            const testResult = await syncRecord(ds,record);
            results.push(testResult);
        }
        await ds.save();
        return results;
    }else if (type === '689B-auto') {
        const record = await syncAutoRecord(ds,file);
        return [record];
    }
else if (type === '689B') {
        const records = await sync689B(ds,file);
        return records;
    }

    throw new Error("incorrect type");

}


export async function bruteParse(ds:Datastore, file:string){
    const types:TesterType[] = ['689B-auto',"689B",'689','590G-V2']; // ,'590G'

    for(const tester of types){
        console.log(tester);
        try{
             return await parseFile(ds,file,tester);
        }catch(E){
            console.log("skipping", E)
        }
    }

    throw new Error("Could not parse file")

}