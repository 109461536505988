
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { DataType } from "./DataType";

export interface SiteTypeDataI {
  id: number;
  type: string;
  deleted: boolean;
  name: string;
  dataTypeID: number;
}

export class SiteTypeData extends Base {
  name: string;
  dataTypeID: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.name = event.data.name;
    this.dataTypeID = event.data.dataTypeID;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.dataTypeID = transform(this.dataTypeID);
  }

  getDataType(): DataType {
    return this.ds.db.get(this.dataTypeID) as DataType;
  }

  toString() {
    // let dataType = this.getDataType();
    return `${this.name}`; //:${dataType.name}`;
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      name: this.name,
      dataTypeID: this.dataTypeID,
    };
  }
}
