import React, { createElement, useContext, useRef, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Button, Divider, Typography, Box} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { useEffect } from 'react';

import useRouter from 'use-react-router';
import { Task } from '../../lib/class/Task';
import { TaskTypeData } from '../../lib/class/TaskTypeData';
import { VirtualFile } from '../../lib/class/VirtualFile';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';
import { TestResult } from '../../lib/class/TestResult';
import moment from 'moment';
import { downloadTextFile } from '../../lib/download';
import { CssStyles } from '../../css-types';

type KeyPair = [string,any,any];


type BooleanPair = [string,string,boolean];

export function Paperwork(props:any){

    const router = useRouter();
	const global = useContext(GlobalContext);
	const {project,theme,ds} = global;

	const styles:CssStyles = {
		header:{
			paddingTop:64
		},
		page:{
			fontFamily:"Times New Roman !important"
		//	"pageBreakAfter": "always"
		}
	}

	const [svg,setSVG] = useState<string|null>(null);
	const [error,setError] = useState<string|null>(null);
	const [images,setImages] = useState<any|null>(null);


    const id = props.match.params.id;
    const job = ds.db.get(id) as Job;

	async function load(job:Job){

		console.log("Load!");

		const site = job.getSite();
		const tasks = job.getTasks();

		document.title = site.getNMI();
		//6100000000

		const preinspection = tasks.filter( t => t.getType().toString() == "Pre-inspection" && t.isComplete() && !t.hasIssues() )[0];
		const ctTest = tasks.filter( t => t.getType().toString() == "CT Testing" && t.isComplete() && !t.hasIssues() )[0];
	
		
		if(preinspection == undefined)
			throw "No Pre-inspection";

		if(ctTest == undefined)
			throw "No CT Test";



		const result = await fetch("/paperwork.svg");
		const txt = await result.text();

		const parser = new DOMParser();
		const htmlDoc = parser.parseFromString(txt, 'text/xml');

		let job_meter_make = ""
		let ctTest_meter_make = ""


		try{
			const id = job.getJobDataItemKey("Meter Model").valueID
			if(id != null)
				job_meter_make = ds.db.get(id).getParent().toString();
			//console.log("job_meter_make",job_meter_make);
		}catch(E){
			//console.error("er",E);
		}

		try{
			const id = ctTest.getJobDataItemKey("Meter Model Amend").valueID;
			if( id != null )
				ctTest_meter_make = ds.db.get(id).getParent().toString();
			console.log("ctTest_meter_make",ctTest_meter_make);
		}catch(E){
			//console.error("er",E);

		}

		let instrument:TestUnit|null = null;



		function applymV(data:string){
			if(typeof data != "undefined" && data != "")
				return data+"mV";
			else
				return ""
		}


		try{
			const id = ctTest.getJobDataItemKey("CT Blue Phase Tapping 1 Result").valueID;
			if(id != null){
				console.log(id);
				const result:TestResult = ds.db.get(id);
				console.log(result);
				instrument = result.getTestUnit();
			}
		}catch(E){
			console.error(E);
		}

		if(instrument == null)
			throw "Error finding instrument";



		const items:KeyPair[] = [
			["text1098",`${job.getStartDate().format("DD/MM/YYYY")} to ${job.getDueDate().format("DD/MM/YYYY")}` ,""],

			["tspan1196",site.customerName, ((typeof site.customerNameAmend == "undefined") || site.customerNameAmend == null) ? "" : site.customerNameAmend ],//ctTest.getJobDataItemValueString("Customer Amend")],
			["text1288",site.address, ((typeof site.addressAmend == "undefined") || site.addressAmend == null) ? "" : site.addressAmend],//ctTest.getJobDataItemValueString("Address Amend")],
			["text1460",site.nmi, ( (typeof site.nmiAmend == "undefined") || site.nmiAmend == null) ? "" : site.nmiAmend  ],//ctTest.getJobDataItemValueString("NMI Amend")],
			["text1520",site.getMeterProvider().toString(),ctTest.getJobDataItemValueString("MPB Amend")],
			["text1560",job.getJobDataItemValueString("Record ID"),ctTest.getJobDataItemValueString("Record ID Amend")],

			["tspan1196-6-1-9",job.getJobDataItemValueString("Constant K"),ctTest.getJobDataItemValueString("Constant K Amend")],
			["tspan1196-6-0-9",job.getJobDataItemValueString("Meter Number"),ctTest.getJobDataItemValueString("Meter Number Amend")],
			["tspan1196-6-04",job_meter_make,ctTest_meter_make],
			["tspan1196-6-4-4",job.getJobDataItemValueString("Meter Model"),ctTest.getJobDataItemValueString("Meter Model Amend")],
			["tspan1196-6-7-5",job.getJobDataItemValueString("Meter Current Rating"),ctTest.getJobDataItemValueString("Meter Current Rating Amend")],


			//["text1722","test","test"],//job.getJobDataItemValueString("CT Ratio"),ctTest.getJobDataItemValueString("CT Ratio Amend")],
			["tspan1196-6",ctTest.getJobDataItemValueString("CT Brand"),""],
			["tspan1196-6-4",ctTest.getJobDataItemValueString("CT Type"),""],
			["tspan1196-6-0",ctTest.getJobDataItemValueString("CT Class"),""],
			["tspan1196-6-1",job.getJobDataItemValueString("CT Ratio"),ctTest.getJobDataItemValueString("CT Ratio Amend")], 
			["tspan1196-6-7",ctTest.getJobDataItemValueString("CT Design Standard"),""],
			["tspan1196-6-9",`${ctTest.getJobDataItemValueString("CT Burden VA")}VA ${ctTest.getJobDataItemValueString("CT Burden R")}Ω`,""], 

			["tspan2762",preinspection.getTech().toString(),""],
			["tspan2912",ctTest.getTech().toString(),""],


			["tspan2762-0",preinspection.getStartTimestamp().format("DD/MM/YY HH:mm").toString(),""],
			["tspan2762-9",ctTest.getStartTimestamp().format("DD/MM/YY HH:mm").toString(),""],

			["tspan3456",job.getJobDataItemValueString("CT Red Phase Serial Number"),ctTest.getJobDataItemValueString("CT Red Phase Serial Number Amend")],
			["tspan3660",job.getJobDataItemValueString("CT White Phase Serial Number"),ctTest.getJobDataItemValueString("CT White Phase Serial Number Amend")],
			["tspan3864",job.getJobDataItemValueString("CT Blue Phase Serial Number"),ctTest.getJobDataItemValueString("CT Blue Phase Serial Number Amend")],

			["tspan3456-1",ctTest.getJobDataItemValueString("CT Red Phase Tapping 1 Ratio"),""],
			["tspan3660-6",ctTest.getJobDataItemValueString("CT White Phase Tapping 1 Ratio"),""],
			["tspan3864-1",ctTest.getJobDataItemValueString("CT Blue Phase Tapping 1 Ratio"),""],
			["tspan3456-1-0",ctTest.getJobDataItemValueString("CT Red Phase Tapping 1 Record Number"),""],
			["tspan3660-6-6",ctTest.getJobDataItemValueString("CT White Phase Tapping 1 Record Number"),""],
			["tspan3864-1-7",ctTest.getJobDataItemValueString("CT Blue Phase Tapping 1 Record Number"),""],

			["tspan3456-1-06",ctTest.getJobDataItemValueString("CT Red Phase Tapping 2 Ratio"),""],
			["tspan3660-6-7",ctTest.getJobDataItemValueString("CT White Phase Tapping 2 Ratio"),""],
			["tspan3864-1-8",ctTest.getJobDataItemValueString("CT Blue Phase Tapping 2 Ratio"),""],
			["tspan3456-1-06-8",ctTest.getJobDataItemValueString("CT Red Phase Tapping 2 Record Number"),""],
			["tspan3660-6-7-6",ctTest.getJobDataItemValueString("CT White Phase Tapping 2 Record Number"),""],
			["tspan3864-1-8-6",ctTest.getJobDataItemValueString("CT Blue Phase Tapping 2 Record Number"),""],

			["tspan3456-1-06-4",ctTest.getJobDataItemValueString("CT Red Phase Tapping 3 Ratio"),""],
			["tspan3660-6-7-2",ctTest.getJobDataItemValueString("CT White Phase Tapping 3 Ratio"),""],
			["tspan3864-1-8-9",ctTest.getJobDataItemValueString("CT Blue Phase Tapping 3 Ratio"),""],
			["tspan3456-1-06-8-0",ctTest.getJobDataItemValueString("CT Red Phase Tapping 3 Record Number"),""],
			["tspan3660-6-7-6-0",ctTest.getJobDataItemValueString("CT White Phase Tapping 3 Record Number"),""],
			["tspan3864-1-8-6-0",ctTest.getJobDataItemValueString("CT Blue Phase Tapping 3 Record Number"),""],
		
			["tspan3456-3",instrument.model,""], // Instrument Description
			["tspan3660-7","",""], // Instrument Description
			["tspan3864-4","",""], // Instrument Description

			["tspan3456-3-9",instrument.serialNumber,""], // Serial No
			["tspan3660-7-5","",""], // Serial No
			["tspan3864-4-0","",""], // Serial No
		
			["tspan3456-3-9-7",instrument.getLastCalibration().format("DD/MM/YY"),""], // Calibration
			["tspan3660-7-5-8","",""], // Calibration
			["tspan3864-4-0-0","",""], // Calibration

			["tspan3456-3-9-7-6",instrument.nataReference,""], // NATA
			["tspan3660-7-5-8-6","",""], // NATA
			["tspan3864-4-0-0-9","",""], // NATA
			
			["tspan3456-3-9-7-6-5",instrument.getNextCalibration().format("DD/MM/YY"),""], // Next test due
			["tspan3660-7-5-8-6-7","",""], // Next test due
			["tspan3864-4-0-0-9-5","",""], // Next test due

			["tspan3456-3-9-7-3",ctTest.getJobDataItemValueString("CT Red Phase Serial Number Amend"),""],
			["tspan3456-3-9-7-3-3",ctTest.getJobDataItemValueString("CT White Phase Serial Number Amend"),""],
			["tspan3456-3-9-7-3-3-9",ctTest.getJobDataItemValueString("CT Blue Phase Serial Number Amend"),""],
			
			["tspan3456-3-9-7-3-6",applymV(ctTest.getJobDataItemValueString("CT Red Phase Energised Voltage")),""],
			["tspan3456-3-9-7-3-3-92",applymV(ctTest.getJobDataItemValueString("CT White Phase Energised Voltage")),""],
			["tspan3456-3-9-7-3-3-9-6",applymV(ctTest.getJobDataItemValueString("CT Blue Phase Energised Voltage")),""],


			["tspan3456-3-9-7-3-7-1",job.getJobDataItemValueString("Customer Contact Name"),ctTest.getJobDataItemValueString("Customer Contact Name Amend")],
			["tspan3456-3-9-7-3-7-2",job.getJobDataItemValueString("Customer Contact Phone"),ctTest.getJobDataItemValueString("Customer Phone Amend")],
			

			["tspan3456-3-9-7-3-7-7",genLines(ctTest.getJobDataItemValueString("Comments")),""],
			["tspan3456-3-9-7-3-7-75",ctTest.getJobDataItemValueString("JHA Control Measures"),""]
						
		];

		
		//console.log(ctTest.isAfterHours());

		const booleanPairs:BooleanPair[] = [
			["path11452-0-5","path11452-4-9-84",ctTest.isAfterHours()], // after hours
			["path11452","path11452-4",instrument.getNextCalibration().isAfter(moment())], // instrument 1
			["path11452-0","path11452-4-9",undefined], // instrument 2
			["path11452-0-8","path11452-4-9-8",undefined], // instrument 3


			["path11452-3","path11452-4-7",ctTest.getJobDataItemValueString("JHA Instructions")], // JHA 1
			["path11452-3-8","path11452-4-7-0",ctTest.getJobDataItemValueString("JHA PPE")], // JHA 2
			["path11452-3-9","path11452-4-7-7",ctTest.getJobDataItemValueString("JHA Induction")], // JHA 3
			["path11452-3-99","path11452-4-7-3",ctTest.getJobDataItemValueString("JHA Access")], // JHA 4
			["path11452-3-2","path11452-4-7-4",ctTest.getJobDataItemValueString("JHA Isolated")], // JHA 5
			["path11452-3-3","path11452-4-7-71",ctTest.getJobDataItemValueString("JHA Live Points")], // JHA 6
			["path11452-3-22","path11452-4-7-02",ctTest.getJobDataItemValueString("JHA Traffic")], // JHA 7
		];


		function escapeHtml(html:string){

			console.log(html)
			const text = document.createTextNode(html);
			const p = document.createElement('p');
			p.appendChild(text);
			console.log(p.innerHTML)
			return p.innerHTML;
		  }

		  function replaceNewLines(str:string){
			  return str.replace("\n","<br></br>");
		  }


		  function genLines(str:string){
			let lines = str.toString().split("\n");
			console.log(lines);
			lines = lines.map(escapeHtml);
			//lines = lines.map( (l,i) => `<tspan x="0" dy="${i*1.2}em">${l}</tspan>` );
			lines = lines.map( (l,i) => `<p style="margin-block-start: 0em;margin-block-end: 0.5em;" >${l}</p>` );
			return lines.join("\n");
		  }


		function genLinethrough(str:string){
			const s = str.split("");
			const out = s.map(c => "\u0336 \u0336 ").join("");
			return out;
		}

		for(const item of items){
			const tag = htmlDoc.getElementById(item[0]);

			console.log(item[0]);
			if(tag) {
				let v = "";
				if(item[0] != "tspan3456-3-9-7-3-7-7")
				 	v = escapeHtml(item[1]);
				else
					v = item[1];


				console.log(v);
				let value = `${v}`;
				console.log(value);
				if(item[2] != ""){
					value = `<tspan dx="0" class="strikethrough">${escapeHtml(item[1])}</tspan> <tspan dx="0">${escapeHtml(item[2])}</tspan>`
				}

				tag.innerHTML = value;
			}
			
		}
//

		for(const booleanPair of booleanPairs){
			if(booleanPair[2] == undefined){

				const tag1 = htmlDoc.getElementById(booleanPair[0]);
				tag1?.remove();

				const tag2 = htmlDoc.getElementById(booleanPair[1]);
				tag2?.remove();

				
			}else{
				const target = booleanPair[2] ? booleanPair[1] : booleanPair[0];
				const tag = htmlDoc.getElementById(target);
				if(tag)
					tag.remove();
			}

		}




		const innerHTML = htmlDoc.children[0].outerHTML;

		setSVG(innerHTML);
		applyStrikes();



		setImages(<React.Fragment>
			<div style={styles.page}>
				<ImagePage task={ctTest} field="Meter photo" label="Meter showing date, serial number, NMI and CT constant" />
			</div>

			<div style={styles.page}>
				<ImagePage task={ctTest} field="Sealed panel photo" label="Photo of whole panel sealed" />
			</div>

			<div style={styles.page}>
				<ImagePage task={ctTest} field="Test block photo" label="Photo of Test Block (showing current links closed)" />
			</div>

			<div style={styles.page}>
			<ImagePage task={ctTest} field="CT chamber photo" label="Photo of CT Chamber with seals on current transformers"/>
			</div>


			<div style={styles.page}>
			<ImagePage task={ctTest} field="CT name plate photo" label="Photo of the CT name plate" />
			</div>


			<div style={styles.page}>
			<ImagePage task={ctTest} field="Main switch photo" label="Photo of Main switch or isolating switches in on position" />
			</div>


			<div style={styles.page}>
			<ImagePage task={ctTest} field="Security locks photo" label="Photo of any security locks / seals fitted to outer doors" />
			</div>

		</React.Fragment>)



	}

	useEffect(() => {
		(async () => {
			try{
				await load(job);
			}catch(E){
				setError((E as any).toString())
			}
		})();
	},[]);


	const formRef = useRef<HTMLDivElement|null>(null);

	function applyStrikes(){
		if(formRef.current == null) return;

		const svg = formRef.current.firstChild as SVGElement;
		const g = svg.getElementsByTagName("g")[0] as SVGGElement;

		// console.log(svg);
		// console.log(g);
		const existingStrikes = Array.from(svg.getElementsByClassName("lineStrike"));

		for(const strike of existingStrikes)
			strike.remove();


		const strikes = Array.from(svg.getElementsByClassName("strikethrough"));

		// console.log(strikes);

		for(const strike of strikes){
			let parent = strike.parentNode;
		
			if(parent != null)
			parent = parent.parentNode;

			if(parent != null)
			parent = parent.parentNode;
			
			//if(parent != null)
			// = parent.parentNode;
			
			// console.log(parent)


			if(parent == null)
			return ;


			const size = strike.getBoundingClientRect();
			// console.log(size)
			const x = size.x - document.body.scrollLeft;
			const y = size.y - document.body.scrollTop+8;

			// console.log(strike.scrollLeft,x);
			const line = document.createElement("line");

			//line.setAttribute("transform",(parent as any).getAttribute("transform"));
			console.log("line",x,y,x+size.width)
			line.setAttribute("class","lineStrike");
			line.setAttribute("x1",x+"");
			line.setAttribute("y1",y+"");
			line.setAttribute("y2",y+"");
			line.setAttribute("x2",(x+size.width)+"");
			//line.setAttribute("style","stroke:rgb(255,0,0);stroke-width:2");

			line.setAttribute("stroke","red");
			line.setAttribute("stroke-width","2");

			// <line x1="0" x2="45" y1="15" y2="15" stroke="red" stroke-width="10" />

			const outer = parent.parentNode;
			if(outer != null)
				svg.appendChild(line);

		 // console.log(line);

		}

		const msg = ""+formRef.current.innerHTML;
		formRef.current.innerHTML = msg;
	}

	const docRef = useRef<any>();


	applyStrikes();


	function downloadPDF(){
		//if(svg != null)
		//
		if(docRef.current != null){
			const html = docRef.current.innerHTML;
		console.log(html)
		downloadTextFile(html,"test.html");

		}
	}

	return <React.Fragment >
		<React.Fragment  >
			{(error!=null)?<div style={{color:"red"}}>{error} <Button variant="outlined" onClick={() => router.history.goBack()}  >Back</Button> </div>:null}  

			{(error==null&&svg!=null)?<React.Fragment>
				<div style={styles.page}>
					<div ref={formRef} dangerouslySetInnerHTML={{ __html: svg }}></div>
				</div>
				{images}
				
			</React.Fragment>:null}  
		</React.Fragment>
		
		<Button onClick={downloadPDF}>Download</Button>
	</React.Fragment>
    
}



interface ImagePageI{
	task:Task;
	field:string;
	label?:string;
}

function ImagePage(props:ImagePageI){
	const {task, field, label} = props;

	const styles:CssStyles = {
		box:{
			height:"295mm",
			width:"208mm",
			textAlign:"center"
		} ,
		image:{
			maxWidth:"180mm",
			maxHeight:"250mm"
			//height:"75%"
		},
		label:{
			marginTop:10,
			textAlign:"center"
		} ,
		innerBox:{
			width:"100%",
			margin:"auto",
			textAlign:"center"
		} 
	}

    const router = useRouter();
	const global = useContext(GlobalContext);
	const {project,theme,ds} = global;

    const [imageData,setImageData] = useState("");

	async function loadImage(){
		const taskType = task.getType();
		const taskTypeDatas = taskType.getData().filter( tt => tt.name == field  );
		const taskTypeData:TaskTypeData = taskTypeDatas[0];
		const taskData = task.getJobDataItem(taskTypeData);
		const file = taskData.getLink() as VirtualFile;
		const image = await ds.getImageBase64(file);
		setImageData(image);
	}

	useEffect( () => {
		loadImage();
	},[])

	if(imageData == "")
		return null;
  
	return <div>
		<Box alignItems="center" display="flex" p={1}  m={1}  sx={ styles.box }>
			<Box p={1} style={styles.innerBox}>
				<img src={imageData} style={styles.image} />
				<div style={styles.label}>{label}</div>
			</Box>
		</Box>
	</div>


}