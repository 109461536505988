export function isEmpty(value: any) {
  return value === "" || value === undefined || value === null;
}

export function ArrayBufferToHex(arrayBuffer: ArrayBuffer) {
  const hashArray = Array.from(new Uint8Array(arrayBuffer)); // convert buffer to byte array
  const hex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join(""); // convert bytes to hex string
  return hex;
}

export async function SHA256(data: Uint8Array) {
  // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", data); // hash the message
  return hashBuffer;
}

export async function SHA256fromString(str: string) {
  const msgUint8 = new TextEncoder().encode(str);
  return await SHA256(msgUint8);
}

export async function SHA256fromStringHex(str: string): Promise<string> {
  return ArrayBufferToHex(await SHA256fromString(str));
}

export async function SHA256fromFile(file: File) {
  const arrayBuffer = await readFileArrayBuffer(file);
  const uint8t = new Uint8Array(arrayBuffer);
  return await SHA256(uint8t);
}

export async function SHA256fromFileHex(file: File): Promise<string> {
  return ArrayBufferToHex(await SHA256fromFile(file));
}
export async function SHA256fromBlobHex(blob: Blob): Promise<string> {
  let arrayBuffer: ArrayBuffer;
  if (typeof Blob.prototype.arrayBuffer == "undefined") {
    console.log("ios polyfill");
    arrayBuffer = await new Response(blob).arrayBuffer();
  } else {
    arrayBuffer = await blob.arrayBuffer();
  }

  const uint8t = new Uint8Array(arrayBuffer);
  return ArrayBufferToHex(await SHA256(uint8t));
}

async function readFileArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e: any) => {
      resolve(e.target.result);
    };

    reader.onerror = function (error: any) {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export function getFileNameExt(file: File): [string, string] {
  const l = file.name;
  const period = l.lastIndexOf(".");
  if (period == -1) {
    return [l, ""];
  } else {
    const name = l.substring(0, period);
    const ext = l.substring(period + 1);
    return [name, ext];
  }
}

//This securely generates a 32-byte random string and encodes it as hex (64 characters).

export function generateRandomHexString(numBytes: number) {
  const bytes = crypto.getRandomValues(new Uint8Array(numBytes));
  const array = Array.from(bytes);
  const hexPairs = array.map((b) => b.toString(16).padStart(2, "0"));
  return hexPairs.join("");
}
