

import { DataEvent, Datastore } from "../Datastore";

import { Base } from "./Base";
import { geocode, LngLat } from "../GoogleMaps";
import { google } from "google-maps";


export interface TechJ{
    id: number;
    type: string;
    deleted:boolean;
    firstName: string;
    lastName: string;
    email:string;
    phone:string;
    address:string;
    location:LngLat|null;
}

export class Tech extends Base{
    
    firstName:string;
    lastName:string;
    email:string;
    phone:string;
    address:string;
    location:LngLat|null;


    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.firstName =  event.data.firstName;
        this.lastName =  event.data.lastName;
        this.email =  event.data.email;
        this.phone =  event.data.phone;
        this.address = event.data.address;
        this.location = event.data.location;
    }
    
    getMarker(){
//        let icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${colour}" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/><path d="M0 0h24v24H0z" fill="none"/></svg>`
        
    }

    toString(){
        return `${this.firstName} ${this.lastName}`;
    }

    async geocode(google:google){
        const result = await geocode(google,this.address);
        const d = {location:result};
        await this.ds.updateEntity(this,d);
    }


    toJSON(){
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            firstName:this.firstName,
            lastName:this.lastName,
            email:this.email,
            phone:this.phone,
            address:this.address,
            location:this.location
        };
    }


}