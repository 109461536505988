import React, {  useState, useContext, useReducer, useEffect } from 'react';
import {GlobalContext} from './withGlobalContext';
import { TimeList } from './TimeList';
import { CollectionFilterTabs } from '../ui/CollectionFilterTabs';
import { CollectionList } from './CollectionList';
import { PlacesList } from './PlacesList';
import { CssStyles } from '../css-types';


interface TabbedCollectionListI{
    type:string;
    map?:boolean;
}

export function TabbedCollectionList(props:TabbedCollectionListI){
    const {type,map} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        loading:{
          marginTop:64,
          textAlign:"center"
        }
      };

    const [rawLoading,setRawLoading] = useState(true)

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.lp.sub(0,"*",forceUpdate),[]);
    const [subject,setSubject] = useState(0);

   async function load(){
        const list = ds.lp.getRaw(type);
        if(list.raw && !list.rawLoaded)
            await list.processRaw()
        
        setRawLoading(false);
    }

    useEffect(() => {
        setRawLoading(true);
        load();
    },[type]);

    if(ds.lp.isLoading() || rawLoading){
        return <div style={styles.loading}>Loading</div>
    }else{
        const list = ds.lp.get(type);
        const {subjects,collection,Render} = list;
        let view:any = null;
        const isSearchTab = subject == subjects.length;


        if(map){
            const items = subjects[subject].data;
          view = <PlacesList key={`places-${subject}-${items.length}`}  search={isSearchTab} items={items} Render={Render}  />

        }else if(!list.temporal || isSearchTab){ // search
            view = <CollectionList search={isSearchTab} key="subject-search" collection={collection} Render={Render} />
        }else{
            console.log("temporal",subjects[subject].temporal);
            const items = subjects[subject].temporal;
            view = <TimeList key={`subject-${subject}-${items.length}`} items={items}  RowView={Render} />
        }

        return <div key={type}>
            <CollectionFilterTabs value={subject} onChange={setSubject} collections={subjects} />
            {view}
        </div>
    }
}