import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { TaskTypeData } from "./TaskTypeData";

export interface TaskTypeJ {
  id: number;
  type: string;
  deleted: boolean;

  name: string;
  description: string;

  jobTypeID: number;
  required: boolean;
}

export class TaskType extends Base {
  name: string;
  description: string;

  jobTypeID: number;
  required: boolean;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.name = event.data.name;
    this.description = event.data.description;
    this.jobTypeID = event.data.jobTypeID;
    this.required = event.data.required;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.jobTypeID = transform(this.jobTypeID);
  }

  isRequired() {
    return this.required;
  }

  getData(): TaskTypeData[] {
    return this.ds.db
      .filter({ type: "TaskTypeData" })
      .filter((t) => t.taskTypeID == this.id);
  }

  getDataString(key: string): TaskTypeData {
    const matches = this.ds.db
      .filter({ type: "TaskTypeData" })
      .filter((t) => t.taskTypeID == this.id && t.name == key);
    if (matches.length == 1) return matches[0];
    else if (matches.length > 1) throw "Too many matches";
    else throw "Not found";
  }

  toString() {
    return `${this.name}`;
  }


  toSearchString() {
    return `taskType:${this.name}`;
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      name: this.name,
      description: this.description,
      jobTypeID: this.jobTypeID,
      required: this.required,
    };
  }
}
