import React, { useContext, useEffect, useReducer, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import { Card, ButtonGroup, Button, FormHelperText, IconButton, InputLabel, CircularProgress, CardMedia} from "@material-ui/core";
import { Task } from '../../lib/class/Task';
import { TaskDataI } from '../../lib/class/TaskData';
import { AttachFile, CameraAlt, Clear, Search } from '@material-ui/icons';
import { uploadFile } from '../../lib/download';
import { generateRandomHexString, getFileNameExt, SHA256fromFileHex } from '../../lib/Data';
import { VirtualFile, VirtualFileI } from '../../lib/class/VirtualFile';
import { getTimestamp } from '../../lib/time';
import useRouter from 'use-react-router';
import { CssStyles } from '../../css-types';




interface VirtualFileDataFieldI{
    task:Task;
    field:string;
    label?:string;
    accept?:string;
    capture?:string;
}


export function VirtualFileDataField(props:VirtualFileDataFieldI){
    const {task,field,label,accept,capture} = props; 

    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        button:{
            marginTop:10
        },
        imageContainer:{
            margin:10,
            textAlign:"center"
        } ,
        imageCard:{
            position:"relative",
            margin:10,
            display:"inline-block"
        } ,
        image:{
            maxWidth:800,
            height:400,
            margin:0
        },
        removeButton:{
            position:"absolute",
            top:0,
            right:0

        } ,
        photoButton:{
           //paddingLeft:60,
           //paddingRight:60
        },
        attachButton:{
            maxWidth:60
         },
         viewButton:{
            position:"absolute",
            top:50,
            right:0

         } ,
    }

    const [imageData,setImageData] = useState("");
    const [imageDataLoading,setImageDataLoading] = useState(false);


    let [taskTypeData,taskData] = task.getData(field);
    const type = taskTypeData.getDataType();

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"valueID",forceUpdate),[]);

/*
    let taskType = task.getType();
    let taskTypeDatas = taskType.getData().filter( tt => tt.name == field  );
    let taskTypeData:TaskTypeData;
    let str = "";

    if(taskTypeDatas.length == 1)
        taskTypeData = taskTypeDatas[0];
    else
        return <div>No '{field}' field found for {taskType.toString()}</div>
        
    let taskData:TaskData|null;
    try{
        taskData = task.getJobDataItem(taskTypeData);
        str = taskData.value;
    }catch(E){
        //console.log(E);
        taskData = null;
    }
*/
    async function takePhoto(){
        await selectFile("environment");
    }

    async function attachPhoto(){
        await selectFile(undefined);
    }


    async function selectFile(capture:string|undefined){
        const file = await uploadFile(accept,capture);
        //console.log(file);
        const hash = await SHA256fromFileHex(file);

        const [name,extension] = getFileNameExt(file);

        const d:VirtualFileI = {
            id:0,
            type:"VirtualFile",
            deleted:false,
            name:name,
            timestamp:getTimestamp(),
            extension:extension,
            mimetype:file.type,
            size:file.size,
            hash:hash,
            key:generateRandomHexString(32),
            serverHas:false
        };

        const virtualFile = await ds.createEntity(d);
        await ds.cacheUploadSave(file,d.key);

        if(taskData == null){
            const d:TaskDataI = {
                id:0,
                type:"TaskData",
                deleted:false,
                taskID:task.id,
                dataID:taskTypeData.id,
                value: null,
                valueID:virtualFile.id
            } 
            taskData = await ds.createEntity(d);
        }else{
            await ds.updateEntityField(taskData,{valueID:virtualFile.id})
        }   
        setImageData("");

        // ds.forceSave();
    }


    async function removeData(){
        if(taskData == null){
            throw "TaskData does not exist"
        }else{
            await ds.updateEntityField(taskData,{valueID:null})
            setImageData("");
        }
        
    }


    async function loadImage(){
        if(taskData != null && taskData.valueID != null && imageData == ""){
            //setImageDataLoading(true);

            console.log("load image")
            const file = taskData.getLink() as VirtualFile;
            console.log('taskData',taskData)
            console.log('file',file);
            const image = await ds.getImageBase64(file);
            setImageData(image);
            //setImageDataLoading(false);
        }
    }
    

    function viewImage(){
        console.log("viewImage");
        if(taskData != null && taskData.valueID != null){
            console.log(taskData.valueID);
            router.history.push("/image/"+taskData.valueID)
        }
    }
  
    loadImage();

    let l = taskTypeData.name;
    if(label)
        l = label;
    

    //let type = taskTypeData.getDataType()
    const validation = task.validateData(taskTypeData);


    //console.log(taskData)

    if(type.name != "id"){
        return <div>Incorrect data type {type.name} rendered by file input field</div>
    }else{
        let hasImage = false
        if(taskData != null && taskData.valueID != null)
            hasImage = true;

        return <div style={{margin:theme.spacing(1)}}>
            <InputLabel>{l}</InputLabel>
            <div>  {hasImage?<div style={styles.imageContainer}><Card style={styles.imageCard}>  {imageData!=""?<CardMedia component="img" style={styles.image} src={imageData} />:<CircularProgress color="primary"/>} <IconButton style={styles.viewButton} color="primary" onClick={viewImage}><Search /></IconButton> <IconButton style={styles.removeButton} color="secondary" onClick={removeData}><Clear /></IconButton></Card></div>:null} </div>
            <div>
            <ButtonGroup fullWidth variant="outlined" style={styles.button} color={hasImage?"default":"primary"} >
                  <Button  style={styles.photoButton} onClick={ () => takePhoto() } fullWidth  ><CameraAlt /> {hasImage?" Update Image":" Upload Image"}</Button>
          
                <Button   style={styles.attachButton} onClick={() => attachPhoto()}  ><AttachFile /></Button>
            </ButtonGroup>
               
                {(validation.length!=0)?<FormHelperText error={true}>{validation}</FormHelperText>:<FormHelperText> </FormHelperText>}
          
            </div>
        </div>
    }


}

