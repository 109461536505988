import moment, { Moment } from "moment";

interface TestResult689bAuto {
  operatorName: string;
  softwareVersion: string;
  testScriptFile: string;
  testName: string;
  meterNumber: string;
  class: string;
  wiring: string;
  meterConstant: string;
  constantUnits: string;
  meterType: string;
  location: string;
  testSteps: TestStep689bAuto[];
  // elementNumber: number; // this is now decided by timestamp order
}

interface TestStep689bAuto {
  timestamp: number;
  step: number;
  mode: string;
  injectedCurrent: number;
  injectedPhase: number;
  testLength: number;
  units: string;
  passThreshold: number;
  error: number;
  result: string;
  frequency: number;
  phaseResults: PhaseResult[];
  meterEnergy: number;
  testEnergy: number;
  temperature: number;
}

interface PhaseResult {
  voltage: number;
  current: number;
  phase: number;
}

export function parseFile689bAuto(file: string) {
  const rows = file
    .replaceAll(`"`, ``)
    .trim()
    .split("\n")
    .map((r) => r.trim().split(","));

  const start = "689B auto test results";
  if (rows[0][0] !== start) throw new Error("Not a 689B auto record");

  const testRows = rows.slice(18, 27);

  console.log(testRows);

  const temp = rows[13][0].toUpperCase();
  const meterNumber = temp.replace("-E2", "").replace("-E1", "");
  // const elementNumber = temp.indexOf("-E2") === -1 ? 1 : 2;

  const result: TestResult689bAuto = {
    operatorName: rows[2][1],
    softwareVersion: rows[4][1],
    testScriptFile: rows[6][1],
    testName: rows[8][1],
    meterNumber: meterNumber,
    class: rows[13][1],
    wiring: rows[13][2],
    meterConstant: rows[13][3],
    constantUnits: rows[13][4],
    meterType: rows[13][5],
    location: rows[13][6],
    testSteps: testRows.map(parseTestStep689bAuto),
    // elementNumber: elementNumber,
  };

  console.log(result);

  return result;
}

function parseTestStep689bAuto(row: string[]) {
  const rawTimestamp = row[0];
  let timestamp: Moment;
  if ( /\d{4}/.test(rawTimestamp))
    timestamp = moment(rawTimestamp, "DD-MM-YYYY HH:m");
  else timestamp = moment(rawTimestamp, "DD-MM-YY HH:m");

  const result: TestStep689bAuto = {
    timestamp: timestamp.valueOf(),
    step: parseInt(row[1]),
    mode: row[2],
    injectedCurrent: parseFloat(row[3]),
    injectedPhase: parseFloat(row[4]),
    testLength: parseFloat(row[5]),
    units: row[6],
    passThreshold: parseFloat(row[7]),
    error: parseFloat(row[8]),
    result: row[9],
    frequency: parseFloat(row[10]),
    phaseResults: parsePhaseResults(row),
    meterEnergy: parseFloat(row[20]),
    testEnergy: parseFloat(row[21]),
    temperature: parseFloat(row[22]),
  };

  return result;
}

function parsePhaseResults(row: string[]): PhaseResult[] {
  return [0, 1, 2].map((i) => parsePhaseResult(row, i));
}

function parsePhaseResult(row: string[], index: number): PhaseResult {
  const offset = 11;
  return {
    voltage: parseFloat(row[offset + index + 3 * 0]),
    current: parseFloat(row[offset + index + 3 * 1]),
    phase: parseFloat(row[offset + index + 3 * 2]),
  };
}
