import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, List,ListItem, ListItemText,  Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, IconButton, TextField, MenuItem} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { Map, Message, Phone, Navigation, Add, Remove, Cancel, Clear } from '@material-ui/icons';
import { createBlankLink } from '../../lib/download';
import { JobType } from '../../lib/class/JobType';
import { JobTypeData, JobTypeDataI } from '../../lib/class/JobTypeData';
import { TaskType } from '../../lib/class/TaskType';
import { TaskTypeData, TaskTypeDataI } from '../../lib/class/TaskTypeData';
import { DataType } from '../../lib/class/DataType';
import { EntityField, SelectField } from '../Fields';
import { JobTask } from '../../lib/class/JobTask';
import { CssStyles } from '../../css-types';

export interface JobTaskCardI{
    value:JobTask
}

export function JobTaskCard(props:JobTaskCardI){
    const {value} = props;


    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        title:{
           // width:250,
            fontWeight:"bold"
        }
    }

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,"*",forceUpdate),[]);

 

    return <Card>
        <CardContent>
            <Typography variant="h6" >Job Task Relationship</Typography>
        </CardContent>
        <Divider/>
        <CardContent>
            <SelectField label="Job Type" name="jobTypeID" value={value} filter={{type:"JobType"}} />       
            <SelectField label="Task Type" name="taskTypeID" value={value} filter={{type:"TaskType"}} />       
        </CardContent>
    </Card>


}
