import React, { ReactNode, useContext,useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Divider, Typography, Button, CardContent, Card} from "@material-ui/core";


interface CatchErrorProps{
  hasFailed:(msg:string) => void;
  isOkay:() => void;
  children:ReactNode
}

interface CatchErrorState{
  hasError:boolean;
  timer:any;
}

export class CatchError extends React.Component<CatchErrorProps,CatchErrorState> {
    state = { hasError:false,timer:null}

    constructor(props:CatchErrorProps) {
      super(props);
      const t = setTimeout(() => this.handleOkay(),250);
      this.setState({timer:t})
    }
  
    handleOkay(){
      console.log("timer handle okay")
      if(this.state.timer != null)
        clearTimeout(this.state.timer);
        this.props.isOkay();
    }
    static getDerivedStateFromError(error:any,) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  


    componentDidCatch(error:any, errorInfo:any) {
      const msg = error.toString();;
      console.log(error);
      console.log(errorInfo);
       if(this.state.timer != null)
        clearTimeout(this.state.timer);
      this.props.hasFailed(msg);
      console.error(error,errorInfo)
    }

    render() {

      const styles = {
        hide:{
          display:'none'
        },
        small:{
          width: 600,
          height:600,
          overflow:"scroll"
        }
      }

      if (this.state.hasError) {
        return null;
      }
  
      return <div style={styles.small}>
        {this.props.children}
      </div>
    }
  }