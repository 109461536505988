import moment from "moment";
import { MuiColor } from "../../css-types";
import { DataEvent, Datastore } from "../Datastore";

import { Base } from "./Base";
import { JobData, JobDataI } from "./JobData";
import { JobType } from "./JobType";
import { JobTypeData } from "./JobTypeData";
import { Site } from "./Site";
import { Task, TaskState, TaskStateToColor, TaskStatus } from "./Task";
import { TaskType } from "./TaskType";

export interface JobJ {
  id: number;
  type: string;
  deleted: boolean;
  //workOrder: string;
  siteID: number;
  typeID: number;
  startDate: number;
  dueDate: number;
  ignore: boolean;
  sent: boolean;
}

export class Job extends Base {
  //workOrder:string;
  siteID: number;
  typeID: number;
  dueDate: number;
  startDate: number;
  sent: boolean;
  ignore: boolean;
  cachedIssues: string;

  cachedTasks: number[];

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);
    //this.workOrder = event.data.workOrder;
    this.siteID = event.data.siteID;
    this.typeID = event.data.typeID;
    this.dueDate = event.data.dueDate;
    this.startDate = event.data.startDate;
    this.sent = event.data.sent;
    this.ignore = event.data.ignore;
    this.cachedIssues = "";
    this.cachedTasks = [];
  }



  getProfit(){
    const tasks = this.getTasks();
    const profits = tasks.map( t => t.getProfit());
    let total = 0;
    for(const profit of profits)
        total += profit;
      
    return profits;
  }


  isIgnored() {
    return this.ignore;
  }

  isSent() {
    return this.sent;
  }

  toSearchString() {
    const type = this.getType();
    const site = this.getSite();

    const meterNumber = this.getJobDataItemString("Meter Number");

    let str = "";

    if (type !== undefined) str += type.name;
    else str += "type";

    str += " ";

    if (site !== undefined) str += `${site.nmi} ${site.address}`;
    else str += "site";

    str += " 'job' ";

    

    str += meterNumber;

    return str;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.siteID = transform(this.siteID);
    this.typeID = transform(this.typeID);
  }

  getType(): JobType {
    return this.ds.db.get(this.typeID) as JobType;
  }

  getSite(): Site {
    return this.ds.db.get(this.siteID) as Site;
  }

  getDueDate() {
    return moment(this.dueDate);
  }

  hasStated() {
    return moment().isAfter(this.getStartDate());
  }

  getStartDate() {
    return moment(this.startDate);
  }

  getTime() {


    // get latest scheduled task?
    const tasks = this.getTasks();
    if (tasks.length == 0) return this.getStartDate();
    else {
      const latest = tasks
        .map((t) => t.getTimestamp())
        .filter((t) => t.isValid())
        .sort((a, b) => b.diff(a));


      if (latest.length != 0) return latest[0];
      else {
        if (this.isOverdue()) {
          return this.getDueDate();
        } else {
          return this.getStartDate();
        }

      }
    }
  }





  async duplicate():Promise<Job>{

    const items = this.getJobData();
    
    console.log(items);


    const j:JobJ = {
              id:0,
              type:"Job",
              deleted:false,
              siteID:this.siteID,
              typeID:this.typeID,
              startDate: this.startDate,
              dueDate: this.dueDate,
              sent:false,
              ignore:false
          }
        
          console.log(j);

        const jobData = Object.fromEntries( items.map( a => ([a.getDataType().name,  a.getValue()])))
          console.log(jobData);

         const job = await this.ds.createEntity(j);


         const keys = Object.keys(jobData);
         for(const key of keys){
             const value = jobData[key];
             const dataTypes = this.ds.db.filter({type:"JobTypeData",name:key, jobTypeID:job.typeID}) as JobTypeData[];
             if(dataTypes.length === 0)
                 throw new Error("dataType no matches");
 
             const dataType = dataTypes[0];
             console.log(dataType, dataTypes)
             const t = dataType.getDataType();
             console.log(t);
             
             const jd:JobDataI = {
                 id:0,
                 type:"JobData",
                 deleted:false,
                 jobID:job.id,
                 dataID:dataType.id,
                 value:null,
                 valueID:null
             }
 
             console.log(t);
 
             if( t.name === "id" ){
                 if(value != null){
                     jd.valueID = value.id;
                 }
 
             }else{
                 jd.value = value
             }
 
             await this.ds.upsert({ type:"JobData", dataID:dataType.id, jobID:job.id },jd);
 
         }


         return job;

  }


  // processTasks() {
    // this.cachedTasks = this.ds.db.filter({ type: "Task", jobID: this.id });
  // }

  resetTasks(){
    this.cachedTasks = [];
  }

  addTask(v:Task){
    if(this.cachedTasks.find(t => t === v.id) === undefined)
      this.cachedTasks.push(v.id);
  }

  removeTask(v:Task){
    this.cachedTasks = this.cachedTasks.filter(t => t === v.id)
  }


  getTasks(): Task[] {
    return this.cachedTasks.map(id => this.ds.db.get(id));
  }

  getJobData(): JobData[] {
    return this.ds.db
      .filter({ type: "JobData" })
      .filter((t) => t.jobID == this.id);
  }

  getMainCompletedTask(){
    const jobType = this.getType();
    const meterTesting: TaskType = this.ds.db.filter({
        type: "TaskType",
        name: "Meter Test",
      })[0];
      const serviceInspection: TaskType = this.ds.db.filter({
        type: "TaskType",
        name: "Service Inspection",
      })[0];


      const ctTesting: TaskType = this.ds.db.filter({
        type: "TaskType",
        name: "CT Testing",
      })[0];


      const targetTask = (() => {
        const  t = jobType.toString();
        if(t ===  "Asset Inspection") return serviceInspection
        if(t === "Mondo CT Testing") return ctTesting;
        return meterTesting;
      })();
        
      return this.getCompletedTask(targetTask);
}


getPrelimTask(){
  const jobType = this.getType();
    const preInspection: TaskType = this.ds.db.filter({
      type: "TaskType",
      name: "Pre-inspection",
    })[0];

    return this.getCompletedTask(preInspection);
}




  getCompletedTask(taskType: TaskType) {
    const matching_tasks = this.getTasks().filter(
      (t) => t.typeID == taskType.id
    );
    const completed = matching_tasks.filter((t) => t.isComplete()   );
    

    if (completed.length == 0) throw "No completed task";
    else if (completed.length > 1) throw "too many matches";
    else return completed[0];
  }

  getTask(taskType: TaskType) {
    const matching_tasks = this.getTasks().filter(
      (t) => t.typeID == taskType.id
    );
    // let completed = matching_tasks.filter((t) => t.isSuccessful());

    if (matching_tasks.length == 0) throw "No tasks";
    else if (matching_tasks.length > 1) throw "too many matches";
    else return matching_tasks[0];
  }

  getJobDataItem(data: JobTypeData) {
    const datas = this.getJobData();
    // console.log(datas);
    const match = datas.filter((d) => d.dataID == data.id);
    if (match.length == 0) {
      console.error(`No match for ${data.name} on job`);
      throw "No match"; //throw `No match for ${data.name} on job`;
    } else if (match.length > 1) throw `Multiple matched data on ${data.name}`;
    else return match[0];
  }

  getJobDataItemKey(key: string) {
    const type = this.getType();
    const attributes = type.getData();
    const match = attributes.filter((a) => a.name == key);

    if (match.length == 0) throw `No Match for key = ${key}`;
    else if (match.length > 1) {
      throw `Multiple matches for key = ${key}`;
    } else {
      const attribute = match[0];
      return this.getJobDataItem(attribute);
    }
  }

  getJobDataItemString(key: string) {
    const type = this.getType();
    const attributes = type.getData();
    const match = attributes.filter((a) => a.name == key);

    if (match.length != 1) return "";
    else {
      try {
        return this.getJobDataItem(match[0]).value;
      } catch (E) {
        return "";
      }
    }
  }

  getJobDataItemValueString(key: string) {
    const type = this.getType();
    const attributes = type.getData();
    const match = attributes.filter((a) => a.name == key);

    if (match.length == 0) return "";
    else if (match.length > 1) {
      return "Multiple matches"; //throw "Multiple matches"
    } else {
      try {
        const attribute = match[0];
        const dataType = attribute.getDataType();
        if (dataType.name == "id") {
          const id = this.getJobDataItem(match[0]).valueID;
          if (id != null) return this.ds.db.get(id);
          else return "";
        } else {
          return this.getJobDataItem(match[0]).value;
        }
      } catch (E) {
        return "";
      }
    }
  }

  getTaskState(taskType: TaskType): TaskState {
    const tasks = this.getTasks().filter((t) => t.typeID == taskType.id);
    const states = tasks.map((t) => t.getState());
    if (states.length == 0) {
      return TaskState.Unplanned;
    } else {
      return Math.min(...states);
    }
  }

  async upsertJobData(jobDataKey: string, value: any) {
    //let taskData = await this.getsertJobDataItemKey(taskDataKey);

    const jobData = await this.getJobDataItemKey(jobDataKey);
    // console.log(jobData);
    // console.log(value);

    await this.ds.updateEntityField(jobData, value);
  }

  getUnplannedTasks() {
    const type = this.getType();

    if (type === undefined) return [];

    const todoTasks = type.getTasks().filter((t) => t.isRequired());
    const tasks = this.getTasks().sort((a, b) =>
      a.getTimestamp().diff(b.getTimestamp())
    );

    const unplanned: TaskType[] = [];
    for (const todo of todoTasks) {
      let found = false;
      for (const task of tasks) {
        if (
          task.typeID == todo.id &&
          task.getState() != TaskState.Aborted &&
          task.getState() != TaskState.Cancelled
        ) {
          found = true;
        }
      }

      if (!found) unplanned.push(todo);
    }

    return unplanned;
  }

  isAssignable() {
    //if there are tasks available and none are assigned
    const unplanned = this.getUnplannedTasks();
    const tasks = this.getTasks() || [];

    const pending = tasks.filter((t) => t.status == TaskStatus.Pending);
    if (pending.length != 0) {
      return false;
    } else {
      if (unplanned.length > 0) return true;
      else return false;
    }
  }

  isPending() {
    return !this.isCompleted();
  }

  isOverdue() {
    const today = moment();
    return !this.isCompleted() && today.isAfter(this.getDueDate());
  }

  hasIssues() {
    return this.cachedIssues.length > 0;
  }

  getIssue() {
    return this.cachedIssues;
  }

  processIssues() {
    let issue = "";
    const tasks = this.getTasks();
    for (let task of tasks) {
      if (task.hasIssues()) issue = task.getIssue();
    }

    if (issue === "") issue = this.validateTaskCount();

    this.cachedIssues = issue;
  }



  validateTaskCount(){
    if(this.typeID === 22588){
      if(this.cachedTasks.length > 1)
        return "Too many tasks associated this job";
    }

    return "";
  }

  hasLocation() {
    const site = this.getSite();
    return site.hasLocation();
  }

  isCompleted() {
    const type = this.getType();
    //console.log("typeID",this.typeID);
    //console.log("type",type);
    if (type === undefined) return false;

    const todoTasks = type.getTasks().filter((t) => t.isRequired());
    const tasks = this.getTasks();

    for (const todo of todoTasks) {
      const done = tasks.filter(
        (t) => t.typeID == todo.id && t.isComplete() && !t.hasIssues()
      );

      if (done.length == 0) return false;
    }

    return true;
  }

  toString() {
    try {
      const site = this.getSite();
      const type = this.getType();

      return `${site.getNMI()} ${type.toString()}`;
    } catch (E) {
      return "site not set";
    }
  }

  getData(name: string): [JobTypeData, JobData] {
    const taskType = this.getType();
    const dataType: JobTypeData = taskType
      .getData()
      .filter((tt) => tt.name === name)[0];

    let taskData: JobData;
    try {
      taskData = this.getJobDataItem(dataType);
    } catch (E) {
      // console.error("getData", name);
      //if(E == "No Match")
      //   taskData = this.createJobDataItem(dataType);
      //else
      throw E;
    }
    return [dataType, taskData];
  }

  async createJobDataItem(type: JobTypeData) {
    const d: JobDataI = {
      id: 0,
      type: "JobData",
      deleted: false,
      jobID: this.id,
      dataID: type.id,
      value: null,
      valueID: null,
    };
    const taskData: JobData = (await this.ds.createEntity(d)) as JobData;
    return taskData;
  }

  async createMissingData() {
    const taskType = this.getType();
    // console.log(taskType);
    const dataTypes = taskType.getData();

    for (const dataType of dataTypes) {
      try {
        this.getJobDataItem(dataType);
      } catch (E) {
        if (E == "No match") await this.createJobDataItem(dataType);
      }
    }
  }

  getColor(): MuiColor {
    return JobToColor(this);
  }

  getTaskColor(): MuiColor {
    const type = this.getType();
    const requiredTasks = type.getTasks().filter((t) => t.isRequired());
    const states = requiredTasks.map((t) => {
      return this.getTaskState(t);
    });
    const last = states.sort((a, b) => a - b)[0];
    return TaskStateToColor(last);
  }


  getTaskIcon():"wrench"|"clock"{

    const tasks = this.getTasks();
    const pending = tasks.filter( t => t.status === TaskStatus.Pending );
    const isScheduled = pending.map(t => t.scheduleTimestamp ).some(t => t != null && t >= Date.now()  )

    return isScheduled ? "clock" : 'wrench';
  }



  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      //   workOrder:this.workOrder,
      siteID: this.siteID,
      typeID: this.typeID,
      dueDate: this.dueDate,
      sent: this.sent,
      ignore: this.ignore,
    };
  }
}

export function JobToColor(job: Job): MuiColor {
  if (job.isAssignable()) return "warning";
  else if (job.isPending()) return "info";
  else if (job.isOverdue()) return "error";
  else if (job.hasIssues()) return "error";
  else if (job.isCompleted()) return "success";
  else if (job.isIgnored()) return "success";
  return "primary";
}
