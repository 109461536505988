import React, { useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, Theme, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, Button} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';


import useMediaQuery from '@material-ui/core/useMediaQuery';

import {JobCard} from "../../ui/ClassView/JobCard"
import {SiteCard} from "../../ui/ClassView/SiteCard"
import {TaskCard} from "../../ui/ClassView/TaskCard"
import { uploadFile } from '../../lib/download';
import { VirtualFileI } from '../../lib/class/VirtualFile';
import { generateRandomHexString, getFileNameExt, SHA256fromFileHex } from '../../lib/Data';
import { getTimestamp } from '../../lib/time';
import { DocumentI } from '../../lib/class/Document';
import { CssStyles } from '../../css-types';


export function CreateDocument(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const sm = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));



	const styles:CssStyles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		}, 
        main:{
            paddingLeft:sm?0:20,
            paddingRight:sm?0:20
          },
          loading:{
              textAlign:"center",
              marginTop:100,
              color:"#888"
          }
	}

    async function upload(){
        const capture = undefined;
        const accept = ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        const file = await uploadFile(accept,capture);
        //console.log(file);
        const hash = await SHA256fromFileHex(file);

        const [name,extension] = getFileNameExt(file);

        const vf:VirtualFileI = {
            id:0,
            type:"VirtualFile",
            deleted:false,
            name:name,
            timestamp:getTimestamp(),
            extension:extension,
            mimetype:file.type,
            size:file.size,
            hash:hash,
            key:generateRandomHexString(32),
            serverHas:false
        };

        const virtualFile = await ds.createEntity(vf);
        await ds.cacheUploadSave(file,vf.key);

        const docD:DocumentI = {
            id:0,
            type:"Document",
            deleted:false,
            label:name,
            description:"",
            timestamp:getTimestamp(),
            fileID:virtualFile.id
        } 
        const doc = await ds.createEntity(docD);
        

    }
    
   
	return <div style={styles.root} key={"create"}> 	
            <Header title={"Create Document"} back={true} />

            <Card>
                <CardContent>
                    <Button color="primary" variant="outlined" onClick={() => upload()}>Upload File</Button>
                </CardContent>
            </Card>
        </div>
    
}

