
import { Base } from "./Base";
import { JobType } from "./JobType";
import { TaskType } from "./TaskType";
import { DataEvent, Datastore } from "../Datastore";

export interface JobTaskI {
  id: number;
  type: string;
  deleted: boolean;
  jobTypeID: number;
  taskTypeID: number;
}

export class JobTask extends Base {
  jobTypeID: number;
  taskTypeID: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.jobTypeID = event.data.jobTypeID;
    this.taskTypeID = event.data.taskTypeID;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.jobTypeID = transform(this.jobTypeID);
    this.taskTypeID = transform(this.taskTypeID);
  }

  getJobType(): JobType {
    return this.ds.db.get(this.jobTypeID) as JobType;
  }

  getTaskType(): TaskType {
    console.log(this.taskTypeID);
    if (typeof this.taskTypeID === undefined) throw new Error("shit");
    return this.ds.db.get(this.taskTypeID) as TaskType;
  }

  toString(): string {
    try {
      const job = this.getJobType();
      const task = this.getTaskType();
      return `${job.name} - ${task.name}`;
    } catch (E) {
      return "error";
    }
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      jobTypeID: this.jobTypeID,
      taskTypeID: this.taskTypeID,
    };
  }
}
