
import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, IconButton, InputAdornment} from "@material-ui/core";
import { Task } from '../../lib/class/Task';
import { Timer } from '@material-ui/icons';
import { getTimestamp } from '../../lib/time';
import { CssStyles } from '../../css-types';



interface TimefieldI{
    label?:string;
    name:string;
    task:Task;
}


export function Timefield(props:TimefieldI){
    const {label,task,name} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        root: {
            flexGrow: 1,
            display:"inline-block",
            verticalAlign:"middle"
        },
        buttonIcon:{
            verticalAlign:"middle"
        }
    }


    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,name,forceUpdate),[]);

    const value = moment((task as any)[name]);
    console.log(value.format("HH:mm"));



    async function changeTime(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
        const key = event.target.name;
        const value = event.target.value;
        const timestamp = moment(value,moment.HTML5_FMT.DATETIME_LOCAL)

        let v:null|number = null;
        if(timestamp.isValid())
            v= timestamp.valueOf();

        const d:any = {};
        d[key] = v;
        await ds.updateEntity(task,d);
      //  await ds.save();
    //   ds.forceSave();
    }

    const validation = task.validateAttribute(name);





    async function setNow(){
        const key = name;
        const value = getTimestamp();
        const d:any = {};
        d[key] = value
        await ds.updateEntity(task,d);

    }


    const inAdor = <InputAdornment position="end">
        <IconButton color="primary" style={styles.buttonIcon} onClick={() => setNow()}><Timer /></IconButton> 
    </InputAdornment>

    return <React.Fragment>
        <TextField  InputProps={{endAdornment:inAdor}} fullWidth style={styles.root} type="datetime-local" label={label} margin="normal"  InputLabelProps={{ shrink: true }} name={name} value={value.format(moment.HTML5_FMT.DATETIME_LOCAL)} onChange={(e) => changeTime(e)}  error={validation.length!=0} helperText={validation}/>

    </React.Fragment>


}
