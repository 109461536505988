

import { SHA256fromBlobHex } from './Data';
import { Datastore } from "./Datastore";

interface SessionI{
	id:number;
	userID:number;
	key:string;
}

export class API{

   // domain:string;
 
	ds:Datastore;


    constructor( ds:Datastore){
		this.ds = ds;
        //this.domain =  domain; //"https://api.geometer.epidev.local";  //"https://api.geometer.epidev.com";
		//this.session = null;
    }


	// async loadSessionCookie():Promise<SessionI|null>{
	// 	try{
	// 		let obj = Cookies.get("session");
	// 		console.log(obj);
	// 		if(obj == undefined) 
	// 			throw "Session not set";
	// 		else
	// 			return JSON.parse(obj.toString());
	// 	}catch(E){
	// 		return null;
	// 	}
	// }



// 	async setSessionCookie(key:string){
//         //Cookies.set('session', key, { domain: 'localhost' });
//        // Cookies.set('session', key, { domain:  this.ds.domain });
//         //Cookies.set('session', key, { domain: 'api.geometer.epidev.local' });


// 		console.log(this.ds.domain);

//         //await this.waitCookie();
//   }

//   async waitCookie(){

//     let start = new Date().getTime();

//     while( typeof Cookies.get('session')  == "undefined"){
//         //console.log( Cookies.get('session') );
//         await sleep(500);
//         let now = (new Date()).getTime();
//         if( now - start > 5*1000 )
//            throw "cookie set timeout"
//     }

	
//   }



  async sendError(msg:string){
	await this.request("error",{error:msg});
  }


    async request(path:string,data?:any,key?:any):Promise<any>{
		const options:any = {
			credentials: 'include',
			headers:{}
		};

		options.cors = "no-cors";
		if(typeof data != "undefined"){
			options.method = "POST";
			if( data instanceof  File || data instanceof Blob ){
				const formData = new FormData();
				formData.append('file', data);
				const hash = await SHA256fromBlobHex(data);
				formData.append('hash', hash);
				if(typeof key != "undefined")
					formData.append('key', key);

				options.body = formData;
			}else{
				options.headers['Content-Type'] = "application/json; charset=UTF-8";
				options.body = JSON.stringify(data);
			}
		}


		options.headers['x-version'] = this.ds.version;
  
		return fetch(`${this.ds.protocol}://${this.ds.apiDomain}/${path}`,options) 
		  .then(async (response) => { 
			// console.log(response.status);
			// console.log(response.statusText);
			// console.log(response.type);
			// console.log(response.url);
			const content_type = response.headers.get('Content-Type');
			if( content_type ===  "application/json; charset=utf-8" ){
				const text = await response.text();
				return JSON.parse(text);
			}else{
				return response.arrayBuffer()
			}
		  }).then( res =>{
				if('error' in res)
					throw res.error;
				
				return res;
			});
	  }


}