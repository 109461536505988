

import { DataEvent, Datastore } from "../Datastore";

import { Base } from "./Base";
import moment from "moment";


export interface DocumentI{
    id: number;
    type: string;
    deleted:boolean;
    label:string;
    description:string;
    timestamp:number;
    fileID:number|null;
}

export class Document extends Base{
    
    label:string;
    description:string;
    timestamp:number;
    fileID:number|null;
    
    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.label =  event.data.label;
        this.description =  event.data.description;
        this.timestamp =  event.data.timestamp;
        this.fileID =  event.data.fileID;
    }    


    getTimestamp(){
        return moment(this.timestamp);
    }

    toString(){
        return `${this.label}`;
    }

    toJSON(){
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            label:this.label,
            description:this.description,
            timestamp:this.timestamp,
            fileID:this.fileID
        };
    }


}