import React,{ ChangeEvent } from "react";
import { Button, TextField } from "@material-ui/core";



export interface SvgEllipse{
    type:"ellipse"
    key:string;
    x:number;
    y:number;
    width:number;
    height:number;
}



interface SvgEllipseRenderI{
    value:SvgEllipse;
    selected:boolean;
    onMouseDown:()=>void;
}

export function SvgEllipseRender(props:SvgEllipseRenderI){
    const {value, onMouseDown, selected} = props;

    return <ellipse cx={value.x} cy={value.y} rx={value.width} ry={value.height} stroke="blue" strokeWidth={1} fill={selected?"rgba(0,0,255,0.2)":"rgba(0,0,255,0)"} onMouseDown={onMouseDown} />
}



interface SvgEllipseFormI{
    value:SvgEllipse;
    onChange:(value:SvgEllipse)=>void;
    onRemove:()=>void;
}

export function SvgEllipseForm(props:SvgEllipseFormI){
    const {value,onChange,onRemove} = props;

    const {key,x,y,width,height} = value;


    function change(key: keyof SvgEllipse, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>){
        const {value:v} = event.target;
        const cloned:SvgEllipse = {...value};
        switch(key){
            case 'height':
                cloned.height = parseFloat(v);
                break;
            case 'width':
                cloned.width = parseFloat(v);
                break;
            case 'x':
                cloned.x = parseFloat(v);
                break;
            case 'y':
                cloned.y = parseFloat(v);
                break;
            case 'key':
                cloned.key = v;
                break;
            default:
                break;
        }
        onChange(cloned);
    }

    return <div>
        <TextField label="key" value={key} type="text" fullWidth margin="normal" onChange={(e) => change('key',e)} />
        <TextField label="x" value={x} type="number" fullWidth margin="normal" onChange={(e) => change('x',e)}  />
        <TextField label="y" value={y} type="number" fullWidth margin="normal" onChange={(e) => change('y',e)}  />
        <TextField label="width" value={width} type="number" fullWidth margin="normal" onChange={(e) => change('width',e)}  />
        <TextField label="height" value={height} type="number" fullWidth margin="normal" onChange={(e) => change('height',e)}  />
        <Button color="secondary" onClick={onRemove}>Remove</Button>
    </div>
}