import React,{ useContext } from "react";
import { Chip } from "@material-ui/core";
import { GlobalContext } from "./withGlobalContext";


interface ColorChipI{
    size?:"small"|"medium";
    label:string;
    style?:any;
    color:string;
    onClick?:any;
}

export function ColorChip(props:ColorChipI){
    const {label,color,onClick} = props;

    const size = ("size" in props) ? props.size : "medium";

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const s:any = Object.assign({},props.style,{
        background:theme.palette[color].main,
        color:theme.palette[color].contrastText
    }); 

    return <Chip size={size} label={label }  style={s} onClick={onClick} />
}
