import React, { useContext } from 'react';
import useRouter from 'use-react-router';

import {ListItem,ListItemText} from "@material-ui/core";

import {GlobalContext} from '../../ui/withGlobalContext';
import moment from 'moment';
import { Document } from '../../lib/class/Document';

export interface DocumentRowI{
    value:Document;
    style?:any;
}

export function DocumentRow(props:DocumentRowI){
    const {value,style} = props;
    const router = useRouter();


    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {
        root:{
            marginBottom:20
        },
        row:Object.assign({
            height:84,
            overflow:"hidden"
        },style)
    };

    console.log(value);

    const timestamp = moment(value.timestamp);
    


    return <ListItem style={styles.row} button divider onClick={() => router.history.push("/document/"+value.id)} >
        <ListItemText primary={`${value.label}`} secondary={`${timestamp.format("DD/MM/YY HH:mm")}`} />
    </ListItem>

}
