import React, { useContext, useState } from 'react';
import moment from "moment";
import {GlobalContext} from '../../ui/withGlobalContext';

import {Button, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job, JobJ } from '../../lib/class/Job';

import useRouter from 'use-react-router';
import { Base } from '../../lib/class/Base';
import { uploadFile, uploadFiles } from '../../lib/download';
import { importCSV, readFileText } from '../../lib/CSV';
import { Site, SiteJ } from '../../lib/class/Site';
import { JobData, JobDataI } from '../../lib/class/JobData';
import { TestResult } from '../../lib/class/TestResult';
import { ProcessedMessage, ReceivedMessage } from '../TesterSync';
import { bruteParse } from '../../lib/class/TestUnitParse/parseFile';
import { syncRecord } from '../../lib/class/TestUnitParse/SyncRecord';

export function TestResultImport(){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const [results,setResults] = useState<ProcessedMessage[]|null>(null);

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64,
            paddingLeft:20,
            paddingRight:20
		}
	}


    async function importFile(){
        console.log("importing")
        const files = await uploadFiles();
        const messages:ProcessedMessage[] = [];

        for(const file of files){
            const data:string = await readFileText(file);
            try{
                const parts = await bruteParse(ds,data);
                messages.push(...parts);
            }catch(E){
                console.log(file.name,E);
                throw E;
            }
        
        } 

        ds.save();
        setResults(messages);
    }


	return  <div style={styles.root} > 	
            <Header title="Test Result Import" back={true} />
            {results == null ?<Card>
                <CardContent>
                    <Button onClick={importFile} color="primary" variant="outlined" >Open File</Button>
                </CardContent>
            </Card>:<ReceivedMessage results={results} />}
        
            <Navigation  />
        </div>
    
}
