
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";

export interface DataTypeI {
  id: number;
  type: string;
  deleted: boolean;
  name: string;
}

export class DataType extends Base {
  name: string;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);
    this.name = event.data.name;
  }

  toString() {
    return `${this.name}`;
  }

  toSearchString(): string {
    return `${this.name} 'DataType'`;
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      name: this.name,
    };
  }
}
