import moment from "moment";
import { Datom } from "../../lib/class/Datom";
import { Job } from "../../lib/class/Job";
import { Site } from "../../lib/class/Site";
import { Task, TaskStatus } from "../../lib/class/Task";
import { Datastore } from "../../lib/Datastore";
import { splitComments } from "./split-comments";



export function getNstValues(ds:Datastore, job:Job, task:Task, site:Site){

    const tech = task.getTech();
    const startTimestamp = task.getStartTimestamp();
    const startTime = startTimestamp.format("HH:mm")
    const endTimestamp = task.getEndTimestamp();
    const endTime = endTimestamp.format("HH:mm")
    const date = endTimestamp.format("DD/MM/YY");


const texts = {
   "Date": [date],
   "Start Time": [startTime],
   "Technician": [tech.toString()],
   "Company Name": [site.customerName, site.customerContactNameAmend],
   "Address": [site.address, site.addressAmend],
   "NMI": [site.nmi, site.nmiAmend],
   "JHA Control Measures": [task.getDataString("JHA Control Measures")], // might be missing key
   "Operator name": [tech.toString()], // what is this
   "Report Time": [startTime], // what is this
   "MPB Name": [task.getDataString("MPB")],
   "Meter Serial Number": [task.getDataString("Meter Serial Number")],
   "Seal Serial Number": [task.getDataString("Seal Serial number")],
   "A to N impedance": [task.getDataString("A to N impedance")],
   "A to N voltage": [task.getDataString("A to N voltage")],
   "N to E voltage": [task.getDataString("N to E voltage")],
   "Finish time": [endTime],
   "Comments": [splitComments(task.getDataString("Comments"),120)],
   "Aborted Status":[ task.status == TaskStatus.Aborted ? "ABORTED" : "" ]
};

// missing comments?


const ellipses = {
   "JHA Instructions True": task.getDataString("JHA Instructions"),
   "JHA Instructions False": !task.getDataString("JHA Instructions"),
   "JHA PPE True": task.getDataString("JHA PPE"),
   "JHA PPE False": !task.getDataString("JHA PPE"),
   "JHA Induction True": task.getDataString("JHA Induction"),
   "JHA Induction False": !task.getDataString("JHA Induction"),
   "JHA Access True": task.getDataString("JHA Access"),
   "JHA Access False": !task.getDataString("JHA Access"),
   "JHA Isolated True": task.getDataString("JHA Isolated"),
   "JHA Isolated False": !task.getDataString("JHA Isolated"),
   "JHA Live Points True": task.getDataString("JHA Live Points"),
   "JHA Live Points False": !task.getDataString("JHA Live Points"),
   "JHA Traffic True": task.getDataString("JHA Traffic"),
   "JHA Traffic False": !task.getDataString("JHA Traffic"),
   "JHA Control Measures True": task.getDataString("JHA Control Measures"),
   "JHA Control Measures False": !task.getDataString("JHA Control Measures"),
   "Ariel Service True": task.getDataString("Ariel Inspection"),
   "Ariel Service False": !task.getDataString("Ariel Inspection"),
   "Service has POA clearance True": task.getDataString("POA / FOHLCB Clearance"),
   "Service has POA clearance False": !task.getDataString("POA / FOHLCB Clearance"),
   "NST Polarity True": task.getDataString("NST Polarity"),
   "NST Polarity False": !task.getDataString("NST Polarity"),
   "A to N impedance True": task.getDataString("A to N impedance test"),
   "A to N impedance False": !task.getDataString("A to N impedance test"),
   "A to N voltage True": task.getDataString("A to N voltage test"),
   "A to N voltage False": !task.getDataString("A to N voltage test"),
   "N to E voltage True": task.getDataString("N to E voltage test"),
   "N to E voltage False": !task.getDataString("N to E voltage test"),
   "NST Tests Passed True": task.getDataString("All tests passed"),
   "NST Tests Passed False": !task.getDataString("All tests passed"),
   "Service Type OH": task.getDataString("Service Type") === 'OH',
   "Service Type Underground": task.getDataString("Service Type") === 'Underground',
   "Service Type Cable": task.getDataString("Service Type") === 'Cable',
   "Service Type Sub": task.getDataString("Service Type") === 'Sub',
   "Service Type Inderminate": task.getDataString("Service Type") === 'Inderminate'
}

return [texts, ellipses];


}