import { SHA256fromStringHex } from "../../Data";
import { Datastore } from "../../Datastore";
import { TestResultRow, TestResultRowI } from "../TestResultRow";
import { parseFile689B } from "./689B";

export async function sync689B(
  ds: Datastore,
  file: string
): Promise<TestResultRow[]> {
  const cleaned = file.replaceAll("\r", "").replaceAll(`"`, ``).trim();
  if (cleaned.indexOf(`rslt_record_start`) === -1) {
    console.log(cleaned);
    throw new Error("Not a regular 689B record");
  }

  const r = /(<rslt_record_start>(.|\n)+?<rslt_record_end>)/gm;
  //   (window as any).reg = r;
  const groups = cleaned.match(r);

  //   console.log("file", file);
  //   (window as any).file = file;
  //   console.log("groups", groups);

  if (groups === null) throw new Error("No matches");

  let sections: TestResultRow[] = [];

  for (let row of groups) {
    sections.push(await sync689bRow(ds, row));
  }

  return sections;
}

async function sync689bRow(ds: Datastore, txtRecord: string) {
  const parsed = parseFile689B(txtRecord); // this is so a error is thrown if not compatible
  const hexHash = await SHA256fromStringHex(txtRecord);

  const resultFilter = { type: "TestResultRow", hash: hexHash };
  const results: TestResultRow[] = ds.db.filter(resultFilter);

  if (results.length > 0) {
    console.log("already exists");
    results[0].syncLinks();
    return results[0];
  } else {
    const resultD: TestResultRowI = {
      id: 0,
      type: "TestResultRow",
      deleted: false,
      testUnitID: null,
      timestamp: null,
      siteID: null,
      taskID: null,
      recordNumber: null,
      meterNumber: null,
      data: txtRecord,
      hash: hexHash,
      techID: ds.db.getMe().id,
    };
    let testResult = (await ds.createEntity(resultD)) as TestResultRow;
    testResult = await testResult.syncLinks();
    return testResult;
  }
  return {} as TestResultRow;
}
