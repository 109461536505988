import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';
import { Redirect } from 'react-router';


export function Landing(){

	const global = useContext(GlobalContext);
	const {project,theme} = global;

	const styles = {
		header:{
			paddingTop:64
		}
	}

	return <Redirect to="/list/Task" />
}