

import { DataEvent, Datastore } from "../Datastore";

import { Base } from "./Base";
import moment from "moment";
import { User } from "./User";


export interface SessionI{
    id: number;
    type: string;
    deleted:boolean;
    userID:number;
    timestamp:number;
    key:string;
    userAgent:string;

}

export class Session extends Base{

        userID:number;
        timestamp:number;
        key:string;
        userAgent:string;
    


    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.userID =  event.data.userID;
        this.timestamp =  event.data.timestamp;
        this.key =  event.data.key;
        this.userAgent = event.data.userAgent;
    }

    transformID(transform:(v:number)=>number){
        super.transformID(transform);
        this.userID = transform(this.userID);
    }


    getUser():User{
        return this.ds.db.get(this.userID);
    }

    getTimestamp(){
        return moment(this.timestamp);
    }

    toString(){
        const user = this.getUser();
        const timestamp = this.getTimestamp();
        return `${user.toString()} ${timestamp.format("DD/MM/YY HH:mm")}`;
    }

    toJSON():SessionI{
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            userID:this.userID,
            timestamp:this.timestamp,
            key:this.key,
            userAgent:this.userAgent
        };
    }


}