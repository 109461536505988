import React, { useContext, useEffect, useReducer } from 'react';
import useRouter from 'use-react-router';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, Divider, Typography, IconButton, Box, Button} from "@material-ui/core";
import { Site } from '../../lib/class/Site';
import { Message, Phone, Navigation,Settings as SettingsIcon } from '@material-ui/icons';
import { createBlankLink } from '../../lib/download';
import { ReadOnlyField } from '../Fields';
import { AmendmentFieldSite } from '../Fields/AmendmentFieldSite';

export interface SiteCardI{
    value:Site
}

export function SiteCard(props:SiteCardI){
    const {value} = props;
    const styles = {};
    const router = useRouter();
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds, google } = global;




    async function geocode(){
        await value.geocode(google);
    }


    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => {
        ds.sub(value.id,"location",forceUpdate);
    },[]);



    return  <Card>
        <CardContent>
        <Box display="flex" p={1} >
            <Box flexGrow={1}><Typography variant="h6" >Site</Typography></Box>
            {ds.db.getMe().isSudo()?<Box><IconButton onClick={() => router.history.push("/entity/"+value.id)} ><SettingsIcon /></IconButton> </Box>:null}
        </Box>
        </CardContent>
        <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Please review the following site information:</Typography>
                <AmendmentFieldSite site={value} type="string" label={"NMI"} value={"nmi"} amend={"nmiAmend"}     />  
                <AmendmentFieldSite site={value} type="string" label={"Address"} value={"address"} amend={"addressAmend"}    adornment={<NavButton value={value} />}  />
                <AmendmentFieldSite site={value} type="string" label={"Company Name"} value={"customerName"} amend={"customerNameAmend"}     />
                <AmendmentFieldSite site={value} type="string" label={"Customer Contact Name"} value={"customerContactName"} amend={"customerContactNameAmend"}     />
                <AmendmentFieldSite site={value} type="string" label={"Customer Contact Phone"} value={"customerPhone"} amend={"customerPhoneAmend"}  adornment={<><MessageButton value={value} /><CallButton value={value} /></>}   />           
                <AmendmentFieldSite site={value} type="string" label={"Meter Box Location"} value={"meterBoxLocation"} amend={"meterBoxLocationAmend"}     />           
          

                
                <ReadOnlyField label="Hazard"  value={value.hazard}   />
                <ReadOnlyField label="Severity"  value={value.severity}   />


                <ReadOnlyField label="Geolocation"  value={JSON.stringify(value.location)}   />
                <Button color="primary" onClick={geocode}>Geocode</Button>
            </CardContent>
    </Card>


/*

        <CardContent>
            <ReadOnlyField label="NMI" value={value.nmi} />
            <ReadOnlyField label="Meter Provider"  value={value.getMeterProvider().toString()}   />
            <ReadOnlyField label="Address"  value={value.address.toString()}  InputProps={{endAdornment:<React.Fragment><NavButton value={value} /></React.Fragment>}} />
            <ReadOnlyField label="Customer Name"  value={value.customerName.toString()}   />
            <ReadOnlyField label="Customer Phone"  value= {value.customerPhone.toString() == "" ? "No phone details ":value.customerPhone.toString()  }   InputProps={{endAdornment:<React.Fragment>   <CallButton value={value} />  <MessageButton value={value} /></React.Fragment>}} />
        </CardContent>
*/


}

interface CallButtonI{
    value:Site;
}
function CallButton(props:CallButtonI){
    const {value} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds, google } = global;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => {
        ds.sub(value.id,"customerPhone",forceUpdate);
        ds.sub(value.id,"customerPhoneAmend",forceUpdate);
    },[]);

    let n = "";

    if((typeof value.customerPhone != "undefined") && value.customerPhone != null && value.customerPhone != "" )
        n = value.customerPhone.toString();

        // console.log(n);

    if((typeof value.customerPhoneAmend != "undefined") && value.customerPhoneAmend != null && value.customerPhoneAmend != "" )
        n = value.customerPhoneAmend.toString();

    // console.log(n);


    function call(){
        createBlankLink(value.getCallHref());
    }
    return <IconButton color="primary" disabled={n==""} onClick={call}><Phone /> </IconButton>
}


interface MessageButtonI{
    value:Site;
}
function MessageButton(props:MessageButtonI){
    const {value} = props;
    let n = "";
    try{value.customerPhone.toString();}catch(E){}
    function message(){
        createBlankLink(value.getMessageHref(""));
    }
    return <IconButton color="primary" disabled={n==""}  onClick={message}><Message /> </IconButton>
}

interface NavButtonI{
    value:Site;
}
function NavButton(props:NavButtonI){
    const {value} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds, google } = global;

    let n = "";
    try{ n = value.address.toString();}catch(E){}


    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => {
        ds.sub(value.id,"address",forceUpdate);
    },[]);



    function message(){
        createBlankLink(value.getGoogleMapHref());
    }
    return <IconButton color="primary" disabled={n==""}  onClick={message}><Navigation /> </IconButton>
}