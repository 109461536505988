import React, { useContext, useEffect, useReducer, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, IconButton, InputAdornment, InputLabel} from "@material-ui/core";
import { Task } from '../../lib/class/Task';
import { Timer } from '@material-ui/icons';
import { getTimestamp } from '../../lib/time';
import { CssStyles } from '../../css-types';



interface TimestampfieldI{
    label?:string;
    name:string;
    task:Task;
    //value:number|null;
    preValue:number|null;
}


export function Timestampfield(props:TimestampfieldI){
    const {label,task,name,preValue} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        root: {
            flexGrow: 1,
            display:"inline-block",
            verticalAlign:"middle"
        },
        buttonIcon:{
            verticalAlign:"middle"
        }
    }

    const value = (task as any)[name];

    // async function changeTime(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
    //     let key = event.target.name;
    //     let value = event.target.value;
    //     let timestamp = moment(value,moment.HTML5_FMT.DATETIME_LOCAL)

    //     let v:null|number = null;
    //     if(timestamp.isValid())
    //         v= timestamp.valueOf();

    //     let d:any = {};
    //     d[key] = v;
    //     await ds.updateEntity(task,d);
    //   //  await ds.save();
    // }


    const [date,setDate] = useState<string>("");
    const [time,setTime] = useState<string>("");

    useEffect(() => {
        // console.log("use Effect");
        if(value != null){
            // console.log("set from value")

            const dd = (moment(value).format(moment.HTML5_FMT.DATE));
            const tt = (moment(value).format(moment.HTML5_FMT.TIME));
            setDate(dd);
            setTime(tt);
            // console.log(value,dd,tt)
        }else if(preValue != null){
            // console.log("set from prevalue")
            setDate(moment(preValue).format(moment.HTML5_FMT.DATE));
            //setTime(moment(preValue).format(moment.HTML5_FMT.TIME));

        }else{
            setDate("");
            setTime("");
        }


    },[preValue,value]);

    //console.log(preValue,value);

    async function changeTime(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
        // console.log(event.target.value)
        setTime(event.target.value);
        //await updateTimestamp(date,event.target.value);
    }
    async function changeDate(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
        // console.log(event.target.value)
        setDate(event.target.value);
        //await updateTimestamp(event.target.value,time);
    }

    async function updateTimestamp(){
        const key = name;
        const momentDate = moment(date,moment.HTML5_FMT.DATE);
        const momentTime = moment(time,moment.HTML5_FMT.TIME);
        const timestamp = momentDate.clone();
        timestamp.set({
            hour:   momentTime.get('hour'),
            minute: momentTime.get('minute'),
            second: momentTime.get('second')
        });

        // console.log(timestamp.format("DD/MM/YY HH:mm"))
        let value:number|null;
        if(timestamp.isValid() && date != "" && time != "")
            value =  timestamp.valueOf();
        else
            value = null;

        const d:any = {};
        d[key] = value
        //console.log(d);
        await ds.updateEntity(task,d);
        // ds.forceSave();
    }

    async function setNow(){
        const key = name;
        const value = getTimestamp();
        const d:any = {};
        d[key] = value
        await ds.updateEntity(task,d);

    }



    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,name,forceUpdate),[]);


    const validation = task.validateAttribute(name);

    const inAdor = <InputAdornment position="end">
        <IconButton color="primary" style={styles.buttonIcon} onClick={() => setNow()}><Timer /></IconButton> 
    </InputAdornment>

    return <React.Fragment>
        <InputLabel>{label}</InputLabel>
        <TextField  fullWidth style={styles.root} type="date" label={"Date"} margin="normal"  InputLabelProps={{ shrink: true }} name={name} value={date} onChange={(e) => changeDate(e)}  error={validation.length!=0} onBlur={() => updateTimestamp()} />
        <TextField  InputProps={{endAdornment:inAdor}} fullWidth style={styles.root}   inputProps={{step: 300}}  type="time" label={"Time"} margin="normal"  InputLabelProps={{ shrink: true }} name={name} value={time} onChange={(e) => changeTime(e)}  error={validation.length!=0} helperText={validation} onBlur={() => updateTimestamp()} />

    </React.Fragment>


}

