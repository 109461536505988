import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Checkbox, FormLabel} from "@material-ui/core";
import { Base } from '../../lib/class/Base';




interface EntityFieldI{
    entity:Base;
    label?:string;
    name:string;
    disabled?:boolean;
    InputProps?:any;
    InputLabelProps?:any;
    type?:string;
    multiline?:boolean;
}

export function EntityField(props:EntityFieldI){
    const {entity,label,name,disabled,InputProps,InputLabelProps,type,multiline} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;



    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(entity.id,name,forceUpdate),[]);


    async function change(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,checked?:any){
        const key = name;
        let v:string|number|boolean = event.target.value;
        
        if(type == "date"){
            v = moment(v,moment.HTML5_FMT.DATE).valueOf()
        }else if(type == "checkbox"){
            v = checked;
        }


        const d:any = {};
        d[key] = v;
        await ds.updateEntity(entity,d,true);
    }
    
    let v = "";
    const value = (entity as any)[name];



    if(type == "date" && value != null && value != ""){
        v = moment(value).format(moment.HTML5_FMT.DATE);
    }else if(value != ""){
        v = value;
    }


    let l = name;
    if(typeof label != "undefined")
        l = label


    function blur(){
        // ds.forceSave();
    }

  
    if(type == "checkbox"){
       return <div>
            <FormLabel>{l}</FormLabel>
            <Checkbox color="primary" checked={value?true:false} onChange={change} />
        </div>
    }else{
        return <TextField label={l} value={v} fullWidth margin="normal" disabled={disabled}  InputProps={InputProps}  InputLabelProps={InputLabelProps} onChange={change} type={type} multiline={multiline} onBlur={blur} />
    }

}



