import React, { useContext,useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Divider, Typography, Button, Card, CardContent} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { CssStyles } from '../../css-types';


export function Logout(){

	const global = useContext(GlobalContext);
	const {project,theme,ds} = global;

	const styles:CssStyles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
		message:{
			textAlign:"center"
		} ,
		error:{
			textAlign:"center",
			color:"red"
		}
	}

	const [message,setMessage] = useState("");
	const [error,setError] = useState("");

	async function runLogout(){

		setMessage("Loading");
		try{
			if(ds.requiresSync()){

				setMessage("Uploading unsaved data");
				await ds.forceSync();
			}

			setMessage("Telling server to remove session");
			await ds.logout();
			setMessage("Done");
		}catch(E){
			setError((E as any).toString())
		}	

	}

	 useEffect( () => {
		runLogout();
	 },[]);
	
	return <div style={styles.root}>
		<Header title="Logout" back={true} />
        <Card>
            <CardContent>
	 			<div style={styles.message}>{message}</div>
	 			<div style={styles.error}>{error}</div>
            </CardContent>
        </Card>
        
        <br/><br/>

	 	{message == "Done" ? <Redirect to="/login" /> : null}


                            

		<Navigation target="datastore_sync"  />
	</div>  


}

//