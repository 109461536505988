import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, Divider, Typography, Button} from "@material-ui/core";

import {Header} from '../../ui/Header'



import { Base } from '../../lib/class/Base';
import { JobTypeCard } from '../../ui/ClassView/JobTypeCard';
import { TestResultCard } from '../../ui/ClassView/TestResultCard';
import { DatomCard } from '../../ui/ClassView/DatomCard';
import { Clear } from '@material-ui/icons';
import { TestUnitCard } from '../../ui/ClassView/TestUnitCard';
import { JobCardDebug } from '../../ui/ClassView/JobCardDebug';
import { TaskCardDebug } from '../../ui/ClassView/TaskCardDebug';
import { TaskTypeCard } from '../../ui/ClassView/TaskTypeCard';
import { DocumentCardDebug } from '../../ui/ClassView/DocumentCardDebug';
import { TechCard } from '../../ui/ClassView/TechCard';
import { JobTaskCard } from '../../ui/ClassView/JobTaskCard';
import { SiteCardDebug } from '../../ui/ClassView/SiteCardDebug';
import { MeteringProviderCard } from '../../ui/ClassView/MeteringProviderCard';
import { AutoTestResultCard } from '../../ui/ClassView/AutoTestResultCard';
import { TestResultRowCard } from '../../ui/ClassView/TestResultRowCard';
import { UserCard } from '../../ui/ClassView/UserCard';
import { TestResult590BV2RowCard } from '../../ui/ClassView/TestResult590BV2RowCard';


export function EntityView(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
        main:{
            paddingLeft:20,
            paddingRight:20
        }
	}

    
    const id = props.match.params.id;
    const entity = ds.db.get(id) as Base;
    console.log(id);
    console.log(entity);

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(entity.id,"deleted",forceUpdate),[]);


    const view_lookup:any = {
        "JobType":JobTypeCard,
        "TaskType":TaskTypeCard,
        "Job":JobCardDebug,
        "Site":SiteCardDebug,
        "Task":TaskCardDebug,
        "TestResult":TestResultCard,
        "AutoTestResult":AutoTestResultCard,
        "TestResultRow":TestResultRowCard,
        "TestResult590BV2":TestResult590BV2RowCard,
        "Datom":DatomCard,
        "TestUnit":TestUnitCard,
        "Document":DocumentCardDebug,
        "Tech":TechCard,
        "JobTask":JobTaskCard,
        "MeteringProvider":MeteringProviderCard,
        "User":UserCard
    }

    let view = <div>View not found for {entity.type}</div>

    if(typeof view_lookup[entity.type] != "undefined"){
        view = React.createElement(view_lookup[entity.type],{value:entity});
    }

    async function toggleRemove(){
        await ds.updateEntity(entity,{deleted:!entity.deleted});

    }




	return <div style={styles.root} key={"entityview"+id} > 	
            <Header title={"Entity "+entity.id} back={true} />
            <div style={styles.main}>
                {view}
            <br/>

            <Card>
                <CardContent>
                    <Typography variant="h6" >Debug</Typography>
                </CardContent>
                <CardContent>
                    <Button fullWidth variant="outlined" onClick={() => toggleRemove()} color="secondary" > <Clear /> {entity.isDeleted()?"Undelete":"Remove"}</Button>
                </CardContent>
            </Card>
            <br/>

            <Card>
                <CardContent>
                    <Typography variant="h6" >Debug</Typography>
                </CardContent>

                <Divider/>
                <CardContent>
                    <pre>{JSON.stringify(entity.toJSON(),undefined,3)}</pre>

                    <br/><br/>
                    <pre>{JSON.stringify(entity.getIDs())}</pre>
                </CardContent>
            </Card>
                
            </div>
        </div>
    
}

