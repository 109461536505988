import moment from "moment";
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { TestUnit } from "./TestUnit";
import { Tech } from "./Tech";
import { unPackTextRecord590GV2 } from "./TestUnitParse/590G-V2";
import { Task } from "./Task";
import { Site } from "./Site";




export interface TestResult590BV2I {
  id: number;
  type: string;
  deleted: boolean;
  timestamp: number | null;
  testUnitID: number | null;
  siteID: number | null;
  recordNumber: number | null;
  hash: string; // is this needed?
  data: string;
  techID: number;
  taskID: number | null;
}






export class TestResult590BV2 extends Base {
  timestamp: number | null;
  testUnitID: number | null;
  siteID: number | null;
  recordNumber: number | null;
  hash: string;
  data: string;
  techID: number;
  taskID: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.siteID = event.data.siteID;
    this.timestamp = event.data.timestamp;
    this.testUnitID = event.data.testUnitID;
    this.hash = event.data.hash;
    this.data = event.data.data;
    this.recordNumber = event.data.recordNumber;
    this.techID = event.data.techID;
    this.taskID = event.data.taskID;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.techID = transform(this.techID);
    if (this.testUnitID != null) this.testUnitID = transform(this.testUnitID);
  }

  toSearchString() {
    const str: string[] = ['"TestResult590BV2"'];

    try {
      str.push(this.getTestUnit().toString());
    } catch {}

    try {
      str.push(this.getTimestampString());
    } catch {}

    try{
      str.push(this.getLocation())
    }catch{}

    return str.join(" ");
  }



    isComplete() {
    return !this.hasError();
  }


  getTech(): Tech {
    if (this.techID == null) throw "Tech ID not set";
    return this.ds.db.get(this.techID) as Tech;
  }



    getLocation():string{
      try{
      const parsed = unPackTextRecord590GV2(this.data);
      return parsed.location
      }catch{
        return '';
      }


    }
  getTechString(): string {
    try {
      return this.getTech().toString();
    } catch (E) {
      return "Tech NA";
    }
  }

  getTestUnit(): TestUnit {
    if (this.testUnitID == null) throw "Test Unit ID not set";
    return this.ds.db.get(this.testUnitID) as TestUnit;
  }

  getTimestamp() {
    return moment(this.timestamp);
  }

  getTimestampString() {
    let timestampString = "Timestamp NA";
    try {
      const timestamp = this.getTimestamp();
      timestampString = timestamp.format("DD/MM/YYYY HH:mm");
    } catch (E) {}
    return `${timestampString} `;
  }

  toString() {
    let unitString = "Unit NA";
    const timestamp = this.getTimestampString();

    try {
      const unit = this.getTestUnit();
      unitString = unit.toString();
    } catch (E) {}

    return `Unit ${unitString} - on ${timestamp}`;
  }

  async syncLinks() {
    const parsed = unPackTextRecord590GV2(this.data);
    const update_data: any = {};

    let updated = false;

    if (this.testUnitID == null) {
      const testUnitD: any = {
        type: "TestUnit",
        serialNumber: parsed.operator,
      };
      const testUnits: TestUnit[] = this.ds.db.filter(testUnitD);
      let testUnit: TestUnit;
      if (testUnits.length === 0) {
        throw new Error("Unrecognized test unit serial number");
      } else {
        testUnit = testUnits[0];
      }
      update_data.testUnitID = testUnit.id;
      updated = true;
    }

    // if (this.meterNumber == null) {
    //   update_data.meterNumber = parsed.mtr_id;
    //   updated = true;
    // }

    if (this.recordNumber == null || this.recordNumber == undefined) {
      update_data.recordNumber = parsed.rec_num;
      updated = true;
    }

    if (this.timestamp == null) {
      update_data.timestamp = parsed.time_stamp;
      updated = true;
    }

    let e: TestResult590BV2 = this;
    if (updated)
      e = (await this.ds.updateEntity(
        this,
        update_data,
        true
      )) as TestResult590BV2;

    try {
      e = await e.syncTask();
    } catch (E) {}

    return e;
  }

  async syncTask(): Promise<TestResult590BV2> {
    const parsed = unPackTextRecord590GV2(this.data);
    const timestamp = this.getTimestamp();
    const targetTask = this.ds.db.filter({
      type: "TaskType",
      name: "CT Testing",
    })[0];
    if (targetTask === undefined)
      throw new Error("Can't find target task type");

    const tasks = this.ds.db.filter({
      type: "Task",
      typeID: targetTask.id,
    }) as Task[];

    let matchingMeterTask: Task | null = null;

    for (const task of tasks) {
      try {
        const job = task.getJob();
        const site = task.getSite();
        // const meterNumber = job.getJobDataItemString("Meter Number");

        // console.log('nmi',site.nmi, parsed.location);
        if (site.nmi === parsed.location) {
          matchingMeterTask = task;
          break;
        }
      } catch (E) {}
    }

    if (matchingMeterTask === null) {
      throw new Error("Could not find matching nmi");
    }
    const task = matchingMeterTask;

    const numbers = task.getRecordNumbers();
    // console.log(
    //   "numbers",
    //   numbers,
    //   numbers.some((s) => s === this.recordNumber)
    // );

    if (numbers.some((s) => s === this.recordNumber)) {
      console.log("found matching task", task);
      return await this.ds.updateEntity(this, { taskID: task.id });
    } else {
      return await this.ds.updateEntity(this, { taskID: null });
    }
  }

  hasError() {
    const error = this.getError();
    return error.length !== 0;
  }

  getError() {
    if (this.timestamp == null) return "Timestamp not set";
    if (this.testUnitID == null) return "Test unit not linked";
    if (this.taskID == null) return "Task not linked";

    return "";
  }
  decode() {
    const record = unPackTextRecord590GV2(this.data);
    return record;
  }

  toJSON(): TestResult590BV2I {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      timestamp: this.timestamp,
      testUnitID: this.testUnitID,
      hash: this.hash,
      data: this.data,
      techID: this.techID,
      siteID: this.siteID,
      taskID: this.taskID,
      // meterNumber: this.meterNumber,
      recordNumber: this.recordNumber,
    };
  }
}
