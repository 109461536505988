import React from 'react';

import { AppBar, Tabs, Tab, Badge} from "@material-ui/core";
import { Search } from '@material-ui/icons';
import { CollectionI } from '../page/ListView/Filters';


export interface CollectionFilterTabsI{
    collections:CollectionI[]
    value:number;
    onChange:(v:number)=>void;
}


export function CollectionFilterTabs(props:CollectionFilterTabsI){
    const {value,onChange,collections} = props;

    const styles = {
    
    }

    function change(v:number){
        onChange(v);
    }
    
    return (
        <AppBar position="static" color="default">
            <Tabs value={value} onChange={(e,v)=> change(v)} variant="scrollable" >
                {collections.map( (c,i) => {
                    return  <Tab  key={i} value={i} label={<Badge  max={9999} badgeContent={c.data.length} color={(c.warning&&c.data.length!=0)?"secondary":"primary"} showZero>{c.label}</Badge>} />
                })}
                <Tab value={collections.length} label={<Search />} />
            </Tabs>
    </AppBar>
    );

}