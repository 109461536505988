import React, { useContext } from 'react';
import {GlobalContext} from '../withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem} from "@material-ui/core";

import {Header} from '../Header'
import { Navigation } from '../Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';

export interface MeteringProviderCardI{
    value:TestUnit
}

export function MeteringProviderCard(props:MeteringProviderCardI){
    const {value} = props;
    const task = value;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const styles = {};

    return <Card>
        <CardContent>
            <Typography variant="h6" >Metering Provider</Typography>
        </CardContent>
        <Divider/>
        <CardContent>
            <EntityField label="Name" name="name" entity={value} />
       </CardContent>
    </Card>
}


