import React from 'react'
import useRouter from 'use-react-router';

import { ListItem, ListItemText } from "@material-ui/core";

import { TestResult } from '../../lib/class/TestResult';

export interface TestResultRowI{
    value:TestResult;
    style?:any;
}

export function TestResultRow(props:TestResultRowI){
    const {value,style} = props;
    const router = useRouter();

    const styles = {
        row:Object.assign({
            opacity:value.deleted?0.25:1,
            height:84,
            overflow:"hidden"
        },style)
    };

    return <ListItem style={styles.row} button divider onClick={() => router.history.push("/entity/"+value.id)} >
        <ListItemText primary={value.toString()} secondary={`${value.getTimestampString()} by ${value.getTechString()}`} />
    </ListItem>
}