import React,{ useContext } from "react";
import { Box, Button, List, Theme, Typography } from "@material-ui/core";
import { Router, withRouter } from "react-router-dom";
import useRouter from "use-react-router";
import { Job, JobToColor } from "../lib/class/Job";
import { Task, TaskStateToColor } from "../lib/class/Task";
import { Tech } from "../lib/class/Tech";
import Link from "./Link";
import { Place, PlaceMap } from "./Maps";
import { GlobalContext } from "./withGlobalContext";



interface PlacesListI{
    search:boolean;
    items:any[];
    Render:Function;
}

export function PlacesList(props:PlacesListI){
    const {search,items,Render} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const router = useRouter(); 

    const topBar = 52 + 52 + (search?78:0);
    const bottomBar = 58;
    const height = window.innerHeight - topBar - bottomBar;


console.log(items);

    const places = [];


    for(const c of items){
        try{
            const marker = generateMarker(router,theme,c);
            places.push(marker);
        }catch(E){
            console.error(E);
        }
    }


    const techs = ds.db.filter({type:"Tech"});
    for(const tech of techs)
        try{
            places.push(generateMarkerTech(router,theme,tech))
        }catch(E){
            console.error("TECH",E);
        }





    return <PlaceMap places={places} height={height} /> 
}









function generateMarker(router:any,theme:Theme,data:any){


    if(data.type == "Job")
        return generateMarkerJob(router,theme,data);
    else if(data.type == "Task")
        return generateMarkerTask(router,theme,data);
    else
        throw "Not valid"




}




function generateMarkerJob(router:any,theme:Theme,job:Job){
    
        const site = job.getSite();

        if(site.location == null){
            console.log(site)
            throw "No lng lat"
        }

        let color =  "black"; 
        
        const taskColor = job.getTaskColor();

        if(typeof taskColor != "undefined")
            color =  theme.palette[taskColor].main;

        const wrench = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path clip-rule="evenodd" fill="none" d="M0 0h24v24H0z"/><path fill="${color}" d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"/></svg>`
        const time = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${color}" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path><path fill="${color}" d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z"></path></svg>`

        const iconType = job.getTaskIcon();

        const icon =  iconType === 'clock' ? time : wrench;


        const encodedIcon = "data:image/svg+xml;base64," + btoa(icon);


        function nav(){
            router.history.push("/job/"+job.id);
        }

        const label = "";
        const link = <Box>
            <strong>{job.toString()}</strong>
            <Box marginBottom={1}>
                <br/>
               <Button size="small" variant="outlined" color="primary" onClick={nav}>View</Button>
            </Box>

       
    </Box>


    /*   
    
    
     <Typography>Requires:</Typography>
        <List>
         
        </List>
    
    {work_order.getSkills().map(rs => {
                return <Typography>{rs.toString()}</Typography>
            })}
            */
//{/*} <Typography ><strong>{work_order.getValue()}: </strong>{work_order.getType().toString()}</Typography>*/} {/* <Typography gutterBottom><strong>Job Type: </strong> </Typography> */

        const place:Place = {
            id:job.id+"",
            lat: site.location.lat, 
            lng: site.location.lng,
            color:color,
            value:null,
            label:label,
            link:link,
            icon:encodedIcon
        }
        return place; 

    
}




function generateMarkerTask(router:any,theme:Theme,task:Task){
    
    const site = task.getSite();

    if(site.location == null){
        console.log(site)
        throw "No lng lat"
    }

    let color = "black";
    const taskColor = task.getStateColor()
    if(typeof taskColor != "undefined")
        color =  theme.palette[taskColor].main;
        
    const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path clip-rule="evenodd" fill="none" d="M0 0h24v24H0z"/><path fill="${color}" d="M22.7 19l-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"/></svg>`
    const encodedIcon = "data:image/svg+xml;base64," + btoa(icon);



    function nav(){
        router.history.push("/task/"+task.id);
    }

    const label = "";
    const link = <Box>
          <strong>{task.toString()}</strong>
        <Box marginBottom={1}>
            <br/>
        <Button size="small" variant="outlined" color="primary" onClick={nav}>View</Button>
        </Box>

</Box>

    const place:Place = {
        id:task.id+"",
        lat: site.location.lat, 
        lng: site.location.lng,
        color:color,
        value:null,
        label:label,
        link:link,
        icon:encodedIcon
    }
    return place;


}





function generateMarkerTech(router:any,theme:Theme,tech:Tech){
    
    

    if(tech.location == null){
        console.log(tech)
        throw "No lng lat"
    }

    const color = "red";

    const icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="${color}" d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/><path d="M0 0h24v24H0z" fill="none"/></svg>`
    const encodedIcon = "data:image/svg+xml;base64," + btoa(icon);




    //let label = `<div id="mapLink"><strong>${task.toString()}</strong></div>`
    const label = "";
    const link = <Box>
          <strong>{tech.toString()}</strong>
        <Box marginBottom={1}>
            <br/>
       
        </Box>

</Box>


/* 

    <Typography>Requires:</Typography>
    <List>
     
    </List>
      {work_order.getSkills().map(rs => {
            return <Typography>{rs.toString()}</Typography>
        })}
        */
//{/*} <Typography ><strong>{work_order.getValue()}: </strong>{work_order.getType().toString()}</Typography>*/} {/* <Typography gutterBottom><strong>Job Type: </strong> </Typography> */

    const place:Place = {
        id:tech.id+"",
        lat: tech.location.lat, 
        lng: tech.location.lng,
        color:color,
        value:null,
        label:label,
        link:link,
        icon:encodedIcon
    }
    return place;


}