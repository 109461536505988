import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));
  
export function Loading(props:any){

	const global = useContext(GlobalContext);
	const {project,theme} = global;

    const classes = useStyles();

	return <React.Fragment>
    <Backdrop className={classes.backdrop} open={true} >
        <CircularProgress color="inherit" />
    </Backdrop>

	</React.Fragment>  
//<When />
}