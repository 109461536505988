import { Button, TextField } from "@material-ui/core";
import { ChangeEvent } from "react";


export interface SvgText{
    type:"text"
    key:string;
    x:number;
    y:number;
    size:number;
    color?:string;
}




interface SvgTextRenderI{
    value:SvgText;
    data:any;
}

export function SvgTextRender(props:SvgTextRenderI){
    const {value, data} = props;


    let v = "";
    
    if( data[0] !== null && data[0] !== undefined)
        v = data[0];

    if(data[1] !== "" && data[1] !== null && data[1] !== undefined){
        console.log(data[1]);
        v = data[1] + ' (amended)';
    }


    if(v.indexOf('\n') !== -1){
        v = v.split("\n").map( (l:string,i:number) => `<tspan x="0" dy="${i===0?"0em":"1.2em"}">${l}</tspan>`).join("\n");

    }

    const invalid = typeof v == 'undefined' || v === null || v === ''

    return `<g transform="translate(${value.x} ${value.y})"><text x="0" y="0" fill="${invalid?"red":(value.color ?? "blue")}" font-size="${value.size}" stroke-width="1" stroke="none" >${invalid?"":v}</text></g>`
}



