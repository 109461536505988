
import { Base } from "./Base";
import { Job } from "./Job";
import { DataEvent, Datastore } from "../Datastore";
import { JobTypeData } from "./JobTypeData";




export interface JobDataI{
    id: number;
    type: string;
    deleted:boolean;
    jobID: number;
    dataID: number;
    value: any;
    valueID:number|null;
}

export class JobData extends Base{
    
    jobID:number;
    dataID:number;
    value:any;
    valueID:number|null;



    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.jobID = event.data.jobID;
        this.dataID =  event.data.dataID;
        this.valueID =  event.data.valueID;
        this.value =  event.data.value;
    }


    transformID(transform:(v:number)=>number){
        super.transformID(transform);
        this.jobID = transform(this.jobID);
        this.dataID = transform(this.dataID);

        const jobDataType = this.getDataType();
        const dataType = jobDataType.getDataType();
        if(dataType.name == "id")
            this.value = transform(this.value);

    }

    getLink(){
        if(this.valueID == null) throw "Value ID is null";
        
        return this.ds.db.get(this.valueID)
    }


    getValue(){
        if(this.valueID !== null) return this.getLink();
        return this.value;
    }



    getJob():Job{
        return this.ds.db.get(this.jobID) as Job;
    }

    getDataType():JobTypeData{
        return this.ds.db.get(this.dataID) as JobTypeData;
    }

    toString(){
        return `${this.value}`;
    }


    toJSON(){
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            jobID:this.jobID,
            dataID:this.dataID,
            value:this.value,
            valueID:this.valueID
        };
    }


}