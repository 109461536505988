import React, { ReactNode, useContext, useEffect, useReducer, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';
import {Theme} from "@material-ui/core";
import {Header} from '../../ui/Header'
import { Task } from '../../lib/class/Task';
import {JobCard} from "../../ui/ClassView/JobCard"
import {SiteCard} from "../../ui/ClassView/SiteCard"
import {TaskCard} from "../../ui/ClassView/TaskCard"
import {TestingForm} from "../../ui/ClassView/TestingForm"
import {TestingForm2021} from "../../ui/ClassView/TestingForm2021"
import { PreinspectionForm } from '../../ui/ClassView/PreinspectionForm';
import { LabourForm } from '../../ui/ClassView/LabourForm';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CssStyles } from '../../css-types';
import { AssetInspectionForm } from '../../ui/ClassView/AssetInspectionForm';


interface TaskViewProps{
    id?:number;
    match?:any;
}

export function TaskView(props:TaskViewProps){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const sm = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));


	const styles:CssStyles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		}, 
        main:{
            paddingLeft:sm?0:20,
            paddingRight:sm?0:20
          },
          loading:{
              textAlign:"center",
              marginTop:100,
              color:"#888"
          } 
	}
    
    const id = props.id ?? props.match.params.id;
    const task = ds.db.get(id) as Task;

    const job = task.getJob();
    const type = task.getType();
    const site = job.getSite();

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(id,"*",forceUpdate),[]);


    const view_lookup:any = {
        "CT Testing":TestingForm2021,
        "Meter Test":TestingForm,
        "Pre-inspection":PreinspectionForm,
        "Labour":LabourForm,
        "Service Inspection":AssetInspectionForm
    }

    let view = <div>View not found for {type.toString()}</div>
    if(typeof view_lookup[type.toString()] != "undefined"){
        view = React.createElement(view_lookup[type.toString()],{task:task});
    }

    const [loaded,setLoaded] = useState(false);
    useEffect( () => setLoaded(true),[]);

	return <div style={styles.root} key={"taskview"+id}> 	
            <Header title={"Task "+type.toString()} back={true} />
            {loaded?<div style={styles.main}>
                <SiteCard value={site} />
                <br/>
                <JobCard value={job} extended={false} />
                <br/>
                <TaskCard value={task} />
                <br/>
                {view}
            </div>:<div style={styles.loading}>Loading</div>}
        </div>
	
    
}

