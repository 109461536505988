
import moment from "moment";
import { Job, JobJ } from '../../../lib/class/Job';
import { Site, SiteJ } from '../../../lib/class/Site';
import { JobData, JobDataI } from '../../../lib/class/JobData';
import { getsertDatom } from '../../../lib/class/Datom';
import { JobTypeData } from '../../../lib/class/JobTypeData';
import { Datastore } from '../../../lib/Datastore';
import { rowHasKeys } from '../utils';

interface OldMondoRow{
    "CT_Ratio":string;
    "CT_SERIAL_(B)":string;
    "CT_SERIAL_(R)":string;
    "CT_SERIAL_(W)":string;
    "CUSTOMER":string;
    "Completion_Result":string;
    "DateAttended":string;
    "EndDate":string;
    "Fact/Flat":string;
    "Issued_To":string;
    "JobType":string;
    "Kfactor":string;
    "LoadType":string;
    "MPB":string;
    "MeterCurrentRating":string;
    "MeterNumber":string;
    "MeterType":string;
    "NMI":string;
    "PostCode":string;
    "RecordID":string;
    "StartDate":string;
    "State":string;
    "Street":string;
    "StreetNum":string;
    "Suburb":string;
    "WorkOrderID":string;
}





    async function upsertJob(ds:Datastore, filter:any,data:any,jobData:any){

        // find any jobdata
        const workOrderTypes = ds.db.filter({type:"JobTypeData",name:"Work Order"});

        if(workOrderTypes.length === 0)
            throw "Work order = 0";
        
        const workOrderType = workOrderTypes[0];

        const matchingDatas:JobData[] = ds.db.filter({ type:"JobData", dataID:workOrderType.id, value:jobData["Work Order"] });
        const jobs:Job[] = matchingDatas.map( d => d.getJob() );

        let job;
        if(jobs.length === 0){
            console.log("not found creating")
            job = await ds.createEntity(data);
        } else if(jobs.length > 1){
            throw new Error("Multiple matches");
        }else{
            console.log("found match")
            job = jobs[0];
        }


        //run through job data and upsert
        const keys = Object.keys(jobData);
        for(const key of keys){
            const value = jobData[key];
            const dataTypes = ds.db.filter({type:"JobTypeData",name:key}) as JobTypeData[];
            if(dataTypes.length === 0)
                throw new Error("dataType no matches");

            const dataType = dataTypes[0];
            const t = dataType.getDataType();
            
            const jd:JobDataI = {
                id:0,
                type:"JobData",
                deleted:false,
                jobID:job.id,
                dataID:dataType.id,
                value:null,
                valueID:null
            }

            if( t.name == "id" ){
                if(value != null){
                    jd.valueID = value.id;
                }

            }else{
                jd.value = value
            }

            await ds.upsert({ type:"JobData", dataID:dataType.id, jobID:job.id },jd);

        }

        return job
    }


    

export function isOldMondoRow(row:unknown):row is OldMondoRow{
    const keys = ["CT_Ratio", "CT_SERIAL_(B)", "CT_SERIAL_(R)", "CT_SERIAL_(W)", "CUSTOMER", "Completion_Result", "DateAttended", "EndDate", "Fact/Flat", "Issued_To", "JobType", "Kfactor", "LoadType", "MPB", "MeterCurrentRating", "MeterNumber", "MeterType", "NMI", "PostCode", "RecordID", "StartDate", "State", "Street", "StreetNum", "Suburb", "WorkOrderID"];
    return rowHasKeys('old mondo',keys, row);
}


export async function importOldMondoRow(ds:Datastore, row:OldMondoRow) {

    const mpbs = ds.db.filter({type:"MeteringProvider",name:"Agility"});
    if(mpbs.length === 0 || mpbs.length > 1)
        throw new Error("Error finding Agility");

    const mpb = mpbs[0];

    const s:SiteJ = {
        id:0,
        type:"Site",
        deleted:false,
        nmi:row.NMI,
        customerName:row.CUSTOMER,
        customerContactName:"",
        customerPhone:"",
        address:(`${row["Fact/Flat"]} ${row.StreetNum} ${row.Street} ${row.Suburb} ${row.State} ${row.PostCode}`).trim(),
        mpID:mpb.id,
        nmiAmend:null,
        customerNameAmend:null,
        customerContactNameAmend:null,
        customerPhoneAmend:null,
        addressAmend:null,
        location:null,
        lifeSupport:false,
        meterBoxLocation:"",
        meterBoxLocationAmend:null,
        hazard:"",
        severity:""
    }
    const site:Site = await ds.upsert( {type:s.type,nmi:s.nmi} ,s );

    
    const jobTypes = ds.db.filter({type:"JobType",name:"Mondo CT Testing"});
    if(jobTypes.length === 0 || jobTypes.length > 1)
        throw new Error("Error finding Mondo job");

    const jobType = jobTypes[0];

    const j:JobJ = {
        id:0,
        type:"Job",
        deleted:false,
        siteID:site.id,
        typeID:jobType.id,
        startDate: moment(row.StartDate,"DD/MM/YYYY").valueOf(),
        dueDate: moment(row.EndDate,"DD/MM/YYYY").valueOf(),
        sent:false,
        ignore:false
    }

    
    function processCTRatio(value:string){
        const re = new RegExp('(\\d+\\/\\d+)');
        const match = re.exec(value);
        if(match != null)
            return match[0];
        else 
            return ""
    }

    function processKFactor(value:string){
        if(value === "0")
            return "";
        else 
            return value;
    }




    const jobData = {
        "Work Order":row.WorkOrderID,
        "Record ID":row.RecordID,
        "Constant K":await getsertDatom(ds,"Constant K",processKFactor(row.Kfactor)),
        "CT Ratio":await getsertDatom(ds,"CT Ratio",processCTRatio(row.CT_Ratio)),
        "Meter Number":row.MeterNumber,
        "Meter Model":await getsertDatom(ds,"Meter Model",row.MeterType),
        "Meter Current Rating":await getsertDatom(ds,"Meter Current Rating",row.MeterCurrentRating), 
        "CT Red Phase Serial Number":row['CT_SERIAL_(R)'],
        "CT White Phase Serial Number":row['CT_SERIAL_(W)'],
        "CT Blue Phase Serial Number":row['CT_SERIAL_(B)']
    }


    const job:Job = await upsertJob(ds, {type:j.type,siteID:j.siteID}, j, jobData);

    
    console.log("job",job);

}

