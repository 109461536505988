import { default as React } from 'react';

import {ListItem,ListItemText, ListItemIcon} from "@material-ui/core";
import { FixedSizeList } from 'react-window';
import { Event } from '@material-ui/icons';
import moment from 'moment';
import { CssStyles } from '../css-types';


interface TimeListI{
    items:any;
    RowView:Function;
}

export function TimeList(props:TimeListI){
    const {items, RowView} = props;
    
    const styles:CssStyles = {
        nothing:{
            textAlign:"center",
            marginTop:100,
            color:"#888"
        } 
    };


    const today = moment().startOf("day");
    const renderItems:any[] = items; 

    function RenderDateView(props:any){
        const {date,style} = props;
        const m = moment(date);
        const isToday = today.isSame(m,"day");
    

        const styles:CssStyles = {
            row:Object.assign({
                height:84,
                overflow:"hidden"
            },style),
            text:{
                color:"#999"
            }
        }

        return <ListItem style={styles.row}>
        <ListItemIcon >
            <Event />
        </ListItemIcon >
            <ListItemText style={styles.text}>
                {m.format("DD/MM/YY dddd")} ({isToday?"Today": m.fromNow()}) 
            </ListItemText>
        </ListItem>
    }

 
    const topBar = 52 + 52
    const bottomBar = 58;
    const height = window.innerHeight - topBar - bottomBar;

    function renderRow(props:any) {
        const { index, style } = props;
        const v = renderItems[index];
        if(typeof v == "number")
            return <RenderDateView style={style} date={v} />
        else{
             return <RowView style={style} value={v} />;
        }
      }

    return <React.Fragment>
        {renderItems.length == 0 ? <div key="nothing" style={styles.nothing}>No Items</div>:<FixedSizeList key="fiexlist" height={height} width={"100%"} itemSize={84} itemCount={renderItems.length} >{renderRow}</FixedSizeList>}
    </React.Fragment>

}


