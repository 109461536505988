import { ListFilterI } from "../../page/ListView/Filters";
import { Base } from "../class/Base";
import { Datastore } from "../Datastore";
import { PGroup, PGroupI } from "./PGroup";




export interface PListI{
    key:string;
    typeCheck:(item:Base) => boolean;
    timeFn:(item:Base)=> moment.Moment;
    temporal:boolean;
    groups:PGroupI[],
    Render:Function;
    raw:boolean;
    removeDeleted:boolean;
    rawFn?:() => Promise<any[]>;
}



export class PList{
    key:string;
    typeCheck:(item:Base) => boolean;
    timeFn:(item:Base)=> moment.Moment;
    groups:PGroup[]
    temporal:boolean;
    Render:Function;
    removeDeleted:boolean;
    raw:boolean;
    rawLoaded:boolean;
    rawFn?:() => Promise<any[]>;



    constructor(props:PListI){
        this.key = props.key;
        this.typeCheck = props.typeCheck;
        this.timeFn = props.timeFn;
        this.temporal = props.temporal;
        this.Render = props.Render;
        this.raw = props.raw;
        this.removeDeleted = props.removeDeleted;
        this.rawFn = props.rawFn;

        this.rawLoaded = false;
        this.groups = props.groups.map(group => new PGroup(group,this.timeFn,this.temporal,this.raw));


    }


    reset(){
        for(const group of this.groups)
            group.reset();
    }
    

    process(item:Base,skipProcess=false,skipTemporalRemove=false){
        if(this.typeCheck(item))
            for(const group of this.groups)
                group.process(item,skipProcess,this.removeDeleted,skipTemporalRemove);
    }

    processTemporalSet(){
        for(const group of this.groups)
            group.processTemporalSet();
    }


    getAllGroup(){
        for(const group of this.groups)
            if(group.key == "All")
                return group.getGroup();
    
        throw "No all group"
    }

    async processRaw(){
        console.log("processRaw");
        if(typeof this.rawFn == "undefined")
            throw "Not a raw function"

        this.reset();

        const result = await this.rawFn();
        console.log(result);

        for(const e of result)
            this.process(e,true,true);


        this.processTemporalSet();
        this.rawLoaded = true;

    }


    getList():ListFilterI{

  

        const all = this.getAllGroup()
        //console.log(all);

        return {
            temporal:this.temporal,
            collection:all.data,
            type:this.key,
            subjects:this.groups.map(g => g.getGroup()),
            Render:this.Render
        }


    }

    toJSON(){
        const j:any = {};
        j.groups = this.groups.map( group => group.toJSON());
        j.key = this.key;
        return j;
    }

    fromJSON(json:any,ds:Datastore){
        const groups = json.groups;
        for(let i = 0; i < groups.length; i++){
            const group = groups[i];
            if(this.groups[i].key == group.key)
                this.groups[i].fromJSON(group,ds);
        }
    }


    getBenchmarkTable(){
        const children = this.groups.map( g => g.getBenchmarkTable() ).flat();
        children.map( t => t[0] = this.key+" "+t[0] );
        return children;


    }

}

