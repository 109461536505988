import React, { useContext, useEffect, useReducer } from 'react';
import {TextField, IconButton, InputAdornment, InputLabel} from "@material-ui/core";

import {GlobalContext} from '../../ui/withGlobalContext';
import { Task } from '../../lib/class/Task';
import { Restore } from '@material-ui/icons';




interface AmendmentFieldI{
    task:Task;
    label:string;
    value:string;
    field:string;
}


export function AmendmentField(props:AmendmentFieldI){
    const {task,label,value,field} = props; 

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const [taskTypeData,taskData] = task.getData(field);
    const type = taskTypeData.getDataType();

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(taskData.id,"value",forceUpdate),[]);

    const l = label ? label : taskTypeData.name;
    let v = (taskData.value==null) ? "" : taskData.value;
    const validation = task.validateData(taskTypeData);

    async function update(e:any){
        await ds.updateEntityField(taskData,e);
    }

    async function restore(){
        await ds.updateEntity(taskData,{value:null});
    }

    const job = task.getJob();
    const amended = taskData.value != null;
    //console.log(value);
    v = amended ? taskData.value : value;

       function blur(){
        // ds.forceSave();
    }


    const endAdornment = amended?<InputAdornment position="end"><IconButton onClick={restore}><Restore /> </IconButton></InputAdornment>:null

    if(type.name == "boolean")
        return <div>
                <InputLabel>{l}</InputLabel>
                <div>Boolean not supported yet</div>
        </div>
    else if(type.name == "string"){
        return <TextField margin="normal" fullWidth label={l} name="value" value={v}  onChange={(e) => update(e) } error={validation.length!=0} helperText={validation} InputProps={{endAdornment:endAdornment}} onBlur={blur} />
    }else if (type.name == "number"){
        return <TextField margin="normal" fullWidth label={l} type="number" name="value" value={v}  onChange={(e) => update(e) } error={validation.length!=0} helperText={validation} InputProps={{endAdornment:endAdornment}} onBlur={blur}   onWheel={ (event:any) => { event.target.blur() } } />
    }else{
        return <div>Data type not supported</div>
    }


}
