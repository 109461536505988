
import { importMondoCtRow, importMondoRow, importNstRow, importOldMondoRow, isMondoCtRow, isMondoRow, isNstRow, isOldMondoRow } from './parsers';
import { Datastore } from '../../lib/Datastore';


export async function importRow(ds:Datastore, row:unknown){
    if(isMondoCtRow(row)){
        return await importMondoCtRow(ds, row);
    } else if(isNstRow(row)){
        console.log("NST row")
        return await importNstRow(ds, row);
    } else if(isMondoRow(row)){
        console.log("mono row")
        return await importMondoRow(ds, row);
    } else if(isOldMondoRow(row)){
        console.log("Old mondo row");
        return await importOldMondoRow(ds, row);
    }
    
    console.log("No parser match");
}

export function rowHasKeys(parser:string,keys:string[], row:unknown):boolean{
    for(const key of keys){
        if(!Object.prototype.hasOwnProperty.call(row,key)){
            console.log(`not ${parser} because missing ${key}`, row)
            return false;
        }
    }
    return true;
}

