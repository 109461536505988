import React, { useContext, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, Divider, Typography, Button} from "@material-ui/core";

import { TestResult } from '../../lib/class/TestResult';
import { EntityField, SelectField } from '../Fields';
import useRouter from "use-react-router";
import { CssStyles } from '../../css-types';

export interface TestResultCardI{
    value:TestResult
}

export function TestResultCard(props:TestResultCardI){
    const {value} = props;

    const router = useRouter();

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        title:{
            fontWeight:"bold"
        }
    }
    const [msg,setMsg] = useState("");
    const [error,setError] = useState("");


    async function syncLinks(){
        value.syncLinks();
    }

    async function searchTask(){
        try{
            const e = await value.syncTask();
            const task = e.getTask();
            setMsg(`linked to task ${task.toString()}` )
        }catch(E:any){
            setError(E.toString());
        }
    }


    function getTaskDebug() {
        const taskLink = value.getTaskDataLink();
        console.log(taskLink)
        const task = taskLink.getTask();
        console.log(task);
    }



    let taskLinkString = ""

    let taskButton:any = null;
    try{
        const taskLink = value.getTaskDataLink();
        const task = taskLink.getTask();
        taskButton = <Button color="primary" variant="outlined" fullWidth onClick={() => router.history.push("/task/"+task.id)   } >View Task</Button>
        taskLinkString = `linked to task ${task.toString()}`;

    }catch(E:any){
        taskLinkString = E.toString();
    }


    async function decode(){
        value.decode();
    }

    const info = value.decode()


    return <Card>
        <CardContent>
            <Typography variant="h6" >Test Result</Typography>
        </CardContent>
        <Divider/>
        <CardContent>
            <div>Record Number: {value.recordNumber}</div>
            <div>Date: {value.getTimestampString()}</div>
            
            <SelectField label="Technician" name="techID" value={value} filter={{type:"Tech"}}  />
            <SelectField label="Test Unit" name="testUnitID" value={value} filter={{type:"TestUnit"}}  />
            <EntityField label="Data" name="data" entity={value} type="textarea" />

           <br/><br/>
           <div>Task Link: {taskLinkString}</div>
            {taskButton}


           <br/><br/>
           <Button fullWidth color="primary" onClick={searchTask} variant="outlined" >Search for task</Button>
           <Button fullWidth color="primary" onClick={syncLinks} variant="outlined" >Sync Links</Button>
           <Button fullWidth color="primary" onClick={decode} variant="outlined" >Decode</Button>
           <Button fullWidth color="primary" onClick={getTaskDebug} variant="outlined" >getTaskDebug</Button>

           

           {msg}

           {error.length>0?<div style={{color:"red"}}>{error}</div>:null }
        
        </CardContent>

    <Divider />
    <CardContent>
        <pre>
            {JSON.stringify(info,undefined,3)}
        </pre>
    </CardContent> 

    <Divider />
    <CardContent>
        <pre>
            {value.data}    
        </pre>
    </CardContent> 


    </Card>


}


