import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, SelectField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';

export interface SiteCardDebugI{
    value:Site
}


//  siteID: number;
//   typeID: number;

export function SiteCardDebug(props:SiteCardDebugI){
    const {value} = props;
    const task = value;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {};




    return <Card>
        <CardContent>
            <Typography variant="h6" >Site</Typography>
        </CardContent>
        <Divider/>
        <CardContent>

              
        <EntityField label="NMI" name="nmi" type="string"  entity={value} />
        <EntityField label="NMI Amend" name="nmiAmend" type="string"  entity={value} />

        <EntityField label="Customer Business Name" name="customerName" type="string"  entity={value} />
        <EntityField label="Customer Business Name Amend" name="customerNameAmend" type="string"  entity={value} />
        
        <EntityField label="Customer Contact Name" name="customerContactName" type="string"  entity={value} />
        <EntityField label="Customer Contact Name Amend" name="customerContactNameAmend" type="string"  entity={value} />
        
        <EntityField label="Customer Phone" name="customerPhone" type="string"  entity={value} />
        <EntityField label="Customer Phone Amend" name="customerPhoneAmend" type="string"  entity={value} />
        
        <EntityField label="Address" name="address" type="string"  entity={value} />
        <EntityField label="Address Amend" name="addressAmend" type="string"  entity={value} />
        
        <EntityField label="Meter Box Location" name="meterBoxLocation" type="string"  entity={value} />
        <EntityField label="Meter Box Location Amend" name="meterBoxLocationAmend" type="string"  entity={value} />
      
        <SelectField label="Meter Provider" name="mpID" value={value} filter={{type:"MeteringProvider"}} /> 
    
        <EntityField label="Life Support" name="lifeSupport" type="checkbox"  entity={value} />
     

       </CardContent>
    </Card>
}

    //   customerName: this.customerName,
    //   customerContactName: this.customerContactName,
    //   customerPhone: this.customerPhone,
    //   address: this.address,
    //   mpID: this.mpID,

    //   nmiAmend: this.nmiAmend,
    //   customerNameAmend: this.customerNameAmend,
    //   customerContactNameAmend: this.customerContactNameAmend,
    //   customerPhoneAmend: this.customerPhoneAmend,
    //   addressAmend: this.addressAmend,
    //   location: this.location,
    //   lifeSupport: this.lifeSupport,

    //   meterBoxLocation: this.meterBoxLocation,
    //   meterBoxLocationAmend: this.meterBoxLocationAmend,

//<SelectField label="Site" name="Site" value={value} filter={{type:"Site"}} /> 
        //<SelectField label="Type" name="JobType" value={value} filter={{type:"JobType"}} /> 

/*

    address: string;
    addressAmend: string|null;
    mpID: number;
    location:LngLat|null;
  
  
  
  
        */