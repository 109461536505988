import React, { useContext, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Button, Card, CardContent} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';

import { uploadFile } from '../../lib/download';
import { importCSV } from '../../lib/CSV';
import { importRow } from './utils';
import { CssStyles } from '../../css-types';

export function CSV_Import(){
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const [error,setError] = useState("");
    const [done,setDone] = useState("");

	const styles:CssStyles = {
		root:{
			paddingTop:64,
			paddingBottom:64,
            paddingLeft:20,
            paddingRight:20
		},
        error:{
            color:"red",
            textAlign:"center"
        },
        done:{
            textAlign:"center"
        }
	}


    async function importFile(){
        try{
            let rowCount = 0;
            const file = await uploadFile();
            const csv:unknown[] = await importCSV(file);
            
            for(const row of csv){
                await importRow(ds,row);
                rowCount++;
            }

            setDone(`${rowCount} records inserted`)
        }catch(E:any){
            console.debug(E);
            console.error(E);
            throw E;
            setError(E.toString());
        }
    }



	return  <div style={styles.root} > 	
            <Header title="CSV Import" back={true} />
            <Card>
                <CardContent>
                    <Button onClick={importFile} color="primary" variant="outlined" >Open File</Button>
                    {done !== "" ? <div style={styles.done}>{done}</div>:null}
                    {error !== "" ? <div style={styles.error}>{error}</div>:null}
                </CardContent>
            </Card>
            <Navigation  />
        </div>
    
}
