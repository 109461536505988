import { getMapURL, geocode, LngLat } from "../GoogleMaps";
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { Task } from "./Task";
import { Job } from "./Job";
import { google } from "google-maps";

export interface SiteJ {
  id: number;
  type: string;
  deleted: boolean;
  nmi: string;
  nmiAmend: string | null;
  customerName: string;
  customerNameAmend: string | null;
  customerContactName: string;
  customerContactNameAmend: string | null;
  customerPhone: string;
  customerPhoneAmend: string | null;
  address: string;
  addressAmend: string | null;
  mpID: number;
  location: LngLat | null;
  meterBoxLocation: string;
  meterBoxLocationAmend: string | null;
  lifeSupport: boolean;
  hazard: string | null;
  severity: string | null;
}

// Company name
// Customer Contact name
// Customer Contact phone
// Address
// NMI

export class Site extends Base {
  //values
  nmi: string;
  nmiAmend: string | null;
  customerName: string;
  customerNameAmend: string | null;
  customerContactName: string;
  customerContactNameAmend: string | null;
  customerPhone: string;
  customerPhoneAmend: string | null;
  address: string;
  addressAmend: string | null;
  meterBoxLocation: string;
  meterBoxLocationAmend: string | null;
  lifeSupport: boolean;
  hazard: string | null;
  severity: string | null;
  //links
  mpID: number;
  location: LngLat | null;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.nmi = event.data.nmi;
    this.customerName = event.data.customerName;
    this.customerContactName = event.data.customerContactName;
    this.customerPhone = event.data.customerPhone;
    this.address = event.data.address;
    this.mpID = event.data.mpID;
    this.location = event.data.location;
    this.meterBoxLocation = event.data.meterBoxLocation;
    this.lifeSupport = event.data.lifeSupport;
    this.hazard = event.data.hazard;
    this.severity = event.data.severity;

    this.nmiAmend = event.data.nmiAmend?? null;
    this.customerNameAmend = event.data.customerNameAmend?? null;
    this.customerContactNameAmend = event.data.customerContactNameAmend?? null;
    this.customerPhoneAmend = event.data.customerPhoneAmend ?? null;
    this.addressAmend = event.data.addressAmended ?? null;
    this.meterBoxLocationAmend = event.data.meterBoxLocationAmend ?? null;
  }

  getAmended(key: string): string {
    const t: any = this;
    if (t[key + "Amend"] != null) return t[key + "Amend"];
    else return t[key];
  }

  getNMI(): string {
    return this.getAmended("nmi");
  }
  getCustomerName(): string {
    return this.getAmended("customerName");
  }
  getCustomerContactName(): string {
    return this.getAmended("customerContactName");
  }
  getCustomerPhone(): string {
    return this.getAmended("customerPhone");
  }
  getAddress(): string {
    return this.getAmended("address");
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.mpID = transform(this.mpID);
  }

  getJobs(): Job[] {
    return this.ds.db.filter({ type: "Job", siteID: this.id });
  }

  getTasks(): Task[] {
    const jobs = this.getJobs();
    let tasks: Task[] = [];
    for (const job of jobs) {
      tasks = tasks.concat(job.getTasks());
    }

    return tasks;
  }

  toString() {
    return this.nmi + " " + this.address;
  }

  getMeterProvider() {
    return this.ds.db.get(this.mpID);
  }

  async geocode(google: google) {
    const address =
      this.addressAmend !== null ? this.addressAmend : this.address;
    
    
      console.log(this.address)
      console.log(this.addressAmend);
      console.log(address);


    const result = await geocode(google, address);
    const d = { location: result };
    await this.ds.updateEntity(this, d);
  }

  getGoogleMapHref() {
    return getMapURL(this.address);
    //`geo:0,0?q=${encodeURI(this.address)}`//getMapURL(this.address);
  }

  hasLocation() {
    return this.location != null;
  }

  getCallHref() {
    let phone = this.customerPhone;
    if (phone[0] === "0") phone = "+61" + phone.substring(1);

    return `tel:${phone}`;
  }

  getMessageHref(body: string) {
    let phone = this.customerPhone;
    if (phone[0] === "0") phone = "+61" + phone.substring(1);

    return `sms:${phone}&body=${body}`;
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      nmi: this.nmi,
      customerName: this.customerName,
      customerContactName: this.customerContactName,
      customerPhone: this.customerPhone,
      address: this.address,
      mpID: this.mpID,

      nmiAmend: this.nmiAmend,
      customerNameAmend: this.customerNameAmend,
      customerContactNameAmend: this.customerContactNameAmend,
      customerPhoneAmend: this.customerPhoneAmend,
      addressAmend: this.addressAmend,
      location: this.location,
      lifeSupport: this.lifeSupport,

      meterBoxLocation: this.meterBoxLocation,
      meterBoxLocationAmend: this.meterBoxLocationAmend,
      hazard: this.hazard,
      severity: this.severity,
    };
  }
}
