
import { Base } from "./Base";
import { JobType } from "./JobType";
import { DataEvent, Datastore } from "../Datastore";
import { DataType } from "./DataType";

export interface JobTypeDataI {
  id: number;
  type: string;
  deleted: boolean;
  name: string;
  jobTypeID: number;
  dataTypeID: number;
}

export class JobTypeData extends Base {
  name: string;
  jobTypeID: number;
  dataTypeID: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.jobTypeID = event.data.jobTypeID;
    this.name = event.data.name;
    this.dataTypeID = event.data.dataTypeID;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.jobTypeID = transform(this.jobTypeID);
    this.dataTypeID = transform(this.dataTypeID);
  }

  getJobType(): JobType {
    return this.ds.db.get(this.jobTypeID) as JobType;
  }

  getDataType(): DataType {
    return this.ds.db.get(this.dataTypeID) as DataType;
  }

  toString() {
    try {
      const jobType = this.getJobType();
      const dataType = this.getDataType();
      return `${this.name}:${dataType.name} for ${jobType.name}`;
    } catch (E) {
      return "error";
    }
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      jobTypeID: this.jobTypeID,
      name: this.name,
      dataTypeID: this.dataTypeID,
    };
  }
}
