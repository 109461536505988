import React, { useState, useContext, useMemo, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent,Theme, ListItem,ListItemText, Divider, Typography, ListSubheader, AppBar, Tabs, Tab, Badge, Chip, CircularProgress} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';

import useRouter from 'use-react-router';
import { CollectionFilterTabs } from '../../ui/CollectionFilterTabs';
import {  TaskStateToColor } from '../../lib/class/Task';
import { ColorChip } from '../../ui/ColorChip';
import { Warning } from '@material-ui/icons';
import { TabbedCollectionList } from '../../ui/TabbedCollectionList';
import { Moment } from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CollectionI, ListFilterI } from './Filters';
import { sleep } from '../../lib/async';
import { ListActions } from './ListActions';

export function ListView(props:any){
  const global = useContext(GlobalContext);
  const { theme, project, ui, ds } = global;
  const sm = useMediaQuery((theme:Theme) => theme.breakpoints.down('sm'));

  const [map,setMap] = useState(false)

  const styles = {
    root:{
      paddingTop:48,
      paddingBottom:64
    }
  }

  const type:string = props.match.params.type;
  return <div style={styles.root} key={type+"list"} > 	
      <Header title={`${type} List`} back={false} />
      <TabbedCollectionList type={type} map={map}  />
      <ListActions type={type} setMap={setMap} map={map} />
      <Navigation  target={"list/"+type}  />
  </div>
}
