import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem, Button, Grid} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, EntityField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';



import { downloadBlobFile, uploadFile } from '../../lib/download';
import { VirtualFile, VirtualFileI } from '../../lib/class/VirtualFile';
import { generateRandomHexString, getFileNameExt, SHA256fromFileHex } from '../../lib/Data';
import { getTimestamp } from '../../lib/time';
import { Document, DocumentI } from '../../lib/class/Document';



export interface DocumentCardDebugI{
    value:Document
}

export function DocumentCardDebug(props:DocumentCardDebugI){
    const {value} = props;
    const task = value;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles = {};


    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,"fileID",forceUpdate),[]);


    async function upload(){
        const capture = undefined;
        const accept = ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        const file = await uploadFile(accept,capture);
        //console.log(file);
        const hash = await SHA256fromFileHex(file);

        const [name,extension] = getFileNameExt(file);

        const vf:VirtualFileI = {
            id:0,
            type:"VirtualFile",
            deleted:false,
            name:name,
            timestamp:getTimestamp(),
            extension:extension,
            mimetype:file.type,
            size:file.size,
            hash:hash,
            key:generateRandomHexString(32),
            serverHas:false
        };

        const virtualFile = await ds.createEntity(vf);
        await ds.cacheUploadSave(file,vf.key);
        const d:any = {
            fileID:virtualFile.id,
            timestamp:moment().valueOf()
        }

        const doc = await ds.updateEntity(value,d);
        

    }

    async function openFile(){
        if(value.fileID != null){
            const virtualFile = ds.db.get(value.fileID) as VirtualFile;
            const ab = await ds.geVirtualFile(virtualFile);
            downloadBlobFile(ab, virtualFile.getFilename() );
        }
    }



    return <Card>
        <CardContent>
            <Typography variant="h6" >Document</Typography>
        </CardContent>
        <Divider/>
        <CardContent>
        <EntityField label="Label" name="label" type="string"  entity={value} />
        <EntityField label="Description" name="description" type="string" multiline={true} entity={value} />
      
    <br/>
    <br/>
      <Grid container spacing={10}>
      <Grid item xs={6} >

            <Button color="primary" variant="outlined" fullWidth onClick={() => openFile()} disabled={value.fileID == null}>Download File</Button>
        </Grid><Grid item xs={6}>
        <Button color="primary" variant="outlined" fullWidth onClick={() => upload()}>Upload File</Button>
      
        </Grid>


        
      </Grid>
       
       </CardContent>
    </Card>
}


/*
   <EntityField label="Model" name="model" value={value.model} entity={value} />
        <EntityField label="Model" name="serialNumber" value={value.serialNumber} entity={value} />
        <EntityField label="NATA Reference" name="nataReference" value={value.nataReference} entity={value} />
        <EntityField label="Last Calibration Date" name="lastCalibrationDate" value={value.lastCalibrationDate} entity={value} type="date" InputLabelProps={{shrink: true }} />
        <EntityField label="Next Calibration Date" name="nextCalibrationDate" value={value.nextCalibrationDate} entity={value} type="date" InputLabelProps={{shrink: true }} />
  */