
import { Base } from "./Base";
import { TaskType } from "./TaskType";
import { DataEvent, Datastore } from "../Datastore";


export interface TaskQuestionJ{
    id: number;
    type: string;
    deleted:boolean;
    typeID: number;
    order: number;
    value: string;
}


export class TaskQuestion extends Base{
    
    typeID:number;
    order:number;
    value:string;



    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);

        this.typeID =  event.data.typeID;
        this.order =  event.data.order;
        this.value =  event.data.value;
    }

    transformID(transform:(v:number)=>number){
        super.transformID(transform);
        this.typeID = transform(this.typeID);
    }


    getType():TaskType{
        return this.ds.db.get(this.typeID) as TaskType;
    }

    toJSON(){
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            typeID:this.typeID,
            order:this.order,
            value:this.value
        };
    }


}