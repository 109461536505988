import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, MenuItem} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { DatomFieldRaw, DualDatomField, EntityField, SelectField, TaskStatusField, TechField, Timefield } from '../Fields';
import { Warning } from '@material-ui/icons';
import { Datom } from '../../lib/class/Datom';
import { TestUnit } from '../../lib/class/TestUnit';
import { JobDataField } from '../Fields/JobDataField';
import { JobDualDatomField } from '../Fields/JobDualDatamField';

export interface JobCardDebugI{
    value:Job
}


//  siteID: number;
//   typeID: number;

export function JobCardDebug(props:JobCardDebugI){
    const {value} = props;
    const task = value;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;


    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(value.id,"*",forceUpdate),[]);

    const styles = {};

    let datas:any[] = [];
    
    try{
        const type = value.getType();
        datas = type.getData();
        console.log("datas",datas.map(d => `${d.name}: ${d.id}`));
    }catch(E){

    }




    return <Card>
        <CardContent>
            <Typography variant="h6" >Job</Typography>
        </CardContent>

        <Divider/>

        <CardContent>
            <SelectField label="Site" name="siteID" value={value} filter={{type:"Site"}} /> 
            <SelectField label="Type" name="typeID" value={value} filter={{type:"JobType"}} />       
            <EntityField label="Start Date" name="startDate" type="date"  entity={value} />
            <EntityField label="Due Date" name="dueDate" type="date"  entity={value} />
            <EntityField label="Ignore" name="ignore" type="checkbox"  entity={value} />
            <EntityField label="Sent" name="sent" type="checkbox"  entity={value} />
       </CardContent>

        <Divider/>

        <CardContent>
            {datas.map(d => <JobDataField key={value.id} job={value} field={d.name} />)}
            <JobDualDatomField value={value} grandParentLabel="Meter Brand" grandParentName="Meter Brand"  label="Meter Model" name="Meter Model"  />
       </CardContent>

    </Card>
}