import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../withGlobalContext';

import {TextField, Card, CardContent, Divider, Typography,Table, TableRow, TableCell, TableBody, TableHead, Button, List, ListItem} from "@material-ui/core";
import { Task, TaskStatus } from '../../lib/class/Task';
import { isEmpty } from "../../lib/Data";

import {DataField, AmendmentField, TaskStatusField, VirtualFileDataField, DatomField, DualDatomField, Timestampfield} from "../Fields";
import { Check, Clear, Warning } from '@material-ui/icons';
import useRouter from 'use-react-router';

interface AssetInspectionFormI{
    task:Task;
}

export function AssetInspectionForm(props:AssetInspectionFormI){
    const {task} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const job = task.getJob();
    const site = job.getSite();
   
    const showForm = true;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"status",forceUpdate),[]);


    return <div>
        <Card>
            <CardContent>
                <Typography variant="h6">Meter Testing Form</Typography>
            </CardContent>
            <Divider/>

            {task.status != TaskStatus.Cancelled? <CardContent>
            <Typography variant="subtitle1">Task start information:</Typography>
            <Timestampfield task={task} label="Task Start" name="startTimestamp" preValue={task.scheduleTimestamp} />
            </CardContent>:null }

          {showForm?<div>
            <CardContent>
                <Typography variant="subtitle1">Please complete a Job Hazard Assessment:</Typography>
                <DataField task={task} label="Do you fully understand all the instructions?" field={"JHA Instructions"} />
                <DataField task={task} label="Do you have the correct PPE?" field={"JHA PPE"} />
                <DataField task={task} label="Do you have safe access &amp; egress from meter position?" field={"JHA Access"} />
                <DataField task={task} label="Are you aware of all live points?" field={"JHA Live Points"} />
                <DataField task={task} label="Can you undertake your NST?" field={"JHA Isolated"} />
                <DataField task={task} label="Is traffic management required?" field={"JHA Traffic"} />
                <DataField task={task} label="Is a site induction required?" field={"JHA Induction"} />
                <DataField task={task} label="Note any control measures used" field={"JHA Control Measures"}  multiline={true}/>
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Please review the following asset information:</Typography>
                <DatomField value={task} label={"Service Type"} name={"Service Type"} type={"Service Type"} parent="Datom Value" />
                <DataField task={task} label="Ariel Service Inspection" field={"Ariel Inspection"} />
                <VirtualFileDataField task={task} field={"Ariel Photo"} label={"Ariel Photo"}  accept="image/*" capture="environment" />

                <DataField task={task} label="Service has POA / FOHLCB Clearance" field={"POA / FOHLCB Clearance"} />
                <VirtualFileDataField task={task} field={"POA / FOHLCB Photo"} label={"POA / FOHLCB Photo"}  accept="image/*" capture="environment" />

                <div>
                    If no to either of the previous checks, report to Jemena faults on 131 646
                </div>
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Meter details</Typography>
                <DatomField value={task} label={"MPB"} name={"MPB"} type={"MPBs"} parent="Datom Value" />
                <DataField task={task} label={"Meter Serial Number"} field={"Meter Serial Number"}  />
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">NST results</Typography>
                <DataField task={task} label={"NST Polarity"} field={"NST Polarity"} booleanDisplay={["Pass","Fail"]} />  
                <DataField task={task} label={"A to N impedance"} field={"A to N impedance"}  />  
                <DataField task={task} label={"A to N impedance test"} field={"A to N impedance test"} booleanDisplay={["Pass","Fail"]} />  

                <DataField task={task} label={"A to N voltage"} field={"A to N voltage"}  />  
                <DataField task={task} label={"A to N voltage test"} field={"A to N voltage test"}  booleanDisplay={["Pass","Fail"]}/>  

                <DataField task={task} label={"N to E voltage"} field={"N to E voltage"}  />  
                <DataField task={task} label={"N to E voltage test"} field={"N to E voltage test"}  booleanDisplay={["Pass","Fail"]}/>  

                <DataField task={task} label={"All tests passed"} field={"All tests passed"} booleanDisplay={["Pass","Fail"]} />  

                <div>
                    If NST test fails, report to Jemena faults on 131 646 and state time and operator name.
                </div>
                
            </CardContent>

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Finalization</Typography>
                <DataField task={task} label={"Seal Serial number"} field={"Seal Serial number"}  />   
                 <VirtualFileDataField task={task} field={"Seal Photo"} label={"Seal Photo"} accept="image/*" capture="environment" />
            </CardContent>



            <CardContent>
                <Typography variant="subtitle1">Photos:</Typography>
                <VirtualFileDataField task={task} field={"Other Photo"} label={"Other Photo"}  accept="image/*" capture="environment" />
            </CardContent>
            </div>:null}

            <Divider/>

            <CardContent>
                <Typography variant="subtitle1">Notes:</Typography>
                <DataField task={task} field={"Comments"} multiline={true} />
            </CardContent>

            <Divider/>

            <CardContent>  
                <Typography variant="subtitle1">Finalise task:</Typography>
                <TaskStatusField name="status" label="Task Status" task={task} />
                {task.status !== TaskStatus.Cancelled? <Timestampfield task={task} label="Task End" name="endTimestamp"  preValue={task.startTimestamp} /> :null}
                
                <TaskDurationField task={task} />
             
            
            </CardContent>

        </Card>
        

    </div>

}



export interface TaskDurationFieldI{
    task:Task;
}

export function TaskDurationField(props:TaskDurationFieldI){
    const {task} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,"startTimestamp",forceUpdate),[]);
    useEffect(() => ds.sub(task.id,"endTimestamp",forceUpdate),[]);

    let duration  = "";
    try{
        duration = ""+task.getDuration();
    }catch(E){

    }


    if(duration == "")
        return null;
    else
        return <TextField label="Duration" fullWidth value={duration+" hours"} margin="normal" disabled/>

}




