import moment from "moment";
import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";
import { TestUnit } from "./TestUnit";
import { Tech } from "./Tech";
import { Task } from "./Task";
import { isEmpty } from "../Data";
import { TaskData, TaskDataI } from "./TaskData";
import { convertRecord, unpackTextRecord689 } from "./TestUnitParse/689";

export interface TestResultI {
  id: number;
  type: string;
  deleted: boolean;
  timestamp: number | null;
  // siteID: number | null;
  recordNumber: number | null;
  testUnitID: number | null;
  hash: string; // is this needed?
  data: string;
  techID: number;
}

export class TestResult extends Base {
  timestamp: number | null;
  recordNumber: number | null;
  testUnitID: number | null;
  hash: string;
  data: string;
  techID: number;

  constructor(ds: Datastore, event: DataEvent) {
    super(ds, event);

    this.timestamp = event.data.timestamp;
    this.recordNumber = event.data.recordNumber;
    this.testUnitID = event.data.testUnitID;
    this.hash = event.data.hash;
    this.data = event.data.data;
    this.techID = event.data.techID;
  }

  transformID(transform: (v: number) => number) {
    super.transformID(transform);
    this.techID = transform(this.techID);
    if (this.testUnitID != null) this.testUnitID = transform(this.testUnitID);
  }

  toSearchString() {
    const str: string[] = [];

    try {
      str.push(this.getTestUnit().toString());
    } catch {}

    try {
      str.push(this.getTimestampString());
    } catch {}
    try {
      str.push(this.recordNumber == null ? "NA" : this.recordNumber + "");
    } catch {}

    try{
      const record = unpackTextRecord689(this.data);
      str.push(record.meterID)
    }catch{}

    return str.join(" ");
  }

  getTech(): Tech {
    if (this.techID == null) throw "Tech ID not set";
    return this.ds.db.get(this.techID) as Tech;
  }
  getTechString(): string {
    try {
      return this.getTech().toString();
    } catch (E) {
      return "Tech NA";
    }
  }

  getTestUnit(): TestUnit {
    if (this.testUnitID == null) throw "Test Unit ID not set";
    return this.ds.db.get(this.testUnitID) as TestUnit;
  }

  getTimestamp() {
    return moment(this.timestamp);
  }

  getTimestampString() {
    let timestampString = "Timestamp NA";
    try {
      const timestamp = this.getTimestamp();
      timestampString = timestamp.format("DD/MM/YYYY HH:mm");
    } catch (E) {}
    return `${timestampString} `;
  }

  toString() {
    let unitString = "Unit NA";
    let recordNumberString = "Record NA";
    const timestamp = this.getTimestampString();

    try {
      const unit = this.getTestUnit();
      unitString = unit.toString();
    } catch (E) {}

    if (this.recordNumber != null) recordNumberString = this.recordNumber + "";
    return `Unit ${unitString} - Record number ${recordNumberString} on ${timestamp}`;
  }

  decode() {
    const record = unpackTextRecord689(this.data);
    return record;
  }

  async syncLinks() {
    const record = unpackTextRecord689(this.data);
    const update_data: any = {};

    let updated = false;

    if (this.testUnitID == null) {
      const testUnitD: any = {
        type: "TestUnit",
        serialNumber: record.testUnitSerialNumber,
      };
      const testUnits: TestUnit[] = this.ds.db.filter(testUnitD);
      let testUnit: TestUnit;
      if (testUnits.length === 0) {
        throw new Error("Unrecognized test unit serial number");
      } else {
        testUnit = testUnits[0];
      }
      update_data.testUnitID = testUnit.id;
      updated = true;
    }

    if (this.recordNumber == null || typeof this.recordNumber === "string") {
      update_data.recordNumber = parseInt(record.recordNumber);
      updated = true;
    }

    if (this.timestamp == null) {
      update_data.timestamp = record.timestamp;
      updated = true;
    }
    let e: TestResult = this;
    if (updated)
      e = (await this.ds.updateEntity(this, update_data, true)) as TestResult;

    try {
      await e.syncTask();
    } catch (E) {}

    return e;
  }

  //* TODO: understand */
  async syncTask(): Promise<TaskData> {
    const record = unpackTextRecord689(this.data);
    const timestamp = this.getTimestamp();
    const targetTask = this.ds.db.filter({
      type: "TaskType",
      name: "Meter Test",
    })[0];
    if (targetTask === undefined)
      throw new Error("Can't find target task type");

    const tasks = this.ds.db.filter({
      type: "Task",
      typeID: targetTask.id,
    }) as Task[];

    let matchingMeterTask: Task | null = null;

    for (const task of tasks) {
      try {
        const job = task.getJob();
        const meterNumber = job.getJobDataItemString("Meter Number");
        if (meterNumber === record.meterID) {
          matchingMeterTask = task;
          break;
        }
      } catch (E) {}
    }

    if (matchingMeterTask === null) {
      throw new Error("Could not find matching meter number");
    }
    const task = matchingMeterTask;

    for (let element = 1; element <= 2; element++) {
      for (let test = 1; test <= 9; test++) {
        const recordNumber = parseInt(
          task.getJobDataItemString(
            `Element ${element} Error Test ${test} Record Number`
          )
        );

        // console.log(recordNumber, this.recordNumber)

        if (!isNaN(recordNumber) && recordNumber === this.recordNumber) {
          const dataKey = `Element ${element} Error Test ${test} Result`;
          const existingResult = task.getJobDataItemString(dataKey);

          if (isEmpty(existingResult)) {
            const taskType = task.getType();
            const taskTypeData = taskType.getDataString(dataKey);

            const d: TaskDataI = {
              id: 0,
              type: "TaskData",
              deleted: false,
              taskID: task.id,
              dataID: taskTypeData.id,
              value: null,
              valueID: this.id,
            };
            const e = (await this.ds.createEntity(d)) as TaskData;
            return e;
          } else {
            throw "Task Result Record already set!";
          }
        }
      }
    }

    throw "No record number match found";
  }

  convertRecord(meterType: string, location: string, operator: string) {
    const record = unpackTextRecord689(this.data);
    const converted = convertRecord(record, meterType, location, operator);
    return converted;
  }

  isComplete() {
    return !this.hasError();
  }

  hasError() {
    const error = this.getError();
    return error.length !== 0;
  }

  getErrorResult() {
    const record = unpackTextRecord689(this.data);
    return record.errorResult;
  }

  getRecordNumber() {
    const record = unpackTextRecord689(this.data);
    return parseInt(record.recordNumber);
  }

  getPowerFactor() {
    const record = unpackTextRecord689(this.data);
    return record.averageTestPowerFactor;
  }

  getRecordInfo() {
    const record = unpackTextRecord689(this.data);
    return {
      recordNumber: record.recordNumber,
      powerFactor: record.averageTestPowerFactor,
    };
  }

  // TODO fix
  getTaskDataLink() {
    const keys: string[] = [];
    for (let element = 1; element <= 2; element++)
      for (let test = 1; test <= 9; test++)
        keys.push(`Element ${element} Error Test ${test} Result`);

    const typeData = this.ds.db.filter({ type: "TaskTypeData" });
    const matchedKeys = typeData.filter((t) => keys.indexOf(t.name) !== -1);

    const typeIDs = matchedKeys.map((t) => t.id);

    const taskDatas = this.ds.db
      .filter({ type: "TaskData" })
      .filter((t) => typeIDs.indexOf(t.dataID) !== -1);

    const linked = taskDatas.filter((t) => t.valueID === this.id);
    

    if (linked.length == 1) return linked[0];
    else if (linked.length > 1) throw "Multiple task link matches!";
    else throw "No task link found";
  }

  getError() {
    if (this.timestamp == null) return "Timestamp not set";
    if (this.testUnitID == null) return "Test unit not linked";
    if (this.recordNumber == null) return "Record number not set";

    // TODO: needs to be reimplemented
    try {
      const link = this.getTaskDataLink(); // will throw an error
    } catch (E) {
      return (E as any).toString();
    }

    return "";
  }

  toJSON() {
    const base = super.toJSON();
    return {
      id: base.id,
      type: base.type,
      deleted: base.deleted,
      timestamp: this.timestamp,
      recordNumber: this.recordNumber,
      testUnitID: this.testUnitID,
      hash: this.hash,
      data: this.data,
      techID: this.techID,
    };
  }
}
