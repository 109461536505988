import React, { useContext, useEffect, useReducer } from 'react';
import {TextField, IconButton, InputAdornment, InputLabel} from "@material-ui/core";

import {GlobalContext} from '../../ui/withGlobalContext';
import { Restore } from '@material-ui/icons';
import { Site } from '../../lib/class/Site';




interface AmendmentFieldSiteI{
    site:Site;
    label:string;
    value:string;
    amend:string;
    type:string;
    adornment?:any;
}


export function AmendmentFieldSite(props:AmendmentFieldSiteI){
    const {site,label,value,type,amend,adornment} = props; 

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    
    
    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => {
        ds.sub(site.id,value,forceUpdate);
        ds.sub(site.id,amend,forceUpdate);
    },[]);
    
    async function update(e:any){
        await ds.updateEntityField(site,e);
    }

    async function restore(){
        const v:any = {};
        v[amend] = null;
        await ds.updateEntity(site,v);
    }

    const s:any = site;
    const amended = s[amend] != null;
    //console.log(value);
    const v = amended ? s[amend] : s[value];

    function blur(){
        // ds.forceSave();
    }



    const endAdornment =<InputAdornment position="end"> {amended?<IconButton onClick={restore}><Restore /></IconButton>:null}{adornment}</InputAdornment>;

    if(type == "boolean")
        return <div>
                <InputLabel>{label}</InputLabel>
                <div>Boolean not supported yet</div>
        </div>
    else if(type == "string"){
        return <TextField margin="normal" fullWidth label={label} name={amend} value={v}  onChange={(e) => update(e) } InputProps={{endAdornment:endAdornment}} onBlur={blur} />
    }else if (type == "number"){
        return <TextField margin="normal" fullWidth label={label} type="number" name={amend} value={v}  onChange={(e) => update(e) }  InputProps={{endAdornment:endAdornment}} onBlur={blur}  onWheel={ (event:any) => { event.target.blur() } } />
    }else{
        return <div>Data type not supported</div>
    }


}
