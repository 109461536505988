import { Base } from "./Base";
import { DataEvent, Datastore } from "../Datastore";



export interface MeteringProviderJ{
        id: number;
        type: string;
        deleted:boolean;
        name: string;
}

export class MeteringProvider extends Base{
    name:string;


    constructor(ds:Datastore, event:DataEvent){
        super(ds,event);
        this.name =  event.data.name;
    }



    toString(){
        return this.name;
    }



    toJSON(){
        const base = super.toJSON();
        return {
            id:base.id,
            type:base.type,
            deleted:base.deleted,
            name:this.name
        };
    }



}