import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader,Table, TableRow, TableCell, TableBody, TableHead, ButtonGroup, Button, MenuItem, FormHelperText, IconButton, InputAdornment, InputLabel, CircularProgress, CardMedia, Checkbox, FormLabel} from "@material-ui/core";

import {Header} from '../../ui/Header'
import { Navigation } from '../../ui/Navigation';
import { Job } from '../../lib/class/Job';
import { TimeList } from '../../ui/TimeList';
import { Task, TaskStatus } from '../../lib/class/Task';
import { Site } from '../../lib/class/Site';
import { TaskTypeData } from '../../lib/class/TaskTypeData';
import { TaskData, TaskDataI } from '../../lib/class/TaskData';
import { Add, ArrowDownward, ArrowDropDown, AttachFile, Camera, CameraAlt, Clear, RestaurantRounded, Restore, Timer } from '@material-ui/icons';
import { Base } from '../../lib/class/Base';
import { uploadFile } from '../../lib/download';
import { generateRandomHexString, getFileNameExt, SHA256fromFileHex } from '../../lib/Data';
import { VirtualFile, VirtualFileI } from '../../lib/class/VirtualFile';
import { getTimestamp } from '../../lib/time';
import { Datom, DatomI } from '../../lib/class/Datom';
import { DatomFieldRaw } from './DatomFieldRaw';






export interface JobDualDatomFieldI{
    label?:string;
    name:string;
    // amendName?:string;
    grandParentName:string;
    grandParentLabel:string;
    value:Job;
}


export function JobDualDatomField(props:JobDualDatomFieldI){
    const {label,name,grandParentName,grandParentLabel,value} = props;
    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;


    const dv = ds.db.find({type:"Datom",value:"Datom Value"}) as Datom;
    const grandparent = ds.db.find({type:"Datom",value:grandParentName}) as Datom;

    const [parent,setParent] = useState<Datom|null>(null)
    const [child,setChild] = useState<Datom|null>(null)

    useEffect(() => {
        try{
        const id = value.getJobDataItemKey(name).valueID 
        if(id!= null){
            const meter = ds.db.get(id) as Datom;
            const brand = meter.getParent();
            setParent(brand);
            setChild(meter);
        }else{
            setParent(null);
            setChild(null)
        }
    }catch(E){
        console.log(E);
    }
    },[]);



    function parentChange(v:Datom|null){
        console.log("parentChange",v);
        setParent(v);
        setChild(null);
    }

    
    function childChange(v:Datom|null){
        console.log("childChange",v);
        setChild(v);
        if(v == null)
            value.upsertJobData(name,{valueID:null});
        else
            value.upsertJobData(name,{valueID:v.id});

        // ds.forceSave();
    }

  

    

    return <React.Fragment>
        <DatomFieldRaw label={grandParentLabel} onChange={parentChange} parent={grandparent} options={grandparent.getValues("Meter Brand")} type="Meter Brand" value={parent}  />
        <DatomFieldRaw label={label} onChange={childChange} parent={parent} options={parent!=null?parent.getValues("Meter Model"):[]} type="Meter Model" value={child} disabled={parent==null}   />
    </React.Fragment>

}



