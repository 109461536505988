import React, { useContext, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Card, CardContent, Divider, Typography, Button} from "@material-ui/core";

import { EntityField, SelectField } from '../Fields';
import useRouter from "use-react-router";
import { AutoTestResult } from '../../lib/class/AutoTestResult';
import { CssStyles } from '../../css-types';
import { TestResultRow } from '../../lib/class/TestResultRow';

export interface TestResultCardI{
    value:TestResultRow
}

export function TestResultRowCard(props:TestResultCardI){
    const {value} = props;

    const router = useRouter();

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const styles:CssStyles = {
        title:{
            fontWeight:"bold"
        }
    }
    const [msg,setMsg] = useState("");
    const [error,setError] = useState("");


    async function syncLinks(){
        value.syncLinks();
    }

    async function searchTask(){
        try{
            const e = await value.syncTask();
            // let task = e.getTask();
            // setMsg(`linked to task ${task.toString()}` )
        }catch(E:any){
            console.error(E.toString());
            setError(E.toString());
        }
    }


    function getTaskDebug() {
        // let taskLink = value.getTaskDataLink();
        // console.log(taskLink)
        // let task = taskLink.getTask();
        // console.log(task);
    }



    // let taskLinkString = ""

    // let taskButton:any = null;
    // try{
    //     let taskLink = value.getTaskDataLink();
    //     let task = taskLink.getTask();
    //     taskButton = <Button color="primary" variant="outlined" fullWidth onClick={() => router.history.push("/task/"+task.id)   } >View Task</Button>
    //     taskLinkString = `linked to task ${task.toString()}`;

    // }catch(E:any){
    //     taskLinkString = E.toString();
    // }


    async function decode(){
        value.decode();
    }

    const info = value.decode()


    return <Card>
        <CardContent>
            <Typography variant="h6" >Test Result Row</Typography>
        </CardContent>
        <Divider/>
        <CardContent>
            
            <div>Date: {value.getTimestampString()}</div>
            
            <SelectField label="Technician" name="techID" value={value} filter={{type:"Tech"}}  />
            <SelectField label="Test Unit" name="testUnitID" value={value} filter={{type:"TestUnit"}}  />
            <SelectField label="Task" name="taskID" value={value} filter={{type:"Task"}}  />
            <EntityField label="Meter Number" name="meterNumber" entity={value} />
            <EntityField label="Record Number" name="recordNumber" entity={value} />
            <EntityField label="Data" name="data" entity={value} type="textarea" />

           <br/><br/>
      

           <br/><br/>
           <Button fullWidth color="primary" onClick={searchTask} variant="outlined" >Search for task</Button>
           <Button fullWidth color="primary" onClick={syncLinks} variant="outlined" >Sync Links</Button>
           <Button fullWidth color="primary" onClick={decode} variant="outlined" >Decode</Button>
           <Button fullWidth color="primary" onClick={getTaskDebug} variant="outlined" >getTaskDebug</Button>

           

           {msg}

           {error.length>0?<div style={{color:"red"}}>{error}</div>:null }
        
        </CardContent>

    <Divider />
    <CardContent>
        <pre>
            {JSON.stringify(info,undefined,3)}
        </pre>
    </CardContent> 

    <Divider />
    <CardContent>
        <pre>
            {value.data}    
        </pre>
    </CardContent> 


    </Card>


}


