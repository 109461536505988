import React, { useContext } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import moment from "moment";

import {TextField, Checkbox, FormLabel} from "@material-ui/core";




interface ReadOnlyFieldI{
    label:string;
    value:any;
    
    InputProps?:any;
    InputLabelProps?:any;
    type?:string;
    error?:boolean;
}

export function ReadOnlyField(props:ReadOnlyFieldI){
    const {label,value, InputProps,InputLabelProps,type,error=false} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    let v = "";
    if(type == "date" && value != null && value != ""){
        v = moment(value).format(moment.HTML5_FMT.DATE);
    }else if(value != ""){
        v = value;
    }

    if(type == "checkbox"){
       return <div>
            <FormLabel>{label}</FormLabel>
            <Checkbox color="primary" checked={value?true:false} />
        </div>
    }else{
        return <TextField label={label} error={error} value={v} fullWidth margin="normal" disabled={true}  InputProps={InputProps}  InputLabelProps={InputLabelProps} type={type} />
    }

}
