import React,{ useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';
import useRouter from 'use-react-router';
import { Job } from '../../lib/class/Job';
import { CatchError } from './catch-error';
import { JobView } from '../JobView';
import { Header } from '../../ui/Header';
import { Task } from '../../lib/class/Task';
import { Base } from '../../lib/class/Base';
import { TaskView } from '../TaskView';
import { Button } from '@material-ui/core';

interface FailedSample{
    item:Base;
    message:string;
}

export function BatchTester(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;
    const router = useRouter();

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		}
	};

    const [jobs,setJobs] = useState<Job[]|null>(null);
    const [tasks,setTasks] = useState<Task[]|null>(null);
    const [index,setIndex] = useState<number>(0);
    const [failed,setFailed] = useState<FailedSample[]>([]);
    

    async function load(){
        // const temp:Job[] = ds.db.filter({type:"Job"});
        // setJobs(temp);

        const temp:Task[] = ds.db.filter({type:"Task"});
        setTasks(temp);

        setIndex(0);
    }


    useEffect(() => {
        load();
    },[]);

    function failedHandler(item:Base, message:string){
        console.log("handle failed")
        const sample:FailedSample = {item,message};
        const f = [...failed,sample];
        setFailed(f);
        setTimeout( () => next(),250);
    }

    function next(){
        console.log("next");
        if(tasks === null) return;
        if((index+1) < tasks.length)
            setIndex(index+1);
    }

    function handleOkay(){
        console.log("handle okay")
        next();
    }   


	return <div style={styles.root}  > 	
            <Header title={"Batch Tester"} back={true} />
            {tasks && <div>{index}/{tasks.length}</div>}

            <pre>
            {failed.map(s => `${s.item.id} ${(s.item as any).getType().toString()} ${s.message}` ).join('\n')} 
            </pre>
            {tasks && <CatchError key={tasks[index].id} isOkay={handleOkay} hasFailed={(e) => failedHandler(tasks[index],e)}>
                    <TaskView id={tasks[index].id} />
                </CatchError>
            }

            <Button color="primary" onClick={next}>Next</Button>
      
    </div>

}

// // key={s.item.id}>{s.item.id} - {(s.item as any).getType().toString()}}

    //   {jobs && <CatchError key={jobs[index].id} isOkay={handleOkay} hasFailed={(e) => failedHandler(jobs[index],e)}>
    //                 <JobView id={jobs[index].id} />
    //             </CatchError>
    //         }