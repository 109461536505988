
import * as React from 'react';
//import { createMuiTheme } from '@material-ui/core/styles';


import {Project} from '../lib/Project';
import { Datastore } from '../lib/Datastore';
//import { DatastoreWorkerManager } from '../workers';
//import {API} from '../core/API';
import {google} from 'google-maps';


export interface GlobalI{
	theme:any;
	project:Project;
	ds:Datastore;
	//dsm:DatastoreWorkerManager;
	ui:UI_I;
	google:google;
}


export interface UI_I{
	//sidebar:boolean;
	//toggleSidebar():void;
	//setSidebar(value:boolean):void;
}
  


export let GlobalContext:React.Context<GlobalI>

export function setGlobalContext(value:GlobalI){
	GlobalContext = React.createContext<GlobalI>(value);
}


/*

	api:a_temp, //this should be fixed
	theme:createMuiTheme({}), //this should be fixed
	project:null,
	ui:{
		sidebar:false,
		toggleSidebar: () => {return;},
		setSidebar: (value:boolean) => {return false;},
	}
}
*/