import React, { useContext, useEffect, useReducer } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {TextField} from "@material-ui/core";
import { Task, TaskStatus } from '../../lib/class/Task';




export interface TaskStatusFieldI{
    label?:string;
    name:string;
    task:Task;
}



export function TaskStatusField(props:TaskStatusFieldI){
        const {task, name, label} = props;

        const global = useContext(GlobalContext);
        const { theme, project, ui, ds } = global;

        async function changeStatus(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>){
            const key = event.target.name;
            const v = event.target.value;
            const d:any = {};
            d[key] = v;
            await ds.updateEntity(task,d);
           // await ds.save();
        //    ds.forceSave();
        }
        
        const validation = task.validateAttribute(name);
        

    const forceUpdate = useReducer((x) => x + 1, 0)[1];
    useEffect(() => ds.sub(task.id,name,forceUpdate),[]);

        

    return  <TextField select name={name} label={label} value={task.status} margin="normal" fullWidth onChange={(e) => changeStatus(e)} error={validation.length!=0} helperText={validation} SelectProps={{native: true}} InputLabelProps={{shrink: true}}>
        <option value={TaskStatus.Pending}>Pending</option>
        <option value={TaskStatus.Successful}>Successful</option>
        <option value={TaskStatus.UnableToComplete}>Unable To Complete</option>
        <option value={TaskStatus.Cancelled}>Cancelled</option>
        <option value={TaskStatus.Aborted}>Aborted</option>
    </TextField>

}