import React, { useState, useContext, useMemo } from 'react';
import {GlobalContext} from './withGlobalContext';

import {Card, CardContent, List,ListItem,ListItemText, Divider, Typography, ListSubheader, AppBar, Tabs, Tab, Badge, Chip, TextField} from "@material-ui/core";

import {Header} from './Header'
import { Navigation } from './Navigation';
import { Job } from '../lib/class/Job';
import { TimeList } from './TimeList';

import useRouter from 'use-react-router';
import { CollectionFilterTabs } from '../ui/CollectionFilterTabs';
import {  TaskStateToColor } from '../lib/class/Task';
import { ColorChip } from './ColorChip';
import { Warning } from '@material-ui/icons';
import { Moment } from 'moment';

import { FixedSizeList } from 'react-window';
import { CssStyles } from '../css-types';



interface CollectionListI{
    search:boolean;
    collection:any[];
    Render:Function;
}

export function CollectionList(props:CollectionListI){
    const {search,collection,Render} = props;

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;

    const [searchString,setSearchString] = useState("");

    const styles:CssStyles = {
        root:{
            paddingLeft:20,
            paddingRight:20
        },
        nothing:{
            textAlign:"center",
            marginTop:100,
            color:"#888"
        }
    }

    const result = useMemo( () => {
        console.log("filtering"); 
        function searchStr(e:any){
            if(typeof e.toSearchString != "undefined")
                return e.toSearchString();
            else if(typeof e.toString != "undefined")
                return e.toString();
            else
                JSON.stringify(e);
        }


        return collection.filter( c => searchStr(c).toLowerCase().indexOf(searchString.toLowerCase()) != -1)}
    , [searchString]);
    
    const topBar = 52 + 52 + (search?78:0);
    const bottomBar = 58;
    const height = window.innerHeight - topBar - bottomBar;

    function renderRow(props:any) {
        const { index, style } = props;
        const v = result[index];
        return <Render key={v.id} style={style} value={v} />
      }

    return <React.Fragment>
        {search?<React.Fragment>
            <div style={styles.root}>
                <TextField autoFocus name="searchString" label="Search" margin="normal" value={searchString} onChange={(e) => setSearchString(e.target.value)} fullWidth />
            </div>    
            <Divider />
        </React.Fragment>:null}
        {result.length == 0 ? <div style={styles.nothing}>No Items</div>:<FixedSizeList height={height} width={"100%"} itemSize={84} itemCount={result.length} >{renderRow}</FixedSizeList>}
    </React.Fragment>
}
