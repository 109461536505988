import React from 'react';
import { useContext, useEffect, useState } from 'react';
import {GlobalContext} from '../../ui/withGlobalContext';

import {Button} from "@material-ui/core";


import meterTestingForm from '../../forms/meter-testing-form.svg';
import meterTestingItems from '../../forms/meter-testing-items.json'
import meterTestingPhotos from '../../forms/meter-testing-photos.json'

import nstForm from '../../forms/nst-form.svg';
import nstItems from '../../forms/nst-items.json';
import nstPhotos from '../../forms/nst-photos.json';

import ctTestingForm from '../../forms/ct-testing.svg';
import ctTestingItems from '../../forms/ct-testing.json';
import ctTestingPhotos from '../../forms/ct-testing-photos.json'

import jstForm from '../../forms/jst.svg'
import jstItems from '../../forms/jst.json'

import { SvgEllipse, SvgEllipseRender } from './items/ellipse';
import { SvgText, SvgTextRender } from './items/text';

import { Task } from '../../lib/class/Task';
import { Job } from '../../lib/class/Job';
import { Datastore } from '../../lib/Datastore';
import { VirtualFile } from '../../lib/class/VirtualFile';
import { downloadBlobFile } from '../../lib/download';
import { getMeterTestingValues } from './meter-testing-values';
import { getNstValues } from './nst-values';
import { getCtTestingValues } from './ct-testing-values';
import { getJstValues } from './jst';

type SvgItem = SvgText | SvgEllipse;


type PreppedFile = {title:string, pages:string[]};


export function FormView(props:any){

    const global = useContext(GlobalContext);
    const { theme, project, ui, ds } = global;


    const id = props.match.params.id;
    const job = ds.db.get(id) as Job;
    const site = job.getSite();

	const styles = {
		root:{
			paddingTop:64,
			paddingBottom:64
		},
        page:{
            position:"relative"
        },
        col:{
            display:'inline-block'
        },
        box:{
            display:'flex',
            userSelect:"none"
        }
	} as const

    const [svg,setSVG] = useState<PreppedFile[]|null>(null);
    
    async function genDoc(){
        const doc = await prepDoc(ds,job);
        setSVG(doc);
    }

    useEffect(() => {
        genDoc();
    },[]);

    async function downloadPDF(){
        if(svg != null){
            for(const f of svg)
            await downloadPdfFromFactory(ds,job,f);
        }
    }

  
	return (
        <div style={styles.page}  >
            <Button onClick={downloadPDF} color="primary" variant='outlined'>Download</Button>
            {svg != null && svg.map( (file,i) =>  file.pages.map( (page,j) =>  <div key={`${i}-${j}`} dangerouslySetInnerHTML={{__html:page}}></div>))}
        </div>            
    );
}


interface SvgItemRenderI{
    value:SvgItem;
    data:any;
}

function SvgItemRender(props:SvgItemRenderI){
    const {value, data} = props;
    if(data === null || data === undefined) return null;

    if(value.type === 'ellipse')
        return SvgEllipseRender({value:value, data:data});

    if(value.type === 'text')
        return SvgTextRender({value:value, data:data});

    return null;
}

function getFiles(ds:Datastore,  task:Task, photos:string[]):[string,VirtualFile][]{
        const items = photos.map(key => {
            const [type,item] = task.getData(key);
            if(item.valueID === null ) return undefined;
            const file = ds.db.get(item.valueID);
            const s:[string,string] = [key,file];
            return s;
        }).filter( i => i !== undefined) as unknown as [string,VirtualFile][];
        return items;
    }


    async function prepFirstPage(items:SvgItem[], valueLookups:any, image:string ){
        const textItems = items.filter(i => i.type === 'text');
        const ellipseItems = items.filter(i => i.type === 'ellipse');
        const blob = await fetch(image);
        let template = await blob.text();

        template = template.replace("</svg>","");

        const [texts, ellipses] = valueLookups;
        const renderedText = textItems.map((item,i) => SvgItemRender({value:item,data:texts[item.key] })).filter(v => v !== null).join("\n")
        const renderedEllipse =  ellipseItems.map((item,i) => SvgItemRender({value:item,data:ellipses[item.key]})).filter(v => v !== null).join("\n");

        template += renderedText;
        template += renderedEllipse;
        template += '</svg>';

        return template;
    }


    async function prepImages(ds:Datastore, task:Task, photos:string[]){
        const files = getFiles(ds, task, photos)
        const pages = [];

        for(const f of files){
            pages.push( await prepImagePage(ds,f[0],f[1]))
        }
        return pages;
    }

    export async function downloadPdfFromFactory(ds:Datastore, job:Job, file:PreppedFile){
        const site = job.getSite();
        
        const result = await ds.api.request("pdf-factory",{pages:file.pages});
        await downloadBlobFile(result,file.title);
    }

    async function prepImagePage(ds:Datastore, label:string, file:VirtualFile) {
        const image = await ds.getImageBase64(file);
        return `<svg aria-hidden="true" width="816" height="1056" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
            <text x="10" y="100" fontSize="14">${label}</text>
            <image x="0" y="0" width="100%" height="100%"  xlink:href="${image}" />
        </svg>`;
    }


    export async function prepDoc(ds:Datastore, job:Job):Promise<{title:string, pages:string[]}[]>{

        const jobType = job.getType();
        const task = job.getMainCompletedTask(); // wtf? how did aborts get through this
        const site = job.getSite();

        const files: {title:string, pages:string[]}[] = []
        
        
        switch(jobType.name){
            case "Asset Inspection":{
                const pages:string[] = [];
                pages.push( await prepFirstPage(nstItems as SvgItem[], getNstValues(ds,job,task,site), nstForm))
                pages.push(...await prepImages(ds,task, nstPhotos));
                files.push({title:`${site.nmi}.pdf`, pages})
                break;
            }
            case "Mondo Meter Testing":{
                const pages:string[] = [];
                pages.push( await prepFirstPage(meterTestingItems as SvgItem[], getMeterTestingValues(ds,job,task,site), meterTestingForm))
                pages.push(...await prepImages(ds,task, meterTestingPhotos));
                files.push({title:`${site.nmi}.pdf`, pages})
                break;
            }
            case "Mondo CT Testing":{
                const prelim = job.getPrelimTask()

                const prelimPages:string[] = [];
                const formPages:string[] = [];

                prelimPages.push( await prepFirstPage(jstItems as SvgItem[], getJstValues(ds,job,prelim,site, 'Prelim'), jstForm))
                files.push({title:`${site.nmi}-prelim.pdf`, pages:prelimPages});

                formPages.push( await prepFirstPage(jstItems as SvgItem[], getJstValues(ds,job,task,site, "CT Test"), jstForm))
                formPages.push( await prepFirstPage(ctTestingItems as SvgItem[], getCtTestingValues(ds,job,task,prelim,site), ctTestingForm))
                formPages.push(...await prepImages(ds,task, ctTestingPhotos));
                files.push({title:`${site.nmi}-test.pdf`, pages:formPages});

                break;
            }
            default:
                throw new Error("Could not find associated form");
        }

        
        return files;
    }




