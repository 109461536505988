
import { CollectionI } from "../../page/ListView/Filters";
import { Base } from "../class/Base";
import { Datastore } from "../Datastore";

type BenchmarkLookup = { [id: string]: Benchmarker };

class Benchmarker {
  parent: string;
  key: string;

  time: number;
  count: number;

  startTimer: number;

  constructor(parent: string, key: string) {
    this.parent = parent;
    this.key = key;
    this.time = 0;
    this.count = 0;
    this.startTimer = 0;
  }

  start() {
    this.startTimer = Date.now();
  }

  end() {
    const end = Date.now();

    this.time += end - this.startTimer;
    this.count++;
  }

  toString() {
    const t = Math.round(this.time / 1000);
    const avg = t / this.count;
    return `${this.key}: ${t}s ${this.count} ${avg}s`;
  }

  toArray() {
    const t = Math.round(this.time / 1000);
    const avg = t / this.count;
    return [this.parent, this.key, t, this.count, avg];
  }
}

export interface PGroupI {
  key: string;
  test: (item: any) => boolean;
  badge: boolean;
  warning: boolean;
}

type TemporalScratchPad = { [key: number]: [number, Base][] };
type TemporalResult = (number | Base)[];

function TemporalScratchPadToJSON(sp: TemporalScratchPad) {
  const obj: any = {};
  for (const key in sp) obj[key] = sp[key].map((v) => [v[0], v[1].id]);
  return obj;
}

function TemporalScratchPadFromJSON(
  json: any,
  ds: Datastore
): TemporalScratchPad {
  const sp: TemporalScratchPad = {};
  for (const key in json)
    sp[parseInt(key)] = json[key].map((v: any) => {
      if (Array.isArray(v)) {
        return [v[0], ds.db.get(v[1])];
      } else {
        const e = ds.db.get(v);
        return [0, e];
      }
    });

  return sp;
}

function TemporalResultToJSON(tr: TemporalResult) {
  const json = [];
  for (const e of tr) {
    if (typeof e == "number") json.push("" + e);
    else json.push(e.id);
  }
  return json;
}

function TemporalResultFromJSON(json: any, ds: Datastore) {
  const tr = [];
  for (const e of json) {
    if (typeof e == "string") tr.push(parseInt(e));
    else tr.push(ds.db.get(e));
  }
  return tr;
}

export class PGroup {
  key: string;
  test: (item: Base) => boolean;
  timeFn: (item: Base) => moment.Moment;
  badge: boolean;
  warning: boolean;
  temporal: boolean;
  raw: boolean;

  result: Base[];
  temporalScratchPad: TemporalScratchPad;
  temporalResult: TemporalResult;

  benchmark: BenchmarkLookup;

  constructor(
    props: PGroupI,
    timeFn: (item: Base) => moment.Moment,
    temporal: boolean,
    raw: boolean
  ) {
    this.key = props.key;
    this.timeFn = timeFn;
    this.test = props.test;
    this.badge = props.badge;
    this.warning = props.warning;
    this.temporal = temporal;
    this.raw = raw;

    this.result = [];
    this.temporalResult = [];
    this.temporalScratchPad = {};

    this.benchmark = {
      process: new Benchmarker(this.key, "process"),
      add: new Benchmarker(this.key, "add"),
      remove: new Benchmarker(this.key, "remove"),
      addTemporal: new Benchmarker(this.key, "addTemporal"),
      removeTemporal: new Benchmarker(this.key, "removeTemporal"),
      processTemporalSet: new Benchmarker(this.key, "processTemporalSet"),
    };
  }

  reset() {
    this.result = [];
    this.temporalResult = [];
    this.temporalScratchPad = {};
  }

  process(
    item: Base,
    skipProcess = false,
    removeDeleted = false,
    skipTemporalRemove = false
  ) {
    this.benchmark["process"].start();
    if (
      (!removeDeleted || (removeDeleted && !item.isDeleted())) &&
      this.test(item)
    )
      this.add(item, skipProcess, skipTemporalRemove);
    else this.remove(item, skipProcess);
    this.benchmark["process"].end();
  }

  private add(
    item: Base,
    skipProcess = false,
    skipTemporalRemove = false
  ) {
    this.benchmark["add"].start();

    let found = false;
    for (const e of this.result) {
      if (e.id == item.id) {
        found = true;
        break; // return; // already exists // --- returning will not process temporal data (which can cause issues on unprocessed edge conditions)
      }
    }

    if (!found) this.result.push(item);

    this.benchmark["add"].end();

    if (this.temporal) this.addTemporal(item, skipProcess, skipTemporalRemove);
  }

  private remove(item: Base, skipProcess = false) {
    this.benchmark["remove"].start();

    for (let i = 0; i < this.result.length; i++)
      if (this.result[i].id == item.id) {
        this.result.splice(i, 1);
        if (this.temporal) this.removeTemporal(item, skipProcess);
        break;
      }

    this.benchmark["remove"].end();
  }

  private addTemporal(
    item: Base,
    skipProcess = false,
    skipTemporalRemove = false
  ) {
    this.benchmark["addTemporal"].start();
    if (!skipTemporalRemove) {
      this.removeTemporal(item, true); // remove existing
      //console.log("temporal remove")
    }

    const timestamp = this.timeFn(item);
    const date = timestamp.startOf("day");
    const index = date.isValid() ? date.valueOf() : 0;

    if (typeof this.temporalScratchPad[index] == "undefined")
      this.temporalScratchPad[index] = [];

    this.temporalScratchPad[index].push([timestamp.valueOf(), item]);

    this.benchmark["addTemporal"].end();

    if (!skipProcess) this.processTemporalSet();
  }

  private removeTemporal(item: Base, skipProcess = false) {
    this.benchmark["removeTemporal"].start();
    const timestamp = this.timeFn(item);
    const date = timestamp.startOf("day");
    const index = date.isValid() ? date.valueOf() : 0;

    //if(typeof this.temporalScratchPad[index] == "undefined"){
    //  return; // date does not exist so just drop out (this should actually never happen)

    let found = false;
    const items = this.temporalScratchPad[index];
    if (typeof items != "undefined") {
      for (let i = 0; i < items.length; i++) {
        if (items[i][1].id == item.id) {
          items.splice(i, 1);
          found = true;
          if (items.length == 0) delete this.temporalScratchPad[index]; // remove date
          break;
        }
      }
    }

    if (!found) {
      //date must of changed, search through all buckets
      for (const index in this.temporalScratchPad) {
        //console.log(index);
        const items = this.temporalScratchPad[index];
        for (let i = 0; i < items.length; i++) {
          if (items[i][1].id == item.id) {
            items.splice(i, 1);
            found = true;
            if (items.length == 0) delete this.temporalScratchPad[index]; // remove date
            break;
          }
        }
        if (found) break;
      }
    }

    this.benchmark["removeTemporal"].end();


    if (!skipProcess) this.processTemporalSet();
  }

  processTemporalSet() {
    this.benchmark["processTemporalSet"].start();

    for (const index in this.temporalScratchPad){
      this.temporalScratchPad[index].sort((a: any, b: any) => b[0] - a[0]); // this.timeFn(a).valueOf() - this.timeFn(b).valueOf() );
    }

    const dates = Object.keys(this.temporalScratchPad)
      .map((k) => parseInt(k))
      .sort((a, b) => b - a);


      if(this.key === "Allz")
        console.log("Task dates",dates);

    const result: TemporalResult = [];

    for (const date of dates) {
      result.push(date);
      const items = this.temporalScratchPad[date];
      for (const item of items) result.push(item[1]);
    }

    console.log(this.key);
    if(this.key === "Allz")
      console.log("Task results",result);

    //console.log("temporal set",result)
    this.temporalResult = result;
    this.benchmark["processTemporalSet"].end();
  }

  getGroup(): CollectionI {
    return {
      label: this.key,
      data: this.result,
      badge: this.badge,
      warning: this.warning,
      temporal: this.temporalResult,
    };
  }

  toJSON() {
    if (this.raw) {
      return {
        key: this.key,
        result: [],
        temporalScratchPad: {},
        temporalResult: [],
      };
    } else {
      return {
        key: this.key,
        result: this.result.map((b) => b.id),
        temporalScratchPad: TemporalScratchPadToJSON(this.temporalScratchPad),
        temporalResult: TemporalResultToJSON(this.temporalResult),
      };
    }
  }

  fromJSON(json: any, ds: Datastore) {
    this.result = json.result.map((e: number) => ds.db.get(e));
    this.temporalScratchPad = TemporalScratchPadFromJSON(
      json.temporalScratchPad,
      ds
    );
    this.temporalResult = TemporalResultFromJSON(json.temporalResult, ds);
  }

  getBenchmarkTable() {
    return Object.values(this.benchmark).map((t) => t.toArray());
  }
}
