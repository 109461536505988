import moment, { Moment } from "moment";
import { Datom } from "../../lib/class/Datom";
import { Job } from "../../lib/class/Job";
import { Site } from "../../lib/class/Site";
import { Task, TaskStatus } from "../../lib/class/Task";
import { Datastore } from "../../lib/Datastore";
import { splitComments } from "./split-comments";



export function getCtTestingValues(ds:Datastore, job:Job, task:Task, prelim:Task, site:Site){

    const tech = task.getTech();
    const startTimestamp = (task.status == TaskStatus.Successful) ? task.getStartTimestamp() : task.getScheduleTimestamp();
    const endTimestamp =  (task.status == TaskStatus.Successful) ? task.getEndTimestamp()  : task.getScheduleTimestamp();
    const startTime = startTimestamp.format("HH:mm")
    const endTime = endTimestamp.format("HH:mm")
    const date = endTimestamp.format("DD/MM/YY");
    
    const meter:Datom = job.getJobDataItemValueString('Meter Model');
    const meterAmend = task.getDataString('Meter Model Amend');
    const brand = meter.getParent();


    const prelimDate = prelim.getStartTimestamp().format("DD/MM/YY HH:mm");
    const testDate = task.getStartTimestamp().format("DD/MM/YY HH:mm");
    const prelimTech = prelim.getTech();

    const notificationPeriod = `${job.getStartDate().format("DD/MM/YY")} - ${job.getDueDate().format("DD/MM/YY")}`;

    const filename = `${site.nmi}.txt`;

    let testUnitSerial  = "";
    let testUnitNextCalibrationDate:Moment|null = null; // = dayjs();;
    let testUnitCalibrationDate:Moment|null = null;
    let testModel = "";
    let testNata = "";
    let calibrated = false;


    let status = '';

     if(task.status == TaskStatus.Successful){
        try{

            const testUnit = task.getTestUnit();
            console.log('testUnit',testUnit);
            testUnitSerial  = testUnit.serialNumber
            testUnitNextCalibrationDate = moment(testUnit.nextCalibrationDate) //.format("DD/MM/YY");
            testUnitCalibrationDate = moment(testUnit.lastCalibrationDate) //.format("DD/MM/YY");
            testModel = testUnit.model;
            testNata = testUnit.nataReference

            calibrated = moment().isBefore(testUnitNextCalibrationDate)
        }catch(E){
            console.error('test unit?',E);

        }
     }else{
        console.log('not succ',task.status)
     }

     console.log(task.status,TaskStatus.Cancelled)

     if(task.status == TaskStatus.Aborted){
        status = "Aborted";
     }

     if(task.status == TaskStatus.Cancelled){
        status = "Cancelled";
     }

     console.log(status);



    const texts = {
    "Status":[status],
    "Job ID":[job.getJobDataItemString("Record ID")],
    "NMI": [site.nmi, site.nmiAmend],
    "Date":[date],
    "Time":[startTime],
    "Notification Period":[notificationPeriod],
    "Site Name":[site.customerName,site.customerNameAmend],
    "Life Support":[site.lifeSupport ? "YES" : "NO"],
    "Address":[site.address, site.addressAmend],
    "Meter Location":[site.meterBoxLocation, site.meterBoxLocationAmend],
    "Phone":[site.customerPhone, site.customerPhoneAmend],
    "Retailer":[],
    "MPB":[],
    "MDP":[],
    "LNSP":[],
    "Meter":[job.getJobDataItemString("Meter Number"),job.getJobDataItemString("Meter Number Amend")],
    "Meter Make":[brand.toString()],
    "Meter Type":[meter.toString(), meterAmend.toString()],
    "Program":[],
    "CT Ratio":[job.getJobDataItemValueString("CT Ratio").toString(),task.getDataString("CT Ratio Amend").toString()],
    "VT Ratio":[],
    "Rating":[job.getJobDataItemValueString("Meter Current Rating").toString(),task.getDataString("Meter Current Rating Amend").toString()],
    "CT Make":[task.getJobDataItemValueString("CT Brand").toString()],
    "CT Type":[task.getJobDataItemValueString("CT Type").toString()],
    "Class":[task.getJobDataItemValueString("CT Class").toString()],
    "Ratio VA":[task.getJobDataItemValueString("CT Burden VA").toString()],
    "Design Standard":[task.getJobDataItemValueString("CT Design Standard").toString()],
    "Preliminary Technician":[prelimTech.toString()],
    "CT Test Technician":[tech.toString()],
    "Prelim Date":[prelimDate],
    "CT Test Date":[testDate],
    "Red Serial":[job.getJobDataItemValueString("CT Red Phase Serial Number").toString() , task.getJobDataItemValueString("CT Red Phase Serial Number Amend").toString()],
    "White Serial":[job.getJobDataItemValueString("CT White Phase Serial Number").toString(), task.getJobDataItemValueString("CT White Phase Serial Number Amend").toString()],
    "Blue Serial":[job.getJobDataItemValueString("CT Blue Phase Serial Number").toString(), task.getJobDataItemValueString("CT Blue Phase Serial Number Amend").toString()],
    "Red Tapping 1":[task.getJobDataItemValueString("CT Red Phase Tapping 1 Ratio").toString()],
    "White Tapping 1":[task.getJobDataItemValueString("CT White Phase Tapping 1 Ratio").toString()],
    "Blue Tapping 1":[task.getJobDataItemValueString("CT Blue Phase Tapping 1 Ratio").toString()],
    "Red 1 Rec":[task.getJobDataItemValueString("CT Red Phase Tapping 1 Record Number").toString()],
    "White 1 Rec":[task.getJobDataItemValueString("CT White Phase Tapping 1 Record Number").toString()],
    "Blue 1 Rec":[task.getJobDataItemValueString("CT Blue Phase Tapping 1 Record Number").toString()],
    "Red Tapping 2":[task.getJobDataItemValueString("CT Red Phase Tapping 2 Ratio").toString()],
    "White Tapping 2":[task.getJobDataItemValueString("CT White Phase Tapping 2 Ratio").toString()],
    "Blue Tapping 2":[task.getJobDataItemValueString("CT Blue Phase Tapping 2 Ratio").toString()],
    "Red 2 Rec":[task.getJobDataItemValueString("CT Red Phase Tapping 2 Record Number").toString()],
    "White 2 Rec":[task.getJobDataItemValueString("CT White Phase Tapping 2 Record Number").toString()],
    "Blue 2 Rec":[task.getJobDataItemValueString("CT Blue Phase Tapping 2 Record Number").toString()],
    "Red Tapping 3":[task.getJobDataItemValueString("CT Red Phase Tapping 3 Ratio").toString()],
    "White Tapping 3":[task.getJobDataItemValueString("CT White Phase Tapping 3 Ratio").toString()],
    "Blue Tapping 3":[task.getJobDataItemValueString("CT Blue Phase Tapping 3 Ratio").toString()],
    "Red 3 Rec":[task.getJobDataItemValueString("CT Red Phase Tapping 3 Record Number").toString()],
    "White 3 Rec":[task.getJobDataItemValueString("CT White Phase Tapping 3 Record Number").toString()],
    "Blue 3 Rec":[task.getJobDataItemValueString("CT Blue Phase Tapping 3 Record Number").toString()],
    "Comments":[task.getJobDataItemValueString("Comments").toString()],
    "Instrument":[testModel],
    "Serial Number":[testUnitSerial],
    "Calibration Test":[testUnitCalibrationDate ? testUnitCalibrationDate.format("DD/MM/YY") : ""],
    "NATA Ref":[testNata],
    "Next Test Due Date":[testUnitNextCalibrationDate ? testUnitNextCalibrationDate.format("DD/MM/YY"): ""],
    "Filename":[filename]
    };



    const ellipses = {
        "Calibration TRUE": calibrated,
        "Calibration FALSE": !calibrated
    }



return [texts, ellipses];

}

